import {
  GET_ALLGRN_REQUEST,
  GET_ALLGRN_SUCCESS,
  GET_ALLGRN_FAILURE,
  GET_LINEITEMSBYGRN_REQUEST,
  GET_LINEITEMSBYGRN_SUCCESS,
  GET_LINEITEMSBYGRN_FAILURE,
  GET_ALLGRN_DATA_FOR_DOWNLOAD_REQUEST,
  GET_ALLGRN_DATA_FOR_DOWNLOAD_SUCCESS,
  GET_ALLGRN_DATA_FOR_DOWNLOAD_FAILURE,
  UPLOADCSV_FOR_GRN_REQUEST,
  UPLOADCSV_FOR_GRN_SUCCESS,
  UPLOADCSV_FOR_GRN_FAILURE,
  SET_PACKSIZE_REQUEST,
  SET_PACKSIZE_SUCCESS,
  SET_PACKSIZE_FAILURE,
} from "./grnTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getAllGRNRequest = () => {
  return {
    type: GET_ALLGRN_REQUEST,
  };
};

const getAllGRNSuccess = (payload) => {
  return {
    type: GET_ALLGRN_SUCCESS,
    payload: payload,
  };
};

const getAllGRNFailure = (error) => {
  return {
    type: GET_ALLGRN_FAILURE,
    payload: error,
  };
};

const getAllGRN = (materialCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAllGRNRequest());

    let url;

    if (materialCode !== "") {
      url = `${baseUrl}/grn/get_by_grn?material=${materialCode}`;
    } else {
      url = `${baseUrl}/grn/get`;
    }

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAllGRNSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAllGRNFailure(err.response));
      });
  };
};

const getLineItemsByGrnRequest = () => {
  return {
    type: GET_LINEITEMSBYGRN_REQUEST,
  };
};

const getLineItemsByGrnSuccess = (payload) => {
  return {
    type: GET_LINEITEMSBYGRN_SUCCESS,
    payload: payload,
  };
};

const getLineItemsByGrnFailure = (error) => {
  return {
    type: GET_LINEITEMSBYGRN_FAILURE,
    payload: error,
  };
};

const getLineItemsByGrn = (grn, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getLineItemsByGrnRequest());
    let url = `${baseUrl}/grn/get_by_grn?grnNumber=${grn}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLineItemsByGrnSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLineItemsByGrnFailure(err.response));
      });
  };
};

const getAllGRNDataForDownloadRequest = () => {
  return {
    type: GET_ALLGRN_DATA_FOR_DOWNLOAD_REQUEST,
  };
};

const getAllGRNDataForDownloadSuccess = (payload) => {
  return {
    type: GET_ALLGRN_DATA_FOR_DOWNLOAD_SUCCESS,
    payload: payload,
  };
};

const getAllGRNDataForDownloadFailure = (error) => {
  return {
    type: GET_ALLGRN_DATA_FOR_DOWNLOAD_FAILURE,
    payload: error,
  };
};

const getAllGRNDataForDownload = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAllGRNDataForDownloadRequest());
    // let url = `${baseUrl}/grn`;
    let url = `${baseUrl}/download_grn_data`;
    // let url = `${baseUrl}/v2/grn?page_number=3&rows_per_page=5&search=`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAllGRNDataForDownloadSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAllGRNDataForDownloadFailure(err.message));
      });
  };
};

const uploadCsvForGrnRequest = () => {
  return {
    type: UPLOADCSV_FOR_GRN_REQUEST,
  };
};

const uploadCsvForGrnSuccess = (payload) => {
  return {
    type: UPLOADCSV_FOR_GRN_SUCCESS,
    payload: payload,
  };
};

const uploadCsvForGrnFailure = (error) => {
  return {
    type: UPLOADCSV_FOR_GRN_FAILURE,
    payload: error,
  };
};

const uploadCsvForGrn = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadCsvForGrnRequest());
    let url = `${baseUrl}/grn/upload_csv`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadCsvForGrnSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadCsvForGrnFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const setPackSizeRequest = () => {
  return {
    type: SET_PACKSIZE_REQUEST,
  };
};

const setPackSizeSuccess = (payload) => {
  return {
    type: SET_PACKSIZE_SUCCESS,
    payload: payload,
  };
};

const setPackSizeFailure = (error) => {
  return {
    type: SET_PACKSIZE_FAILURE,
    payload: error,
  };
};

const setPackSize = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(setPackSizeRequest());
    let url = `${baseUrl}/pack_size/update`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(setPackSizeSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(setPackSizeFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getAllGRN,
  getLineItemsByGrn,
  getAllGRNDataForDownload,
  uploadCsvForGrn,
  setPackSize,
};
