/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_MODULES_REQUEST,
  GET_MODULES_SUCCESS,
  GET_MODULES_FAILURE,
  GET_SUB_MODULES_FAILURE,
  GET_SUB_MODULES_SUCCESS,
  GET_SUB_MODULES_REQUEST,
  GET_MODULES_FOR_VIEW_REQUEST,
  GET_MODULES_FOR_VIEW_SUCCESS,
  GET_MODULES_FOR_VIEW_FAILURE,
  GET_MODULES_FOR_EDIT_REQUEST,
  GET_MODULES_FOR_EDIT_SUCCESS,
  GET_MODULES_FOR_EDIT_FAILURE,
  GET_SUB_MODULES_FOR_VIEW_REQUEST,
  GET_SUB_MODULES_FOR_VIEW_SUCCESS,
  GET_SUB_MODULES_FOR_VIEW_FAILURE,
  GET_SUB_MODULES_FOR_CREATE_ROLE_FAILURE,
  GET_SUB_MODULES_FOR_CREATE_ROLE_SUCCESS,
  GET_SUB_MODULES_FOR_CREATE_ROLE_REQUEST,
} from "./modulesTypes";

import axios from "axios";
// import Config from "../../../config/index";
import Config from "../../config/index";
let userManagementUrl = Config.userManagementUrl;

const getModulesRequest = () => {
  return {
    type: GET_MODULES_REQUEST,
  };
};

const getModuleSuccess = (payload) => {
  return {
    type: GET_MODULES_SUCCESS,
    payload: payload,
  };
};

const getModuleFaliure = (error) => {
  return {
    type: GET_MODULES_FAILURE,
    payload: error,
  };
};

const getModules = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getModulesRequest());
    let url = `${userManagementUrl}/sub-module`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getModuleSuccess(response.data));
        return response.data;
      })
      .catch((err) => {
        dispatch(getModuleFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getSubModuleRequest = () => {
  return {
    type: GET_SUB_MODULES_REQUEST,
  };
};

const getSubModuleSuccess = (payload) => {
  return {
    type: GET_SUB_MODULES_SUCCESS,
    payload: payload,
  };
};

const getSubModuleFaliure = (error) => {
  return {
    type: GET_SUB_MODULES_FAILURE,
    payload: error,
  };
};

const getSubModule = (jwtToken, roleId, moduleId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSubModuleRequest());
    let url = `${userManagementUrl}/roles/get_access_type_for_edit?role_id=${roleId}&module_id=${moduleId}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSubModuleSuccess(response.data));
        return response.data;
      })
      .catch((err) => {
        dispatch(getSubModuleFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getSubModuleForCreateRoleRequest = () => {
  return {
    type: GET_SUB_MODULES_FOR_CREATE_ROLE_REQUEST,
  };
};

const getSubModuleForCreateRoleSuccess = (payload) => {
  return {
    type: GET_SUB_MODULES_FOR_CREATE_ROLE_SUCCESS,
    payload: payload,
  };
};

const getSubModuleForCreateRoleFaliure = (error) => {
  return {
    type: GET_SUB_MODULES_FOR_CREATE_ROLE_FAILURE,
    payload: error,
  };
};

const getSubModuleForCreateRole = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSubModuleForCreateRoleRequest());
    let url = `${userManagementUrl}/roles/get_actions_for_role_creation`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSubModuleForCreateRoleSuccess(response.data));
        return response.data;
      })
      .catch((err) => {
        dispatch(getSubModuleForCreateRoleFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getModuleForViewRequest = () => {
  return {
    type: GET_MODULES_FOR_VIEW_REQUEST,
  };
};

const getModuleForViewSuccess = (payload) => {
  return {
    type: GET_MODULES_FOR_VIEW_SUCCESS,
    payload: payload,
  };
};

const getModuleForViewFaliure = (error) => {
  return {
    type: GET_MODULES_FOR_VIEW_FAILURE,
    payload: error,
  };
};

const getModuleForView = (jwtToken, roleId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getModuleForViewRequest());
    let url = `${userManagementUrl}/roles/get_accesses_by_role_for_view?role_id=${roleId}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getModuleForViewSuccess(response.data));
        return response.data;
      })
      .catch((err) => {
        dispatch(getModuleForViewFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getModuleForEditRequest = () => {
  return {
    type: GET_MODULES_FOR_EDIT_REQUEST,
  };
};

const getModuleForEditSuccess = (payload) => {
  return {
    type: GET_MODULES_FOR_EDIT_SUCCESS,
    payload: payload,
  };
};

const getModuleForEditFaliure = (error) => {
  return {
    type: GET_MODULES_FOR_EDIT_FAILURE,
    payload: error,
  };
};

const getModuleForEdit = (jwtToken, roleId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getModuleForEditRequest());
    let url = `${userManagementUrl}/roles/get_actions_for_edit_role?role_id=${roleId}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getModuleForEditSuccess(response.data));
        return response.data;
      })
      .catch((err) => {
        dispatch(getModuleForEditFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getSubModuleForViewRequest = () => {
  return {
    type: GET_SUB_MODULES_FOR_VIEW_REQUEST,
  };
};

const getSubModuleForViewSuccess = (payload) => {
  return {
    type: GET_SUB_MODULES_FOR_VIEW_SUCCESS,
    payload: payload,
  };
};

const getSubModuleForViewFaliure = (error) => {
  return {
    type: GET_SUB_MODULES_FOR_VIEW_FAILURE,
    payload: error,
  };
};

const getSubModuleForView = (jwtToken, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSubModuleForViewRequest());
    let url = `${userManagementUrl}/roles/get_access_type_for_view?role_id=${payload.roleId}&module_id=${payload.moduleId}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSubModuleForViewSuccess(response.data));
        return response.data;
      })
      .catch((err) => {
        dispatch(getSubModuleForViewFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export {
  getModules,
  getSubModule,
  getSubModuleForView,
  getModuleForView,
  getSubModuleForCreateRole,
  getModuleForEdit
};
