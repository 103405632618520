export const GET_PIVFILLING_REQUEST = "GET_PIVFILLING_REQUEST";
export const GET_PIVFILLING_SUCCESS = "GET_PIVFILLING_SUCCESS";
export const GET_PIVFILLING_FAILURE = "GET_PIVFILLING_FAILURE";

export const GET_RELEASEDPIV_REQUEST = "GET_RELEASEDPIV_REQUEST";
export const GET_RELEASEDPIV_SUCCESS = "GET_RELEASEDPIV_SUCCESS";
export const GET_RELEASEDPIV_FAILURE = "GET_RELEASEDPIV_FAILURE";

export const GET_ASSETS_BY_DOCUMENTNO_REQUEST =
  "GET_ASSETS_BY_DOCUMENTNO_REQUEST";
export const GET_ASSETS_BY_DOCUMENTNO_SUCCESS =
  "GET_ASSETS_BY_DOCUMENTNO_SUCCESS";
export const GET_ASSETS_BY_DOCUMENTNO_FAILURE =
  "GET_ASSETS_BY_DOCUMENTNO_FAILURE";

export const REPRINT_ASSET_REQUEST = "REPRINT_ASSET_REQUEST";
export const REPRINT_ASSET_SUCCESS = "REPRINT_ASSET_SUCCESS";
export const REPRINT_ASSET_FAILURE = "REPRINT_ASSET_FAILURE";

export const REPRINT_ALL_ASSET_REQUEST = "REPRINT_ALL_ASSET_REQUEST";
export const REPRINT_ALL_ASSET_SUCCESS = "REPRINT_ALL_ASSET_SUCCESS";
export const REPRINT_ALL_ASSET_FAILURE = "REPRINT_ALL_ASSET_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
