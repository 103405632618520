/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { useFormik } from "formik";
import TextError from "utils/TextError";
import * as Yup from "yup";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Alert,
  Collapse,
  Icon,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

import { addMinutes, format } from "date-fns";

// Functions from store
import {
  getSubModulesByRoleId,
  getProductionAllocation,
  getUsersToAssign,
  getLocationsForProductionAllocation,
  updateFromLocationProductionAllocation,
  releaseToProductionProcess,
} from "../../store";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

// Utils component
import Config from "../../config/index";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForAllocateUser = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const ProductionAllocation = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getProductionAllocation,
  allocation,
  getUsersToAssign,
  listOfUsers,
  getLocationsForProductionAllocation,
  listOfLocations,
  updateFromLocationProductionAllocation,
  releaseToProductionProcess,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let productionAllocationModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "15e460a1-fa35-49c8-9c06-3f4081c61cb3"
  );

  let viewProductionAllocationAccess = productionAllocationModule
    ? productionAllocationModule.actionId.includes(
        "d172c83f-2c49-44f2-be62-c37a001578c7"
      )
    : null;

  let addToAllocateAccess = productionAllocationModule
    ? productionAllocationModule.actionId.includes(
        "90c0ff9a-b121-4ebd-b594-ccafda2e4002"
      )
    : null;

  let updateLocationAccess = productionAllocationModule
    ? productionAllocationModule.actionId.includes(
        "485f0ab1-2333-4d9a-a868-5c394f507b14"
      )
    : null;

  const [paginationObj, setPaginationObj] = useState({});
  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [loading, setLoading] = useState(true);
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getProductionAllocation(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [materialCode, setMaterialCode] = useState("");

  const [usersOptions, setUsersOptions] = useState([]);

  useEffect(() => {
    let tempUsersOptions = [];
    let data = listOfUsers.usersToAssign.data
      ? listOfUsers.usersToAssign.data
      : [];

    data.map((user) => {
      tempUsersOptions.push({
        label: user.userName,
        value: user.userId,
      });
    });
    setUsersOptions(tempUsersOptions);
  }, [listOfUsers]);

  const [locationOptions, setLocationOptionsOptions] = useState([]);

  useEffect(() => {
    let tempLocationsOptions = [];
    let data = listOfLocations.locations.data
      ? listOfLocations.locations.data
      : [];

    data.map((location) => {
      tempLocationsOptions.push({
        label: `${location.storingLocation} / ${location.warehouseName}`,
        value: location.storingLocation,
        warehouseType: location.warehouseType,
      });
    });
    setLocationOptionsOptions(tempLocationsOptions);
  }, [listOfLocations]);

  const statusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Not Picked",
      value: 1,
    },
    {
      label: "Picked",
      value: 2,
    },
    {
      label: "Kitted",
      value: 3,
    },
    {
      label: "Delivered",
      value: 4,
    },
  ];

  const [state, setState] = useState({
    columns: [
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Batch No / Qty", accessor: "batchNumber" },
      { Header: "Phase No", accessor: "phaseNumber" },
      { Header: "Phase Des", accessor: "phaseDescription" },
      { Header: "Pick List ID", accessor: "pickupListCode" },
      { Header: "Resource Category", accessor: "resourceCategory" },
      { Header: "Resource", accessor: "resource" },
      { Header: "Bulk Code / Des", accessor: "finalMaterialCode" },
      { Header: "Item Code / Des", accessor: "materialCode" },
      { Header: "Item Qty", accessor: "qty" },
      { Header: "From Loc", accessor: "source" },
      { Header: "To Loc", accessor: "destination" },
      {
        Header: "Start Time",
        accessor: "pickupStartTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "End TIme",
        accessor: "pickupEndTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        searchType: "select",
        selectOptions: statusOptions,
        placeholder: "All Status",
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [singlePivData, setSinglePivData] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  useEffect(() => {
    let tempProductionAllocation = [];

    let data = allocation.allocation.data ? allocation.allocation.data : [];

    setErrorMsgFromApi(allocation?.error?.data?.msg);
    setLoading(allocation.loading);
    setPaginationObj(allocation?.allocation?.pagination);

    data.map((piv, index) => {
      let bulkDes = piv?.finalMaterialDescription;
      let itemDes = piv.materialDescription;
      let phaseDes = piv.phaseDescription;
      let len = desLength;

      const pivObject = {
        privateKey: piv.pickupListCode,
        documentNo: piv.documentNo,
        batchNumber: `${piv.batchNumber} / ${piv.batchSize} ${piv.batchUom}`,
        finalMaterialCode: `${piv.finalMaterialCode} / ${
          bulkDes ? bulkDes.substring(0, len) : ""
        }`,
        materialCode: `${piv.materialCode} / ${
          itemDes ? itemDes.substring(0, len) : ""
        }`,
        resourceCategory: piv.resourceCategory,
        resource: piv.resource,
        phaseNumber: piv.phaseNumber,
        pickupListCode: piv.pickupListCode,
        phaseDescription: phaseDes ? phaseDes.substring(0, len) : "",
        qty: `${piv.qty} ${piv.uom}`,
        source: (
          <span style={{ display: "flex" }}>
            <MDTypography
              variant="h6"
              fontWeight="regular"
              style={{ marginTop: "0.45rem" }}
            >
              {piv.source}
            </MDTypography>
            {piv.status === 1 ? (
              <Tooltip title="Edit Location" style={{ marginLeft: "0.75rem" }}>
                <MDButton
                  disabled={!updateLocationAccess}
                  color="info"
                  variant="gradient"
                  type="button"
                  iconOnly
                  size="small"
                  onClick={() => {
                    setSinglePivData(piv);
                    setMaterialCode(piv.materialCode);
                    getLocationsForProductionAllocation(
                      piv.materialCode,
                      jwtToken
                    );
                    handleOpenEditFromLocModal();
                  }}
                >
                  <Icon> edit </Icon>
                </MDButton>
              </Tooltip>
            ) : (
              <Tooltip title="Edit Location" style={{ marginLeft: "0.75rem" }}>
                <MDButton
                  disabled
                  color="info"
                  variant="gradient"
                  type="button"
                  iconOnly
                  size="small"
                  onClick={() => {
                    setSinglePivData(piv);
                    handleOpenEditFromLocModal();
                  }}
                >
                  <Icon> edit </Icon>
                </MDButton>
              </Tooltip>
            )}
          </span>
        ),
        destination: piv.destination,
        pickupStartTime: piv.pickupStartTime,
        pickupEndTime: piv.pickupEndTime,
        status: (
          <button
            className={
              (piv.status === 1 && `status-error`) ||
              (piv.status === 2 && `status-warning`) ||
              (piv.status === 3 && `status-info`) ||
              (piv.status === 4 && `status-success`)
            }
            style={{ width: "5.5rem" }}
          >
            {(piv.status === 1 && `Not Picked`) ||
              (piv.status === 2 && `Picked`) ||
              (piv.status === 3 && `Kitted`) ||
              (piv.status === 4 && `Delivered`)}
          </button>
        ),
        original: piv,
        disableSelection:
          piv.source === "NA" || piv.source === "" || piv.source === null
            ? true
            : false,
      };

      tempProductionAllocation.push(pivObject);
    });

    viewProductionAllocationAccess &&
      setState({ ...state, rows: tempProductionAllocation });
  }, [allocation]);

  const [openEditFromLocModal, setOpenEditFromLocModal] = useState(false);

  const handleOpenEditFromLocModal = () => {
    setOpenEditFromLocModal(true);
  };

  const handleCloseEditFromLocModal = () => {
    setOpenEditFromLocModal(false);
    setFromLocSelected("");
  };

  const [fromLocSelected, setFromLocSelected] = useState("");

  const handleFromLocChange = (selectedFromLoc) => {
    setFromLocSelected(selectedFromLoc);
  };

  const [isErrorEditFromLoc, setIsErrorEditFromLoc] = useState(false);
  const [isSuccessEditFromLoc, setIsSuccessEditFromLoc] = useState(false);

  useEffect(() => {
    if (isErrorEditFromLoc) {
      setTimeout(() => {
        setIsErrorEditFromLoc(false);
      }, 3000);
    }
  }, [isErrorEditFromLoc]);

  useEffect(() => {
    if (isSuccessEditFromLoc) {
      setTimeout(() => {
        setIsSuccessEditFromLoc(false);
      }, 3000);
    }
  }, [isSuccessEditFromLoc]);

  const initialValueForEditFromLoc = {
    fromLoc: "",
  };

  const validationSchemaEditFromLoc = Yup.object({
    fromLoc: Yup.object().required("Select location"),
  });

  const [updateLocationErrorMsg, setUpdateLocationErrorMsg] = useState("");

  const onSubmitForEditFromLoc = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    const payload = {
      srNo: singlePivData.srNo,
      documentNo: singlePivData.documentNo,
      phaseNumber: singlePivData.phaseNumber,
      pickupListCode: singlePivData.pickupListCode,
      source: fromLocSelected.value,
      warehouseType: fromLocSelected.warehouseType,
    };

    let res = await updateFromLocationProductionAllocation(payload, jwtToken);

    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessEditFromLoc(true);
      setFromLocSelected("");
      getProductionAllocation(pageNo, rowsPerPage, searchObject, jwtToken);
      resetForm();

      setTimeout(() => {
        handleCloseEditFromLocModal();
        setButtonName("Submit");
      }, 2250);
    }
    if (!res.status) {
      setUpdateLocationErrorMsg(res.data.data.message);
      setIsErrorEditFromLoc(true);
      setButtonName("Submit");
    }
  };

  const formikForEditFromLoc = useFormik({
    initialValues: initialValueForEditFromLoc,
    validationSchema: validationSchemaEditFromLoc,
    onSubmit: onSubmitForEditFromLoc,
  });

  const [disableButton, setDisableButton] = useState(false);
  const [selectedPivData, setSelectedPivData] = useState([]);

  const pivDataSelectionHandler = (data) => {
    setSelectedPivData(data);
  };

  useEffect(() => {
    let value = selectedPivData.some(
      (data) =>
        data.fromLoc === "NA" || data.fromLoc === "" || data.fromLoc === null
    );
    setDisableButton(value);
  }, [selectedPivData]);

  const [openAllocateModal, setOpenAllocateModal] = useState(false);

  const handleOpenAllocateModal = () => {
    getUsersToAssign(jwtToken);
    setOpenAllocateModal(true);
  };
  const handleCloseAllocateModal = () => {
    setAllocateUserSelected("");
    setOpenAllocateModal(false);
  };

  const [allocateUserSelected, setAllocateUserSelected] = useState("");
  const handleAllocateUserChange = (selectedallocateUser) => {
    setAllocateUserSelected(selectedallocateUser);
  };

  const [isErrorAllocateUser, setIsErrorAllocateUser] = useState(false);
  const [isSuccessAllocateUser, setIsSuccessAllocateUser] = useState(false);

  useEffect(() => {
    if (isErrorAllocateUser) {
      setTimeout(() => {
        setIsErrorAllocateUser(false);
      }, 3000);
    }
  }, [isErrorAllocateUser]);

  useEffect(() => {
    if (isSuccessAllocateUser) {
      setTimeout(() => {
        setIsSuccessAllocateUser(false);
      }, 3000);
    }
  }, [isSuccessAllocateUser]);

  const [buttonName, setButtonName] = useState("Submit");

  const initialValueForAllocateUser = {
    allocateUser: "",
  };

  const validationSchemaAllocateUser = Yup.object({
    allocateUser: Yup.object().required("Select User"),
  });

  const [releaseErrorMsg, setReleasseErrorMsg] = useState("");

  const onSubmitForAllocateUser = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let payload = [];

    selectedPivData.map((data) => {
      const tempPayload = {
        ...data.original,
        userId: allocateUserSelected.value,
      };

      payload.push(tempPayload);
    });

    let res = await releaseToProductionProcess(payload, jwtToken);

    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessAllocateUser(true);
      setAllocateUserSelected("");
      getProductionAllocation(pageNo, rowsPerPage, searchObject, jwtToken);
      resetForm();

      setTimeout(() => {
        handleCloseAllocateModal();
        setButtonName("Submit");
      }, 2250);
    }
    if (!res.status) {
      setReleasseErrorMsg(res.data.data.msg);
      setIsErrorAllocateUser(true);
      setButtonName("Submit");
    }
  };

  const formikForAllocateUser = useFormik({
    initialValues: initialValueForAllocateUser,
    validationSchema: validationSchemaAllocateUser,
    onSubmit: onSubmitForAllocateUser,
  });

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    canSearch={true}
                    selection={true}
                    serverSidePagination={true}
                    paginationObj={paginationObj}
                    pageNoValue={pageNo}
                    rowsPerPageValue={rowsPerPage}
                    pageNoProp={importPageNo}
                    rowsPerPageProp={importRowsPerPage}
                    searchProp={importSearch}
                    uncheckProp={uncheck}
                    errorMsg={errorMsgFromApi}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 30,
                      entries: [30, 50, 75, 100],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    options={{ draggable: false }}
                    selectionButtonClick={pivDataSelectionHandler}
                    selectionButtonComponent={
                      <span style={{ display: "flex" }}>
                        <Tooltip title="Add to Allocate">
                          <MDButton
                            disabled={!addToAllocateAccess || disableButton}
                            color="white"
                            type="button"
                            style={{
                              marginTop: "0.5rem",
                              color: "white",
                              border: "1px solid #0275d8",
                              background: "#0275d8",
                              // marginLeft: 5,
                            }}
                            onClick={() => {
                              handleOpenAllocateModal();
                            }}
                          >
                            Add to Allocate
                          </MDButton>
                        </Tooltip>
                        {disableButton && (
                          <MDTypography
                            color="error"
                            variant="h6"
                            style={{
                              textAlign: "center",
                              marginLeft: "1rem",
                              marginTop: "1rem",
                            }}
                          >
                            Remove records with "NA/null" source!
                          </MDTypography>
                        )}
                      </span>
                    }
                  />
                </MDBox>

                <Modal
                  open={openEditFromLocModal}
                  onClose={handleCloseEditFromLocModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Edit From Location
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseEditFromLocModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForEditFromLoc.handleSubmit}
                      >
                        <MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Select Source Location
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css"
                              placeholder="Select location..."
                              name="fromLoc"
                              value={fromLocSelected}
                              options={locationOptions}
                              onChange={(selectedOption) => {
                                handleFromLocChange(selectedOption);
                                formikForEditFromLoc.setFieldValue(
                                  "fromLoc",
                                  selectedOption
                                );
                              }}
                            />
                            {formikForEditFromLoc.errors.fromLoc && (
                              <TextError
                                msg={formikForEditFromLoc.errors.fromLoc}
                              />
                            )}
                          </MDBox>

                          <MDBox mt={2}>
                            <Collapse in={isSuccessEditFromLoc}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessEditFromLoc(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Location edited successfully!
                              </Alert>
                            </Collapse>
                            <Collapse in={isErrorEditFromLoc}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorEditFromLoc(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {/* Could not edit the Location! */}
                                {updateLocationErrorMsg}
                              </Alert>
                            </Collapse>
                          </MDBox>

                          <div className="mt-3">
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="info"
                              fullWidth
                              disabled={
                                !formikForEditFromLoc.isValid ||
                                buttonName === "Please wait..."
                              }
                            >
                              {buttonName}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>

                <Modal
                  open={openAllocateModal}
                  onClose={handleCloseAllocateModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForAllocateUser}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Allocate User
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseAllocateModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForAllocateUser.handleSubmit}
                      >
                        <MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Allocate User
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css"
                              placeholder="Select user..."
                              name="allocateUser"
                              value={allocateUserSelected}
                              options={usersOptions}
                              onChange={(selectedOption) => {
                                handleAllocateUserChange(selectedOption);
                                formikForAllocateUser.setFieldValue(
                                  "allocateUser",
                                  selectedOption
                                );
                              }}
                            />
                            {formikForAllocateUser.errors.allocateUser && (
                              <TextError
                                msg={formikForAllocateUser.errors.allocateUser}
                              />
                            )}
                          </MDBox>

                          <MDBox mt={2}>
                            <Collapse in={isSuccessAllocateUser}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessAllocateUser(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Allocate User successfully!
                              </Alert>
                            </Collapse>
                            <Collapse in={isErrorAllocateUser}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorAllocateUser(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {/* Could not Allocate User! */}
                                {releaseErrorMsg}
                              </Alert>
                            </Collapse>
                          </MDBox>

                          <div className="mt-3">
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="info"
                              fullWidth
                              disabled={
                                !formikForAllocateUser.isValid ||
                                buttonName === "Please wait..."
                              }
                            >
                              {buttonName}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {loading && (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    allocation: state.getProductionAllocationReducer,
    listOfUsers: state.getUsersToAssignReducer,
    listOfLocations: state.getLocationsForProductionAllocationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getProductionAllocation: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getProductionAllocation(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
    getUsersToAssign: (jwtToken) => dispatch(getUsersToAssign(jwtToken)),
    getLocationsForProductionAllocation: (materialCode, jwtToken) =>
      dispatch(getLocationsForProductionAllocation(materialCode, jwtToken)),
    updateFromLocationProductionAllocation: (payload, jwtToken) =>
      dispatch(updateFromLocationProductionAllocation(payload, jwtToken)),
    releaseToProductionProcess: (payload, jwtToken) =>
      dispatch(releaseToProductionProcess(payload, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionAllocation);
