import {
  GET_PIVFILLING_REQUEST,
  GET_PIVFILLING_FAILURE,
  GET_PIVFILLING_SUCCESS,
  GET_RELEASEDPIV_REQUEST,
  GET_RELEASEDPIV_SUCCESS,
  GET_RELEASEDPIV_FAILURE,
  GET_ASSETS_BY_DOCUMENTNO_REQUEST,
  GET_ASSETS_BY_DOCUMENTNO_SUCCESS,
  GET_ASSETS_BY_DOCUMENTNO_FAILURE,
} from "./pivFillingAndReleasedPivTypes";

const initialStateForPivFilling = {
  loading: false,
  pivFillingData: [],
  error: "",
};

const getPivFillingReducer = (state = initialStateForPivFilling, action) => {
  switch (action.type) {
    case GET_PIVFILLING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PIVFILLING_SUCCESS:
      return {
        loading: false,
        pivFillingData: action.payload,
        error: "",
      };
    case GET_PIVFILLING_FAILURE:
      return {
        loading: false,
        pivFillingData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForReleasedPiv = {
  loading: false,
  releasedPivData: [],
  error: "",
};

const getReleasedPivReducer = (state = initialStateForReleasedPiv, action) => {
  switch (action.type) {
    case GET_RELEASEDPIV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RELEASEDPIV_SUCCESS:
      return {
        loading: false,
        releasedPivData: action.payload,
        error: "",
      };
    case GET_RELEASEDPIV_FAILURE:
      return {
        loading: false,
        releasedPivData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAssetsByDocumentNo = {
  loading: false,
  assetsByDocumentNoData: [],
  error: "",
};

const getAssetsByDocumentNoReducer = (
  state = initialStateForAssetsByDocumentNo,
  action
) => {
  switch (action.type) {
    case GET_ASSETS_BY_DOCUMENTNO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ASSETS_BY_DOCUMENTNO_SUCCESS:
      return {
        loading: false,
        assetsByDocumentNoData: action.payload,
        error: "",
      };
    case GET_ASSETS_BY_DOCUMENTNO_FAILURE:
      return {
        loading: false,
        assetsByDocumentNoData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getPivFillingReducer,
  getReleasedPivReducer,
  getAssetsByDocumentNoReducer,
};
