export const GET_PHASEWISECHARGING_REQUEST = "GET_PHASEWISECHARGING_REQUEST";
export const GET_PHASEWISECHARGING_SUCCESS = "GET_PHASEWISECHARGING_SUCCESS";
export const GET_PHASEWISECHARGING_FAILURE = "GET_PHASEWISECHARGING_FAILURE";

export const DOWNLOAD_PHASEWISECHARGING_REQUEST =
  "DOWNLOAD_PHASEWISECHARGING_REQUEST";
export const DOWNLOAD_PHASEWISECHARGING_SUCCESS =
  "DOWNLOAD_PHASEWISECHARGING_SUCCESS";
export const DOWNLOAD_PHASEWISECHARGING_FAILURE =
  "DOWNLOAD_PHASEWISECHARGING_FAILURE";
