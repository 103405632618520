import {
  GET_RELEASE_PICKLIST_REQUEST,
  GET_RELEASE_PICKLIST_SUCCESS,
  GET_RELEASE_PICKLIST_FAILURE,
  CREATE_RELEASE_REQUEST,
  CREATE_RELEASE_SUCCESS,
  CREATE_RELEASE_FAILURE,
  REMOVE_RELEASEPICKLIST_REQUEST,
  REMOVE_RELEASEPICKLIST_SUCCESS,
  REMOVE_RELEASEPICKLIST_FAILURE,
  GET_USERS_TO_ASSIGN_REQUEST,
  GET_USERS_TO_ASSIGN_SUCCESS,
  GET_USERS_TO_ASSIGN_FAILURE,
} from "./releasePicklistTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getReleasePicklistRequest = () => {
  return {
    type: GET_RELEASE_PICKLIST_REQUEST,
  };
};

const getReleasePicklistSuccess = (payload) => {
  return {
    type: GET_RELEASE_PICKLIST_SUCCESS,
    payload: payload,
  };
};

const getReleasePicklistFailure = (error) => {
  return {
    type: GET_RELEASE_PICKLIST_FAILURE,
    payload: error,
  };
};

const getReleasePicklist = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getReleasePicklistRequest());
    let url = `${baseUrl}/added_to_rel_store_issu/get?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getReleasePicklistSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getReleasePicklistFailure(err.response));
      });
  };
};

const createReleaseRequest = () => {
  return {
    type: CREATE_RELEASE_REQUEST,
  };
};

const createReleaseSuccess = (payload) => {
  return {
    type: CREATE_RELEASE_SUCCESS,
    payload: payload,
  };
};

const createReleaseFailure = (error) => {
  return {
    type: CREATE_RELEASE_FAILURE,
    payload: error,
  };
};

const createRelease = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createReleaseRequest());
    let url = `${baseUrl}/pickuplist/assign_user/release_to_hhd`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(createReleaseSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createReleaseFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const removeReleasePicklistRequest = () => {
  return {
    type: REMOVE_RELEASEPICKLIST_REQUEST,
  };
};

const removeReleasePicklistSuccess = (payload) => {
  return {
    type: REMOVE_RELEASEPICKLIST_SUCCESS,
    payload: payload,
  };
};

const removeReleasePicklistFailure = (error) => {
  return {
    type: REMOVE_RELEASEPICKLIST_FAILURE,
    payload: error,
  };
};

const removeReleasePicklist = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(removeReleasePicklistRequest());
    let url = `${baseUrl}/st_issued_pickuplist/remove_from_release`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(removeReleasePicklistSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(removeReleasePicklistFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getUsersToAssignRequest = () => {
  return {
    type: GET_USERS_TO_ASSIGN_REQUEST,
  };
};

const getUsersToAssignSuccess = (payload) => {
  return {
    type: GET_USERS_TO_ASSIGN_SUCCESS,
    payload: payload,
  };
};

const getUsersToAssignFailure = (error) => {
  return {
    type: GET_USERS_TO_ASSIGN_FAILURE,
    payload: error,
  };
};

const getUsersToAssign = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getUsersToAssignRequest());
    let url = `${baseUrl}/pickuplist/get_users`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getUsersToAssignSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getUsersToAssignFailure(err.message));
      });
  };
};

export {
  getReleasePicklist,
  removeReleasePicklist,
  createRelease,
  getUsersToAssign,
};
