import {
  GET_MATERIALDESC_REQUEST,
  GET_MATERIALDESC_SUCCESS,
  GET_MATERIALDESC_FAILURE,
  PRINT_UNDERTEST_REQUEST,
  PRINT_UNDERTEST_SUCCESS,
  PRINT_UNDERTEST_FAILURE,
} from "./underTestTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getMaterialDescRequest = () => {
  return {
    type: GET_MATERIALDESC_REQUEST,
  };
};

const getMaterialDescSuccess = (payload) => {
  return {
    type: GET_MATERIALDESC_SUCCESS,
    payload: payload,
  };
};

const getMaterialDescFailure = (error) => {
  return {
    type: GET_MATERIALDESC_FAILURE,
    payload: error,
  };
};

const getMaterialDesc = (materialCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getMaterialDescRequest());
    let url = `${baseUrl}/material/get?materialCode=${materialCode}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getMaterialDescSuccess(response.data.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getMaterialDescFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const underTestRequest = () => {
  return {
    type: PRINT_UNDERTEST_REQUEST,
  };
};

const underTestSuccess = (payload) => {
  return {
    type: PRINT_UNDERTEST_SUCCESS,
    payload: payload,
  };
};

const underTestFailure = (error) => {
  return {
    type: PRINT_UNDERTEST_FAILURE,
    payload: error,
  };
};

const underTestPrint = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(underTestRequest());
    let url = `${baseUrl}/under_test/print_label`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(underTestSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(underTestFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export { underTestPrint, getMaterialDesc };
