import {
  GET_ADDEDPIV_REQUEST,
  GET_ADDEDPIV_SUCCESS,
  GET_ADDEDPIV_FAILURE,
  REMOVE_PIVFROMISSUANCERELEASE_REQUEST,
  REMOVE_PIVFROMISSUANCERELEASE_SUCCESS,
  REMOVE_PIVFROMISSUANCERELEASE_FAILURE,
  RELEASE_PIV_REQUEST,
  RELEASE_PIV_SUCCESS,
  RELEASE_PIV_FAILURE,
} from "./issuanceReleaseTypes";

const initialStateForAddedPiv = {
  loading: false,
  piv: [],
  error: "",
};

const getAddedPivReducer = (state = initialStateForAddedPiv, action) => {
  switch (action.type) {
    case GET_ADDEDPIV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ADDEDPIV_SUCCESS:
      return {
        loading: false,
        piv: action.payload,
        error: "",
      };
    case GET_ADDEDPIV_FAILURE:
      return {
        loading: false,
        piv: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForRemovePiv = {
  loading: false,
  piv: [],
  error: "",
};

const removePivReducer = (state = initialStateForRemovePiv, action) => {
  switch (action.type) {
    case REMOVE_PIVFROMISSUANCERELEASE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_PIVFROMISSUANCERELEASE_SUCCESS:
      return {
        loading: false,
        piv: action.payload,
        error: "",
      };
    case REMOVE_PIVFROMISSUANCERELEASE_FAILURE:
      return {
        loading: false,
        piv: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForReleasePiv = {
  loading: false,
  piv: [],
  error: "",
};

const releasePivReducer = (state = initialStateForReleasePiv, action) => {
  switch (action.type) {
    case RELEASE_PIV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RELEASE_PIV_SUCCESS:
      return {
        loading: false,
        piv: action.payload,
        error: "",
      };
    case RELEASE_PIV_FAILURE:
      return {
        loading: false,
        piv: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getAddedPivReducer, removePivReducer, releasePivReducer };
