import {
  PRINT_STOCK_CUT_OVER_REQUEST,
  PRINT_STOCK_CUT_OVER_SUCCESS,
  PRINT_STOCK_CUT_OVER_FAILURE,
  GET_ADD_TO_STORE_REQUEST,
  GET_ADD_TO_STORE_SUCCESS,
  GET_ADD_TO_STORE_FAILURE,
} from "./stockCutOverTypes";

const initialStateForStockCutOver = {
  loading: false,
  stockCutOver: [],
  error: "",
};

const stockCutOverReducer = (state = initialStateForStockCutOver, action) => {
  switch (action.type) {
    case PRINT_STOCK_CUT_OVER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PRINT_STOCK_CUT_OVER_SUCCESS:
      return {
        loading: false,
        stockCutOver: action.payload,
        error: "",
      };
    case PRINT_STOCK_CUT_OVER_FAILURE:
      return {
        loading: false,
        stockCutOver: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAddToStore = {
  loading: false,
  addToStore: [],
  error: "",
};

const getAddToStoreReducer = (state = initialStateForAddToStore, action) => {
  switch (action.type) {
    case GET_ADD_TO_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ADD_TO_STORE_SUCCESS:
      return {
        loading: false,
        addToStore: action.payload,
        error: "",
      };
    case GET_ADD_TO_STORE_FAILURE:
      return {
        loading: false,
        addToStore: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { stockCutOverReducer, getAddToStoreReducer };
