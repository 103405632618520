import {
  GET_PIV_REQUEST,
  GET_PIV_SUCCESS,
  GET_PIV_FAILURE,
  UPDATE_PICKLISTLOCATION_REQUEST,
  UPDATE_PICKLISTLOCATION_SUCCESS,
  UPDATE_PICKLISTLOCATION_FAILURE,
  ADD_RELEASEFORISSUANCE_REQUEST,
  ADD_RELEASEFORISSUANCE_SUCCESS,
  ADD_RELEASEFORISSUANCE_FAILURE,
} from "./materialIssuanceTypes";

const initialStateForPiv = {
  loading: false,
  piv: [],
  error: "",
};

const getPivReducer = (state = initialStateForPiv, action) => {
  switch (action.type) {
    case GET_PIV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PIV_SUCCESS:
      return {
        loading: false,
        piv: action.payload,
        error: "",
      };
    case GET_PIV_FAILURE:
      return {
        loading: false,
        piv: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUpdateLocation = {
  loading: false,
  piv: [],
  error: "",
};

const updatePicklistLocationReducer = (
  state = initialStateForUpdateLocation,
  action
) => {
  switch (action.type) {
    case UPDATE_PICKLISTLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PICKLISTLOCATION_SUCCESS:
      return {
        loading: false,
        piv: action.payload,
        error: "",
      };
    case UPDATE_PICKLISTLOCATION_FAILURE:
      return {
        loading: false,
        piv: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAddToReleasePiv = {
  loading: false,
  piv: [],
  error: "",
};

const addToReleaseForIssuanceReducer = (
  state = initialStateForAddToReleasePiv,
  action
) => {
  switch (action.type) {
    case ADD_RELEASEFORISSUANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_RELEASEFORISSUANCE_SUCCESS:
      return {
        loading: false,
        piv: action.payload,
        error: "",
      };
    case ADD_RELEASEFORISSUANCE_FAILURE:
      return {
        loading: false,
        piv: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getPivReducer,
  updatePicklistLocationReducer,
  addToReleaseForIssuanceReducer,
};
