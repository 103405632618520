// // /*********************
//  * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
//  * __________________
//  *
//  *  Mobillor Technologies Pvt. Ltd.
//  *  All Rights Reserved.
//  *
//  * NOTICE:  All information contained herein is, and remains
//  * the property of Mobillor Technologies Pvt. Ltd.
//  * The intellectual and technical concepts contained
//  * herein are proprietary to Mobillor Technologies Pvt. Ltd.
//  * may be covered by Rebublic Of India and Foreign Patents,
//  * patents in process, and are protected by trade secret or copyright law.
//  * Dissemination of this information or reproduction of this material
//  * is strictly forbidden unless prior written permission is obtained
//  * from Mobillor Technologies Pvt. Ltd.
//  */

import React from "react";
import { useEffect, useState } from "react";

import { connect } from "react-redux";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

//  formik and yup
import { useFormik } from "formik";
import * as Yup from "yup";

// @mui material icons
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import TextError from "utils/TextError";

import { Tree } from "primereact/tree";

// Functions from store
import {
  getAllRoles,
  deleteRoles,
  createRoles,
  editRoles,
  getModules,
  getSubModule,
  getSubModuleForView,
  getModuleForEdit,
  getSubModulesByRoleId,
  getModuleForView,
  getSubModuleForCreateRole,
  getDepartments,
} from "../../../store";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const UserRoles = ({
  getAllRolesReducer,
  getAllRoles,
  deleteRoles,
  createRoles,
  editRoles,
  getModules,
  getModulesReducer,
  getSubModule,
  getSubModulesReducer,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getSubModuleForView,
  getSubModulesForViewReducer,
  getModuleForView,
  getModulesForViewReducer,
  getModuleForEdit,
  getModulesForEditReducer,
  getSubModuleForCreateRole,
  getSubModulesForCreateRoleReducer,
  getDepartments,
  departments,
}) => {
  const deleteStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 340, sm: 340, md: 340, lg: 330, xl: 330 },
    boxShadow: 24,
    p: 2,
    pt: 3,
  };

  const styleForCreateRole = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 340, sm: 340, md: 450, lg: 450, xl: 450 },
    height: "fit-content",
    maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
    boxShadow: 24,
    p: 1,
    pt: 2,
  };

  let data = cookies.get("loginDetailsForWMS");

  let jwtToken = data?.jwt;
  let role = data?.data?.roleName;
  let roleId = data.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesAccessData, setModulesAccessData] = useState([]);

  useEffect(() => {
    setModulesAccessData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  const [submitLoader, setSubmitLoader] = useState(false);

  const [productsData, setProductsData] = useState({});

  const [roleDataForEdit, setRoleDataForEdit] = useState({});
  const [roleIdForView, setRoleIdForView] = useState("");
  const [moduleIdForView, setModuleIdForView] = useState("");
  let [roleIdForUpdate, setRoleIdForUpdate] = useState("");
  let [moduleIdForUpdate, setModuleIdForUpdate] = useState("");

  useEffect(() => {
    getAllRoles(jwtToken);
  }, [getAllRoles]);

  useEffect(() => {
    getSubModuleForCreateRole(jwtToken);
  }, [getSubModuleForCreateRole]);

  let subModuleDataForCreate = getSubModulesForCreateRoleReducer
    ?.subModulesDataForCreateRole?.data
    ? getSubModulesForCreateRoleReducer?.subModulesDataForCreateRole?.data
    : [];

  let [subModuleForCreateRole, setSubModuleForCreateRole] = useState({});

  // useEffect(async () => {
  //   let tempSubModulesData = {};
  //   let subModulesData = await getSubModuleForCreateRole(jwtToken);
  //   tempSubModulesData["subModulesForCreateRole"] = subModulesData.data;
  //   setSubModuleForCreateRole(tempSubModulesData);
  // }, []);

  useEffect(() => {
    getSubModule(jwtToken, roleIdForUpdate, moduleIdForUpdate);
  }, [getSubModule, roleIdForUpdate, moduleIdForUpdate]);

  useEffect(() => {
    getModuleForView(jwtToken, roleIdForView);
  }, [getModuleForView, roleIdForView]);

  useEffect(() => {
    getModuleForEdit(jwtToken, roleIdForView);
  }, [getModuleForView, roleIdForView]);

  let payload = { roleId: roleIdForView, moduleId: moduleIdForView };

  useEffect(() => {
    getSubModuleForView(jwtToken, payload);
  }, [getSubModuleForView, roleIdForView, moduleIdForView]);

  let subModuleDataForView = getSubModulesForViewReducer?.subModuleData?.data
    ? getSubModulesForViewReducer?.subModuleData?.data
    : [];

  let moduleDataForView = getModulesForViewReducer?.moduleData?.data
    ? getModulesForViewReducer?.moduleData?.data
    : [];

  let moduleDataForEdit = getModulesForEditReducer?.moduleData?.data
    ? getModulesForEditReducer?.moduleData?.data
    : [];

  let viewLoading = getModulesForViewReducer?.loading;
  let editLoading = getModulesForEditReducer?.loading;

  // const [modulesDataForView, setModulesDataForView] = useState([]);
  // useEffect(async () => {
  //   let tempModulesData = {};
  //   let modulesData = await getModuleForView(jwtToken, roleIdForView);
  //   tempModulesData["ModulesForView"] = modulesData.data;
  //   setModulesDataForView(tempModulesData);
  // }, [roleIdForView]);

  const [departmentSelected, setDepartmentSelected] = useState({});
  const handleDepartmentSelected = (selectedOption) => {
    setDepartmentSelected(selectedOption);
  };

  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  useEffect(() => {
    let tempDepartments = departments.departments.data
      ? departments.departments.data
      : [];
    let departmentOptionsTemp = [];
    tempDepartments.map((department) => {
      departmentOptionsTemp.push({
        label: department.departmentName,
        value: department.departmentId,
      });
    });
    setDepartmentsOptions(departmentOptionsTemp);
  }, [departments]);

  let rolesModules = modulesAccessData.find(
    (moduleId) => moduleId.moduleId == "d9339c6e-dbde-4d8b-a26f-f5c91c632048"
  );

  let createAccess = rolesModules
    ? rolesModules.actionId.includes("a2828d90-6491-45fb-aa23-f4f237cff293")
    : null;

  let viewAccess = rolesModules
    ? rolesModules.actionId.includes("7371a603-5f72-45ba-a5e9-54c4aa57707f")
    : null;

  let updateAccess = rolesModules
    ? rolesModules.actionId.includes("e1e0c6c4-3970-4499-992a-e202fc91bb25")
    : null;

  let deleteAccess = rolesModules
    ? rolesModules.actionId.includes("59ac529d-0079-4aa4-a665-a98ffc50de06")
    : null;

  const [loading, setLoading] = useState(false);

  // const [nodes, setNodes] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState(moduleDataForEdit);

  useEffect(() => {
    setSelectedKeys(moduleDataForEdit);
  }, [getModulesForEditReducer]);

  let payloadArr = [];

  Object.keys(selectedKeys).forEach(
    (key) => (selectedKeys[key].actionId = key)
  );

  Object.values(selectedKeys).forEach((el) => payloadArr.push(el));

  const [opendeleteRolesModal, setOpendeleteRolesModal] = useState(false);
  const [singleClientDataForDelete, setClientDataForDelete] = useState({});

  const handleOpendeleteRolesModal = (department = {}) => {
    setClientDataForDelete(department);
    setOpendeleteRolesModal(true);
  };

  const handleClosedeleteRolesModal = () => {
    setOpendeleteRolesModal(false);
  };
  const [isErrordeleteRoles, setIsErrordeleteRoles] = useState(false);

  useEffect(() => {
    if (isErrordeleteRoles) {
      setTimeout(() => {
        setIsErrordeleteRoles(false);
      }, 3000);
    }
  }, [isErrordeleteRoles]);

  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");

  const deleteRolesData = async () => {
    let roleId = singleClientDataForDelete.roleId;
    let response = await deleteRoles(jwtToken, roleId);
    if (response.status) {
      handleClosedeleteRolesModal();
      getAllRoles(jwtToken);
    }
    if (!response.status) {
      setDeleteErrorMsg(response.data.data.message);
      setIsErrordeleteRoles(true);
    }
  };

  const [openEditRoleModal, setOpenEditRoleModal] = useState(false);

  const handleOpenEditRoleModal = (data) => {
    setOpenEditRoleModal(true);
    setRoleDataForEdit(data);
    setDepartmentSelected({
      label: data.departmentName,
      value: data.departmentId,
    });
    setRoleIdForUpdate(data.roleId);
    setRoleIdForView(data.roleId);
  };

  const handleCloseEditRoleModal = () => {
    setOpenEditRoleModal(false);
    getAllRoles(jwtToken);
    formik.setErrors("");
  };

  const [isSuccessEditRole, setIsSuccessEditRole] = useState(false);
  const [isErrorEditRole, setIsErrorEditRole] = useState(false);

  useEffect(() => {
    if (isSuccessEditRole) {
      setTimeout(() => {
        setIsSuccessEditRole(false);
      }, 3000);
    }
  }, [isSuccessEditRole]);

  useEffect(() => {
    if (isErrorEditRole) {
      setTimeout(() => {
        setIsErrorEditRole(false);
      }, 3000);
    }
  }, [isErrorEditRole]);

  const initialValues = {
    roleName: roleDataForEdit ? roleDataForEdit.roleName : "",
    description: roleDataForEdit ? roleDataForEdit.description : "",
    departmentId: roleDataForEdit ? roleDataForEdit.departmentId : "",
  };

  const validationSchema = Yup.object({
    roleName: Yup.string().required("Enter the role name"),
    description: Yup.string().required("Enter the description"),
  });

  const [editErrorMsg, setEditErrorMsg] = useState("");
  const [buttonName, setButtonName] = useState("Submit");
  const onSubmit = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    setSubmitLoader(true);

    values.roleId = roleDataForEdit?.roleId;
    values.roleName = roleDataForEdit?.roleName;
    values.departmentId = departmentSelected.value;

    values.actionIds = payloadArr;

    let response = await editRoles(values, jwtToken);
    if (response.status === true) {
      setSubmitLoader(false);
      setIsSuccessEditRole(true);
      getAllRoles(jwtToken);

      setRoleIdForUpdate("");
      setModuleIdForUpdate("");
      getSubModuleForView(jwtToken, payload);
      getModuleForView(jwtToken, roleIdForView);
      getSubModule(jwtToken, roleIdForUpdate, moduleIdForUpdate);
      getSubModulesByRoleId(roleId, jwtToken);
      getModules(jwtToken);
      resetForm();
      window.location.reload();
    }
    if (response.status === false) {
      setEditErrorMsg(response.data.data.message);
      setSubmitLoader(false);
      setIsErrorEditRole(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  //    create role
  const [openCreateRoleModal, setOpenCreateRoleModal] = useState(false);

  const handleOpenCreateRoleModal = (data) => {
    setRoleIdForUpdate(data?.roleId);
    setOpenCreateRoleModal(true);
  };

  const handleCloseCreateRoleModal = () => {
    setOpenCreateRoleModal(false);
    getAllRoles(jwtToken);
  };

  const [isSuccessCreateRole, setIsSuccessCreateRole] = useState(false);
  const [isErrorCreateRole, setIsErrorCreateRole] = useState(false);
  useEffect(() => {
    if (isSuccessCreateRole) {
      setTimeout(() => {
        setIsSuccessCreateRole(false);
      }, 3000);
    }
  }, [isSuccessCreateRole]);

  useEffect(() => {
    if (isErrorCreateRole) {
      setTimeout(() => {
        setIsErrorCreateRole(false);
      }, 3000);
    }
  }, [isErrorCreateRole]);

  const initialValuesForCreateRole = {
    roleName: "",
    description: "",
    departmentId: "",
  };

  const validationSchemaForCreateRole = Yup.object({
    roleName: Yup.string().required("Enter the role name"),
    description: Yup.string().required("Enter the description"),
  });

  const [createErrorMsg, setCreateErrorMsg] = useState("");

  const onSubmitForCreateRole = async (values, { resetForm }) => {
    setSubmitLoader(true);

    // subModulesActionsForCreateRole.forEach((object) => {
    //   delete object["accessTypeName"];
    // });
    values.actionIds = payloadArr;
    values.departmentId = departmentSelected.value;

    let response = await createRoles(values, jwtToken);

    if (response.status === true) {
      setSubmitLoader(false);
      setIsSuccessCreateRole(true);
      getAllRoles(jwtToken);
      resetForm();
      setSubModulesActionsForCreateRole([]);
      getSubModuleForView(jwtToken, payload);
      getModuleForView(jwtToken, roleIdForView);
      getSubModule(jwtToken, roleIdForUpdate, moduleIdForUpdate);
      getSubModulesByRoleId(roleId, jwtToken);
      getModules(jwtToken);
    }
    if (response.status === false) {
      setCreateErrorMsg(response.data.data.message);
      setSubmitLoader(false);
      setIsErrorCreateRole(true);
    }
  };

  const formikForCreateRole = useFormik({
    initialValues: initialValuesForCreateRole,
    onSubmit: onSubmitForCreateRole,
    validationSchema: validationSchemaForCreateRole,
  });

  const selectCrudOperation = (a) => {};
  const [openCrudOperationModal, setOpenCrudOperationModal] = useState(false);

  const handleOpenCrudOperationModal = (data) => {
    setOpenCrudOperationModal(true);
  };

  const handleCloseCrudOperationModal = () => {
    setOpenCrudOperationModal(false);
  };

  let [productsList, setProductsList] = useState([]);
  let [modules, setModules] = useState({});
  let [modulesForView, setModulesForView] = useState({});

  let [subModule, setSubModule] = useState({});

  // submodules ka data
  let subModulesData = getSubModulesReducer?.subModulesData?.data
    ? getSubModulesReducer?.subModulesData?.data
    : [];

  const [testingData, setTestingData] = useState([]);
  const [testSubModule, setTestSubModule] = useState(subModulesData || []);

  const [modulesActions, setModulesActions] = useState([]);
  const [subModulesActions, setSubModulesActions] = useState([]);
  const [subModulesActionsForCreateRole, setSubModulesActionsForCreateRole] =
    useState([]);

  const [parentModuleId, setParentModuleId] = useState("");

  const [itemTable, setItemTable] = useState({
    columns: [
      { Header: "role name", accessor: "roleName" },
      { Header: "description", accessor: "description" },
      {
        Header: "action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });

  const { columns: itemColumns, rows: itemRows } = itemTable;

  useEffect(() => {
    let tempRoles = [];
    let data = getAllRolesReducer?.rolesData?.data
      ? getAllRolesReducer?.rolesData?.data
      : [];

    setLoading(getAllRolesReducer.loading);

    if (getAllRolesReducer?.rolesData?.data?.length) {
      data?.map((data) => {
        const singledata = {
          roleName: data.roleName,
          description: data.description,

          action: (
            <>
              <span style={{ display: "flex" }}>
                {viewAccess ? (
                  <Tooltip title="View">
                    <MDButton
                      style={{ marginLeft: "10px" }}
                      variant="gradient"
                      color="info"
                      iconOnly
                      type="button"
                      onClick={() => handleopenViewModal(data)}
                    >
                      <Icon> visibility_icon </Icon>
                    </MDButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="View">
                    <MDButton
                      disabled
                      style={{ marginLeft: "10px" }}
                      variant="gradient"
                      color="info"
                      iconOnly
                      type="button"
                      onClick={() => handleopenViewModal(data)}
                    >
                      <Icon> visibility_icon </Icon>
                    </MDButton>
                  </Tooltip>
                )}
                {updateAccess ? (
                  <Tooltip title="Edit Role">
                    <MDButton
                      style={{ marginLeft: "10px" }}
                      variant="gradient"
                      color="success"
                      iconOnly
                      type="button"
                      onClick={() => {
                        getDepartments(jwtToken);
                        handleOpenEditRoleModal(data);
                      }}
                    >
                      <Icon> edit </Icon>
                    </MDButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit Role">
                    <MDButton
                      disabled
                      style={{ marginLeft: "10px" }}
                      variant="gradient"
                      color="success"
                      iconOnly
                      type="button"
                      onClick={() => {
                        handleOpenEditRoleModal(data);
                      }}
                    >
                      <Icon> edit </Icon>
                    </MDButton>
                  </Tooltip>
                )}

                {deleteAccess ? (
                  <Tooltip title="Delete Role">
                    <MDButton
                      style={{ marginLeft: "10px" }}
                      variant="gradient"
                      color="error"
                      iconOnly
                      type="button"
                      onClick={() => handleOpendeleteRolesModal(data)}
                    >
                      <DeleteIcon />
                    </MDButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Delete Role">
                    <MDButton
                      disabled
                      style={{ marginLeft: "10px" }}
                      variant="gradient"
                      color="error"
                      iconOnly
                      type="button"
                      onClick={() => handleOpendeleteRolesModal(data)}
                    >
                      <DeleteIcon />
                    </MDButton>
                  </Tooltip>
                )}
              </span>
            </>
          ),
        };
        tempRoles.push(singledata);
      });
    }
    viewAccess && setItemTable({ ...itemTable, rows: tempRoles });
  }, [getAllRolesReducer]);

  const [openViewModal, setopenViewModal] = useState(false);

  const handleopenViewModal = (data = {}) => {
    setRoleIdForView(data.roleId);
    setopenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setopenViewModal(false);
    getAllRoles(jwtToken);
    getSubModuleForView(jwtToken, payload);
    getModuleForView(jwtToken, roleIdForView);
    getSubModule(jwtToken, roleIdForUpdate, moduleIdForUpdate);
    getSubModulesByRoleId(roleId, jwtToken);
    getModules(jwtToken);
  };

  return (
    <>
      <>
        <MDBox mt={-8}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                {createAccess && (
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                    <MDBox color="text">
                      <Tooltip title="Create Role">
                        <MDButton
                          color="info"
                          variant="outlined"
                          type="button"
                          onClick={() => {
                            getDepartments(jwtToken);
                            handleOpenCreateRoleModal();
                          }}
                        >
                          Create Role
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                )}
                <MDBox mt={createAccess ? -3 : 0}>
                  <DataTable
                    table={{ columns: itemColumns, rows: itemRows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 30,
                      entries: [30, 50, 75, 100],
                    }}
                  />
                </MDBox>
              </Card>
            </Grid>
            <Grid>
              <Modal
                open={opendeleteRolesModal}
                onClose={handleClosedeleteRolesModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>Are you sure you want to delete?</MDTypography>
                  <MDBox mt={2}>
                    <div style={{ display: "flex" }}>
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "0.5rem" }}
                          color="info"
                          variant="outlined"
                          onClick={handleClosedeleteRolesModal}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div>
                        <MDButton
                          style={{ width: "100%", marginLeft: "0.5rem" }}
                          color="error"
                          onClick={deleteRolesData}
                        >
                          Delete
                        </MDButton>
                      </div>
                    </div>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isErrordeleteRoles}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrordeleteRoles(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {/* Please check the details. Server error! */}
                        {deleteErrorMsg}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
            </Grid>

            <Modal
              open={openCreateRoleModal}
              onClose={handleCloseCreateRoleModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForCreateRole}>
                <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Create Role
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseCreateRoleModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>

                <hr />

                <MDBox pt={1} pb={3} px={1} overflow="auto">
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForCreateRole.handleSubmit}
                  >
                    <MDBox mb={2}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        flexGrow={1}
                        style={{ fontWeight: "500" }}
                      >
                        Role Name
                      </MDTypography>

                      <MDInput
                        type="text"
                        name="roleName"
                        fullWidth
                        autoComplete="off"
                        onChange={formikForCreateRole.handleChange}
                        onBlur={formikForCreateRole.handleBlur}
                        value={formikForCreateRole.values.roleName}
                        error={
                          formikForCreateRole.touched.roleName &&
                          formikForCreateRole.errors.roleName &&
                          true
                        }
                      />
                      {formikForCreateRole.touched.roleName &&
                        formikForCreateRole.errors.roleName && (
                          <TextError
                            msg={formikForCreateRole.errors.roleName}
                          />
                        )}
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        flexGrow={1}
                        style={{ fontWeight: "500" }}
                      >
                        Description
                      </MDTypography>

                      <MDInput
                        // type="text"
                        name="description"
                        fullWidth
                        autoComplete="off"
                        onChange={formikForCreateRole.handleChange}
                        onBlur={formikForCreateRole.handleBlur}
                        value={formikForCreateRole.values.description}
                        error={
                          formikForCreateRole.touched.description &&
                          formikForCreateRole.errors.description &&
                          true
                        }
                      />
                      {formikForCreateRole.touched.description &&
                        formikForCreateRole.errors.description && (
                          <TextError
                            msg={formikForCreateRole.errors.description}
                          />
                        )}
                    </MDBox>

                    <MDBox mb={2}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        style={{ fontWeight: "500" }}
                      >
                        Department Name
                      </MDTypography>
                      <Select
                        className="select-css"
                        placeholder="Choose one..."
                        name="departmentId"
                        options={departmentsOptions}
                        value={departmentSelected}
                        onChange={(selectedOption) => {
                          handleDepartmentSelected(selectedOption);
                          formikForCreateRole.handleChange("departmentId")(
                            selectedOption.value
                          );
                        }}
                      />
                      {formikForCreateRole.errors.departmentId && (
                        <TextError
                          msg={formikForCreateRole.errors.departmentId}
                        />
                      )}
                    </MDBox>
                    <MDBox mb={0}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        style={{ fontWeight: "500" }}
                      >
                        Modules
                      </MDTypography>
                    </MDBox>
                    <div className="card flex justify-content-center">
                      <Tree
                        value={subModuleDataForCreate}
                        selectionMode="checkbox"
                        selectionKeys={selectedKeys}
                        onSelectionChange={(e) => setSelectedKeys(e.value)}
                        className="w-full md:w-30rem p5"
                      />
                    </div>
                    {/* {productsList ? getMainAccordionForCreateRole() : null} */}

                    <MDBox ml={2} mr={2}>
                      <MDBox></MDBox>
                    </MDBox>

                    <MDBox>
                      <Collapse in={isErrorCreateRole}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorCreateRole(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {/* Please check the details. Server error! */}
                          {createErrorMsg}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessCreateRole}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessCreateRole(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Role created successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                    <MDBox mt={2}>
                      {!submitLoader ? (
                        <MDButton
                          variant="contained"
                          color="info"
                          fullWidth
                          type="submit"
                          disabled={!formikForCreateRole.isValid}
                        >
                          Submit
                        </MDButton>
                      ) : (
                        <MDButton
                          variant="contained"
                          color="info"
                          fullWidth
                          disabled
                        >
                          <CircularProgress
                            style={{ color: "white", marginRight: "10px" }}
                            size={20}
                          />
                          Please Wait...{" "}
                        </MDButton>
                      )}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>

            {/* ///////////////// edited model ------------- */}
            <Modal
              open={openEditRoleModal}
              onClose={handleCloseEditRoleModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForCreateRole}>
                <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Edit Role
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseEditRoleModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>

                <hr />

                <MDBox pt={1} pb={3} px={1} overflow="auto">
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <MDBox mb={2}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        flexGrow={1}
                        style={{ fontWeight: "500" }}
                      >
                        Role Name
                      </MDTypography>

                      <MDInput
                        type="text"
                        name="roleName"
                        fullWidth
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.roleName}
                        error={
                          formik.touched.roleName &&
                          formik.errors.roleName &&
                          true
                        }
                      />
                      {formik.touched.roleName && formik.errors.roleName && (
                        <TextError msg={formik.errors.roleName} />
                      )}
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        flexGrow={1}
                        style={{ fontWeight: "500" }}
                      >
                        Description
                      </MDTypography>

                      <MDInput
                        // type="text"
                        name="description"
                        fullWidth
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                        error={
                          formik.touched.description &&
                          formik.errors.description &&
                          true
                        }
                      />

                      {formik.touched.description &&
                        formik.errors.description && (
                          <TextError msg={formik.errors.description} />
                        )}
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        style={{ fontWeight: "500" }}
                      >
                        Department Name
                      </MDTypography>
                      <Select
                        className="select-css"
                        placeholder="Choose one..."
                        name="departmentId"
                        options={departmentsOptions}
                        value={departmentSelected}
                        onChange={(selectedOption) => {
                          handleDepartmentSelected(selectedOption);
                          formik.handleChange("departmentId")(
                            selectedOption.value
                          );
                        }}
                      />
                      {formik.errors.departmentId && (
                        <TextError msg={formik.errors.departmentId} />
                      )}
                    </MDBox>

                    <MDBox mb={0}>
                      <MDTypography variant="h6" fontWeight="medium">
                        Modules
                      </MDTypography>
                    </MDBox>
                    {!editLoading ? (
                      <div className="card flex justify-content-center">
                        <Tree
                          value={subModuleDataForCreate}
                          selectionMode="checkbox"
                          selectionKeys={selectedKeys}
                          onSelectionChange={(e) => setSelectedKeys(e.value)}
                          className="w-full md:w-30rem"
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "100px",
                          marginBottom: "100px",
                        }}
                      >
                        <CircularProgress color="info" />
                      </div>
                    )}
                    {/* <MDBox>{productsList ? getMainAccordion() : null}</MDBox> */}
                    <MDBox>
                      <Collapse in={isErrorEditRole}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorEditRole(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {/* Please check the details. Server error! */}
                          {editErrorMsg}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessEditRole}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessEditRole(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Role updated successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                    <MDBox mt={2}>
                      {!submitLoader ? (
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                          disabled={!formik.isValid}
                        >
                          Submit
                        </MDButton>
                      ) : (
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          disabled
                        >
                          <CircularProgress
                            style={{ color: "white", marginRight: "10px" }}
                            size={20}
                          />
                          Please wait...{" "}
                        </MDButton>
                      )}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            <Grid>
              <Modal
                open={openViewModal}
                onClose={handleCloseViewModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForCreateRole}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      View Access Module
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseViewModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            padding: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDBox t={1} pb={3} px={1} overflow="auto">
                    <MDCard>
                      <MDBox mt={2}>
                        <div>
                          <MDCard>
                            <MDCard>
                              <MDCard>
                                <MDBox>
                                  <MDBox mb={0}>
                                    <MDTypography
                                      variant="h6"
                                      fontWeight="medium"
                                    >
                                      Modules
                                    </MDTypography>
                                  </MDBox>

                                  {!viewLoading ? (
                                    <div className="card flex justify-content-center">
                                      <Tree
                                        value={moduleDataForView}
                                        selectionMode="none"
                                        className="w-full md:w-30rem"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: "100px",
                                        marginBottom: "100px",
                                      }}
                                    >
                                      <CircularProgress color="info" />
                                    </div>
                                  )}
                                  {/* {productsList
                                    ? getMainAccordionForView()
                                    : null} */}
                                </MDBox>
                              </MDCard>
                            </MDCard>
                          </MDCard>
                        </div>
                      </MDBox>
                    </MDCard>
                  </MDBox>
                </MDCard>
              </Modal>
            </Grid>
          </Grid>
        </MDBox>
      </>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getAllRolesReducer: state.getAllRolesReducer,
    getModulesReducer: state.getModulesReducer,
    getSubModulesReducer: state.getSubModulesReducer,
    getSubModulesForViewReducer: state.getSubModulesForViewReducer,
    getModulesForViewReducer: state.getModulesForViewReducer,
    getModulesForEditReducer: state.getModulesForEditReducer,
    getSubModulesForCreateRoleReducer: state.getSubModulesForCreateRoleReducer,
    departments: state.getDepartmentsReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    deleteRoles: (jwtToken, productId) =>
      dispatch(deleteRoles(jwtToken, productId)),
    getAllRoles: (jwtToken) => dispatch(getAllRoles(jwtToken)),
    createRoles: (payload, jwtToken) =>
      dispatch(createRoles(payload, jwtToken)),
    editRoles: (payload, jwtToken) => dispatch(editRoles(payload, jwtToken)),
    getModules: (jwtToken) => dispatch(getModules(jwtToken)),
    getSubModuleForCreateRole: (jwtToken) =>
      dispatch(getSubModuleForCreateRole(jwtToken)),
    getSubModule: (jwtToken, roleId, moduleId) =>
      dispatch(getSubModule(jwtToken, roleId, moduleId)),
    getModuleForView: (jwtToken, roleId) =>
      dispatch(getModuleForView(jwtToken, roleId)),
    getModuleForEdit: (jwtToken, roleId) =>
      dispatch(getModuleForEdit(jwtToken, roleId)),
    getSubModuleForView: (jwtToken, payload) =>
      dispatch(getSubModuleForView(jwtToken, payload)),
    getDepartments: (jwtToken) => dispatch(getDepartments(jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(UserRoles);
