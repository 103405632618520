/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { TabView, TabPanel } from "primereact/tabview";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";

// Layouts
import RmWarehouse from "./rm-warehouse";
import WipWarehouse from "./wip-warehouse";

const Warehouses = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <TabView>
              <TabPanel header="Raw Material">
                <RmWarehouse />
              </TabPanel>
              <TabPanel header="Work In Progress">
                <WipWarehouse />
              </TabPanel>
            </TabView>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Warehouses;
