import {
  GET_PRODUCTION_ALLOCATION_FAILURE,
  GET_PRODUCTION_ALLOCATION_REQUEST,
  GET_PRODUCTION_ALLOCATION_SUCCESS,
  GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_FAILURE,
  GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_REQUEST,
  GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_SUCCESS,
  RELEASETO_PRODUCTION_PROCESS_FAILURE,
  RELEASETO_PRODUCTION_PROCESS_REQUEST,
  RELEASETO_PRODUCTION_PROCESS_SUCCESS,
  UPDATE_FROM_LOCATION_FAILURE,
  UPDATE_FROM_LOCATION_REQUEST,
  UPDATE_FROM_LOCATION_SUCCESS,
  UPDATE_RESOURCE_FAILURE,
  UPDATE_RESOURCE_REQUEST,
  UPDATE_RESOURCE_SUCCESS,
} from "./productionAllocationTypes";

const initialStateForProductionAllocation = {
  loading: false,
  allocation: [],
  error: "",
};

const getProductionAllocationReducer = (
  state = initialStateForProductionAllocation,
  action
) => {
  switch (action.type) {
    case GET_PRODUCTION_ALLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCTION_ALLOCATION_SUCCESS:
      return {
        loading: false,
        allocation: action.payload,
        error: "",
      };
    case GET_PRODUCTION_ALLOCATION_FAILURE:
      return {
        loading: false,
        allocation: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUpdateResource = {
  loading: false,
  resource: {},
  error: "",
};

const updateResourceReducer = (
  state = initialStateForUpdateResource,
  action
) => {
  switch (action.type) {
    case UPDATE_RESOURCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_RESOURCE_SUCCESS:
      return {
        loading: false,
        resource: action.payload,
        error: "",
      };
    case UPDATE_RESOURCE_FAILURE:
      return {
        loading: false,
        resource: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUpdateFromLocation = {
  loading: false,
  location: {},
  error: "",
};

const updateFromLocationReducer = (
  state = initialStateForUpdateFromLocation,
  action
) => {
  switch (action.type) {
    case UPDATE_FROM_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_FROM_LOCATION_SUCCESS:
      return {
        loading: false,
        location: action.payload,
        error: "",
      };
    case UPDATE_FROM_LOCATION_FAILURE:
      return {
        loading: false,
        location: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForReleaseToProduction = {
  loading: false,
  production: {},
  error: "",
};

const releaseToProductionProcessReducer = (
  state = initialStateForReleaseToProduction,
  action
) => {
  switch (action.type) {
    case RELEASETO_PRODUCTION_PROCESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RELEASETO_PRODUCTION_PROCESS_SUCCESS:
      return {
        loading: false,
        production: action.payload,
        error: "",
      };
    case RELEASETO_PRODUCTION_PROCESS_FAILURE:
      return {
        loading: false,
        production: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLocations = {
  loading: false,
  locations: [],
  error: "",
};

const getLocationsForProductionAllocationReducer = (
  state = initialStateForLocations,
  action
) => {
  switch (action.type) {
    case GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_SUCCESS:
      return {
        loading: false,
        locations: action.payload,
        error: "",
      };
    case GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_FAILURE:
      return {
        loading: false,
        locations: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getProductionAllocationReducer,
  updateResourceReducer,
  updateFromLocationReducer,
  releaseToProductionProcessReducer,
  getLocationsForProductionAllocationReducer,
};
