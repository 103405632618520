export const GET_ASSETMASTER_REQUEST = "GET_ASSETMASTER_REQUEST";
export const GET_ASSETMASTER_SUCCESS = "GET_ASSETMASTER_SUCCESS";
export const GET_ASSETMASTER_FAILURE = "GET_ASSETMASTER_FAILURE";

export const GET_ASSETTYPES_REQUEST = "GET_ASSETTYPES_REQUEST";
export const GET_ASSETTYPES_SUCCESS = "GET_ASSETTYPES_SUCCESS";
export const GET_ASSETTYPES_FAILURE = "GET_ASSETTYPES_FAILURE";

export const CREATE_NEWASSET_REQUEST = "CREATE_NEWASSET_REQUEST";
export const CREATE_NEWASSET_SUCCESS = "CREATE_NEWASSET_SUCCESS";
export const CREATE_NEWASSET_FAILURE = "CREATE_NEWASSET_FAILURE";

export const UPDATE_ASSET_REQUEST = "UPDATE_ASSET_REQUEST";
export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS";
export const UPDATE_ASSET_FAILURE = "UPDATE_ASSET_FAILURE";

export const DELETE_ASSET_REQUEST = "DELETE_ASSET_REQUEST";
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS";
export const DELETE_ASSET_FAILURE = "DELETE_ASSET_FAILURE";

export const PRINT_ASSET_REQUEST = "PRINT_ASSET_REQUEST";
export const PRINT_ASSET_SUCCESS = "PRINT_ASSET_SUCCESS";
export const PRINT_ASSET_FAILURE = "PRINT_ASSET_FAILURE";

export const PRINT_VIRTUAL_BIN_REQUEST = "PRINT_VIRTUAL_BIN_REQUEST";
export const PRINT_VIRTUAL_BIN_SUCCESS = "PRINT_VIRTUAL_BIN_SUCCESS";
export const PRINT_VIRTUAL_BIN_FAILURE = "PRINT_VIRTUAL_BIN_FAILURE";
