import {
  GET_PRODUCTION_PROCESS_FAILURE,
  GET_PRODUCTION_PROCESS_REQUEST,
  GET_PRODUCTION_PROCESS_SUCCESS,
  GET_RESOURCESFORALLOCATION_REQUEST,
  GET_RESOURCESFORALLOCATION_SUCCESS,
  GET_RESOURCESFORALLOCATION_FAILURE,
  GET_PRODUCTION_PROCESS_FORREPORTS_REQUEST,
  GET_PRODUCTION_PROCESS_FORREPORTS_SUCCESS,
  GET_PRODUCTION_PROCESS_FORREPORTS_FAILURE,
} from "./productionProcessTypes";

const initialStateForProductionProcess = {
  loading: false,
  process: [],
  error: "",
};

const getProductionProcessReducer = (
  state = initialStateForProductionProcess,
  action
) => {
  switch (action.type) {
    case GET_PRODUCTION_PROCESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCTION_PROCESS_SUCCESS:
      return {
        loading: false,
        process: action.payload,
        error: "",
      };
    case GET_PRODUCTION_PROCESS_FAILURE:
      return {
        loading: false,
        process: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForResourceAllocation = {
  loading: false,
  resource: [],
  error: "",
};

const getResourcesForAllocationReducer = (
  state = initialStateForResourceAllocation,
  action
) => {
  switch (action.type) {
    case GET_RESOURCESFORALLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RESOURCESFORALLOCATION_SUCCESS:
      return {
        loading: false,
        resource: action.payload,
        error: "",
      };
    case GET_RESOURCESFORALLOCATION_FAILURE:
      return {
        loading: false,
        resource: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForReports = {
  loading: false,
  process: [],
  error: "",
};

const getProductionProcessForReportsReducer = (
  state = initialStateForReports,
  action
) => {
  switch (action.type) {
    case GET_PRODUCTION_PROCESS_FORREPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCTION_PROCESS_FORREPORTS_SUCCESS:
      return {
        loading: false,
        process: action.payload,
        error: "",
      };
    case GET_PRODUCTION_PROCESS_FORREPORTS_FAILURE:
      return {
        loading: false,
        process: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getProductionProcessReducer,
  getResourcesForAllocationReducer,
  getProductionProcessForReportsReducer,
};
