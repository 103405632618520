/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDTypography from "components/MDTypography";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

import { CSVLink } from "react-csv";

// Functions from store
import {
  getPhaseWiseCharging,
  downloadPhaseWiseCharging,
  getSubModulesByRoleId,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";

// Config file
import Config from "../../../config/index";
import { addMinutes, format } from "date-fns";

let desLength = Config.descriptionLength;
const cookies = new Cookies();

const styleForDownloadModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleForPhaseSpecModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 500, lg: 600, xl: 600 },
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const PhaseWiseCharging = ({
  getPhaseWiseCharging,
  phaseWiseChargingState,
  downloadPhaseWiseCharging,
  phaseWiseChargingReports,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let reportsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "07390a6c-3bf9-4474-9396-b7d6192eb805"
  );

  let viewReportsAccess = reportsModule
    ? reportsModule.actionId.includes("d25b5d36-957c-4f79-9f4e-f19a30b05476")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [loading, setLoading] = useState(true);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };
  useEffect(() => {
    getPhaseWiseCharging(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const statusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Not Charged",
      value: 0,
    },
    {
      label: "Charged",
      value: 1,
    },
  ];

  const [state, setState] = useState({
    columns: [
      { Header: "PO No", accessor: "poNumber" },
      { Header: "Phase No/Location", accessor: "phaseNumber" },
      { Header: "Bulk Code/Des", accessor: "poBulkCode" },
      { Header: "Batch No", accessor: "poBatchNumber" },
      { Header: "Batch Qty", accessor: "phaseBatchQty" },
      { Header: "Filled/Charged Qty", accessor: "phaseChargedQty" },
      { Header: "Total No of Picklist", accessor: "phasePickupListCount" },
      { Header: "Total No of Store Item", accessor: "storeItem" },
      { Header: "Total No of Line Item", accessor: "lineItem" },
      { Header: "Total No of Intermediate Item", accessor: "intermediateItem" },
      {
        Header: "Charging Start Time",
        accessor: "startChargingTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Charging End Time",
        accessor: "endChargingTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        searchType: "select",
        selectOptions: statusOptions,
        placeholder: "All Status",
      },
      { Header: "User", accessor: "userName" },
      {
        Header: "Phase Specifications Result",
        accessor: "phaseSpecifications",
        cannotSearch: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  useEffect(() => {
    let tempReports = [];
    let data = phaseWiseChargingState.phaseCharging.data
      ? phaseWiseChargingState.phaseCharging.data
      : [];

    setLoading(phaseWiseChargingState.loading);
    setPaginationObj(phaseWiseChargingState?.phaseCharging?.pagination);

    data.map((singleData) => {
      let des = singleData?.poBulkDescription;
      let len = desLength;

      const dataObject = {
        poNumber: singleData.poNumber,
        phaseNumber: singleData.phaseNumber,
        poBatchNumber: singleData.poBatchNumber,
        poBulkCode: `${singleData.poBulkCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        userName: singleData.userName,
        phaseBatchQty: `${singleData.phaseBatchQty} ${singleData.poBulkUom}`,
        phaseChargedQty: `${singleData.phaseChargedQty} ${singleData.poBulkUom}`,
        phasePickupListCount: singleData.phasePickupListCount,
        storeItem: singleData.storeItem,
        lineItem: singleData.lineItem,
        intermediateItem: singleData.intermediateItem,
        startChargingTime: singleData.startChargingTime,
        endChargingTime: singleData.endChargingTime,
        phaseSpecifications: singleData.phaseSpecifications.length > 0 && (
          <Tooltip title="View" style={{ marginLeft: "0.75rem" }}>
            <MDButton
              color="info"
              variant="gradient"
              type="button"
              iconOnly
              size="small"
              onClick={() => {
                setPhaseSpecData(singleData.phaseSpecifications);
                handleOpenPhaseSpecModal();
              }}
            >
              <Icon> visibility_icon </Icon>
            </MDButton>
          </Tooltip>
        ),
        status:
          (singleData.status === 0 && "Not Charged") ||
          (singleData.status === 1 && "Charged"),
      };
      tempReports.push(dataObject);
    });
    viewReportsAccess && setState({ ...state, rows: tempReports });
  }, [phaseWiseChargingState]);

  const [phaseSpecModal, setOpenPhaseSpecModal] = useState(false);
  const [phaseSpecData, setPhaseSpecData] = useState([]);

  const handleOpenPhaseSpecModal = () => {
    setOpenPhaseSpecModal(true);
  };

  const handleClosePhaseSpecModal = () => {
    setOpenPhaseSpecModal(false);
  };

  const [phaseSpecState, setPhaseSpecState] = useState({
    columns: [
      { Header: "User Input", accessor: "userInput" },
      { Header: "phaseParameter", accessor: "phaseParameter" },
      { Header: "phaseSpecification", accessor: "phaseSpecification" },
    ],
    rows: [],
  });

  const { columns: phaseSpecColumns, rows: phaseSpecRows } = phaseSpecState;

  useEffect(() => {
    let tempPhaseSpec = [];

    let data = phaseSpecData ? phaseSpecData : [];

    data.map((data) => {
      const phaseSpecObj = {
        userInput: data.userInput,
        phaseParameter: data.phaseParameter,
        phaseSpecification: data.phaseSpecification,
      };

      tempPhaseSpec.push(phaseSpecObj);
    });

    setPhaseSpecState({ ...phaseSpecState, rows: tempPhaseSpec });
  }, [phaseSpecData]);

  const [loadingData, setLoadingData] = useState(false);
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    let tempReports = [];
    let data = phaseWiseChargingReports.phaseCharging.data
      ? phaseWiseChargingReports.phaseCharging.data
      : [];

    data.map((singleData, index) => {
      let des = singleData?.poBulkDescription;
      let len = desLength;

      const formatDateStartTime = new Date(singleData.startChargingTime);
      const formatDateEndTime = new Date(singleData.endChargingTime);

      const dataObject = {
        poNumber: singleData.poNumber,
        phaseNumber: singleData.phaseNumber,
        poBatchNumber: singleData.poBatchNumber,
        poBulkCode: `${singleData.poBulkCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        phaseBatchQty: `${singleData.phaseBatchQty} ${singleData.poBulkUom}`,
        phaseChargedQty: `${singleData.phaseChargedQty} ${singleData.poBulkUom}`,
        phasePickupListCount: singleData.phasePickupListCount,
        storeItem: singleData.storeItem,
        lineItem: singleData.lineItem,
        intermediateItem: singleData.intermediateItem,
        startChargingTime: singleData.startChargingTime
          ? format(
              addMinutes(
                formatDateStartTime,
                formatDateStartTime.getTimezoneOffset()
              ),
              "dd/MM/yyyy, HH:mm"
            )
          : "",
        endChargingTime: singleData.endChargingTime
          ? format(
              addMinutes(
                formatDateEndTime,
                formatDateEndTime.getTimezoneOffset()
              ),
              "dd/MM/yyyy, HH:mm"
            )
          : "",
        status: singleData.status === 0 ? "Not Charged" : "Charged",
        userName: singleData.userName,
      };
      tempReports.push(dataObject);
    });
    setDownloadData(tempReports);
  }, [phaseWiseChargingReports]);

  const headers = [
    { label: "PO No", key: "poNumber" },
    { label: "Phase No/Location", key: "phaseNumber" },
    { label: "Bulk Code/Des", key: "poBulkCode" },
    { label: "Batch No", key: "poBatchNumber" },
    { label: "Batch Qty", key: "phaseBatchQty" },
    { label: "Filled/Charged Qty", key: "phaseChargedQty" },
    { label: "Total No of Picklist", key: "phasePickupListCount" },
    { label: "Total No of Store Item", key: "storeItem" },
    { label: "Total No of Line Item", key: "lineItem" },
    { label: "Total No of Intermediate Item", key: "intermediateItem" },
    {
      label: "Charging Start Time",
      key: "startChargingTime",
      searchType: "date",
    },
    {
      label: "Charging End Time",
      key: "endChargingTime",
      searchType: "date",
    },
    {
      label: "Status",
      key: "status",
    },
    { label: "User", key: "userName" },
  ];

  const csvLink = {
    filename: "Phase Charging Report.csv",
    headers: headers,
    data: downloadData,
  };

  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [disableDownloadButton, setDisableDownloadButton] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const handleOpenDownloadModal = (data = {}) => {
    setOpenDownloadModal(true);
  };

  const handleCloseDownloadModal = () => {
    setDisableDownloadButton(false);
    setOpenDownloadModal(false);
  };

  const downloadHandler = async () => {
    setLoadingData(true);

    let res = await downloadPhaseWiseCharging(
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    );

    if (res.status) {
      setModalMsg("Ready for download!");
      setLoadingData(false);
      handleOpenDownloadModal();
    }
    if (!res.status) {
      setModalMsg("Could not prepare the data for download!");
      setLoadingData(false);
      setDisableDownloadButton(true);
      handleOpenDownloadModal();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {viewReportsAccess && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  // mb={-6}
                >
                  <MDBox color="text">
                    <Tooltip title="Download">
                      <MDButton
                        style={{ marginRight: "1rem", marginLeft: "10px" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        iconOnly
                        onClick={() => {
                          downloadHandler();
                        }}
                      >
                        <Icon>download</Icon>
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              )}

              <MDBox mt={viewReportsAccess ? -3 : 0}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>

              <Modal
                open={openDownloadModal}
                onClose={handleCloseDownloadModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForDownloadModal}>
                  <MDTypography>{`${
                    disableDownloadButton
                      ? "Could not prepare the data for download!"
                      : "Ready for download!"
                  }`}</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDownloadModal}
                    >
                      Cancel
                    </MDButton>
                    {!disableDownloadButton && (
                      <CSVLink {...csvLink}>
                        <MDButton
                          style={{ width: "48%", marginRight: "0.5rem" }}
                          type="button"
                          variant="outlined"
                          color="error"
                        >
                          Download
                        </MDButton>
                      </CSVLink>
                    )}
                  </MDBox>
                </MDCard>
              </Modal>

              <div>
                <Modal
                  open={phaseSpecModal}
                  onClose={handleClosePhaseSpecModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForPhaseSpecModal}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Locations
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleClosePhaseSpecModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>

                    <MDBox pt={1} pb={3} px={3}>
                      <DataTable
                        table={{
                          columns: phaseSpecColumns,
                          rows: phaseSpecRows,
                        }}
                        isSorted={true}
                        entriesPerPage={{
                          defaultValue: 30,
                          entries: [30, 50, 75, 100],
                        }}
                        showTotalEntries={true}
                        noEndBorder
                        options={{ draggable: false }}
                      />
                    </MDBox>
                  </MDCard>
                </Modal>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {loadingData && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <MDTypography color="warning" variant="h2">
            Preparing Data. Please wait...
          </MDTypography>
        </Backdrop>
      )}
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    phaseWiseChargingState: state.getPhaseWiseChargingReducer,
    phaseWiseChargingReports: state.downloadPhaseWiseChargingReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getPhaseWiseCharging: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getPhaseWiseCharging(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
    downloadPhaseWiseCharging: (pageNo, rowsPerPage, search, jwtToken) =>
      dispatch(
        downloadPhaseWiseCharging(pageNo, rowsPerPage, search, jwtToken)
      ),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(PhaseWiseCharging);
