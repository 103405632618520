import {
  GET_PHASEWISECHARGING_REQUEST,
  GET_PHASEWISECHARGING_SUCCESS,
  GET_PHASEWISECHARGING_FAILURE,
  DOWNLOAD_PHASEWISECHARGING_REQUEST,
  DOWNLOAD_PHASEWISECHARGING_SUCCESS,
  DOWNLOAD_PHASEWISECHARGING_FAILURE,
} from "./phaseWiseChargingTypes";

const initialStateForPhaseCharging = {
  loading: false,
  phaseCharging: [],
  error: "",
};

const getPhaseWiseChargingReducer = (
  state = initialStateForPhaseCharging,
  action
) => {
  switch (action.type) {
    case GET_PHASEWISECHARGING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PHASEWISECHARGING_SUCCESS:
      return {
        loading: false,
        phaseCharging: action.payload,
        error: "",
      };
    case GET_PHASEWISECHARGING_FAILURE:
      return {
        loading: false,
        phaseCharging: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDownload = {
  loading: false,
  phaseCharging: [],
  error: "",
};

const downloadPhaseWiseChargingReducer = (
  state = initialStateForDownload,
  action
) => {
  switch (action.type) {
    case DOWNLOAD_PHASEWISECHARGING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_PHASEWISECHARGING_SUCCESS:
      return {
        loading: false,
        phaseCharging: action.payload,
        error: "",
      };
    case DOWNLOAD_PHASEWISECHARGING_FAILURE:
      return {
        loading: false,
        phaseCharging: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getPhaseWiseChargingReducer, downloadPhaseWiseChargingReducer };
