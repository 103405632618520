import {
  GET_PHASEWISECHARGING_REQUEST,
  GET_PHASEWISECHARGING_SUCCESS,
  GET_PHASEWISECHARGING_FAILURE,
  DOWNLOAD_PHASEWISECHARGING_REQUEST,
  DOWNLOAD_PHASEWISECHARGING_SUCCESS,
  DOWNLOAD_PHASEWISECHARGING_FAILURE,
} from "./phaseWiseChargingTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getPhaseWiseChargingRequest = () => {
  return {
    type: GET_PHASEWISECHARGING_REQUEST,
  };
};

const getPhaseWiseChargingSuccess = (payload) => {
  return {
    type: GET_PHASEWISECHARGING_SUCCESS,
    payload: payload,
  };
};

const getPhaseWiseChargingFailure = (error) => {
  return {
    type: GET_PHASEWISECHARGING_FAILURE,
    payload: error,
  };
};

const getPhaseWiseCharging = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPhaseWiseChargingRequest());
    let url = `${baseUrl}/reports/phase_charging_report?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}&download=0`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPhaseWiseChargingSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPhaseWiseChargingFailure(err.message));
      });
  };
};

const downloadPhaseWiseChargingRequest = () => {
  return {
    type: DOWNLOAD_PHASEWISECHARGING_REQUEST,
  };
};

const downloadPhaseWiseChargingSuccess = (payload) => {
  return {
    type: DOWNLOAD_PHASEWISECHARGING_SUCCESS,
    payload: payload,
  };
};

const downloadPhaseWiseChargingFailure = (error) => {
  return {
    type: DOWNLOAD_PHASEWISECHARGING_FAILURE,
    payload: error,
  };
};

const downloadPhaseWiseCharging = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(downloadPhaseWiseChargingRequest());
    let url = `${baseUrl}/reports/phase_charging_report?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}&download=1`;

    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(downloadPhaseWiseChargingSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(downloadPhaseWiseChargingFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export { getPhaseWiseCharging, downloadPhaseWiseCharging };
