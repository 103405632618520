import {
  GET_ASSETMASTER_REQUEST,
  GET_ASSETMASTER_SUCCESS,
  GET_ASSETMASTER_FAILURE,
  CREATE_NEWASSET_REQUEST,
  CREATE_NEWASSET_SUCCESS,
  CREATE_NEWASSET_FAILURE,
  UPDATE_ASSET_REQUEST,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_FAILURE,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAILURE,
  GET_ASSETTYPES_REQUEST,
  GET_ASSETTYPES_SUCCESS,
  GET_ASSETTYPES_FAILURE,
} from "./assetMasterTypes";

const initialState = {
  loading: false,
  assets: [],
  error: "",
};

const getAssetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSETMASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ASSETMASTER_SUCCESS:
      return {
        loading: false,
        assets: action.payload,
        error: "",
      };
    case GET_ASSETMASTER_FAILURE:
      return {
        loading: false,
        assets: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForAssetTypes = {
  loading: false,
  assetTypes: [],
  error: "",
};

const getAssetTypesReducer = (state = initialStateForAssetTypes, action) => {
  switch (action.type) {
    case GET_ASSETTYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ASSETTYPES_SUCCESS:
      return {
        loading: false,
        assetTypes: action.payload,
        error: "",
      };
    case GET_ASSETTYPES_FAILURE:
      return {
        loading: false,
        assetTypes: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreate = {
  loading: false,
  newPallet: {},
  error: "",
};

const createNewAssetReducer = (state = initialStateForCreate, action) => {
  switch (action.type) {
    case CREATE_NEWASSET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_NEWASSET_SUCCESS:
      return {
        loading: true,
        newPallet: action.payload,
        error: "",
      };
    case CREATE_NEWASSET_FAILURE:
      return {
        loading: false,
        newPallet: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUpdate = {
  loading: false,
  asset: {},
  error: "",
};

const updateAssetReducer = (state = initialStateForUpdate, action) => {
  switch (action.type) {
    case UPDATE_ASSET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ASSET_SUCCESS:
      return {
        loading: true,
        asset: action.payload,
        error: "",
      };
    case UPDATE_ASSET_FAILURE:
      return {
        loading: false,
        asset: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDelete = {
  loading: false,
  asset: {},
  error: "",
};

const deleteAssetReducer = (state = initialStateForDelete, action) => {
  switch (action.type) {
    case DELETE_ASSET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ASSET_SUCCESS:
      return {
        loading: true,
        asset: action.payload,
        error: "",
      };
    case DELETE_ASSET_FAILURE:
      return {
        loading: false,
        asset: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getAssetsReducer,
  createNewAssetReducer,
  updateAssetReducer,
  deleteAssetReducer,
  getAssetTypesReducer,
};
