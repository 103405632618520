import {
  GET_PRODUCTION_PROCESS_FAILURE,
  GET_PRODUCTION_PROCESS_REQUEST,
  GET_PRODUCTION_PROCESS_SUCCESS,
  GET_RESOURCESFORALLOCATION_REQUEST,
  GET_RESOURCESFORALLOCATION_SUCCESS,
  GET_RESOURCESFORALLOCATION_FAILURE,
  UPDATE_USER_FOR_PRODUCTION_PROCESS_REQUEST,
  UPDATE_USER_FOR_PRODUCTION_PROCESS_SUCCESS,
  UPDATE_USER_FOR_PRODUCTION_PROCESS_FAILURE,
  GET_PRODUCTION_PROCESS_FORREPORTS_REQUEST,
  GET_PRODUCTION_PROCESS_FORREPORTS_SUCCESS,
  GET_PRODUCTION_PROCESS_FORREPORTS_FAILURE,
} from "./productionProcessTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getProductionProcessRequest = () => {
  return {
    type: GET_PRODUCTION_PROCESS_REQUEST,
  };
};

const getProductionProcessSuccess = (payload) => {
  return {
    type: GET_PRODUCTION_PROCESS_SUCCESS,
    payload: payload,
  };
};

const getProductionProcessFailure = (error) => {
  return {
    type: GET_PRODUCTION_PROCESS_FAILURE,
    payload: error,
  };
};

const getProductionProcess = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getProductionProcessRequest());
    let url = `${baseUrl}/production_process/get_production_process?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getProductionProcessSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getProductionProcessFailure(err.response));
      });
  };
};

const getResourcesForAllocationRequest = () => {
  return {
    type: GET_RESOURCESFORALLOCATION_REQUEST,
  };
};

const getResourcesForAllocationSuccess = (payload) => {
  return {
    type: GET_RESOURCESFORALLOCATION_SUCCESS,
    payload: payload,
  };
};

const getResourcesForAllocationFailure = (error) => {
  return {
    type: GET_RESOURCESFORALLOCATION_FAILURE,
    payload: error,
  };
};

const getResourcesForAllocation = (
  documentNo,
  phaseNumber,
  resourceCategory,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getResourcesForAllocationRequest());
    let url = `${baseUrl}/production_allocation/get_resource?documentNo=${
      documentNo ? documentNo : ""
    }&phaseNumber=${phaseNumber ? phaseNumber : ""}&resourceCategory=${
      resourceCategory ? resourceCategory : ""
    }`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getResourcesForAllocationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getResourcesForAllocationFailure(err.response));
      });
  };
};

const updateUserForProductionProcessRequest = () => {
  return {
    type: UPDATE_USER_FOR_PRODUCTION_PROCESS_REQUEST,
  };
};

const updateUserForProductionProcessSuccess = (payload) => {
  return {
    type: UPDATE_USER_FOR_PRODUCTION_PROCESS_SUCCESS,
    payload: payload,
  };
};

const updateUserForProductionProcessFaliure = (error) => {
  return {
    type: UPDATE_USER_FOR_PRODUCTION_PROCESS_FAILURE,
    payload: error,
  };
};

const updateUserForProductionProcess = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(updateUserForProductionProcessRequest());
    let url = `${baseUrl}/production_process/update_user`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateUserForProductionProcessSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateUserForProductionProcessFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getProductionProcessForReportsRequest = () => {
  return {
    type: GET_PRODUCTION_PROCESS_FORREPORTS_REQUEST,
  };
};

const getProductionProcessForReportsSuccess = (payload) => {
  return {
    type: GET_PRODUCTION_PROCESS_FORREPORTS_SUCCESS,
    payload: payload,
  };
};

const getProductionProcessForReportsFailure = (error) => {
  return {
    type: GET_PRODUCTION_PROCESS_FORREPORTS_FAILURE,
    payload: error,
  };
};

const getProductionProcessForReports = (
  pageNo,
  rowsPerPage,
  search,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getProductionProcessForReportsRequest());
    let url = `${baseUrl}/production_process/get_production_process?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}&download=1`;

    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getProductionProcessForReportsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getProductionProcessForReportsFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getProductionProcess,
  getResourcesForAllocation,
  updateUserForProductionProcess,
  getProductionProcessForReports,
};
