import {
  GET_ITEMMASTER_REQUEST,
  GET_ITEMMASTER_SUCCESS,
  GET_ITEMMASTER_FAILURE,
  EDIT_ITEMMASTER_REQUEST,
  EDIT_ITEMMASTER_SUCCESS,
  EDIT_ITEMMASTER_FAILURE,
} from "./itemMasterTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getItemMasterRequest = () => {
  return {
    type: GET_ITEMMASTER_REQUEST,
  };
};

const getItemMasterSuccess = (payload) => {
  return {
    type: GET_ITEMMASTER_SUCCESS,
    payload: payload,
  };
};

const getItemMasterFailure = (error) => {
  return {
    type: GET_ITEMMASTER_FAILURE,
    payload: error,
  };
};

const getItemMaster = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemMasterRequest());
    let url = `${baseUrl}/material?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemMasterSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemMasterFailure(err.message));
      });
  };
};

const editItemMasterRequest = () => {
  return {
    type: EDIT_ITEMMASTER_REQUEST,
  };
};

const editItemMasterSuccess = (payload) => {
  return {
    type: EDIT_ITEMMASTER_SUCCESS,
    payload: payload,
  };
};

const editItemMasterFailure = (error) => {
  return {
    type: EDIT_ITEMMASTER_FAILURE,
    payload: error,
  };
};

const editItemMaster = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editItemMasterRequest());
    let url = `${baseUrl}/material/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editItemMasterSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editItemMasterFailure(err.response));
        return { status: false, message: err.response };
      });
  };
};

export { getItemMaster, editItemMaster };
