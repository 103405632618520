import {
  GET_ALLGRN_REQUEST,
  GET_ALLGRN_SUCCESS,
  GET_ALLGRN_FAILURE,
  GET_LINEITEMSBYGRN_REQUEST,
  GET_LINEITEMSBYGRN_SUCCESS,
  GET_LINEITEMSBYGRN_FAILURE,
  GET_ALLGRN_DATA_FOR_DOWNLOAD_REQUEST,
  GET_ALLGRN_DATA_FOR_DOWNLOAD_SUCCESS,
  GET_ALLGRN_DATA_FOR_DOWNLOAD_FAILURE,
  SET_PACKSIZE_REQUEST,
  SET_PACKSIZE_SUCCESS,
  SET_PACKSIZE_FAILURE,
} from "./grnTypes";

const initialState = {
  loading: false,
  GRN: [],
  error: "",
};

const getAllGRNReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLGRN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALLGRN_SUCCESS:
      return {
        loading: false,
        GRN: action.payload,
        error: "",
      };
    case GET_ALLGRN_FAILURE:
      return {
        loading: false,
        GRN: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLineItems = {
  loading: false,
  lineItems: [],
  error: "",
};

const getLineItemsByGrnReducer = (state = initialStateForLineItems, action) => {
  switch (action.type) {
    case GET_LINEITEMSBYGRN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LINEITEMSBYGRN_SUCCESS:
      return {
        loading: false,
        lineItems: action.payload,
        error: "",
      };
    case GET_LINEITEMSBYGRN_FAILURE:
      return {
        loading: false,
        lineItems: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDataForDownload = {
  loading: false,
  GRNDataForDownload: [],
  error: "",
};

const getAllGRNDataForDownloadReducer = (
  state = initialStateForDataForDownload,
  action
) => {
  switch (action.type) {
    case GET_ALLGRN_DATA_FOR_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALLGRN_DATA_FOR_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        GRNDataForDownload: action.payload,
        error: "",
      };
    case GET_ALLGRN_DATA_FOR_DOWNLOAD_FAILURE:
      return {
        loading: false,
        GRNDataForDownload: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPackSize = {
  loading: false,
  packSize: [],
  error: "",
};

const setPackSizeReducer = (state = initialStateForPackSize, action) => {
  switch (action.type) {
    case SET_PACKSIZE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_PACKSIZE_SUCCESS:
      return {
        loading: false,
        packSize: action.payload,
        error: "",
      };
    case SET_PACKSIZE_FAILURE:
      return {
        loading: false,
        packSize: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getAllGRNReducer,
  getLineItemsByGrnReducer,
  getAllGRNDataForDownloadReducer,
  setPackSizeReducer,
};
