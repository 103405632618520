export const GETZONES_BYCATEGORYANDWAREHOUSE_REQUEST =
  "GETZONES_BYCATEGORYANDWAREHOUSE_REQUEST";
export const GETZONES_BYCATEGORYANDWAREHOUSE_SUCCESS =
  "GETZONES_BYCATEGORYANDWAREHOUSE_SUCCESS";
export const GETZONES_BYCATEGORYANDWAREHOUSE_FAILURE =
  "GETZONES_BYCATEGORYANDWAREHOUSE_FAILURE";

export const GETOPENCATEGORYZONES_BYWAREHOUSE_REQUEST =
  "GETOPENCATEGORYZONES_BYWAREHOUSE_REQUEST";
export const GETOPENCATEGORYZONES_BYWAREHOUSE_SUCCESS =
  "GETOPENCATEGORYZONES_BYWAREHOUSE_SUCCESS";
export const GETOPENCATEGORYZONES_BYWAREHOUSE_FAILURE =
  "GETOPENCATEGORYZONES_BYWAREHOUSE_FAILURE";

export const GETALLCATEGORIES_REQUEST = "GETALLCATEGORIES_REQUEST";
export const GETALLCATEGORIES_SUCCESS = "GETALLCATEGORIES_SUCCESS";
export const GETALLCATEGORIES_FAILURE = "GETALLCATEGORIES_FAILURE";

export const ADDZONE_TOWAREHOUSE_REQUEST = "ADDZONE_TOWAREHOUSE_REQUEST";
export const ADDZONE_TOWAREHOUSE_SUCCESS = "ADDZONE_TOWAREHOUSE_SUCCESS";
export const ADDZONE_TOWAREHOUSE_FAILURE = "ADDZONE_TOWAREHOUSE_FAILURE";

export const DELETEZONE_FROMWAREHOUSE_REQUEST =
  "DELETEZONE_FROMWAREHOUSE_REQUEST";
export const DELETEZONE_FROMWAREHOUSE_SUCCESS =
  "DELETEZONE_FROMWAREHOUSE_SUCCESS";
export const DELETEZONE_FROMWAREHOUSE_FAILURE =
  "DELETEZONE_FROMWAREHOUSE_FAILURE";

/*----------------------------------------------------------------------------------------------------*/

export const GETZONES_BYWAREHOUSE_REQUEST = "GETZONES_BYWAREHOUSE_REQUEST";
export const GETZONES_BYWAREHOUSE_SUCCESS = "GETZONES_BYWAREHOUSE_SUCCESS";
export const GETZONES_BYWAREHOUSE_FAILURE = "GETZONES_BYWAREHOUSE_FAILURE";

export const GETOPENBINS_BYWAREHOUSE_REQUEST =
  "GETOPENBINS_BYWAREHOUSE_REQUEST";
export const GETOPENBINS_BYWAREHOUSE_SUCCESS =
  "GETOPENBINS_BYWAREHOUSE_SUCCESS";
export const GETOPENBINS_BYWAREHOUSE_FAILURE =
  "GETOPENBINS_BYWAREHOUSE_FAILURE";

export const GETZONES_BYITEMCODE_REQUEST = "GETZONES_BYITEMCODE_REQUEST";
export const GETZONES_BYITEMCODE_SUCCESS = "GETZONES_BYITEMCODE_SUCCESS";
export const GETZONES_BYITEMCODE_FAILURE = "GETZONES_BYITEMCODE_FAILURE";

export const GETLOCATIONS_BYITEMCODE_REQUEST =
  "GETLOCATIONS_BYITEMCODE_REQUEST";
export const GETLOCATIONS_BYITEMCODE_SUCCESS =
  "GETLOCATIONS_BYITEMCODE_SUCCESS";
export const GETLOCATIONS_BYITEMCODE_FAILURE =
  "GETLOCATIONS_BYITEMCODE_FAILURE";
