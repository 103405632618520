/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

import { addMinutes, format } from "date-fns";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getPivTracking,
  getSubModulesByRoleId,
  getUsersToAssign,
  updateUser,
  getStoreLocationByMaterialCode,
  updatePicklistLocation,
  reprintPivTracking,
  createMaterialReturnProcess,
} from "../../store";

// Config
import Config from "../../config/index";

// Router Components
import { useNavigate } from "react-router-dom";

// React-Select component and styling
import Select, { components } from "react-select";
import "../../index.css";

// Cookies
import Cookies from "universal-cookie";
import { Opacity } from "@mui/icons-material";

const cookies = new Cookies();

const MultiValue = (props) => (
  <components.MultiValue {...props}>{props.data.sku}</components.MultiValue>
);

const reprintStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForLocationsModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 500, lg: 625, xl: 625 },
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const PivTracking = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getPivTracking,
  pivTrackingReducer,
  getUsersToAssign,
  usersData,
  updateUser,
  getStoreLocationByMaterialCode,
  getStoreLocationByMaterialCodeReducer,
  updatePicklistLocation,
  reprintPivTracking,
  createMaterialReturnProcess,
}) => {
  const desLength = Config.descriptionLength;

  let loginDetails = cookies.get("loginDetailsForWMS");
  let printerConfig = cookies.get("printerConfigForWarehouse");
  let printerId = cookies.get("printerIdForWarehouse");

  const navigate = useNavigate();

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let pivTrackingModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "45426dff-5974-4e1d-8555-d0b1ce0600a7"
  );

  let viewPivTracking = pivTrackingModule
    ? pivTrackingModule.actionId.includes(
        "eb22c328-8b3b-4977-a9b2-e7ceb4fc8a86"
      )
    : null;

  let reprintAccess = pivTrackingModule
    ? pivTrackingModule.actionId.includes(
        "059b058f-4bf0-45c2-83fc-a9161abb711b"
      )
    : null;

  let createMaterialReturnAccess = pivTrackingModule
    ? pivTrackingModule.actionId.includes(
        "9a645653-4732-45df-bbfe-b11061782099"
      )
    : null;

  let updateUserAccess = pivTrackingModule
    ? pivTrackingModule.actionId.includes(
        "0f6d8c9b-c964-4acd-a500-e48abf3c98f7"
      )
    : null;

  let updateFromLocationAccess = pivTrackingModule
    ? pivTrackingModule.actionId.includes(
        "de187eb7-1b5e-42e1-b5e2-9711fb178d4c"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [loading, setLoading] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getPivTracking(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [materialCode, setMaterialCode] = useState("");

  const [storeLocationOptions, setStoreLocationOptions] = useState([]);

  useEffect(() => {
    let tempStoreLocationOptions = [];
    let data = getStoreLocationByMaterialCodeReducer.storeLocationData
      ? getStoreLocationByMaterialCodeReducer.storeLocationData
      : [];

    data.map((store) => {
      tempStoreLocationOptions.push({
        label: `${store.storingLocation} / ${store.warehouseName}`,
        value: store.storingLocation,
      });
    });
    setStoreLocationOptions(tempStoreLocationOptions);
  }, [getStoreLocationByMaterialCodeReducer]);

  const [usersOptions, setUsersOptions] = useState([]);

  useEffect(() => {
    let tempUsersOptions = [];
    let data = usersData.usersToAssign.data ? usersData.usersToAssign.data : [];

    data.map((user) => {
      tempUsersOptions.push({
        label: user.userName,
        value: user.userId,
      });
    });
    setUsersOptions(tempUsersOptions);
  }, [usersData]);

  const [skuDetails, setSkuDetails] = useState([]);

  const [uidsOptions, setUidsOptions] = useState([]);

  useEffect(() => {
    let tempUsersOptions = [];
    skuDetails.map((sku) => {
      tempUsersOptions.push({
        label: `${sku.sku}/${sku.qty}/${sku.uom}`,
        value: sku.sku,
        sku: sku.sku,
        qty: sku.qty,
      });
    });
    setUidsOptions(tempUsersOptions);
  }, [skuDetails]);

  const returnTypeOptions = [
    {
      label: "Full Picklist",
      value: 1,
    },
    {
      label: "Partial Picklist",
      value: 0,
    },
  ];

  const statusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Not Picked",
      value: 1,
    },
    {
      label: "Picked",
      value: 2,
    },
    {
      label: "Kitted",
      value: 3,
    },
    {
      label: "Delivered",
      value: 4,
    },
    {
      label: "Returned",
      value: 6,
    },
  ];

  const chargingStatusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Not Charged",
      value: 0,
    },
    {
      label: "Charged",
      value: 1,
    },
  ];

  const [state, setState] = useState({
    columns: [
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Pick List ID", accessor: "pickupListCode" },
      { Header: "Batch No / Qty", accessor: "batchNumber" },
      { Header: "Bulk Code / Des", accessor: "finalMaterialCode" },
      { Header: "Phase No / Des", accessor: "phaseNumber" },
      { Header: "Item Code / Des", accessor: "materialCode" },
      { Header: "Item Qty", accessor: "qty" },
      { Header: "From Loc", accessor: "source" },
      { Header: "To Loc", accessor: "destination" },
      {
        Header: "Start time",
        accessor: "pickupStartTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "End time",
        accessor: "pickupEndTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },

      { Header: "Location", accessor: "locations" },
      {
        Header: "charging Status",
        accessor: "chargedStatus",
        searchType: "select",
        selectOptions: chargingStatusOptions,
        placeholder: "All Status",
      },
      {
        Header: "Status",
        accessor: "status",
        searchType: "select",
        selectOptions: statusOptions,
        placeholder: "All Status",
      },
      { Header: "User", accessor: "userName" },

      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [buttonName, setButtonName] = useState("Submit");

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  const [locationsModal, setOpenLocationsModal] = useState(false);
  const [locationsData, setLocationsData] = useState([]);
  const [singlePivData, setSinglePivData] = useState({});

  const handleOpenLocationsModal = () => {
    setOpenLocationsModal(true);
  };

  const handleCloseLocationsModal = () => {
    setOpenLocationsModal(false);
  };

  useEffect(() => {
    let tempPiv = [];

    let data = pivTrackingReducer.piv.data ? pivTrackingReducer.piv.data : [];

    setErrorMsgFromApi(pivTrackingReducer?.error?.data?.msg);
    setPaginationObj(pivTrackingReducer?.piv?.pagination);
    setLoading(pivTrackingReducer.loading);

    data.map((piv) => {
      let bulkDes = piv?.finalMaterialDescription;
      let itemDes = piv.materialDescription;
      let phaseDes = piv.phaseDescription;
      let len = desLength;

      const pivObject = {
        privateKey: piv.pickupListCode,
        documentNo: piv.documentNo,
        pickupListCode: piv.pickupListCode,
        chargedStatus: piv.chargedStatus === 1 ? "Charged" : "Not Charged",
        batchNumber: `${piv.batchNumber} / ${piv.batchSize} ${piv.batchUom}`,
        finalMaterialCode: `${piv.finalMaterialCode} / ${
          bulkDes ? bulkDes.substring(0, len) : ""
        }`,
        materialCode: `${piv.materialCode} / ${
          itemDes ? itemDes.substring(0, len) : ""
        }`,
        phaseNumber: `${piv.phaseNumber} / ${
          phaseDes ? phaseDes.substring(0, len) : ""
        } `,
        qty: `${piv.qty} ${piv.uom}`,
        isFull: piv.isFull,
        source: (
          <span style={{ display: "flex" }}>
            <MDTypography variant="h6" fontWeight="regular">
              {piv.source}
            </MDTypography>
            <Tooltip title="Edit Location" style={{ marginLeft: "0.75rem" }}>
              <MDButton
                disabled={!updateFromLocationAccess}
                color="info"
                variant="gradient"
                type="button"
                iconOnly
                size="small"
                onClick={() => {
                  setSinglePivData(piv);
                  setMaterialCode(piv.materialCode);
                  getStoreLocationByMaterialCode(piv.materialCode, jwtToken);

                  handleOpenEditFromLocModal();
                }}
              >
                <Icon> edit </Icon>
              </MDButton>
            </Tooltip>
          </span>
        ),
        destination: piv.destination,
        srNo: piv.srNo,
        pickupStartTime: piv.pickupStartTime,
        pickupEndTime: piv.pickupEndTime,
        userName: (
          <span style={{ display: "flex" }}>
            <Tooltip title="Edit User">
              <MDButton
                disabled={piv.status !== 1 || !updateUserAccess}
                color="success"
                variant="gradient"
                type="button"
                iconOnly
                size="small"
                onClick={() => {
                  getUsersToAssign(jwtToken);
                  handleOpenAddUserModal(piv);
                }}
              >
                <Icon> edit_icon </Icon>
              </MDButton>
            </Tooltip>

            <MDTypography
              variant="h6"
              fontWeight="regular"
              style={{ marginLeft: "0.75rem", width: "7rem" }}
            >
              {piv.userName}
            </MDTypography>
          </span>
        ),
        status: (
          <button
            className={
              (piv.status === 1 && `status-error`) ||
              (piv.status === 2 && `status-warning`) ||
              (piv.status === 3 && `status-info`) ||
              (piv.status === 4 && `status-success`) ||
              (piv.status === 6 && `status-returned`)
            }
            style={{ width: "5.5rem" }}
          >
            {(piv.status === 1 && `Not Picked`) ||
              (piv.status === 2 && `Picked`) ||
              (piv.status === 3 && `Kitted`) ||
              (piv.status === 4 && `Delivered`) ||
              (piv.status === 6 && `Returned`)}
          </button>
        ),
        locations:
          (piv.locations.length === 1 &&
            `${piv?.locations[0]?.assetId} - ${piv?.locations[0]?.assetType}`) ||
          (piv.locations.length === 0 && "") ||
          (piv.locations.length > 1 && (
            <Tooltip title="View Locations" style={{ marginLeft: "0.75rem" }}>
              <MDButton
                color="info"
                variant="gradient"
                type="button"
                iconOnly
                size="small"
                onClick={() => {
                  setLocationsData(piv.locations);
                  handleOpenLocationsModal();
                }}
              >
                <Icon> visibility_icon </Icon>
              </MDButton>
            </Tooltip>
          )),
        action: (
          <>
            <Tooltip title="Reprint">
              <MDButton
                disabled={
                  !reprintAccess || piv.status === 1 || piv.status === 2
                }
                iconOnly
                variant="gradient"
                color="success"
                onClick={() => {
                  if (printerConfig) {
                    handleOpenReprintModal(piv);
                  }
                  if (!printerConfig) {
                    navigate("/printer-configurator");
                  }
                }}
              >
                <Icon> print </Icon>
              </MDButton>
            </Tooltip>

            {piv.status === 1 || piv.status === 6 || piv.chargedStatus === 1 ? (
              <Tooltip title="Material return is not allowed when the pickuplist is notPicked, MaterialReturned or charged.">
                <MDButton
                  style={{
                    marginLeft: "5px",
                    color: "white",
                    background: "grey",
                    opacity: 0.5,
                    cursor: "none",
                  }}
                  type="button"
                  iconOnly
                >
                  <Icon> forward </Icon>
                </MDButton>
              </Tooltip>
            ) : (
              <Tooltip title="Create material return">
                <MDButton
                  disabled={!createMaterialReturnAccess}
                  style={{ marginLeft: "5px" }}
                  color="warning"
                  variant="gradient"
                  type="button"
                  iconOnly
                  onClick={() => {
                    getUsersToAssign(jwtToken);
                    handleOpenFullMRPModal(piv);
                    setSkuDetails(
                      piv.skuDetails.length !== 0 ? piv.skuDetails : []
                    );
                  }}
                >
                  <Icon> forward </Icon>
                </MDButton>
              </Tooltip>
            )}
          </>
        ),
      };
      tempPiv.push(pivObject);
    });
    viewPivTracking && setState({ ...state, rows: tempPiv });
  }, [pivTrackingReducer]);

  const [locationsState, setLocationsState] = useState({
    columns: [
      { Header: "Asset ID", accessor: "assetId" },
      { Header: "Asset Type", accessor: "assetType" },
      { Header: "Qty", accessor: "qty" },
    ],
    rows: [],
  });

  const { columns: locationsColumns, rows: locationsRows } = locationsState;

  useEffect(() => {
    let tempLocationsObj = [];

    let data = locationsData ? locationsData : [];

    data.map((data) => {
      const locationObj = {
        assetId: data.assetId,
        assetType: data.assetType,
        qty: `${data.qty} ${data.uom}`,
      };

      tempLocationsObj.push(locationObj);
    });

    setLocationsState({ ...locationsState, rows: tempLocationsObj });
  }, [locationsData]);

  const [isErrorAddUser, setIsErrorAddUser] = useState(false);
  const [isSuccessAddUser, setIsSuccessAddUser] = useState(false);

  useEffect(() => {
    if (isErrorAddUser) {
      setTimeout(() => {
        setIsErrorAddUser(false);
      }, 3000);
    }
  }, [isErrorAddUser]);

  useEffect(() => {
    if (isSuccessAddUser) {
      setTimeout(() => {
        setIsSuccessAddUser(false);
      }, 3000);
    }
  }, [isSuccessAddUser]);

  const [userSelected, setAddUserSelected] = useState("");

  const handleAddUserChange = (selectedUser) => {
    setAddUserSelected(selectedUser);
  };

  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  const [singlePivTrackingDataForUpdate, setPivTrackingDataForUpdate] =
    useState({});

  const handleOpenAddUserModal = (data = {}) => {
    setOpenAddUserModal(true);
    setPivTrackingDataForUpdate(data);
  };

  const handleCloseAddUserModal = () => {
    setOpenAddUserModal(false);
  };

  const initialValueForAddUser = {
    user: "",
  };

  const validationSchemaAddUser = Yup.object({
    user: Yup.object().required("Select user"),
  });

  const [updateUserErrorMsg, setUpdateUserErrorMsg] = useState("");

  const onSubmitForAddUser = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let payload = {
      documentNo: singlePivTrackingDataForUpdate?.documentNo,
      phaseNumber: singlePivTrackingDataForUpdate?.phaseNumber,
      srNo: singlePivTrackingDataForUpdate?.srNo,
      pickupListCode: singlePivTrackingDataForUpdate?.pickupListCode,
      userId: userSelected?.value,
    };

    let res = await updateUser(payload, jwtToken);

    if (res.status) {
      setIsSuccessAddUser(true);
      setAddUserSelected("");
      getPivTracking(pageNo, rowsPerPage, searchObject, jwtToken);
      resetForm();

      setTimeout(() => {
        handleCloseAddUserModal();
        setButtonName("Submit");
      }, 2250);
    }
    if (!res.status) {
      setUpdateUserErrorMsg(res.data.data.message);
      setIsErrorAddUser(true);
    }
  };

  const formikForAddUser = useFormik({
    initialValues: initialValueForAddUser,
    validationSchema: validationSchemaAddUser,
    onSubmit: onSubmitForAddUser,
  });

  const [returnTypeSelected, setReturnTypeSelected] = useState("");

  const handleReturnTypeChange = (selectedUser) => {
    setReturnTypeSelected(selectedUser);
    setUidsSelected([]);
    setAddUserSelected("");
  };

  const [uidsSelected, setUidsSelected] = useState([]);

  const handleUidsChange = (selectedUser) => {
    setUidsSelected(selectedUser);
  };

  const [isErrorFullMRP, setIsErrorFullMRP] = useState(false);
  const [isSuccessFullMRP, setIsSuccessFullMRP] = useState(false);

  useEffect(() => {
    if (isErrorFullMRP) {
      setTimeout(() => {
        setIsErrorFullMRP(false);
      }, 3000);
    }
  }, [isErrorFullMRP]);

  useEffect(() => {
    if (isSuccessFullMRP) {
      setTimeout(() => {
        setIsSuccessFullMRP(false);
      }, 3000);
    }
  }, [isSuccessFullMRP]);

  const [openFullMRPModal, setOpenFullMRPModal] = useState(false);

  const handleOpenFullMRPModal = (data = {}) => {
    setOpenFullMRPModal(true);
    setPivTrackingDataForUpdate(data);
  };

  const handleCloseFullMRPModal = () => {
    setOpenFullMRPModal(false);
    setReturnTypeSelected("");
    setAddUserSelected("");
  };

  const [openConfirmReturnModal, setOpenConfirmReturnModal] = useState(false);

  const handleOpenConfirmReturnModal = () => {
    setOpenConfirmReturnModal(true);
  };
  const handleCloseConfirmReturnModal = () => {
    setOpenConfirmReturnModal(false);
  };

  const initialValueForFullMRP = {
    user: "",
  };

  const validationSchemaFullMRP = Yup.object({
    returnType: Yup.object().required("Select return type"),
    user: Yup.object().required("Select user"),
  });

  const [updateFullMRPErrorMsg, setUpdateFullMRPErrorMsg] = useState("");

  const onSubmitForFullMRP = async (values, { resetForm }) => {
    handleOpenConfirmReturnModal();
  };

  const formikForFullMRP = useFormik({
    initialValues: initialValueForFullMRP,
    validationSchema: validationSchemaFullMRP,
    onSubmit: onSubmitForFullMRP,
  });

  const [isErrorEditFromLoc, setIsErrorEditFromLoc] = useState(false);
  const [isSuccessEditFromLoc, setIsSuccessEditFromLoc] = useState(false);

  useEffect(() => {
    if (isErrorEditFromLoc) {
      setTimeout(() => {
        setIsErrorEditFromLoc(false);
      }, 3000);
    }
  }, [isErrorEditFromLoc]);

  useEffect(() => {
    if (isSuccessEditFromLoc) {
      setTimeout(() => {
        setIsSuccessEditFromLoc(false);
      }, 3000);
    }
  }, [isSuccessEditFromLoc]);

  const [fromLocSelected, setFromLocSelected] = useState("");

  const handleFromLocChange = (selectedFromLoc) => {
    setFromLocSelected(selectedFromLoc);
  };

  const [openEditFromLocModal, setOpenEditFromLocModal] = useState(false);

  const handleOpenEditFromLocModal = () => {
    setOpenEditFromLocModal(true);
  };

  const handleCloseEditFromLocModal = () => {
    setOpenEditFromLocModal(false);
  };

  const initialValueForEditFromLoc = {
    fromLoc: "",
  };

  const validationSchemaEditFromLoc = Yup.object({
    fromLoc: Yup.object().required("Select location"),
  });

  const [fromLocErrorMsg, setFromLocErrorMsg] = useState("");

  const onSubmitForEditFromLoc = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    const payload = {
      srNo: singlePivData.srNo,
      documentNo: singlePivData.documentNo,
      phaseNumber: singlePivData.phaseNumber,
      pickupListCode: singlePivData.pickupListCode,
      source: fromLocSelected.value,
      destination: "",
    };

    let res = await updatePicklistLocation([payload], jwtToken);

    if (res.status) {
      setIsSuccessEditFromLoc(true);
      setFromLocSelected("");
      getPivTracking(pageNo, rowsPerPage, searchObject, jwtToken);
      resetForm();

      setTimeout(() => {
        handleCloseEditFromLocModal();
        setButtonName("Submit");
      }, 2250);
    }
    if (!res.status) {
      setFromLocErrorMsg(res.data.data.message);
      setIsErrorEditFromLoc(true);
    }
  };

  const formikForEditFromLoc = useFormik({
    initialValues: initialValueForEditFromLoc,
    validationSchema: validationSchemaEditFromLoc,
    onSubmit: onSubmitForEditFromLoc,
  });

  const [isErrorReprint, setIsErrorReprint] = useState(false);
  const [isSuccessReprint, setIsSuccessReprint] = useState(false);

  useEffect(() => {
    if (isErrorReprint) {
      setTimeout(() => {
        setIsErrorReprint(false);
      }, 3000);
    }
  }, [isErrorReprint]);

  useEffect(() => {
    if (isSuccessReprint) {
      setTimeout(() => {
        setIsSuccessReprint(false);
      }, 3000);
    }
  }, [isSuccessReprint]);

  const [reprintData, setReprintData] = useState({});
  const [openReprintModal, setOpenReprintModal] = useState(false);

  const handleOpenReprintModal = (data = {}) => {
    setReprintData(data);
    setOpenReprintModal(true);
  };

  const handleCloseReprintModal = () => {
    setOpenReprintModal(false);
  };

  const [reprintErrorMsg, setReprintErrorMsg] = useState("");
  const [printButtonName, setPrintButtonName] = useState("Yes");
  const onClickReprint = async () => {
    setPrintButtonName("Please wait...");
    const payload = {
      ...reprintData,
      printerId,
    };

    let res = await reprintPivTracking(payload, jwtToken);

    if (res.status) {
      setIsSuccessReprint(true);
      getPivTracking(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        setOpenReprintModal(false);
        setPrintButtonName("Yes");
      }, 2250);
    }
    if (!res.status) {
      setReprintErrorMsg(res.data.data.message);
      setIsErrorReprint(true);
      setPrintButtonName("Yes");
    }
  };

  const [isErrorConfirmReturn, setIsErrorConfirmReturn] = useState(false);
  const [isSuccessConfirmReturn, setIsSuccessConfirmReturn] = useState(false);

  useEffect(() => {
    if (isSuccessConfirmReturn) {
      setTimeout(() => {
        setIsSuccessConfirmReturn(false);
      }, 3000);
    }
  }, [isSuccessConfirmReturn]);
  useEffect(() => {
    if (isErrorConfirmReturn) {
      setTimeout(() => {
        setIsErrorConfirmReturn(false);
      }, 3000);
    }
  }, [isErrorConfirmReturn]);

  const [confirmErrorMsg, setConfirmErrorMsg] = useState("");

  const confirmReturnData = async () => {
    setPrintButtonName("Please wait...");
    let payload = {
      skuDetails: uidsSelected.length !== 0 ? uidsSelected : skuDetails,
      pickupListCode: singlePivTrackingDataForUpdate?.pickupListCode,
      documentNo: singlePivTrackingDataForUpdate?.documentNo,
      srNo: singlePivTrackingDataForUpdate?.srNo,
      phaseNumber: singlePivTrackingDataForUpdate?.phaseNumber,
      materialCode: singlePivTrackingDataForUpdate?.materialCode,
      qty: singlePivTrackingDataForUpdate?.qty,
      source: singlePivTrackingDataForUpdate?.source,
      destination: singlePivTrackingDataForUpdate?.destination,
      isFull: returnTypeSelected?.value,

      materialReturnUser: userSelected?.value,
    };

    let res = await createMaterialReturnProcess(payload, jwtToken);

    if (res.status) {
      setIsSuccessConfirmReturn(true);
      setAddUserSelected("");
      getPivTracking(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleCloseFullMRPModal();
        handleCloseConfirmReturnModal();
        setPrintButtonName("Yes");
      }, 2250);
    }
    if (!res.status) {
      setConfirmErrorMsg(res?.data?.data?.msg);
      setIsErrorConfirmReturn(true);
      setPrintButtonName("Yes");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  errorMsg={errorMsgFromApi}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>
              <div>
                <Modal
                  open={locationsModal}
                  onClose={handleCloseLocationsModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForLocationsModal}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Locations
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseLocationsModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>

                    <MDBox pt={1} pb={3} px={3}>
                      <DataTable
                        table={{
                          columns: locationsColumns,
                          rows: locationsRows,
                        }}
                        isSorted={true}
                        entriesPerPage={{
                          defaultValue: 30,
                          entries: [30, 50, 75, 100],
                        }}
                        showTotalEntries={true}
                        noEndBorder
                        options={{ draggable: false }}
                      />
                    </MDBox>
                  </MDCard>
                </Modal>
              </div>

              <Modal
                open={openEditFromLocModal}
                onClose={handleCloseEditFromLocModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Edit From Location
                    </MDTypography>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseEditFromLocModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForEditFromLoc.handleSubmit}
                    >
                      <MDBox>
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Select Source Location
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            placeholder="Select location..."
                            name="fromLoc"
                            value={fromLocSelected}
                            options={storeLocationOptions}
                            onChange={(selectedOption) => {
                              handleFromLocChange(selectedOption);
                              formikForEditFromLoc.setFieldValue(
                                "fromLoc",
                                selectedOption
                              );
                            }}
                          />
                          {formikForEditFromLoc.errors.fromLoc && (
                            <TextError
                              msg={formikForEditFromLoc.errors.fromLoc}
                            />
                          )}
                        </MDBox>

                        <MDBox mt={2}>
                          <Collapse in={isSuccessEditFromLoc}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessEditFromLoc(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Location edited successfully!
                            </Alert>
                          </Collapse>
                          <Collapse in={isErrorEditFromLoc}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorEditFromLoc(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {/* Could not edit the Location! */}
                              {fromLocErrorMsg}
                            </Alert>
                          </Collapse>
                        </MDBox>

                        <div className="mt-3">
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                            disabled={
                              !formikForEditFromLoc.isValid ||
                              printButtonName === "Please wait..."
                            }
                          >
                            {printButtonName}
                          </MDButton>
                        </div>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openAddUserModal}
                onClose={handleCloseAddUserModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Change User & Confirm
                    </MDTypography>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseAddUserModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForAddUser.handleSubmit}
                    >
                      <MDBox>
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Picklist Id:-{" "}
                            <span style={{ fontWeight: 400 }}>
                              {singlePivTrackingDataForUpdate?.pickupListCode}
                            </span>
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Process Order No:-{" "}
                            <span style={{ fontWeight: 400 }}>
                              {singlePivTrackingDataForUpdate?.documentNo}
                            </span>
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Item Code:-{" "}
                            <span style={{ fontWeight: 400 }}>
                              {singlePivTrackingDataForUpdate?.materialCode}
                            </span>
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Select User
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            placeholder="Select user..."
                            name="user"
                            value={userSelected}
                            options={usersOptions}
                            onChange={(selectedOption) => {
                              handleAddUserChange(selectedOption);
                              formikForAddUser.setFieldValue(
                                "user",
                                selectedOption
                              );
                            }}
                          />
                          {formikForAddUser.errors.user && (
                            <TextError msg={formikForAddUser.errors.user} />
                          )}
                        </MDBox>

                        <MDBox mt={2}>
                          <Collapse in={isSuccessAddUser}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessAddUser(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              User added and picklist released successfully!
                            </Alert>
                          </Collapse>
                          <Collapse in={isErrorAddUser}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorAddUser(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {/* Could not Add the User and release! */}
                              {updateUserErrorMsg}
                            </Alert>
                          </Collapse>
                        </MDBox>

                        <div className="mt-3">
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="success"
                            fullWidth
                            disabled={
                              !formikForAddUser.isValid ||
                              buttonName === "Please wait..."
                            }
                          >
                            {buttonName}
                          </MDButton>
                        </div>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={openFullMRPModal}
                onClose={handleCloseFullMRPModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Assign User & Confirm
                    </MDTypography>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseFullMRPModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForFullMRP.handleSubmit}
                    >
                      <MDBox>
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Return Type
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            placeholder="Select return type..."
                            name="returnType"
                            value={returnTypeSelected}
                            options={returnTypeOptions}
                            onChange={(selectedOption) => {
                              handleReturnTypeChange(selectedOption);
                              formikForFullMRP.setFieldValue(
                                "returnType",
                                selectedOption
                              );
                            }}
                          />
                          {formikForFullMRP.errors.returnType && (
                            <TextError
                              msg={formikForFullMRP.errors.returnType}
                            />
                          )}
                        </MDBox>
                        {returnTypeSelected?.value === 0 && (
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              UID
                            </MDTypography>

                            <Select
                              isMulti
                              isClearable
                              className="select-css"
                              placeholder="Select return type..."
                              name="uid"
                              value={uidsSelected}
                              options={uidsOptions}
                              components={{ MultiValue }}
                              onChange={(selectedOption) => {
                                handleUidsChange(selectedOption);
                                formikForFullMRP.setFieldValue(
                                  "uid",
                                  selectedOption
                                );
                              }}
                            />
                            {formikForFullMRP.errors.uid && (
                              <TextError msg={formikForFullMRP.errors.uid} />
                            )}
                          </MDBox>
                        )}

                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Select User
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            placeholder="Select user..."
                            name="user"
                            value={userSelected}
                            options={usersOptions}
                            onChange={(selectedOption) => {
                              handleAddUserChange(selectedOption);
                              formikForFullMRP.setFieldValue(
                                "user",
                                selectedOption
                              );
                            }}
                          />
                          {formikForFullMRP.errors.user && (
                            <TextError msg={formikForFullMRP.errors.user} />
                          )}
                        </MDBox>

                        <div className="mt-3">
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="warning"
                            fullWidth
                            disabled={
                              !formikForFullMRP.isValid ||
                              (returnTypeSelected?.value === 0 &&
                                uidsSelected.length === 0) ||
                              buttonName === "Please wait..."
                            }
                          >
                            Confirm
                          </MDButton>
                        </div>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openConfirmReturnModal}
                onClose={handleCloseConfirmReturnModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>Are you sure you want to submit?</MDTypography>
                  <MDBox mt={2} display="flex">
                    <div>
                      <MDButton
                        style={{ width: "100%", marginRight: "0.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseConfirmReturnModal}
                      >
                        Cancel
                      </MDButton>
                    </div>
                    <div style={{ float: "right" }}>
                      <MDButton
                        style={{ width: "100%", marginLeft: "0.5rem" }}
                        color="warning"
                        onClick={confirmReturnData}
                        disabled={printButtonName === "Please wait..."}
                      >
                        {printButtonName}
                      </MDButton>
                    </div>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isErrorConfirmReturn}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorConfirmReturn(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {confirmErrorMsg}
                      </Alert>
                    </Collapse>
                    <Collapse in={isSuccessConfirmReturn}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessConfirmReturn(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Material returned successfully
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openReprintModal}
                onClose={handleCloseReprintModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={reprintStyle}>
                  <MDTypography>Are you sure you want to print ?</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseReprintModal}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{ width: "48%", marginLeft: "0.5rem" }}
                      color="success"
                      onClick={onClickReprint}
                      disabled={buttonName === "Please wait..."}
                    >
                      {buttonName}
                    </MDButton>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessReprint}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessReprint(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Reprint successful!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorReprint}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorReprint(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {/* Could not reprint */}
                        {reprintErrorMsg}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    pivTrackingReducer: state.getPivTrackingReducer,
    usersData: state.getUsersToAssignReducer,
    getStoreLocationByMaterialCodeReducer:
      state.getStoreLocationByMaterialCodeReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getPivTracking: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getPivTracking(pageNo, rowsPerPage, searchObject, jwtToken)),
    getUsersToAssign: (jwtToken) => dispatch(getUsersToAssign(jwtToken)),

    updateUser: (payload, jwtToken) => dispatch(updateUser(payload, jwtToken)),
    getStoreLocationByMaterialCode: (materialCode, jwtToken) =>
      dispatch(getStoreLocationByMaterialCode(materialCode, jwtToken)),
    updatePicklistLocation: (payload, jwtToken) =>
      dispatch(updatePicklistLocation(payload, jwtToken)),
    reprintPivTracking: (payload, jwtToken) =>
      dispatch(reprintPivTracking(payload, jwtToken)),
    createMaterialReturnProcess: (payload, jwtToken) =>
      dispatch(createMaterialReturnProcess(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(PivTracking);
