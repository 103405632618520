/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getAddedPiv,
  removePiv,
  releasePiv,
  validationForRelease,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../../config/index";

const desLength = Config.descriptionLength;

const cookies = new Cookies();

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const ReleaseForIssuance = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getAddedPiv,
  addedPiv,
  removePiv,
  releasePiv,
  validationForRelease,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let userId = loginDetails?.data.userId;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let palletModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "0dff8f23-2f51-47b0-a611-4ca56d8d3217"
  );

  let viewPivAccess = palletModule
    ? palletModule.actionId.includes("7917a56f-ef42-4926-a894-276836998cd7")
    : null;

  let removeAccess = palletModule
    ? palletModule.actionId.includes("5e0e21ac-7919-4340-98fe-d7c7fae901ef")
    : null;

  let releaseAccess = palletModule
    ? palletModule.actionId.includes("0c21f932-bdf6-4274-80c2-11033d5cca87")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getAddedPiv(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [state, setState] = useState({
    columns: [
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Batch No / Qty", accessor: "batchNumber" },
      { Header: "Resource Category/ Resource", accessor: "resourceCategory" },
      { Header: "Bulk Code / Des", accessor: "finalMaterialCode" },
      { Header: "MRP Type", accessor: "mrpType" },
      { Header: "RM Category", accessor: "rmCategory" },
      { Header: "Phase No", accessor: "phaseNumber" },
      { Header: "Phase Des", accessor: "phaseDescription" },
      { Header: "Item Code / Des", accessor: "materialCode" },
      { Header: "Item Qty", accessor: "qty" },
      { Header: "From Loc", accessor: "source" },
      { Header: "To Loc", accessor: "destination" },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [loading, setLoading] = useState(false);

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  useEffect(() => {
    let tempPiv = [];

    let data = addedPiv.piv.data ? addedPiv.piv.data : [];

    setErrorMsgFromApi(addedPiv?.error?.data?.msg);
    setLoading(addedPiv.loading);
    setPaginationObj(addedPiv?.piv?.pagination);

    data.map((piv, index) => {
      let bulkDes = piv?.finalMaterialDescription;
      let itemDes = piv.materialDescription;
      let phaseDes = piv.phaseDescription;
      let rmDes = piv.rmCatDescription;
      let len = desLength;

      const pivObject = {
        privateKey: piv.pickupListCode,
        documentNo: piv.documentNo,
        batchNumber: `${piv.batchNumber} / ${piv.batchSize} ${piv.batchUom}`,
        finalMaterialCode: `${piv.finalMaterialCode} / ${
          bulkDes ? bulkDes.substring(0, len) : ""
        }`,
        materialCode: `${piv.materialCode} / ${
          itemDes ? itemDes.substring(0, len) : ""
        }`,
        mrpType: piv.mrpType,
        rmCategory: `${piv.rmCategory} / ${
          rmDes ? rmDes.substring(0, len) : ""
        }`,
        phaseNumber: piv.phaseNumber,
        phaseDescription: phaseDes ? phaseDes.substring(0, len) : "",
        qty: `${piv.qty} ${piv.uom}`,
        source: piv.source,
        destination: piv.destination,
        srNo: piv.srNo,
        pickupListCode: piv.pickupListCode,
        resourceCategory: `${piv.resourceCategory} - ${piv.resource}`,
        warehouseType: piv.warehouseType,
      };
      tempPiv.push(pivObject);
    });

    viewPivAccess && setState({ ...state, rows: tempPiv });
  }, [addedPiv]);

  const [selectedPivData, setSelectedPivData] = useState([]);

  const pivDataSelectionHandler = (data) => {
    setSelectedPivData(data);
  };

  const [isErrorReleaseForIssuance, setIsErrorReleaseForIssuance] =
    useState(false);
  const [isSuccessReleaseForIssuance, setIsSuccessReleaseForIssuance] =
    useState(false);

  useEffect(() => {
    if (isErrorReleaseForIssuance) {
      setTimeout(() => {
        setIsErrorReleaseForIssuance(false);
      }, 3000);
    }
  }, [isErrorReleaseForIssuance]);

  useEffect(() => {
    if (isSuccessReleaseForIssuance) {
      setTimeout(() => {
        setIsSuccessReleaseForIssuance(false);
      }, 3000);
    }
  }, [isSuccessReleaseForIssuance]);

  const [openReleaseForIssuanceModal, setOpenReleaseForIssuanceModal] =
    useState(false);

  const handleOpenReleaseForIssuanceModal = () => {
    setOpenReleaseForIssuanceModal(true);
  };
  const handleCloseReleaseForIssuanceModal = () => {
    setOpenReleaseForIssuanceModal(false);
  };

  const [releaseRecordsErrorMsg, setReleaseRecordsErrorMsg] = useState("");

  const releasePivHandler = async () => {
    let payload = [];

    selectedPivData.map((data) => {
      const tempPayload = {
        documentNo: data.documentNo,
        phaseNumber: data.phaseNumber,
        pickupListCode: data.pickupListCode,
        srNo: data.srNo,
        warehouseType: data.warehouseType,
      };

      payload.push(tempPayload);
    });

    let res = await releasePiv(payload, jwtToken);

    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessReleaseForIssuance(true);
      getAddedPiv(pageNo, rowsPerPage, searchObject, jwtToken);
      handleCloseReleaseForIssuanceModal();
    }
    if (!res.status) {
      setReleaseRecordsErrorMsg(res.data.data.message);
      setIsErrorReleaseForIssuance(true);
      setErrorMsgFromApi(true);
    }
  };

  const [isErrorRemoveRecords, setIsErrorRemoveRecords] = useState(false);
  const [isSuccessRemoveRecords, setIsSuccessRemoveRecords] = useState(false);

  useEffect(() => {
    if (isErrorRemoveRecords) {
      setTimeout(() => {
        setIsErrorRemoveRecords(false);
      }, 3000);
    }
  }, [isErrorRemoveRecords]);

  useEffect(() => {
    if (isSuccessRemoveRecords) {
      setTimeout(() => {
        setIsSuccessRemoveRecords(false);
      }, 3000);
    }
  }, [isSuccessRemoveRecords]);

  const [openRemoveRecordsModal, setOpenRemoveRecordsModal] = useState(false);

  const handleOpenRemoveRecordsModal = () => {
    setOpenRemoveRecordsModal(true);
  };
  const handleCloseRemoveRecordsModal = () => {
    setOpenRemoveRecordsModal(false);
  };

  const [removeRecordsErrorMsg, setRemoveRecordsErrorMsg] = useState("");

  const removeRecordsHandler = async () => {
    let payload = [];

    selectedPivData.map((data) => {
      const tempPayload = {
        documentNo: data.documentNo,
        phaseNumber: data.phaseNumber,
        pickupListCode: data.pickupListCode,
        srNo: data.srNo,
      };

      payload.push(tempPayload);
    });

    let res = await removePiv(payload, jwtToken);

    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessRemoveRecords(true);
      getAddedPiv(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleCloseRemoveRecordsModal();
      }, 2250);
    }
    if (!res.status) {
      setRemoveRecordsErrorMsg(res.data.data.message);
      setIsErrorRemoveRecords(true);
    }
  };

  const [validationErrorMsg, setValidationErrorMsg] = useState("");
  const [errorDocumentNo, setErrorDocumentNo] = useState("");
  const [validationErrorArray, setValidationErrorArray] = useState([]);

  useEffect(() => {
    setValidationErrorMsg("");
    setErrorDocumentNo("");
  }, [selectedPivData]);

  useEffect(() => {
    const msg = validationErrorArray.map((data) => {
      return `${data.documentNo}-${data.phaseNumber}`;
    });

    setErrorDocumentNo(msg.join(", "));
  }, [validationErrorArray]);

  const releaseValidationHandler = async () => {
    let payload = [];

    selectedPivData.map((data) => {
      const tempPayload = {
        pickupListCode: data.pickupListCode,
        srNo: data.srNo,
        documentNo: data.documentNo,
        phaseNumber: data.phaseNumber,
        resourceCategory: data.resourceCategory,
      };

      payload.push(tempPayload);
    });

    let res = await validationForRelease(payload, jwtToken);

    if (res.status) {
      handleOpenReleaseForIssuanceModal();
    }

    if (!res.status) {
      setValidationErrorArray(res.data.data.data);
      setValidationErrorMsg(res.data.data.msg);
    }
  };

  return (
    <div>
      <MDBox mt={-6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mt={-2}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  errorMsg={errorMsgFromApi}
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                  uncheckProp={uncheck}
                  selection={true}
                  selectionButtonClick={pivDataSelectionHandler}
                  selectionButtonComponent={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <span>
                        <Tooltip title="Remove">
                          <MDButton
                            type="button"
                            color="white"
                            style={{
                              marginRight: "15px",
                              color: "white",
                              border: "1px solid #ef5350",
                              background: "#ef5350",
                            }}
                            onClick={() => {
                              handleOpenRemoveRecordsModal();
                            }}
                            disabled={!removeAccess}
                          >
                            <CloseOutlinedIcon fontSize="large" /> &nbsp; Remove
                          </MDButton>
                        </Tooltip>
                        <Tooltip title="Release">
                          <MDButton
                            type="button"
                            variant="gradient"
                            color="warning"
                            style={{
                              marginLeft: "0.5rem",
                            }}
                            onClick={() => {
                              releaseValidationHandler();
                            }}
                            disabled={!releaseAccess}
                          >
                            Release
                          </MDButton>
                        </Tooltip>
                      </span>
                      <span>
                        {validationErrorMsg && (
                          <>
                            <MDTypography
                              color="error"
                              variant="p"
                              style={{
                                // textAlign: "center",
                                marginLeft: "1rem",
                                marginTop: "1rem",
                              }}
                            >
                              {validationErrorMsg}
                            </MDTypography>
                            <br />
                            <MDTypography
                              color="error"
                              variant="p"
                              style={{
                                // textAlign: "center",
                                marginLeft: "1rem",
                                // marginTop: "1rem",
                              }}
                            >
                              Document No - {errorDocumentNo}
                            </MDTypography>
                          </>
                        )}
                      </span>
                    </span>
                  }
                />
              </MDBox>

              <Modal
                open={openReleaseForIssuanceModal}
                onClose={handleCloseReleaseForIssuanceModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>
                    Are you sure you want to Release the Selected Records?
                  </MDTypography>
                  <MDBox mt={3} display="flex">
                    <div>
                      <MDButton
                        style={{ width: "100%", marginRight: "0.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseReleaseForIssuanceModal}
                      >
                        Cancel
                      </MDButton>
                    </div>
                    <div style={{ float: "right" }}>
                      <MDButton
                        style={{ width: "100%", marginLeft: "0.5rem" }}
                        color="warning"
                        onClick={releasePivHandler}
                      >
                        Release
                      </MDButton>
                    </div>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessReleaseForIssuance}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessReleaseForIssuance(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Released For Issuance successfully!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorReleaseForIssuance}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorReleaseForIssuance(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {/* Could not Release For Issuance! */}
                        {releaseRecordsErrorMsg}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={openRemoveRecordsModal}
                onClose={handleCloseRemoveRecordsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>
                    Are you sure you want to Remove the Selected Records?
                  </MDTypography>
                  <MDBox mt={3} display="flex">
                    <div>
                      <MDButton
                        style={{ width: "100%", marginRight: "0.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseRemoveRecordsModal}
                      >
                        Cancel
                      </MDButton>
                    </div>
                    <div style={{ float: "right" }}>
                      <MDButton
                        style={{ width: "100%", marginLeft: "0.5rem" }}
                        color="error"
                        onClick={removeRecordsHandler}
                      >
                        Remove
                      </MDButton>
                    </div>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessRemoveRecords}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessRemoveRecords(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Removed successfully!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorRemoveRecords}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorRemoveRecords(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {/* Could not Remove! */}
                        {removeRecordsErrorMsg}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    addedPiv: state.getAddedPivReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getAddedPiv: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getAddedPiv(pageNo, rowsPerPage, searchObject, jwtToken)),
    removePiv: (payload, jwtToken) => dispatch(removePiv(payload, jwtToken)),
    releasePiv: (payload, jwtToken) => dispatch(releasePiv(payload, jwtToken)),
    validationForRelease: (payload, jwtToken) =>
      dispatch(validationForRelease(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseForIssuance);
