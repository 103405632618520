/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

import { CSVLink } from "react-csv";

// Functions from store
import {
  getSubModulesByRoleId,
  getSkuForReprint,
  getSkuForReprintForReports,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";

// Config file
import Config from "../../../config/index";
import { addMinutes, format } from "date-fns";

let desLength = Config.descriptionLength;
const cookies = new Cookies();

const styleForEditExpiry = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForDownloadModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ReprintReport = ({
  getSkuForReprint,
  skuForReprint,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getSkuForReprintForReports,
  skuForDownload,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userId = loginDetails?.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let reportsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "07390a6c-3bf9-4474-9396-b7d6192eb805"
  );

  let viewReportsAccess = reportsModule
    ? reportsModule.actionId.includes("c6f43e5e-3578-4a16-8755-a12581c224a7")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [loading, setLoading] = useState(true);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getSkuForReprint(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [state, setState] = useState({
    columns: [
      { Header: "Pallet Id", accessor: "palletId", align: "left" },
      { Header: "UID", accessor: "sku", align: "left" },
      { Header: "GRN No", accessor: "grnNumber", align: "left" },
      { Header: "Batch", accessor: "batchNumber", align: "left" },
      { Header: "Inv Qty", accessor: "invoiceQty", align: "left" },
      { Header: "Actual Qty", accessor: "actualQty", align: "left" },
      {
        Header: "Material Code / Des",
        accessor: "materialCode",
        align: "left",
      },
      { Header: "PO No", accessor: "asnNumber", align: "left" },
      { Header: "Inv No", accessor: "invoiceNumber", align: "left" },
      { Header: "GRN Date", accessor: "grnDate", align: "left" },
      {
        Header: "Vendor Code / Name",
        accessor: "vendorCode",
        align: "left",
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  useEffect(() => {
    let tempSkus = [];
    let data = skuForReprint.sku.data ? skuForReprint.sku.data : [];

    setLoading(skuForReprint.loading);
    setPaginationObj(skuForReprint?.sku?.pagination);

    data.map((sku) => {
      const formatDate = sku.grnDate ? new Date(sku.grnDate) : "";

      let des = sku?.materialDescription;
      let len = desLength;
      const object = {
        privateKey: sku.grnNumber,
        sku: sku.sku,
        palletId: sku.palletId,
        grnNumber: sku.grnNumber,
        batchNumber: sku.batchNumber,
        materialCode: `${sku.materialCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        invoiceQty: `${sku.invoiceQty} ${sku.uom}`,
        actualQty: `${sku.actualQty} ${sku.uom}`,
        invoiceNumber: sku.invoiceNumber,
        asnNumber: sku.asnNumber,
        vendorCode: `${sku.vendorCode} / ${sku.vendorName}`,
        grnDate: sku.grnDate
          ? format(
              addMinutes(formatDate, formatDate.getTimezoneOffset()),
              "do MMMM yyyy"
            )
          : "",
      };
      tempSkus.push(object);
    });

    viewReportsAccess && setState({ ...state, rows: tempSkus });
  }, [skuForReprint]);

  const [loadingData, setLoadingData] = useState(false);
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    let tempReports = [];
    let data = skuForDownload.sku.data ? skuForDownload.sku.data : [];

    data.map((sku) => {
      let des = sku?.materialDescription;
      let len = desLength;
      const formatDate = sku.grnDate ? new Date(sku.grnDate) : "";

      const dataObject = {
        sku: sku.sku,
        palletId: sku.palletId,
        grnNumber: sku.grnNumber,
        batchNumber: sku.batchNumber,
        materialCode: `${sku.materialCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        invoiceQty: `${sku.invoiceQty} ${sku.uom}`,
        actualQty: `${sku.actualQty} ${sku.uom}`,
        invoiceNumber: sku.invoiceNumber,
        asnNumber: sku.asnNumber,
        vendorCode: `${sku.vendorCode} / ${sku.vendorName}`,
        grnDate: sku.grnDate
          ? format(
              addMinutes(formatDate, formatDate.getTimezoneOffset()),
              "do MMMM yyyy"
            )
          : "",
      };
      tempReports.push(dataObject);
    });
    setDownloadData(tempReports);
  }, [skuForDownload]);

  const headers = [
    { label: "Pallet Id", key: "palletId" },
    { label: "UID", key: "sku" },
    { label: "GRN No", key: "grnNumber" },
    { label: "Batch", key: "batchNumber" },
    { label: "Inv Qty", key: "invoiceQty" },
    { label: "Actual Qty", key: "actualQty" },
    {
      label: "Material Code / Des",
      key: "materialCode",
    },
    { label: "PO No", key: "asnNumber" },
    { label: "Inv No", key: "invoiceNumber" },
    { label: "GRN Date", key: "grnDate" },
    {
      label: "Vendor Code / Name",
      key: "vendorCode",
    },
  ];

  const csvLink = {
    filename: "Reprint Report.csv",
    headers: headers,
    data: downloadData,
  };

  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [disableDownloadButton, setDisableDownloadButton] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const handleOpenDownloadModal = (data = {}) => {
    setOpenDownloadModal(true);
  };

  const handleCloseDownloadModal = () => {
    setDisableDownloadButton(false);
    setOpenDownloadModal(false);
  };

  const downloadHandler = async () => {
    setLoadingData(true);

    let res = await getSkuForReprintForReports(
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    );

    if (res.status) {
      setModalMsg("Ready for download!");
      setLoadingData(false);
      handleOpenDownloadModal();
    }
    if (!res.status) {
      setModalMsg("Could not prepare the data for download!");
      setLoadingData(false);
      setDisableDownloadButton(true);
      handleOpenDownloadModal();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {viewReportsAccess && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  // mb={-6}
                >
                  <MDBox color="text">
                    <Tooltip title="Download">
                      <MDButton
                        style={{ marginRight: "1rem", marginLeft: "10px" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        iconOnly
                        onClick={() => {
                          downloadHandler();
                        }}
                      >
                        <Icon>download</Icon>
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              )}

              <MDBox mt={viewReportsAccess ? -3 : 0}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>

              <Modal
                open={openDownloadModal}
                onClose={handleCloseDownloadModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForDownloadModal}>
                  <MDTypography>{`${
                    disableDownloadButton
                      ? "Could not prepare the data for download!"
                      : "Ready for download!"
                  }`}</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDownloadModal}
                    >
                      Cancel
                    </MDButton>
                    {!disableDownloadButton && (
                      <CSVLink {...csvLink}>
                        <MDButton
                          style={{ width: "48%", marginRight: "0.5rem" }}
                          type="button"
                          variant="outlined"
                          color="error"
                        >
                          Download
                        </MDButton>
                      </CSVLink>
                    )}
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {loadingData && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <MDTypography color="warning" variant="h2">
            Preparing Data. Please wait...
          </MDTypography>
        </Backdrop>
      )}
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    skuForReprint: state.skuForReprint,
    skuForDownload: state.getSkuForReprintForReportsReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getSkuForReprint: (pageNo, rowsPerPage, search, jwtToken) =>
      dispatch(getSkuForReprint(pageNo, rowsPerPage, search, jwtToken)),
    getSkuForReprintForReports: (pageNo, rowsPerPage, search, jwtToken) =>
      dispatch(
        getSkuForReprintForReports(pageNo, rowsPerPage, search, jwtToken)
      ),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(ReprintReport);
