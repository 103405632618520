export const GET_STORE_ISSUANCE_REQUEST = "GET_STORE_ISSUANCE_REQUEST";
export const GET_STORE_ISSUANCE_SUCCESS = "GET_STORE_ISSUANCE_SUCCESS";
export const GET_STORE_ISSUANCE_FAILURE = "GET_STORE_ISSUANCE_FAILURE";

export const CREATE_ADD_TO_RELEASE_REQUEST = "CREATE_ADD_TO_RELEASE_REQUEST";
export const CREATE_ADD_TO_RELEASE_SUCCESS = "CREATE_ADD_TO_RELEASE_SUCCESS";
export const CREATE_ADD_TO_RELEASE_FAILURE = "CREATE_ADD_TO_RELEASE_FAILURE";

export const VALIDATION_STOREISSUANCE_REQUEST =
  "VALIDATION_STOREISSUANCE_REQUEST";
export const VALIDATION_STOREISSUANCE_SUCCESS =
  "VALIDATION_STOREISSUANCE_SUCCESS";
export const VALIDATION_STOREISSUANCE_FAILURE =
  "VALIDATION_STOREISSUANCE_FAILURE";
