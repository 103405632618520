import {
  GET_WORK_FLOW_REQUEST,
  GET_WORK_FLOW_SUCCESS,
  GET_WORK_FLOW_FAILURE,
  GET_LINEITEMSBYGRN_REQUEST,
  GET_LINEITEMSBYGRN_SUCCESS,
  GET_LINEITEMSBYGRN_FAILURE,
  GET_WORK_FLOW_DATA_FOR_DOWNLOAD_REQUEST,
  GET_WORK_FLOW_DATA_FOR_DOWNLOAD_SUCCESS,
  GET_WORK_FLOW_DATA_FOR_DOWNLOAD_FAILURE,
  GET_MATERIAL_CODE_REQUEST,
  GET_MATERIAL_CODE_SUCCESS,
  GET_MATERIAL_CODE_FAILURE,
} from "./workFlowTypes";

const initialState = {
  loading: false,
  workFlow: [],
  error: "",
};

const getWorkFlowReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORK_FLOW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WORK_FLOW_SUCCESS:
      return {
        loading: false,
        workFlow: action.payload,
        error: "",
      };
    case GET_WORK_FLOW_FAILURE:
      return {
        loading: false,
        workFlow: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLineItems = {
  loading: false,
  lineItems: [],
  error: "",
};

const getLineItemsByGrnReducer = (state = initialStateForLineItems, action) => {
  switch (action.type) {
    case GET_LINEITEMSBYGRN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LINEITEMSBYGRN_SUCCESS:
      return {
        loading: false,
        lineItems: action.payload,
        error: "",
      };
    case GET_LINEITEMSBYGRN_FAILURE:
      return {
        loading: false,
        lineItems: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDataForDownload = {
  loading: false,
  GRNDataForDownload: [],
  error: "",
};

const getWorkFlowDataForDownloadReducer = (
  state = initialStateForDataForDownload,
  action
) => {
  switch (action.type) {
    case GET_WORK_FLOW_DATA_FOR_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WORK_FLOW_DATA_FOR_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        GRNDataForDownload: action.payload,
        error: "",
      };
    case GET_WORK_FLOW_DATA_FOR_DOWNLOAD_FAILURE:
      return {
        loading: false,
        GRNDataForDownload: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForMaterialCode = {
  loading: false,
  materialCode: [],
  error: "",
};

const getMaterialCodeReducer = (
  state = initialStateForMaterialCode,
  action
) => {
  switch (action.type) {
    case GET_MATERIAL_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MATERIAL_CODE_SUCCESS:
      return {
        loading: false,
        materialCode: action.payload,
        error: "",
      };
    case GET_MATERIAL_CODE_FAILURE:
      return {
        loading: false,
        materialCode: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getWorkFlowReducer,
  getLineItemsByGrnReducer,
  getWorkFlowDataForDownloadReducer,
  getMaterialCodeReducer,
};
