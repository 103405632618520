export const GETWAREHOUSE_REQUEST = "GETWAREHOUSE_REQUEST";
export const GETWAREHOUSE_SUCCESS = "GETWAREHOUSE_SUCCESS";
export const GETWAREHOUSE_FAILURE = "GETWAREHOUSE_FAILURE";

export const GETWAREHOUSE_BYID_REQUEST = "GETWAREHOUSE_BYID_REQUEST";
export const GETWAREHOUSE_BYID_SUCCESS = "GETWAREHOUSE_BYID_SUCCESS";
export const GETWAREHOUSE_BYID_FAILURE = "GETWAREHOUSE_BYID_FAILURE";

export const UPDATEWAREHOUSE_REQUEST = "UPDATEWAREHOUSE_REQUEST";
export const UPDATEWAREHOUSE_SUCCESS = "UPDATEWAREHOUSE_SUCCESS";
export const UPDATEWAREHOUSE_FAILURE = "UPDATEWAREHOUSE_FAILURE";

export const CREATE_WAREHOUSE_REQUEST = "CREATE_WAREHOUSE_REQUEST";
export const CREATE_WAREHOUSE_SUCCESS = "CREATE_WAREHOUSE_SUCCESS";
export const CREATE_WAREHOUSE_FAILURE = "CREATE_WAREHOUSE_FAILURE";


export const GET_DELIVERYWAREHOUSE_REQUEST = "GET_DELIVERYWAREHOUSE_REQUEST";
export const GET_DELIVERYWAREHOUSE_SUCCESS = "GET_DELIVERYWAREHOUSE_SUCCESS";
export const GET_DELIVERYWAREHOUSE_FAILURE = "GET_DELIVERYWAREHOUSE_FAILURE";





export const UPLOAD_EXCEL_FOR_WAREHOUSE_REQUEST = "UPLOAD_EXCEL_FOR_WAREHOUSE_REQUEST";
export const UPLOAD_EXCEL_FOR_WAREHOUSE_SUCCESS = "UPLOAD_EXCEL_FOR_WAREHOUSE_SUCCESS";
export const UPLOAD_EXCEL_FOR_WAREHOUSE_FAILURE = "UPLOAD_EXCEL_FOR_WAREHOUSE_FAILURE";