/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_TEMP_USER_REQUEST,
  GET_TEMP_USER_SUCCESS,
  GET_TEMP_USER_FAILURE,
  CREATE_TEMP_USER_REQUEST,
  CREATE_TEMP_USER_SUCCESS,
  CREATE_TEMP_USER_FAILURE,
  UPDATE_TEMP_USER_REQUEST,
  UPDATE_TEMP_USER_SUCCESS,
  UPDATE_TEMP_USER_FAILURE,
  DELETE_TEMP_USER_REQUEST,
  DELETE_TEMP_USER_SUCCESS,
  DELETE_TEMP_USER_FAILURE,
} from "./tempUsersTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getTempUsersRequest = () => {
  return {
    type: GET_TEMP_USER_REQUEST,
  };
};

const getTempUsersSuccess = (payload) => {
  return {
    type: GET_TEMP_USER_SUCCESS,
    payload: payload,
  };
};

const getTempUsersFaliure = (error) => {
  return {
    type: GET_TEMP_USER_FAILURE,
    payload: error,
  };
};

const getTempUsers = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getTempUsersRequest());
    let url = `${baseUrl}/temporary_user/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getTempUsersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getTempUsersFaliure(err.message));
      });
  };
};

const createTempUserRequest = () => {
  return {
    type: CREATE_TEMP_USER_REQUEST,
  };
};

const createTempUserSuccess = (payload) => {
  return {
    type: CREATE_TEMP_USER_SUCCESS,
    payload: payload,
  };
};

const createTempUserFaliure = (error) => {
  return {
    type: CREATE_TEMP_USER_FAILURE,
    payload: error,
  };
};

const createTempUser = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createTempUserRequest());
    let url = `${baseUrl}/temporary_user/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createTempUserSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createTempUserFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateTempUserRequest = () => {
  return {
    type: UPDATE_TEMP_USER_REQUEST,
  };
};

const updateTempUserSuccess = (payload) => {
  return {
    type: UPDATE_TEMP_USER_SUCCESS,
    payload: payload,
  };
};

const updateTempUserFaliure = (error) => {
  return {
    type: UPDATE_TEMP_USER_FAILURE,
    payload: error,
  };
};

const updateTempUser = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateTempUserRequest());
    let url = `${baseUrl}/temporary_user/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateTempUserSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateTempUserFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const deleteTempUserRequest = () => {
  return {
    type: DELETE_TEMP_USER_REQUEST,
  };
};

const deleteTempUserSuccess = (payload) => {
  return {
    type: DELETE_TEMP_USER_SUCCESS,
    payload: payload,
  };
};

const deleteTempUserFaliure = (error) => {
  return {
    type: DELETE_TEMP_USER_FAILURE,
    payload: error,
  };
};

const deleteTempUser = (id, role, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteTempUserRequest());
    let url = `${baseUrl}/temporary_user/delete?id=${id}&role=${role}`;
    return axios
      .delete(url, headers)
      .then((response) => {
        dispatch(deleteTempUserSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteTempUserFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export { getTempUsers, createTempUser, updateTempUser, deleteTempUser };
