/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

//  formik and yup
import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getWorkFlow,
  printWorkFlow,
  getSubModulesByRoleId,
  getMaterialDesc,
} from "../../store";

// config file
import Config from "../../config/index";

// Cookies
import Cookies from "universal-cookie";

import { Switch, ThemeProvider, createTheme } from "@mui/material";
import Select from "react-select";
import MDSnackbar from "components/MDSnackbar";

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "white",
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "rgb(25, 118, 210)",
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.5,
          backgroundColor: "grey",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.6,
            backgroundColor: "rgb(25, 118, 210)",
          },
        },
      },
    },
  },
});

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 800, lg: 1000, xl: 1200 },
  overflow: "auto",
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const styleForAddPackSize = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForLabelModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 1000,
  width: "auto",
  maxWidth: 1000,
  minWidth: 500,
  // height: 600,
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const printModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForUserModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const WorkFlow = ({
  getWorkFlow,
  getWorkFlowReducer,
  printWorkFlow,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,

  getMaterialDesc,
  getMaterialDescReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let printerConfig = cookies.get("printerConfigForWarehouse");
  let printerId = cookies.get("printerIdForWarehouse");

  let jwtToken = loginDetails?.jwt;
  let role = loginDetails?.data.role;
  let userId = loginDetails.data.userId;
  let userName = loginDetails.data.userName;
  let roleId = loginDetails.data.roleId;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let workFlowModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "b17d1f05-17ae-41b8-9d87-10cddc1853ce"
  );

  let viewWorkFlowAccess = workFlowModule
    ? workFlowModule.actionId.includes("3b6766cd-da19-4681-a643-614ffc3fc1eb")
    : null;

  let printWorkFlowAccess = workFlowModule
    ? workFlowModule.actionId.includes("fd2bad60-e217-4ad4-9b32-9b64fe31054f")
    : null;

  const navigate = useNavigate();

  const [batchNumber, setBatchNumber] = useState("");

  const [state, setState] = useState({
    columns: [
      {
        Header: "GRN No",
        accessor: "GRNNumber",
        align: "left",
      },
      {
        Header: "GRN Line No",
        accessor: "GRNLineNumber",
        align: "left",
      },
      { Header: "PO No", accessor: "PONumber", align: "left" },
      { Header: "ASN No", accessor: "ASNNumber", align: "left" },

      { Header: "GRN Date", accessor: "GrnDate", align: "left" },
      {
        Header: "Material Code/Desc",
        accessor: "itemCodeDesc",
        align: "left",
      },
      { Header: "MFG Date", accessor: "MfgDate", align: "left" },
      { Header: "Invoice Qty", accessor: "InvoiceQuantity", align: "left" },
      { Header: "Actual Qty", accessor: "ActualQuantity", align: "left" },
      { Header: "UOM", accessor: "UOM", align: "left" },
      { Header: "Batch Number", accessor: "BatchNumber", align: "left" },

      { Header: "Shelf Life", accessor: "ShelfLife", align: "left" },

      {
        Header: "Storing Location",
        accessor: "StoringLocation",
        align: "left",
      },

      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  useEffect(() => {
    let tempGRN = [];
    let data = getWorkFlowReducer.workFlow.data
      ? getWorkFlowReducer.workFlow.data
      : [];

    setErrorMsgFromApi(getWorkFlowReducer?.error?.data?.msg);

    setLoading(getWorkFlowReducer.loading);

    data.map((singleGrn) => {
      const year = parseInt(singleGrn?.GrnDate?.substring(0, 4));
      const month = parseInt(singleGrn?.GrnDate?.substring(4, 6)) - 1;
      const day = parseInt(singleGrn?.GrnDate?.substring(6, 8));

      const dateObject = new Date(year, month, day);
      const formattedDate = dateObject.toLocaleDateString("en-GB");

      const year2 = parseInt(singleGrn?.MfgDate?.substring(0, 4));
      const month2 = parseInt(singleGrn?.MfgDate?.substring(4, 6)) - 1;
      const day2 = parseInt(singleGrn?.MfgDate?.substring(6, 8));

      const dateObject2 = new Date(year2, month2, day2);
      const formattedDate2 = dateObject2.toLocaleDateString("en-GB");

      // const formatDate = singleGrn.grnDate ? new Date(singleGrn.grnDate) : "";
      const grnObj = {
        privateKey: singleGrn.GRNNumber,
        UOM: singleGrn.UOM,
        GRNNumber: singleGrn.GRNNumber,
        PONumber: singleGrn.PONumber,
        ASNNumber: singleGrn.ASNNumber,
        MaterialCode: singleGrn.MaterialCode,
        BatchNumber: singleGrn.BatchNumber,
        InvoiceNo: singleGrn.InvoiceNo,

        VendorCode: singleGrn.VendorCode,
        MfgDate: singleGrn.MfgDate === "00000000" ? "" : formattedDate2,
        ActualQuantity: singleGrn.ActualQuantity,
        InvoiceQuantity: singleGrn.InvoiceQuantity,
        SourceLocation: singleGrn.SourceLocation,
        LotNo: singleGrn.LotNo,
        ShelfLife: singleGrn.ShelfLife,
        GRNLineNumber: singleGrn.GRNLineNumber,
        StoringLocation: singleGrn.StoringLocation,
        Plant: singleGrn.Plant,
        VendorName: singleGrn.VendorName,
        MaterialDescription: singleGrn.MaterialDescription,
        IsPrinted: singleGrn.IsPrinted,
        IsPutaway: singleGrn.IsPutaway,
        StockType: singleGrn.StockType,
        PackSize: singleGrn.PackSize,
        TemperatureCondition: singleGrn.TemperatureCondition,
        TemperatureConditionText: singleGrn.TemperatureConditionText,

        vendorCodeName: `${singleGrn.VendorCode} / ${singleGrn.VendorName}`,
        itemCodeDesc: `${singleGrn.MaterialCode} / ${singleGrn.MaterialDescription}`,
        GrnDate: singleGrn.GrnDate === "00000000" ? "" : formattedDate,

        action: (
          <Tooltip title="Print">
            <MDButton
              variant="gradient"
              color="success"
              disabled={!printWorkFlowAccess}
              iconOnly
              type="button"
              onClick={() => {
                handleOpenUpdateWorkFlowModal(singleGrn);
              }}
            >
              <Icon> print </Icon>
            </MDButton>
          </Tooltip>
        ),
      };
      tempGRN.push(grnObj);
    });

    viewWorkFlowAccess && setState({ ...state, rows: tempGRN });
  }, [getWorkFlowReducer]);

  const [openUpdateWorkFlowModal, setOpenUpdateWorkFlowModal] = useState(false);
  const [singleWorkFlowDataForUpdate, setWorkFlowDataForUpdate] = useState({});
  const handleOpenUpdateWorkFlowModal = (department = {}) => {
    setWorkFlowDataForUpdate(department);
    setOpenUpdateWorkFlowModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };
  const handleCloseUpdateWorkFlowModal = () => {
    setOpenUpdateWorkFlowModal(false);
    formikForUpdate.setErrors({});
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    totalQty: singleWorkFlowDataForUpdate.totalQty,
  };

  const validationSchemaForUpdate = Yup.object({
    totalQty: Yup.number()
      .min(1)
      .max(parseInt(singleWorkFlowDataForUpdate.InvoiceQuantity))
      .required("Enter the qty!"),
  });

  const [updateErrorMsg, setUpdateErrorMsg] = useState("");
  const [buttonName, setButtonName] = useState("Print");
  const onSubmitForUpdate = async (values) => {
    setButtonName("Please wait...");
    singleWorkFlowDataForUpdate.totalQty = values.totalQty;
    singleWorkFlowDataForUpdate.packSize = values.totalQty;
    singleWorkFlowDataForUpdate.totalPacks = 1;
    singleWorkFlowDataForUpdate.printerId = printerId;
    singleWorkFlowDataForUpdate.userId = userId;
    singleWorkFlowDataForUpdate.userName = userName;
    let payload = singleWorkFlowDataForUpdate;

    let res = await printWorkFlow(payload, jwtToken);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getWorkFlow(materialDescription?.materialCode, batchNumber, jwtToken);
      setTimeout(() => {
        handleCloseUpdateWorkFlowModal();
        setButtonName("Print");
      }, 2250);
    }
    if (!res.status) {
      setUpdateErrorMsg(res.data.data.msg);
      setIsErrorForUpdate(true);
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const [show, setShow] = useState(false);

  const toggleSnackbar = () => setShow(!show);

  const [openAddUserModal, setOpenAddUserModal] = useState(true);

  const [singlePivFillingDataForUpdate, setPivFillingDataForUpdate] = useState(
    {}
  );

  const handleOpenAddUserModal = (data = {}) => {
    setOpenAddUserModal(true);
    setPivFillingDataForUpdate(data);
  };

  const handleCloseAddUserModal = () => {
    setOpenAddUserModal(false);
  };

  const initialValueForAddUser = {
    batchNumber: "",
  };

  const validationSchemaAddUser = Yup.object({
    // batchNumber: Yup.string().required("Enter batchNumber"),
  });

  const [editUserErrorMsg, setEditUserErrorMsg] = useState("");

  const onSubmitForAddUser = (values, { resetForm }) => {
    setBatchNumber(values.batchNumber);
    setMaterialDescription(materialDescription);
    getWorkFlow(
      materialDescription?.materialCode,
      values.batchNumber,
      jwtToken
    );
    setTimeout(() => {
      handleCloseAddUserModal();
    }, 2250);
  };

  const formik = useFormik({
    initialValues: initialValueForAddUser,
    validationSchema: validationSchemaAddUser,
    onSubmit: onSubmitForAddUser,
  });

  const [materialDescription, setMaterialDescription] = useState("");
  const searchSapCodeHandler = async () => {
    let res = await getMaterialDesc(formik.values.sapCode, jwtToken);
  };

  // const SapCodeNotification = (msg) =>
  //   toast.error(msg, {
  //     position: "top-center",
  //     autoClose: 2000,
  //   });

  useEffect(() => {
    setMaterialDescription(getMaterialDescReducer?.desc[0]);
  }, [getMaterialDescReducer]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-3}
              >
                <MDBox display="flex">
                  <MDBox mr={2}>
                    <MDButton
                      color="info"
                      type="button"
                      size="small"
                      onClick={handleOpenAddUserModal}
                    >
                      Filters
                    </MDButton>
                  </MDBox>
                  <MDBox mt={1}>
                    <MDTypography
                      variant="h5"
                      fontWeight="medium"
                      color="error"
                    >
                      Please select filters to get data!
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>

              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  errorMsg={errorMsgFromApi}
                />
              </MDBox>
              <Modal
                open={openAddUserModal}
                onClose={handleCloseAddUserModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForUserModal}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Filters
                    </MDTypography>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseAddUserModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <MDBox p={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontSize: 14, fontWeight: 500 }}
                          >
                            Search Material Code
                          </MDTypography>
                          <span style={{ display: "flex" }}>
                            <MDInput
                              type="text"
                              name="sapCode"
                              fullWidth
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.sapCode}
                              error={
                                formik.touched.sapCode &&
                                formik.errors.sapCode &&
                                true
                              }
                            />

                            <Tooltip title="Search SAP Code">
                              <MDButton
                                variant="gradient"
                                color="info"
                                iconOnly
                                type="button"
                                style={{ marginLeft: "0.5rem" }}
                                onClick={searchSapCodeHandler}
                              >
                                <Icon> search </Icon>
                              </MDButton>
                            </Tooltip>
                          </span>
                          {formik.touched.sapCode && formik.errors.sapCode && (
                            <TextError msg={formik.errors.sapCode} />
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12}>
                        <MDBox p={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontSize: 14, fontWeight: 500 }}
                          >
                            Material Description
                          </MDTypography>
                          <MDInput
                            type="text"
                            name="materialDescription"
                            disabled
                            fullWidth
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={materialDescription?.materialDescription}
                            error={
                              formik.touched.materialDescription &&
                              formik.errors.materialDescription &&
                              true
                            }
                          />
                          {formik.touched.materialDescription &&
                            formik.errors.materialDescription && (
                              <TextError
                                msg={formik.errors.materialDescription}
                              />
                            )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12}>
                        <MDBox p={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontSize: 14, fontWeight: 500 }}
                          >
                            Batch Number
                          </MDTypography>
                          <MDInput
                            type="text"
                            name="batchNumber"
                            fullWidth
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.batchNumber}
                            error={
                              formik.touched.batchNumber &&
                              formik.errors.batchNumber &&
                              true
                            }
                          />
                          {formik.touched.batchNumber &&
                            formik.errors.batchNumber && (
                              <TextError msg={formik.errors.batchNumber} />
                            )}
                        </MDBox>
                      </Grid>
                    </Grid>

                    <MDBox display="flex" style={{ float: "right" }} p={1}>
                      <MDButton
                        color="success"
                        type="submit"
                        size="small"
                        disabled={!formik.isValid || !materialDescription}
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openUpdateWorkFlowModal}
                onClose={handleCloseUpdateWorkFlowModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={printModal}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Print
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseUpdateWorkFlowModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForUpdate.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6">
                          Invoice Qty:-{" "}
                          {singleWorkFlowDataForUpdate?.InvoiceQuantity}
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Qty</MDTypography>
                        <MDInput
                          type="number"
                          name="totalQty"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForUpdate.handleBlur}
                          onChange={formikForUpdate.handleChange}
                          value={formikForUpdate.values.totalQty}
                          error={
                            formikForUpdate.touched.totalQty &&
                            formikForUpdate.errors.totalQty &&
                            true
                          }
                        />
                        {formikForUpdate.touched.totalQty &&
                          formikForUpdate.errors.totalQty && (
                            <TextError msg={formikForUpdate.errors.totalQty} />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorForUpdate}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {updateErrorMsg}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessForUpdate}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Work off printed successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                          disabled={
                            !formikForUpdate.isValid ||
                            buttonName === "Please wait..."
                          }
                        >
                          {buttonName}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {/* <MDSnackbar
        color="error"
        icon="notifications"
        title="Alert"
        content="Please select material code and enter batch number to get data"
        open={show}
        vertical="top"
        horizontal="center"
        close={toggleSnackbar}
      /> */}
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getWorkFlowReducer: state.getWorkFlowReducer,
    lineItemsByGrn: state.lineItemsByGrn,
    getMaterialDescReducer: state.getMaterialDescReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getWorkFlow: (materialCode, batchNumber, jwtToken) =>
      dispatch(getWorkFlow(materialCode, batchNumber, jwtToken)),
    getMaterialDesc: (materialCode, jwtToken) =>
      dispatch(getMaterialDesc(materialCode, jwtToken)),

    printWorkFlow: (payload, jwtToken) =>
      dispatch(printWorkFlow(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(WorkFlow);
