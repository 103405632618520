import {
  GET_INBOUNDREPORTS_REQUEST,
  GET_INBOUNDREPORTS_SUCCESS,
  GET_INBOUNDREPORTS_FAILURE,
} from "./inboundTypes";

const initialStateForInboundReports = {
  loading: false,
  inboundReports: [],
  error: "",
};

const getInboundReportsReducer = (
  state = initialStateForInboundReports,
  action
) => {
  switch (action.type) {
    case GET_INBOUNDREPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_INBOUNDREPORTS_SUCCESS:
      return {
        loading: false,
        inboundReports: action.payload,
        error: "",
      };
    case GET_INBOUNDREPORTS_FAILURE:
      return {
        loading: false,
        inboundReports: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getInboundReportsReducer };
