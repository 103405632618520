import {
  GET_STORE_ISSUANCE_REQUEST,
  GET_STORE_ISSUANCE_SUCCESS,
  GET_STORE_ISSUANCE_FAILURE,
 
} from "./storeIssuanceTypes";

const initialStateForStoreIssuance = {
  loading: false,
  storeIssuance: [],
  error: "",
};

const getStoreIssuanceReducer = (state = initialStateForStoreIssuance, action) => {
  switch (action.type) {
    case GET_STORE_ISSUANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_STORE_ISSUANCE_SUCCESS:
      return {
        loading: false,
        storeIssuance: action.payload,
        error: "",
      };
    case GET_STORE_ISSUANCE_FAILURE:
      return {
        loading: false,
        storeIssuance: [],
        error: action.payload,
      };
    default:
      return state;
  }
};



export { getStoreIssuanceReducer };
