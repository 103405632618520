export const PRINT_STOCK_CUT_OVER_REQUEST = "PRINT_STOCK_CUT_OVER_REQUEST";
export const PRINT_STOCK_CUT_OVER_SUCCESS = "PRINT_STOCK_CUT_OVER_SUCCESS";
export const PRINT_STOCK_CUT_OVER_FAILURE = "PRINT_STOCK_CUT_OVER_FAILURE";

export const GET_ADD_TO_STORE_REQUEST = "GET_ADD_TO_STORE_REQUEST";
export const GET_ADD_TO_STORE_SUCCESS = "GET_ADD_TO_STORE_SUCCESS";
export const GET_ADD_TO_STORE_FAILURE = "GET_ADD_TO_STORE_FAILURE";

export const UPDATE_ADD_TO_STORE_REQUEST = "UPDATE_ADD_TO_STORE_REQUEST";
export const UPDATE_ADD_TO_STORE_SUCCESS = "UPDATE_ADD_TO_STORE_SUCCESS";
export const UPDATE_ADD_TO_STORE_FAILURE = "UPDATE_ADD_TO_STORE_FAILURE";
