/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDTypography from "components/MDTypography";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";

import { CSVLink } from "react-csv";

// Functions from store
import { getInboundReports, getSubModulesByRoleId } from "../../../store";

// Cookies
import Cookies from "universal-cookie";

// Config file
import Config from "../../../config/index";

let desLength = Config.descriptionLength;
const cookies = new Cookies();

const styleForDownloadModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Inbound = ({
  getInboundReports,
  inboundReportsState,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let reportsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "07390a6c-3bf9-4474-9396-b7d6192eb805"
  );

  let viewReportsAccess = reportsModule
    ? reportsModule.actionId.includes("2f0f389b-2977-46f0-a701-d59e76753eaf")
    : null;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInboundReports(jwtToken);
  }, []);

  const statusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Unrestricted-use",
      value: "Unrestricted-use",
    },
    {
      label: "Blocked",
      value: "Blocked",
    },
    {
      label: "Quality Inspection",
      value: "Quality Inspection",
    },
    {
      label: "NA",
      value: "NA",
    },
  ];

  const [state, setState] = useState({
    columns: [
      { Header: "Document Type", accessor: "documentType" },
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Item Code", accessor: "materialCode" },
      { Header: "Description", accessor: "materialDescription" },
      { Header: "Batch No", accessor: "batchNumber" },
      { Header: "Qty", accessor: "totalQty" },
      { Header: "Printing", accessor: "printing" },
      { Header: "Palletization", accessor: "palletization" },
      { Header: "Warehouse", accessor: "storingLocation" },
      { Header: "Asset", accessor: "asset" },
      {
        Header: "Status",
        accessor: "stockType",
        searchType: "select",
        selectOptions: statusOptions,
        placeholder: "All Status",
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  useEffect(() => {
    let tempReports = [];
    let data = inboundReportsState.inboundReports.data
      ? inboundReportsState.inboundReports.data
      : [];

    setLoading(inboundReportsState.loading);
    setDisableDownloadButton(data.length === 0);

    data.map((singleData) => {
      let des = singleData?.materialDescription;
      let len = desLength;

      const dataObject = {
        documentType: singleData.documentType,
        documentNo: singleData.documentNo,
        materialCode: singleData.materialCode,
        materialDescription: des ? des.substring(0, len) : "",
        batchNumber: singleData.batchNumber,
        totalQty: `${singleData.totalQty} ${singleData.uom}`,
        printing: singleData.printing,
        palletization: singleData.palletization,
        storingLocation: singleData.storingLocation,
        asset: singleData.asset,
        stockType:
          (singleData.stockType === "F" && "Unrestricted-use") ||
          (singleData.stockType === "S" && "Blocked") ||
          (singleData.stockType === "X" && "Quality Inspection") ||
          (singleData.stockType === "NA" && "NA"),
      };
      tempReports.push(dataObject);
    });
    viewReportsAccess && setState({ ...state, rows: tempReports });
  }, [inboundReportsState]);

  const headers = [
    { label: "Document Type", key: "documentType" },
    { label: "Document No", key: "documentNo" },
    { label: "Item Code", key: "materialCode" },
    { label: "Description", key: "materialDescription" },
    { label: "Batch No", key: "batchNumber" },
    { label: "Qty", key: "totalQty" },
    { label: "Printing", key: "printing" },
    { label: "Palletization", key: "palletization" },
    { label: "Putaway", key: "putaway" },
    { label: "Warehouse", key: "storingLocation" },
    { label: "Asset", key: "asset" },
    { label: "Status", key: "stockType" },
  ];

  const csvLink = {
    filename: "Inbound Reports.csv",
    headers: headers,
    data: rows,
  };

  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [disableDownloadButton, setDisableDownloadButton] = useState(false);

  const handleOpenDownloadModal = () => {
    setOpenDownloadModal(true);
  };

  const handleCloseDownloadModal = () => {
    setDisableDownloadButton(false);
    setOpenDownloadModal(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {viewReportsAccess && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  // mb={-6}
                >
                  <MDBox color="text">
                    <Tooltip title="Download">
                      <MDButton
                        style={{ marginRight: "1rem", marginLeft: "10px" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        iconOnly
                        onClick={() => {
                          handleOpenDownloadModal();
                        }}
                      >
                        <Icon>download</Icon>
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              )}

              <MDBox mt={viewReportsAccess ? -3 : 0}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                />
              </MDBox>

              <Modal
                open={openDownloadModal}
                onClose={handleCloseDownloadModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForDownloadModal}>
                  <MDTypography>{`${
                    disableDownloadButton
                      ? "Could not prepare the data for download!"
                      : "Ready for download!"
                  }`}</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDownloadModal}
                    >
                      Cancel
                    </MDButton>
                    {!disableDownloadButton && (
                      <CSVLink {...csvLink}>
                        <MDButton
                          style={{ width: "48%", marginRight: "0.5rem" }}
                          type="button"
                          variant="outlined"
                          color="error"
                        >
                          Download
                        </MDButton>
                      </CSVLink>
                    )}
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    inboundReportsState: state.inboundReportsState,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getInboundReports: (jwtToken) => dispatch(getInboundReports(jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Inbound);
