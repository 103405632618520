export const GET_PRODUCTION_PROCESS_REQUEST = "GET_PRODUCTION_PROCESS_REQUEST";
export const GET_PRODUCTION_PROCESS_SUCCESS = "GET_PRODUCTION_PROCESS_SUCCESS";
export const GET_PRODUCTION_PROCESS_FAILURE = "GET_PRODUCTION_PROCESS_FAILURE";

export const GET_RESOURCESFORALLOCATION_REQUEST =
  "GET_RESOURCESFORALLOCATION_REQUEST";
export const GET_RESOURCESFORALLOCATION_SUCCESS =
  "GET_RESOURCESFORALLOCATION_SUCCESS";
export const GET_RESOURCESFORALLOCATION_FAILURE =
  "GET_RESOURCESFORALLOCATION_FAILURE";

export const UPDATE_USER_FOR_PRODUCTION_PROCESS_REQUEST =
  "UPDATE_USER_FOR_PRODUCTION_PROCESS_REQUEST";
export const UPDATE_USER_FOR_PRODUCTION_PROCESS_SUCCESS =
  "UPDATE_USER_FOR_PRODUCTION_PROCESS_SUCCESS";
export const UPDATE_USER_FOR_PRODUCTION_PROCESS_FAILURE =
  "UPDATE_USER_FOR_PRODUCTION_PROCESS_FAILURE";

export const GET_PRODUCTION_PROCESS_FORREPORTS_REQUEST =
  "GET_PRODUCTION_PROCESS_FORREPORTS_REQUEST";
export const GET_PRODUCTION_PROCESS_FORREPORTS_SUCCESS =
  "GET_PRODUCTION_PROCESS_FORREPORTS_SUCCESS";
export const GET_PRODUCTION_PROCESS_FORREPORTS_FAILURE =
  "GET_PRODUCTION_PROCESS_FORREPORTS_FAILURE";
