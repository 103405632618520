/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

import { addMinutes, format } from "date-fns";

// Functions from store
import {
  getPivFilling,
  getSubModulesByRoleId,
  getAssetsByDocumentNo,
  reprintAllAsset,
  reprintAsset,
  getUsersToAssign,
  updateUserPivFilling,
} from "../../../store";

// Config
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const styleForUserModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForLocationsModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 500, lg: 625, xl: 625 },
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 800, lg: 800, xl: 800 },
  overflow: "auto",
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const styleForReprint = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 500, lg: 500, xl: 500 },
  overflow: "auto",
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const PivFilling = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getPivFilling,
  pivFillingReducer,
  getAssetsByDocumentNo,
  getAssetsByDocumentNoReducer,
  reprintAllAsset,
  reprintAsset,
  getUsersToAssign,
  usersData,
  updateUserPivFilling,
}) => {
  const desLength = Config.descriptionLength;

  let loginDetails = cookies.get("loginDetailsForWMS");
  let printerId = cookies.get("printerIdForWarehouse");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails?.data?.roleId;
  let userName = loginDetails?.data?.userName;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let pivFillingModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "66144171-7623-47c7-88ba-23de809df874"
  );

  let viewPivFilling = pivFillingModule
    ? pivFillingModule.actionId.includes("c3109aea-308f-48ca-8ecb-ecfc8b71f44a")
    : null;

  let printPivFilling = pivFillingModule
    ? pivFillingModule.actionId.includes("900b3fa9-e473-444a-b8ce-d18c1d9877fb")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getPivFilling(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [usersOptions, setUsersOptions] = useState([]);

  useEffect(() => {
    let tempUsersOptions = [];
    let data = usersData.usersToAssign.data ? usersData.usersToAssign.data : [];

    data.map((user) => {
      tempUsersOptions.push({
        label: user.userName,
        value: user.userId,
      });
    });
    setUsersOptions(tempUsersOptions);
  }, [usersData]);

  const statusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Not Filled",
      value: 0,
    },
    {
      label: "Filled",
      value: 1,
    },
    {
      label: "Released",
      value: 2,
    },
  ];

  const [state, setState] = useState({
    columns: [
      {
        Header: "Re-Print",
        accessor: "reprint",
        align: "center",
        cannotSearch: true,
      },
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Bulk Code / Des", accessor: "materialCode" },
      { Header: "Batch No / Qty", accessor: "batchNumber" },
      { Header: "Filled Qty", accessor: "actualWeight" },
      { Header: "Location", accessor: "locations" },
      {
        Header: "Release Date",
        accessor: "cd",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        searchType: "select",
        selectOptions: statusOptions,
        placeholder: "All Status",
      },
      { Header: "User", accessor: "userName" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [itemTable, setItemTable] = useState({
    columns: [
      {
        Header: "Re-Print",
        accessor: "reprint",
        align: "center",
        cannotSearch: true,
      },
      { Header: "Asset Id / UID", accessor: "uId", align: "left" },
      { Header: "Actual Weight", accessor: "actualWeight", align: "left" },
    ],
    rows: [],
  });

  const { columns: itemColumns, rows: itemRows } = itemTable;

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  const [errorMsgForLineItemFromApi, setErrorMsgForLineItemFromApi] =
    useState("");

  const [locationsModal, setOpenLocationsModal] = useState(false);
  const [locationsData, setLocationsData] = useState([]);

  const [singleLineItemData, setSingleLineItemData] = useState({});

  const handleOpenLocationsModal = () => {
    setOpenLocationsModal(true);
  };

  const handleCloseLocationsModal = () => {
    setOpenLocationsModal(false);
  };

  useEffect(() => {
    let tempPiv = [];

    let data = pivFillingReducer.pivFillingData.data
      ? pivFillingReducer.pivFillingData.data
      : [];

    setErrorMsgFromApi(pivFillingReducer?.error?.data?.msg);
    setPaginationObj(pivFillingReducer?.pivFillingData?.pagination);
    setLoading(pivFillingReducer?.loading);

    data.map((piv) => {
      let bulkDes = piv?.materialDescription;
      let len = desLength;

      const pivObject = {
        reprint: (
          <Tooltip>
            <MDButton
              disabled={!printPivFilling}
              variant="gradient"
              color="info"
              iconOnly
              size="small"
              type="button"
              onClick={() => {
                setDocumentNo(piv.documentNo);
                getAssetsByDocumentNo(piv.documentNo, jwtToken);
                handleOpenLineItemsModal();
              }}
            >
              <Icon> visibility_icon </Icon>
            </MDButton>
          </Tooltip>
        ),
        documentNo: piv.documentNo,
        materialCode: `${piv.materialCode} / ${
          bulkDes ? bulkDes.substring(0, len) : ""
        }`,
        batchNumber: `${piv.batchNumber} / ${piv.qty} ${piv.uom}`,
        actualWeight: `${piv.actualWeight} ${piv.uom}`,
        userName: (
          <span style={{ display: "flex" }}>
            <Tooltip title="Edit User">
              <MDButton
                disabled={piv.isUpdatable !== 1}
                color="success"
                variant="gradient"
                type="button"
                iconOnly
                size="small"
                onClick={() => {
                  getUsersToAssign(jwtToken);
                  handleOpenAddUserModal(piv);
                }}
              >
                <Icon> edit_icon </Icon>
              </MDButton>
            </Tooltip>

            <MDTypography
              variant="h6"
              fontWeight="regular"
              style={{ marginLeft: "0.75rem", width: "7rem" }}
            >
              {piv.userName}
            </MDTypography>
          </span>
        ),
        cd: piv.cd,
        status: (
          <button
            className={
              (piv.status === 0 && `status-error`) ||
              (piv.status === 1 && `status-warning`) ||
              (piv.status === 2 && `status-success`)
            }
            style={{ width: "5.5rem" }}
          >
            {(piv.status === 0 && `Not Filled`) ||
              (piv.status === 1 && `Filled`) ||
              (piv.status === 2 && `Released`)}
          </button>
        ),
        locations: piv.locations.length > 0 && (
          <Tooltip title="View Locations" style={{ marginLeft: "0.75rem" }}>
            <MDButton
              color="info"
              variant="gradient"
              type="button"
              iconOnly
              size="small"
              onClick={() => {
                setLocationsData(piv?.locations);
                handleOpenLocationsModal();
              }}
            >
              <Icon> visibility_icon </Icon>
            </MDButton>
          </Tooltip>
        ),
      };
      tempPiv.push(pivObject);
    });

    viewPivFilling && setState({ ...state, rows: tempPiv });
  }, [pivFillingReducer]);

  const [isErrorReprint, setIsErrorReprint] = useState(false);
  const [isSuccessReprint, setIsSuccessReprint] = useState(false);

  useEffect(() => {
    if (isErrorReprint) {
      setTimeout(() => {
        setIsErrorReprint(false);
      }, 3000);
    }
  }, [isErrorReprint]);

  useEffect(() => {
    if (isSuccessReprint) {
      setTimeout(() => {
        setIsSuccessReprint(false);
      }, 3000);
    }
  }, [isSuccessReprint]);

  const [isErrorReprintAll, setIsErrorReprintAll] = useState(false);
  const [isSuccessReprintAll, setIsSuccessReprintAll] = useState(false);

  useEffect(() => {
    if (isErrorReprintAll) {
      setTimeout(() => {
        setIsErrorReprintAll(false);
      }, 3000);
    }
  }, [isErrorReprintAll]);

  useEffect(() => {
    if (isSuccessReprintAll) {
      setTimeout(() => {
        setIsSuccessReprintAll(false);
      }, 3000);
    }
  }, [isSuccessReprintAll]);

  const [reprintData, setReprintData] = useState({});
  const [openReprintModal, setOpenReprintModal] = useState(false);

  const handleOpenReprintModal = (data = {}) => {
    setReprintData(data);
    setOpenReprintModal(true);
  };

  const handleCloseReprintModal = () => {
    setNoOfPrint("");
    setOpenReprintModal(false);
  };

  const [noOfPrint, setNoOfPrint] = useState("");

  const noOfPrintHandler = (e) => {
    setNoOfPrint(e.target.value);
  };

  const [noOfPrintAll, setNoOfPrintAll] = useState("");

  const noOfPrintAllHandler = (e) => {
    setNoOfPrintAll(e.target.value);
  };

  const [reprintErrorMsg, setReprintErrorMsg] = useState("");

  const onClickRePrint = async () => {
    const payload = {
      uId: reprintData.uId,
      printerId: printerId,
      userName: userName,
      noOfPrint: +noOfPrint,
    };

    let res = await reprintAsset(payload, jwtToken);

    if (res.status) {
      setIsSuccessReprint(true);
      getPivFilling(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleCloseReprintModal();
      }, 2250);
    }
    if (!res.status) {
      setReprintErrorMsg(res.data.data.msg);
      setIsErrorReprint(true);
    }
  };

  const [openReprintAllModal, setOpenReprintAllModal] = useState(false);

  const handleOpenReprintAllModal = (data = {}) => {
    setOpenReprintAllModal(true);
  };

  const handleCloseReprintAllModal = () => {
    setNoOfPrintAll("");
    setOpenReprintAllModal(false);
  };

  const [reprintAllErrorMsg, setReprintAllErrorMsg] = useState("");

  const onClickRePrintAll = async () => {
    const payload = {
      documentNo: documentNo,
      printerId: printerId,
      userName: userName,
      noOfPrint: +noOfPrintAll,
    };

    let res = await reprintAllAsset(payload, jwtToken);

    if (res.status) {
      setIsSuccessReprintAll(true);
      getPivFilling(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleCloseReprintAllModal();
      }, 2250);
    }
    if (!res.status) {
      setReprintAllErrorMsg(res.data.data.msg);
      setIsErrorReprintAll(true);
    }
  };

  const [loadingForLineItems, setLoadingForLineItems] = useState(true);

  const [openGrnLineItemsModal, setOpenGrnLineItemsModal] = useState(false);

  const handleOpenLineItemsModal = () => {
    setOpenGrnLineItemsModal(true);
  };

  const handleCloseLineItemsModal = () => {
    setOpenGrnLineItemsModal(false);
  };

  const [documentNo, setDocumentNo] = useState("");

  useEffect(() => {
    let tempAssets = [];
    let data = getAssetsByDocumentNoReducer.assetsByDocumentNoData.data
      ? getAssetsByDocumentNoReducer?.assetsByDocumentNoData.data
      : [];

    setLoadingForLineItems(getAssetsByDocumentNoReducer.loading);

    data.map((lineItem) => {
      const lineItemObj = {
        actualWeight: `${lineItem.actualWeight} ${lineItem.uom}`,
        uId: lineItem.uId,
        reprint: (
          <Tooltip title="Re-Print">
            <MDButton
              color="success"
              variant="gradient"
              type="button"
              iconOnly
              onClick={() => {
                handleOpenReprintModal(lineItem);
                setSingleLineItemData(lineItem);
                setReprintData(lineItem);
              }}
            >
              <Icon> print </Icon>
            </MDButton>
          </Tooltip>
        ),
      };
      tempAssets.push(lineItemObj);
    });
    setItemTable({ ...itemTable, rows: tempAssets });
  }, [getAssetsByDocumentNoReducer]);

  const [locationsState, setLocationsState] = useState({
    columns: [
      { Header: "Location", accessor: "location" },
      { Header: "UID", accessor: "uId" },
      { Header: "Qty", accessor: "qty" },
    ],
    rows: [],
  });

  const { columns: locationsColumns, rows: locationsRows } = locationsState;

  useEffect(() => {
    let tempLocationsObj = [];

    let data = locationsData ? locationsData : [];

    data.map((data) => {
      const locationObj = {
        location: data?.location,
        uId: data?.uId,
        qty: `${data?.qty} ${data?.uom}`,
      };

      tempLocationsObj.push(locationObj);
    });

    setLocationsState({ ...locationsState, rows: tempLocationsObj });
  }, [locationsData]);

  const [isErrorAddUser, setIsErrorAddUser] = useState(false);
  const [isSuccessAddUser, setIsSuccessAddUser] = useState(false);

  useEffect(() => {
    if (isErrorAddUser) {
      setTimeout(() => {
        setIsErrorAddUser(false);
      }, 3000);
    }
  }, [isErrorAddUser]);

  useEffect(() => {
    if (isSuccessAddUser) {
      setTimeout(() => {
        setIsSuccessAddUser(false);
      }, 3000);
    }
  }, [isSuccessAddUser]);

  const [userSelected, setAddUserSelected] = useState("");
  const handleAddUserChange = (selectedUser) => {
    setAddUserSelected(selectedUser);
  };

  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  const [singlePivFillingDataForUpdate, setPivFillingDataForUpdate] = useState(
    {}
  );

  const handleOpenAddUserModal = (data = {}) => {
    setOpenAddUserModal(true);
    setPivFillingDataForUpdate(data);
  };

  const handleCloseAddUserModal = () => {
    setOpenAddUserModal(false);
  };

  const initialValueForAddUser = {
    user: "",
  };

  const validationSchemaAddUser = Yup.object({
    user: Yup.object().required("Select user"),
  });

  const [editUserErrorMsg, setEditUserErrorMsg] = useState("");
  const [buttonName, setButtonName] = useState("Submit");
  const onSubmitForAddUser = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let payload = {
      documentNo: singlePivFillingDataForUpdate?.documentNo,
      userId: userSelected?.value,
    };

    let res = await updateUserPivFilling(payload, jwtToken);

    if (res.status) {
      setIsSuccessAddUser(true);
      setAddUserSelected("");
      getPivFilling(pageNo, rowsPerPage, searchObject, jwtToken);
      resetForm();

      setTimeout(() => {
        handleCloseAddUserModal();
        setButtonName("Confirm");
      }, 2250);
    }
    if (!res.status) {
      setEditUserErrorMsg(res.data.data.message);
      setIsErrorAddUser(true);
      setButtonName("Confirm");
    }
  };

  const formikForAddUser = useFormik({
    initialValues: initialValueForAddUser,
    validationSchema: validationSchemaAddUser,
    onSubmit: onSubmitForAddUser,
  });

  return (
    <>
      <MDBox mt={-6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mt={-2}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  errorMsg={errorMsgFromApi}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>

              <div>
                <Modal
                  open={openGrnLineItemsModal}
                  onClose={handleCloseLineItemsModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Items
                      </MDTypography>
                      <MDBox>
                        <span>
                          <Tooltip title="Close">
                            <IconButton
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                                marginLeft: "-55px",
                                marginBottom: "5px",
                              }}
                              aria-label="close"
                              color="inherit"
                              onClick={handleCloseLineItemsModal}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </span>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginBottom: "1rem" }} />
                    {!loadingForLineItems ? (
                      <MDBox>
                        <MDButton
                          color="success"
                          variant="contained"
                          onClick={handleOpenReprintAllModal}
                          style={{ float: "right", marginRight: "10px" }}
                        >
                          Print All
                        </MDButton>

                        <DataTable
                          table={{ columns: itemColumns, rows: itemRows }}
                          isSorted={true}
                          tableSearch={true}
                          entriesPerPage={{
                            defaultValue: 30,
                            entries: [30, 50, 75, 100],
                          }}
                          showTotalEntries={true}
                          noEndBorder
                          errorMsg={errorMsgForLineItemFromApi}
                        />
                      </MDBox>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "100px",
                          marginBottom: "100px",
                        }}
                      >
                        <CircularProgress color="info" />
                      </div>
                    )}
                  </MDCard>
                </Modal>
              </div>
              <Modal
                open={openReprintModal}
                onClose={handleCloseReprintModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForReprint}>
                  <MDTypography>
                    Are you sure you want to reprint ?
                  </MDTypography>
                  <MDBox style={{ display: "flex" }}>
                    <MDTypography
                      variant="h6"
                      style={{ marginTop: "0.6rem", marginRight: "1rem" }}
                    >
                      No of Print
                    </MDTypography>
                    <MDInput
                      type="number"
                      style={{ width: "10rem" }}
                      value={noOfPrint}
                      onChange={noOfPrintHandler}
                    />
                  </MDBox>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseReprintModal}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{ width: "48%", marginLeft: "0.5rem" }}
                      color="success"
                      onClick={onClickRePrint}
                      disabled={!noOfPrint}
                    >
                      Yes
                    </MDButton>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessReprint}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessReprint(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Reprint successful!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorReprint}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorReprint(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {/* Could not reprint */}
                        {reprintErrorMsg}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openReprintAllModal}
                onClose={handleCloseReprintAllModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForReprint}>
                  <MDTypography>
                    Are you sure you want to reprint all ?
                  </MDTypography>
                  <MDBox style={{ display: "flex" }}>
                    <MDTypography
                      variant="h6"
                      style={{ marginTop: "0.6rem", marginRight: "1rem" }}
                    >
                      No of Print
                    </MDTypography>
                    <MDInput
                      type="number"
                      style={{ width: "10rem" }}
                      value={noOfPrintAll}
                      onChange={noOfPrintAllHandler}
                    />
                  </MDBox>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseReprintAllModal}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{ width: "48%", marginLeft: "0.5rem" }}
                      color="success"
                      onClick={onClickRePrintAll}
                      disabled={!noOfPrintAll}
                    >
                      Yes
                    </MDButton>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessReprintAll}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessReprintAll(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Reprint All successful!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorReprintAll}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorReprintAll(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {/* Could not reprint all */}
                        {reprintAllErrorMsg}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
              <div>
                <Modal
                  open={locationsModal}
                  onClose={handleCloseLocationsModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForLocationsModal}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Locations
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseLocationsModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>

                    <MDBox pt={1} pb={3} px={3}>
                      <DataTable
                        table={{
                          columns: locationsColumns,
                          rows: locationsRows,
                        }}
                        isSorted={true}
                        entriesPerPage={{
                          defaultValue: 30,
                          entries: [30, 50, 75, 100],
                        }}
                        showTotalEntries={true}
                        noEndBorder
                        options={{ draggable: false }}
                      />
                    </MDBox>
                  </MDCard>
                </Modal>
              </div>

              <Modal
                open={openAddUserModal}
                onClose={handleCloseAddUserModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForUserModal}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Change User
                    </MDTypography>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseAddUserModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForAddUser.handleSubmit}
                    >
                      <MDBox>
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Process Order No:-{" "}
                            <span style={{ fontWeight: 400 }}>
                              {singlePivFillingDataForUpdate?.documentNo}
                            </span>
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Item Code:-{" "}
                            <span style={{ fontWeight: 400 }}>
                              {singlePivFillingDataForUpdate?.materialCode}
                            </span>
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Select User
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            placeholder="Select user..."
                            name="user"
                            value={userSelected}
                            options={usersOptions}
                            onChange={(selectedOption) => {
                              handleAddUserChange(selectedOption);
                              formikForAddUser.setFieldValue(
                                "user",
                                selectedOption
                              );
                            }}
                          />
                          {formikForAddUser.errors.user && (
                            <TextError msg={formikForAddUser.errors.user} />
                          )}
                        </MDBox>

                        <MDBox mt={2}>
                          <Collapse in={isSuccessAddUser}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessAddUser(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              User edited successfully!
                            </Alert>
                          </Collapse>
                          <Collapse in={isErrorAddUser}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorAddUser(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {/* Could not Add the User and release! */}
                              {editUserErrorMsg}
                            </Alert>
                          </Collapse>
                        </MDBox>

                        <div className="mt-3">
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="success"
                            fullWidth
                            disabled={
                              !formikForAddUser.isValid ||
                              buttonName === "Please wait..."
                            }
                          >
                            {buttonName}
                          </MDButton>
                        </div>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    pivFillingReducer: state.getPivFillingReducer,
    getAssetsByDocumentNoReducer: state.getAssetsByDocumentNoReducer,
    usersData: state.getUsersToAssignReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getPivFilling: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getPivFilling(pageNo, rowsPerPage, searchObject, jwtToken)),
    getAssetsByDocumentNo: (documentNo, jwtToken) =>
      dispatch(getAssetsByDocumentNo(documentNo, jwtToken)),
    reprintAllAsset: (payload, jwtToken) =>
      dispatch(reprintAllAsset(payload, jwtToken)),
    reprintAsset: (payload, jwtToken) =>
      dispatch(reprintAsset(payload, jwtToken)),
    getUsersToAssign: (jwtToken) => dispatch(getUsersToAssign(jwtToken)),
    updateUserPivFilling: (payload, jwtToken) =>
      dispatch(updateUserPivFilling(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(PivFilling);
