export const GET_PICKUPLISTWISECHARGING_REQUEST =
  "GET_PICKUPLISTWISECHARGING_REQUEST";
export const GET_PICKUPLISTWISECHARGING_SUCCESS =
  "GET_PICKUPLISTWISECHARGING_SUCCESS";
export const GET_PICKUPLISTWISECHARGING_FAILURE =
  "GET_PICKUPLISTWISECHARGING_FAILURE";

export const DOWNLOAD_PICKUPLISTWISECHARGING_REQUEST =
  "DOWNLOAD_PICKUPLISTWISECHARGING_REQUEST";
export const DOWNLOAD_PICKUPLISTWISECHARGING_SUCCESS =
  "DOWNLOAD_PICKUPLISTWISECHARGING_SUCCESS";
export const DOWNLOAD_PICKUPLISTWISECHARGING_FAILURE =
  "DOWNLOAD_PICKUPLISTWISECHARGING_FAILURE";
