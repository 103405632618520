import {
  GET_PICKLISTTRACKING_FAILURE,
  GET_PICKLISTTRACKING_REQUEST,
  GET_PICKLISTTRACKING_SUCCESS,
} from "./picklistTrackingTypes";

const initialStateForPickListTracking = {
  loading: false,
  pickList: [],
  error: "",
};

const getPickListTrackingReducer = (
  state = initialStateForPickListTracking,
  action
) => {
  switch (action.type) {
    case GET_PICKLISTTRACKING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PICKLISTTRACKING_SUCCESS:
      return {
        loading: false,
        pickList: action.payload,
        error: "",
      };
    case GET_PICKLISTTRACKING_FAILURE:
      return {
        loading: false,
        pickList: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getPickListTrackingReducer };
