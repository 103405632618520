/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getStoreIssuance,
  addToRelease,
  validationForStoreIssuance,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../../config/index";

import { addMinutes, format } from "date-fns";

const desLength = Config.descriptionLength;

const cookies = new Cookies();

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const StoreIssuance = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getStoreIssuance,
  storeIssuance,
  addToRelease,
  validationForStoreIssuance,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let userId = loginDetails?.data.userId;
  let roleId = loginDetails.data.roleId;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let palletModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "b0ea8017-5741-4469-95a4-20f56414f28e"
  );

  let viewPivAccess = palletModule
    ? palletModule.actionId.includes("02e3d3e3-9d72-4185-84ea-e99f22d8646c")
    : null;

  let releaseAccess = palletModule
    ? palletModule.actionId.includes("65e0b3fa-3124-4b21-ac92-da48b645f32f")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getStoreIssuance(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [state, setState] = useState({
    columns: [
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Item Code / Des", accessor: "materialCode" },
      { Header: "Item Qty", accessor: "qty" },
      {
        Header: "Date & Time",
        accessor: "proReleaseTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      { Header: "From Loc", accessor: "source" },
      { Header: "To Loc", accessor: "destination" },
      { Header: "Pick List ID", accessor: "pickupListCode" },
      { Header: "Batch No / Qty", accessor: "batchNumber" },
      { Header: "Resource Category/ Resource", accessor: "resourceCategory" },
      { Header: "Bulk Code / Des", accessor: "finalMaterialCode" },
      { Header: "MRP Type", accessor: "mrpType" },
      { Header: "RM Category / Des", accessor: "rmCategory" },
      { Header: "Phase No", accessor: "phaseNumber" },
      { Header: "Phase Des", accessor: "phaseDescription" },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  useEffect(() => {
    let tempPiv = [];

    let data = storeIssuance.storeIssuance.data
      ? storeIssuance.storeIssuance.data
      : [];

    setErrorMsgFromApi(storeIssuance?.error?.data?.msg);
    setLoading(storeIssuance.loading);
    setPaginationObj(storeIssuance?.storeIssuance?.pagination);

    data.map((piv, index) => {
      let bulkDes = piv?.finalMaterialDescription;
      let itemDes = piv.materialDescription;
      let phaseDes = piv.phaseDescription;
      let rmDes = piv.rmCatDescription;
      let len = desLength;

      const formatDate = new Date(piv.proReleaseTime);
      const pivObject = {
        privateKey: piv.pickupListCode,
        documentNo: piv.documentNo,
        batchNumber: `${piv.batchNumber} / ${piv.batchSize} ${piv.batchUom}`,
        finalMaterialCode: `${piv.finalMaterialCode} / ${
          bulkDes ? bulkDes.substring(0, len) : ""
        }`,
        materialCode: `${piv.materialCode} / ${
          itemDes ? itemDes.substring(0, len) : ""
        }`,
        mrpType: piv.mrpType,
        rmCategory: `${piv.rmCategory} / ${
          rmDes ? rmDes.substring(0, len) : ""
        }`,
        phaseNumber: piv.phaseNumber,
        phaseDescription: phaseDes ? phaseDes.substring(0, len) : "",
        qty: `${piv.qty} ${piv.uom}`,
        destination: piv.destination,
        pickupListCode: piv.pickupListCode,
        source: piv.source,
        resourceCategory: `${piv.resourceCategory} - ${piv.resource}`,
        srNo: piv.srNo,
        proReleaseTime: piv.proReleaseTime,
      };
      tempPiv.push(pivObject);
    });

    viewPivAccess && setState({ ...state, rows: tempPiv });
  }, [storeIssuance]);

  const [selectedStoreIssuanceData, setSelectedStoreIssuanceData] = useState(
    []
  );

  const storeIssuanceDataSelectionHandler = (data) => {
    setSelectedStoreIssuanceData(data);
  };

  const [isErrorReleaseRecords, setIsErrorReleaseRecords] = useState(false);
  const [isSuccessReleaseRecords, setIsSuccessReleaseRecords] = useState(false);

  useEffect(() => {
    if (isErrorReleaseRecords) {
      setTimeout(() => {
        setIsErrorReleaseRecords(false);
      }, 3000);
    }
  }, [isErrorReleaseRecords]);

  useEffect(() => {
    if (isSuccessReleaseRecords) {
      setTimeout(() => {
        setIsSuccessReleaseRecords(false);
      }, 3000);
    }
  }, [isSuccessReleaseRecords]);

  const [openReleaseRecordsModal, setOpenReleaseRecordsModal] = useState(false);

  const handleOpenReleaseRecordsModal = () => {
    setOpenReleaseRecordsModal(true);
  };
  const handleCloseReleaseRecordsModal = () => {
    setOpenReleaseRecordsModal(false);
  };

  const [releaseRecordsErrorMsg, setReleaseRecordsErrorMsg] = useState("");

  const handleReleaseRecords = async () => {
    let payload = [];

    selectedStoreIssuanceData.map((data) => {
      const tempPayload = {
        documentNo: data.documentNo,
        phaseNumber: data.phaseNumber,
        srNo: data.srNo,
      };

      payload.push(tempPayload);
    });

    let res = await addToRelease(payload, jwtToken);

    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessReleaseRecords(true);
      getStoreIssuance(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleCloseReleaseRecordsModal();
      }, 2250);
    }
    if (!res.status) {
      setReleaseRecordsErrorMsg(res.data.data.message);
      setIsErrorReleaseRecords(true);
    }
  };

  const [validationErrorMsg, setValidationErrorMsg] = useState("");
  const [errorDocumentNo, setErrorDocumentNo] = useState("");
  const [validationErrorArray, setValidationErrorArray] = useState([]);

  useEffect(() => {
    setValidationErrorMsg("");
    setErrorDocumentNo("");
  }, [selectedStoreIssuanceData]);

  useEffect(() => {
    const msg = validationErrorArray.map((data) => {
      return `${data.documentNo}-${data.phaseNumber}`;
    });

    setErrorDocumentNo(msg.join(", "));
  }, [validationErrorArray]);

  const releaseValidationHandler = async () => {
    let payload = [];

    selectedStoreIssuanceData.map((data) => {
      const tempPayload = {
        pickupListCode: data.pickupListCode,
        srNo: data.srNo,
        documentNo: data.documentNo,
        phaseNumber: data.phaseNumber,
        resourceCategory: data.resourceCategory,
      };

      payload.push(tempPayload);
    });

    let res = await validationForStoreIssuance(payload, jwtToken);

    if (res.status) {
      handleOpenReleaseRecordsModal();
    }

    if (!res.status) {
      setValidationErrorArray(res.data.data.data);
      setValidationErrorMsg(res.data.data.msg);
    }
  };

  return (
    <div>
      <MDBox mt={-6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mt={-2}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  errorMsg={errorMsgFromApi}
                  selection={true}
                  selectionButtonClick={storeIssuanceDataSelectionHandler}
                  selectionButtonComponent={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <span>
                        <Tooltip title="Add to Release">
                          <MDButton
                            disabled={!releaseAccess}
                            color="white"
                            type="button"
                            style={{
                              width: "10rem",
                              marginTop: "0.5rem",
                              color: "white",
                              border: "1px solid #0275d8",
                              background: "#0275d8",
                              // marginLeft: 5,
                            }}
                            onClick={() => {
                              releaseValidationHandler();
                            }}
                          >
                            Add to Release
                          </MDButton>
                        </Tooltip>
                      </span>
                      <span>
                        {validationErrorMsg && (
                          <>
                            <MDTypography
                              color="error"
                              variant="p"
                              style={{
                                // textAlign: "center",
                                marginLeft: "1rem",
                                marginTop: "1rem",
                              }}
                            >
                              {validationErrorMsg}
                            </MDTypography>
                            <br />
                            <MDTypography
                              color="error"
                              variant="p"
                              style={{
                                // textAlign: "center",
                                marginLeft: "1rem",
                                // marginTop: "1rem",
                              }}
                            >
                              Document No - {errorDocumentNo}
                            </MDTypography>
                          </>
                        )}
                      </span>
                    </span>
                  }
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>

              <Modal
                open={openReleaseRecordsModal}
                onClose={handleCloseReleaseRecordsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>
                    Are you sure you want to Release the Selected Records?
                  </MDTypography>
                  <MDBox mt={3} display="flex">
                    <div>
                      <MDButton
                        style={{ width: "100%", marginRight: "0.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseReleaseRecordsModal}
                      >
                        Cancel
                      </MDButton>
                    </div>
                    <div style={{ float: "right" }}>
                      <MDButton
                        style={{ width: "100%", marginLeft: "0.5rem" }}
                        color="warning"
                        onClick={handleReleaseRecords}
                      >
                        Release
                      </MDButton>
                    </div>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessReleaseRecords}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessReleaseRecords(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Released successfully!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorReleaseRecords}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorReleaseRecords(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {/* Could not Release! */}
                        {releaseRecordsErrorMsg}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    storeIssuance: state.getStoreIssuanceReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getStoreIssuance: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getStoreIssuance(pageNo, rowsPerPage, searchObject, jwtToken)),
    addToRelease: (payload, jwtToken) =>
      dispatch(addToRelease(payload, jwtToken)),
    validationForStoreIssuance: (payload, jwtToken) =>
      dispatch(validationForStoreIssuance(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreIssuance);
