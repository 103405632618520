export const GET_RELEASE_PICKLIST_REQUEST = "GET_RELEASE_PICKLIST_REQUEST";
export const GET_RELEASE_PICKLIST_SUCCESS = "GET_RELEASE_PICKLIST_SUCCESS";
export const GET_RELEASE_PICKLIST_FAILURE = "GET_RELEASE_PICKLIST_FAILURE";

export const CREATE_RELEASE_REQUEST = "CREATE_RELEASE_REQUEST";
export const CREATE_RELEASE_SUCCESS = "CREATE_RELEASE_SUCCESS";
export const CREATE_RELEASE_FAILURE = "CREATE_RELEASE_FAILURE";

export const REMOVE_RELEASEPICKLIST_REQUEST = "REMOVE_RELEASEPICKLIST_REQUEST";
export const REMOVE_RELEASEPICKLIST_SUCCESS = "REMOVE_RELEASEPICKLIST_SUCCESS";
export const REMOVE_RELEASEPICKLIST_FAILURE = "REMOVE_RELEASEPICKLIST_FAILURE";

export const GET_USERS_TO_ASSIGN_REQUEST = "GET_USERS_TO_ASSIGN_REQUEST";
export const GET_USERS_TO_ASSIGN_SUCCESS = "GET_USERS_TO_ASSIGN_SUCCESS";
export const GET_USERS_TO_ASSIGN_FAILURE = "GET_USERS_TO_ASSIGN_FAILURE";
