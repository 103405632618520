export const GET_WORK_FLOW_REQUEST = "GET_WORK_FLOW_REQUEST";
export const GET_WORK_FLOW_SUCCESS = "GET_WORK_FLOW_SUCCESS";
export const GET_WORK_FLOW_FAILURE = "GET_WORK_FLOW_FAILURE";

export const GET_LINEITEMSBYGRN_REQUEST = "GET_LINEITEMSBYGRN_REQUEST";
export const GET_LINEITEMSBYGRN_SUCCESS = "GET_LINEITEMSBYGRN_SUCCESS";
export const GET_LINEITEMSBYGRN_FAILURE = "GET_LINEITEMSBYGRN_FAILURE";

export const GET_WORK_FLOW_DATA_FOR_DOWNLOAD_REQUEST =
  "GET_WORK_FLOW_DATA_FOR_DOWNLOAD_REQUEST";
export const GET_WORK_FLOW_DATA_FOR_DOWNLOAD_SUCCESS =
  "GET_WORK_FLOW_DATA_FOR_DOWNLOAD_SUCCESS";
export const GET_WORK_FLOW_DATA_FOR_DOWNLOAD_FAILURE =
  "GET_WORK_FLOW_DATA_FOR_DOWNLOAD_FAILURE";

export const UPLOADCSV_FOR_GRN_REQUEST = "UPLOADCSV_FOR_GRN_REQUEST";
export const UPLOADCSV_FOR_GRN_SUCCESS = "UPLOADCSV_FOR_GRN_SUCCESS";
export const UPLOADCSV_FOR_GRN_FAILURE = "UPLOADCSV_FOR_GRN_FAILURE";

export const PRINT_WORK_FLOW_REQUEST = "PRINT_WORK_FLOW_REQUEST";
export const PRINT_WORK_FLOW_SUCCESS = "PRINT_WORK_FLOW_SUCCESS";
export const PRINT_WORK_FLOW_FAILURE = "PRINT_WORK_FLOW_FAILURE";

export const GET_MATERIAL_CODE_REQUEST = "GET_MATERIAL_CODE_REQUEST";
export const GET_MATERIAL_CODE_SUCCESS = "GET_MATERIAL_CODE_SUCCESS";
export const GET_MATERIAL_CODE_FAILURE = "GET_MATERIAL_CODE_FAILURE";
