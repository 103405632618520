import {
  GETWAREHOUSE_REQUEST,
  GETWAREHOUSE_SUCCESS,
  GETWAREHOUSE_FAILURE,
  GETWAREHOUSE_BYID_REQUEST,
  GETWAREHOUSE_BYID_SUCCESS,
  GETWAREHOUSE_BYID_FAILURE,
  UPDATEWAREHOUSE_REQUEST,
  UPDATEWAREHOUSE_SUCCESS,
  UPDATEWAREHOUSE_FAILURE,
  CREATE_WAREHOUSE_REQUEST,
  CREATE_WAREHOUSE_SUCCESS,
  CREATE_WAREHOUSE_FAILURE,
  UPLOAD_EXCEL_FOR_WAREHOUSE_FAILURE,
  UPLOAD_EXCEL_FOR_WAREHOUSE_SUCCESS,
  UPLOAD_EXCEL_FOR_WAREHOUSE_REQUEST,
  GET_DELIVERYWAREHOUSE_REQUEST,
  GET_DELIVERYWAREHOUSE_SUCCESS,
  GET_DELIVERYWAREHOUSE_FAILURE,
} from "./warehousesTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getWarehouseRequest = () => {
  return {
    type: GETWAREHOUSE_REQUEST,
  };
};

const getWarehouseSuccess = (payload) => {
  return {
    type: GETWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const getWarehouseFailure = (error) => {
  return {
    type: GETWAREHOUSE_FAILURE,
    payload: error,
  };
};

const getWarehouses = (warehouseType, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getWarehouseRequest());
    let url = `${baseUrl}/warehouses/get?type=${warehouseType}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getWarehouseSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getWarehouseFailure(err.response));
      });
  };
};

const getWarehouseByIdRequest = () => {
  return {
    type: GETWAREHOUSE_BYID_REQUEST,
  };
};

const getWarehouseByIdSuccess = (payload) => {
  return {
    type: GETWAREHOUSE_BYID_SUCCESS,
    payload: payload,
  };
};

const getWarehouseByIdFailure = (error) => {
  return {
    type: GETWAREHOUSE_BYID_FAILURE,
    payload: error,
  };
};

const getWarehouseById = (id, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getWarehouseByIdRequest());
    let url = `${baseUrl}/warehouses/get_by_id?id=${id}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getWarehouseByIdSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getWarehouseByIdFailure(err.message));
      });
  };
};

const updateWarehouseRequest = () => {
  return {
    type: UPDATEWAREHOUSE_REQUEST,
  };
};

const updateWarehouseSuccess = (payload) => {
  return {
    type: UPDATEWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const updateWarehouseFailure = (error) => {
  return {
    type: UPDATEWAREHOUSE_FAILURE,
    payload: error,
  };
};

const updateWarehouse = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateWarehouseRequest());
    let url = `${baseUrl}/warehouses/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateWarehouseSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateWarehouseFailure(err.message));
        return { status: false, message: err.message };
      });
  };
};

const createWarehouseRequest = () => {
  return {
    type: CREATE_WAREHOUSE_REQUEST,
  };
};

const createWarehouseSuccess = (payload) => {
  return {
    type: CREATE_WAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const createWarehouseFailure = (error) => {
  return {
    type: CREATE_WAREHOUSE_FAILURE,
    payload: error,
  };
};

const createWarehouse = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createWarehouseRequest());
    let url = `${baseUrl}/warehouses/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createWarehouseSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createWarehouseFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const uploadWarehouseRequest = () => {
  return {
    type: UPLOAD_EXCEL_FOR_WAREHOUSE_REQUEST,
  };
};

const uploadWarehouseSuccess = (payload) => {
  return {
    type: UPLOAD_EXCEL_FOR_WAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const uploadWarehouseFailure = (error) => {
  return {
    type: UPLOAD_EXCEL_FOR_WAREHOUSE_FAILURE,
    payload: error,
  };
};

const uploadWarehouse = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadWarehouseRequest());
    let url = `${baseUrl}/warehouse/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadWarehouseSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadWarehouseFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getDeliveryWarehouseRequest = () => {
  return {
    type: GET_DELIVERYWAREHOUSE_REQUEST,
  };
};

const getDeliveryWarehouseSuccess = (payload) => {
  return {
    type: GET_DELIVERYWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const getDeliveryWarehouseFailure = (error) => {
  return {
    type: GET_DELIVERYWAREHOUSE_FAILURE,
    payload: error,
  };
};

const getDeliveryWarehouses = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  let type = "s";
  return (dispatch) => {
    dispatch(getDeliveryWarehouseRequest());
    let url = `${baseUrl}/warehouses/get?type=${type}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getDeliveryWarehouseSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getDeliveryWarehouseFailure(err.message));
      });
  };
};

export {
  getWarehouses,
  getWarehouseById,
  updateWarehouse,
  createWarehouse,
  uploadWarehouse,
  getDeliveryWarehouses,
};
