export const GET_ALLGRN_REQUEST = "GET_ALLGRN_REQUEST";
export const GET_ALLGRN_SUCCESS = "GET_ALLGRN_SUCCESS";
export const GET_ALLGRN_FAILURE = "GET_ALLGRN_FAILURE";

export const GET_LINEITEMSBYGRN_REQUEST = "GET_LINEITEMSBYGRN_REQUEST";
export const GET_LINEITEMSBYGRN_SUCCESS = "GET_LINEITEMSBYGRN_SUCCESS";
export const GET_LINEITEMSBYGRN_FAILURE = "GET_LINEITEMSBYGRN_FAILURE";

export const GET_ALLGRN_DATA_FOR_DOWNLOAD_REQUEST =
  "GET_ALLGRN_DATA_FOR_DOWNLOAD_REQUEST";
export const GET_ALLGRN_DATA_FOR_DOWNLOAD_SUCCESS =
  "GET_ALLGRN_DATA_FOR_DOWNLOAD_SUCCESS";
export const GET_ALLGRN_DATA_FOR_DOWNLOAD_FAILURE =
  "GET_ALLGRN_DATA_FOR_DOWNLOAD_FAILURE";

export const UPLOADCSV_FOR_GRN_REQUEST = "UPLOADCSV_FOR_GRN_REQUEST";
export const UPLOADCSV_FOR_GRN_SUCCESS = "UPLOADCSV_FOR_GRN_SUCCESS";
export const UPLOADCSV_FOR_GRN_FAILURE = "UPLOADCSV_FOR_GRN_FAILURE";

export const SET_PACKSIZE_REQUEST = "SET_PACKSIZE_REQUEST";
export const SET_PACKSIZE_SUCCESS = "SET_PACKSIZE_SUCCESS";
export const SET_PACKSIZE_FAILURE = "SET_PACKSIZE_FAILURE";
