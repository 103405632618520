import {
  GET_ADDEDPIV_REQUEST,
  GET_ADDEDPIV_SUCCESS,
  GET_ADDEDPIV_FAILURE,
  REMOVE_PIVFROMISSUANCERELEASE_REQUEST,
  REMOVE_PIVFROMISSUANCERELEASE_SUCCESS,
  REMOVE_PIVFROMISSUANCERELEASE_FAILURE,
  RELEASE_PIV_REQUEST,
  RELEASE_PIV_SUCCESS,
  RELEASE_PIV_FAILURE,
} from "./issuanceReleaseTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getAddedPivRequest = () => {
  return {
    type: GET_ADDEDPIV_REQUEST,
  };
};

const getAddedPivSuccess = (payload) => {
  return {
    type: GET_ADDEDPIV_SUCCESS,
    payload: payload,
  };
};

const getAddedPivFailure = (error) => {
  return {
    type: GET_ADDEDPIV_FAILURE,
    payload: error,
  };
};

const getAddedPiv = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getAddedPivRequest());
    let url = `${baseUrl}/added_to_released_piv/get?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAddedPivSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAddedPivFailure(err.response));
      });
  };
};

const removePivRequest = () => {
  return {
    type: REMOVE_PIVFROMISSUANCERELEASE_REQUEST,
  };
};

const removePivSuccess = (payload) => {
  return {
    type: REMOVE_PIVFROMISSUANCERELEASE_SUCCESS,
    payload: payload,
  };
};

const removePivFaliure = (error) => {
  return {
    type: REMOVE_PIVFROMISSUANCERELEASE_FAILURE,
    payload: error,
  };
};

const removePiv = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(removePivRequest());
    let url = `${baseUrl}/piv/remove_piv_to_release_pickuplist`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(removePivSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(removePivFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const releasePivRequest = () => {
  return {
    type: RELEASE_PIV_REQUEST,
  };
};

const releasePivSuccess = (payload) => {
  return {
    type: RELEASE_PIV_SUCCESS,
    payload: payload,
  };
};

const releasePivFaliure = (error) => {
  return {
    type: RELEASE_PIV_FAILURE,
    payload: error,
  };
};

const releasePiv = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(releasePivRequest());
    let url = `${baseUrl}/piv/release`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(releasePivSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(releasePivFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export { getAddedPiv, removePiv, releasePiv };
