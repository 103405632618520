/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_PRODUCTION_ALLOCATION_FAILURE,
  GET_PRODUCTION_ALLOCATION_REQUEST,
  GET_PRODUCTION_ALLOCATION_SUCCESS,
  GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_FAILURE,
  GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_REQUEST,
  GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_SUCCESS,
  RELEASETO_PRODUCTION_PROCESS_FAILURE,
  RELEASETO_PRODUCTION_PROCESS_REQUEST,
  RELEASETO_PRODUCTION_PROCESS_SUCCESS,
  UPDATE_FROM_LOCATION_FAILURE,
  UPDATE_FROM_LOCATION_REQUEST,
  UPDATE_FROM_LOCATION_SUCCESS,
  UPDATE_RESOURCE_FAILURE,
  UPDATE_RESOURCE_REQUEST,
  UPDATE_RESOURCE_SUCCESS,
} from "./productionAllocationTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getProductionAllocationRequest = () => {
  return {
    type: GET_PRODUCTION_ALLOCATION_REQUEST,
  };
};

const getProductionAllocationSuccess = (payload) => {
  return {
    type: GET_PRODUCTION_ALLOCATION_SUCCESS,
    payload: payload,
  };
};

const getProductionAllocationFaliure = (error) => {
  return {
    type: GET_PRODUCTION_ALLOCATION_FAILURE,
    payload: error,
  };
};

const getProductionAllocation = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getProductionAllocationRequest());
    let url = `${baseUrl}/production_allocation/get_production_allocation?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getProductionAllocationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getProductionAllocationFaliure(err.response));
      });
  };
};

const updateResourceRequest = () => {
  return {
    type: UPDATE_RESOURCE_REQUEST,
  };
};

const updateResourceSuccess = (payload) => {
  return {
    type: UPDATE_RESOURCE_SUCCESS,
    payload: payload,
  };
};

const updateResourceFailure = (error) => {
  return {
    type: UPDATE_RESOURCE_FAILURE,
    payload: error,
  };
};

const updateResource = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateResourceRequest());
    let url = `${baseUrl}/production_process/update_resource`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateResourceSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateResourceFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateFromLocationRequest = () => {
  return {
    type: UPDATE_FROM_LOCATION_REQUEST,
  };
};

const updateFromLocationSuccess = (payload) => {
  return {
    type: UPDATE_FROM_LOCATION_SUCCESS,
    payload: payload,
  };
};

const updateFromLocationFailure = (error) => {
  return {
    type: UPDATE_FROM_LOCATION_FAILURE,
    payload: error,
  };
};

const updateFromLocationProductionAllocation = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateFromLocationRequest());
    let url = `${baseUrl}/production_allocation/update_location`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateFromLocationSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateFromLocationFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const releaseToProductionProcessRequest = () => {
  return {
    type: RELEASETO_PRODUCTION_PROCESS_REQUEST,
  };
};

const releaseToProductionProcessSuccess = (payload) => {
  return {
    type: RELEASETO_PRODUCTION_PROCESS_SUCCESS,
    payload: payload,
  };
};

const releaseToProductionProcessFailure = (error) => {
  return {
    type: RELEASETO_PRODUCTION_PROCESS_FAILURE,
    payload: error,
  };
};

const releaseToProductionProcess = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(releaseToProductionProcessRequest());
    let url = `${baseUrl}/production_allocation/add_to_allocate`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(releaseToProductionProcessSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(releaseToProductionProcessFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getLocationsForProductionAllocationRequest = () => {
  return {
    type: GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_REQUEST,
  };
};

const getLocationsForProductionAllocationSuccess = (payload) => {
  return {
    type: GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_SUCCESS,
    payload: payload,
  };
};

const getLocationsForProductionAllocationFailure = (error) => {
  return {
    type: GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_FAILURE,
    payload: error,
  };
};

const getLocationsForProductionAllocation = (materialCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLocationsForProductionAllocationRequest());
    let url = `${baseUrl}/production_allocation/get_locations?materialCode=${materialCode}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLocationsForProductionAllocationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLocationsForProductionAllocationFailure(err.message));
      });
  };
};

export {
  getProductionAllocation,
  updateResource,
  updateFromLocationProductionAllocation,
  releaseToProductionProcess,
  getLocationsForProductionAllocation,
};
