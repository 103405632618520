/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useState, useEffect, useMemo } from "react";

import { connect } from "react-redux";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Dashboard components
import MDBox from "./components/MDBox";

// Dashboard example components
import Sidenav from "./examples/Sidenav";
import Configurator from "./examples/Configurator";

// Dashboard themes
import theme from "./assets/theme";

// Dashboard Dark Mode themes
import themeDark from "./assets/theme-dark";

// Dashboard routes
// import routes from "./routes";

// Dashboard contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "./context";

// JWT and Cookies
import { isExpired } from "react-jwt";
import Cookies from "universal-cookie";

import { getModulesByRoleId, getSubModulesByRoleId } from "../src/store";

// Dashboard layouts (includes sub route components)
import Profile from "./layouts/profile";
import Login from "./layouts/authentication/login";
import Logout from "./layouts/authentication/logout";
import ResetPassword from "./layouts/authentication/reset-password";
import SetPassword from "./layouts/authentication/set-password";
import ForgotPassword from "./layouts/authentication/forgot-password";
import Home from "layouts/home";
import Reprint from "layouts/reprint";
import Warehouses from "layouts/warehouses";
import UserManagement from "layouts/user-management";
import DeviceManagement from "layouts/device-management";
import ViewLocation from "layouts/view-location";
import PrinterConfigurator from "layouts/printer-configurator";
import GrnDetails from "layouts/grn-details";
import AssetMaster from "layouts/asset-master";
import UnderTestPrint from "layouts/under-test-print";
import PivTracking from "layouts/piv-tracking";
import PicklistTracking from "layouts/picklist-tracking";
import ProductionAllocation from "layouts/production-allocation";
import ProductionProcess from "layouts/production-process";
import PivFillingAndReleasedPiv from "layouts/piv-filling-and-release";
import WarehouseOccupancy from "layouts/reports/warehouse-occupancy";
import MaterialIssuanceParent from "layouts/material-issuance-parent";
import StoreIssuanceParent from "layouts/store-issuance-parent";
import Inbound from "layouts/reports/inbound";
import ReprintReport from "layouts/reports/reprint";
import PivTrackingReport from "layouts/reports/piv-tracking";
import ProductionProcessReport from "layouts/reports/production-process";

import PhaseWiseCharging from "layouts/reports/phase-wise-charging";
import PickuplistWiseCharging from "layouts/reports/pickuplist-wise-charging";
import ItemMaster from "layouts/item-master";
import ConsolidatedPiv from "layouts/reports/consolidated-piv";
import WorkFlow from "layouts/work-flow";
import StockCutOverParent from "layouts/stock-cut-over-parent";

const cookies = new Cookies();

const App = ({ getSubModulesByRoleId, getSubModulesByRoleIdReducer }) => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Login details from cookies
  let loginDetails = cookies.get("loginDetailsForWMS");

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      // bgColor="white"
      // shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      // color="dark"
      // sx={{ cursor: "pointer" }}
      // onClick={handleConfiguratorOpen}
    >
      {/* <Icon fontSize="small" color="inherit">
        settings
      </Icon> */}
    </MDBox>
  );

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails ? loginDetails.data.roleId : "";

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  let loading = getSubModulesByRoleIdReducer.loading;

  let modulesData = getSubModulesByRoleIdReducer
    ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
    : null;

  let warehousesModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "6acb9f91-c5ee-4a7e-b39f-a84ae23ff619"
      )
    : "";

  let underTestModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "1754eb44-af40-42b2-b46f-d1784c334896"
      )
    : "";

  let stockCutOverModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "b8dc59c9-beaa-4750-b4ff-822c2f68c272"
      )
    : "";

  let grnDetailsModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "7f5bf8ba-bce5-434b-8bc6-7f8f0739abda"
      )
    : "";

  let reprintModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "cb6cabe4-7621-4a3d-8be6-6d43e87befc4"
      )
    : "";

  let workOffModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "8f3a3056-14d0-43e4-85f9-34f3f58d33ca"
      )
    : "";

  let assetMasterModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "03d46ffe-6f11-4846-91e2-e8fbb2f779cd"
      )
    : "";

  let materialIssuanceModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "511218db-be4c-44d2-896d-4c832b2d3f6b"
      )
    : "";

  let storeIssuanceModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "4d1bbe7e-e752-48e3-986f-b2ae4745d405"
      )
    : "";

  let pivTrackingModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "60e34942-b6a2-4615-8f0e-f9674fe8d98f"
      )
    : "";

  let pivFillingAndReleasedPivModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "c53dae9d-3fee-4132-92f8-0d17deef918b"
      )
    : "";

  let picklistTrackingModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "60e34942-b6a2-4615-8f0e-f9674fe8d98f"
      )
    : "";

  let productionAllocationModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "d8326dc9-1fda-4a5f-aac0-aa17cf8b28a8"
      )
    : "";

  let productionProcessModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "04e08875-fc13-4fb4-8e3c-e7cb0973998e"
      )
    : "";

  let itemMasterModule = modulesData
    ? modulesData.find((moduleId) => moduleId.parentModuleId == "")
    : "";

  let userManagementModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "a482ffd0-bc1d-41f9-84ee-cc0d32c85cc7"
      )
    : "";

  let deviceManagementModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "45b87d85-cf0a-44f7-8137-92fe8709cacb"
      )
    : "";

  let reportsModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "10072e36-93ec-4c3e-a0dd-bb394716c234"
      )
    : "";

  const routes = [
    {
      type: "collapse",
      name: "Home",
      key: "home",
      icon: <Icon fontSize="small">home</Icon>,
      route: "/home",
      component: <Home />,
    },
    warehousesModule
      ? {
          type: "collapse",
          name: "Warehouses",
          key: "warehouses",
          icon: <Icon fontSize="small">store_outlined</Icon>,
          route: "/warehouses",
          component: <Warehouses />,
        }
      : "",
    underTestModule
      ? {
          type: "collapse",
          name: "Under Test Print",
          key: "under-test",
          icon: <Icon fontSize="small">local_shipping</Icon>,
          route: "/under-test",
          component: <UnderTestPrint />,
        }
      : "",
    stockCutOverModule
      ? {
          type: "collapse",
          name: "Stock Cut Over",
          key: "stock-cut-over",
          icon: <Icon fontSize="small">adjust</Icon>,
          route: "/stock-cut-over",
          component: <StockCutOverParent />,
        }
      : "",
    !itemMasterModule
      ? {
          type: "collapse",
          name: "Item Master",
          key: "item-master",
          icon: <Icon fontSize="small">description</Icon>,
          route: "/item-master",
          component: <ItemMaster />,
        }
      : "",
    grnDetailsModule
      ? {
          type: "collapse",
          name: "GRN Details",
          key: "grn-details",
          icon: <Icon fontSize="small">receipt</Icon>,
          route: "/grn-details",
          component: <GrnDetails />,
        }
      : "",
    workOffModule
      ? {
          type: "collapse",
          name: "Work Off",
          key: "work-off",
          icon: <Icon fontSize="small">account_tree</Icon>,
          route: "/work-off",
          component: <WorkFlow />,
        }
      : "",

    reprintModule
      ? {
          type: "collapse",
          name: "Reprint",
          key: "reprint",
          icon: <Icon fontSize="small">print_two_tone</Icon>,
          route: "/reprint",
          component: <Reprint />,
        }
      : "",
    materialIssuanceModule
      ? {
          type: "collapse",
          name: "Material Issuance",
          key: "material-issuance",
          icon: <Icon fontSize="small">production_quantity_limits</Icon>,
          route: "/material-issuance",
          component: <MaterialIssuanceParent />,
        }
      : "",
    // materialIssuanceModule
    //   ? {
    //       type: "collapse",
    //       name: "Release For Issuance",
    //       key: "issuance-release",
    //       icon: <Icon fontSize="small">grading</Icon>,
    //       route: "/issuance-release",
    //       component: <ReleaseForIssuance />,
    //     }
    //   : "",

    storeIssuanceModule
      ? {
          type: "collapse",
          name: "Store Issuance",
          key: "store-issuance",
          icon: <Icon fontSize="small">receipt_long</Icon>,
          route: "/store-issuance",
          component: <StoreIssuanceParent />,
        }
      : "",
    pivTrackingModule
      ? {
          type: "collapse",
          name: "PIV Tracking",
          key: "piv-tracking",
          icon: <Icon fontSize="small">gps_fixed</Icon>,
          route: "/piv-tracking",
          component: <PivTracking />,
        }
      : "",
    pivFillingAndReleasedPivModule
      ? {
          type: "collapse",
          name: "Released PIV And Filling",
          key: "released-piv-and-filling",
          icon: <Icon fontSize="small">edit</Icon>,
          route: "/released-piv-and-filling",
          component: <PivFillingAndReleasedPiv />,
        }
      : "",
    picklistTrackingModule
      ? {
          type: "collapse",
          name: "Movement Tracking",
          key: "movement-tracking",
          icon: <Icon fontSize="small">location_searching</Icon>,
          route: "/movement-tracking",
          component: <PicklistTracking />,
        }
      : "",
    productionAllocationModule
      ? {
          type: "collapse",
          name: "Production Allocation",
          key: "production-allocation",
          icon: <Icon fontSize="small">inventory</Icon>,
          route: "/production-allocation",
          component: <ProductionAllocation />,
        }
      : "",
    productionProcessModule
      ? {
          type: "collapse",
          name: "Production Process",
          key: "production-process",
          icon: <Icon fontSize="small">account_tree</Icon>,
          route: "/production-process",
          component: <ProductionProcess />,
        }
      : "",
    assetMasterModule
      ? {
          type: "collapse",
          name: "Asset Master",
          key: "asset-master",
          icon: <Icon fontSize="small">web_asset</Icon>,
          route: "/asset-master",
          component: <AssetMaster />,
        }
      : "",
    deviceManagementModule
      ? {
          type: "collapse",
          name: "Device Management",
          key: "device-management",
          icon: <Icon fontSize="small">mobile_friendly</Icon>,
          route: "/device-management",
          component: <DeviceManagement />,
        }
      : "",
    reportsModule
      ? {
          type: "expand",
          name: "Reports",
          key: "reports",
          icon: <Icon fontSize="small">file_copy</Icon>,
          subRoutes: [
            {
              type: "collapse",
              name: "Warehouse Occu.",
              key: "warehouse-occupancy",
              icon: <Icon fontSize="small">insert_drive_file_outlined</Icon>,
              route: "/warehouse-occupancy",
            },
            {
              type: "collapse",
              name: "Inbound",
              key: "inbound",
              icon: <Icon fontSize="small">archive</Icon>,
              route: "/inbound",
            },
            {
              type: "collapse",
              name: "Reprint",
              key: "reprint-report",
              icon: <Icon fontSize="small">print_two_tone</Icon>,
              route: "/reprint-report",
            },
            {
              type: "collapse",
              name: "PIV Tracking",
              key: "piv-tracking-report",
              icon: <Icon fontSize="small">gps_fixed</Icon>,
              route: "/piv-tracking-report",
            },
            {
              type: "collapse",
              name: "Production Pro.",
              key: "production-process-report",
              icon: <Icon fontSize="small">account_tree</Icon>,
              route: "/production-process-report",
            },
            {
              type: "collapse",
              name: "Phase Charging",
              key: "phase-charging-report",
              icon: <Icon fontSize="small">payment</Icon>,
              route: "/phase-charging-report",
            },
            {
              type: "collapse",
              name: "Pickuplist Charging",
              key: "pickuplist-charging-report",
              icon: <Icon fontSize="small">swap_horiz</Icon>,
              route: "/pickuplist-charging-report",
            },
            {
              type: "collapse",
              name: "Consolidated PIV",
              key: "consolidated-piv-report",
              icon: <Icon fontSize="small">insert_drive_file</Icon>,
              route: "/consolidated-piv-report",
            },
          ],
        }
      : "",
    userManagementModule
      ? {
          type: "collapse",
          name: "User Management",
          key: "user-management",
          icon: <Icon fontSize="small">group</Icon>,
          route: "/user-management",
          component: <UserManagement />,
        }
      : "",

    {
      type: "collapse",
      name: "Logout",
      key: "logout",
      icon: <Icon fontSize="small">lock</Icon>,
      route: "/authentication/logout",
      component: <Logout />,
    },
  ];

  let forgotPasswordData = cookies.get("forgot-password-for-warehouse");
  let token = loginDetails?.jwt;
  const isMyTokenExpired = isExpired(token);

  if (loginDetails && !isMyTokenExpired) {
    if (loginDetails.status) {
      cookies.set("jwtForMobillorEditor", token, { path: "/" });
      return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {configsButton}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route
              exact
              path="/user-profile"
              element={<Profile />}
              key="profile"
            />
            <Route
              exact
              path="/authentication/reset-password"
              element={<ResetPassword />}
              key="resetPassword"
            />
            <Route
              exact
              path="/:warehouseType/:warehouseId"
              element={<ViewLocation />}
            />
            <Route
              exact
              path="/grn-details"
              element={<GrnDetails />}
              key="grn-details"
            />
            <Route
              exact
              path="/warehouse-occupancy"
              element={<WarehouseOccupancy />}
              key="warehouse-occupancy"
            />
            <Route exact path="/inbound" element={<Inbound />} key="inbound" />
            <Route
              exact
              path="/reprint-report"
              element={<ReprintReport />}
              key="reprint-report"
            />
            <Route
              exact
              path="/piv-tracking-report"
              element={<PivTrackingReport />}
              key="piv-tracking-report"
            />
            <Route
              exact
              path="/production-process-report"
              element={<ProductionProcessReport />}
              key="production-process-report"
            />
            <Route
              exact
              path="/phase-charging-report"
              element={<PhaseWiseCharging />}
              key="phase-charging-report"
            />
            <Route
              exact
              path="/pickuplist-charging-report"
              element={<PickuplistWiseCharging />}
              key="pickuplist-charging-report"
            />
            <Route
              exact
              path="/consolidated-piv-report"
              element={<ConsolidatedPiv />}
              key="consolidated-piv-report"
            />
            <Route
              exact
              path="/printer-configurator"
              element={<PrinterConfigurator />}
              key="printer-configurator"
            />
          </Routes>
        </ThemeProvider>
      );
    } else {
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <Routes>
          <Route
            path="/authentication/logout"
            element={<Logout />}
            key="logout"
          />
          {/* <Route
            path="/grn-details"
            element={<Navigate to="/authentication/logout" replace />}
          /> */}
        </Routes>
      </ThemeProvider>;
    }
  } else if (forgotPasswordData) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route
            exact
            path="/set-new-password"
            element={<ForgotPassword />}
            key="set-new-password"
          />

          <Route exact path="/" element={<Login />} key="login" />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route
            exact
            path="/authentication/set-password"
            element={<SetPassword />}
            key="setPassword"
          />
          <Route
            exact
            path="/authentication/forgot-password/:email"
            element={<ForgotPassword />}
            key="forgotPassword"
          />
          <Route exact path="/" element={<Login />} key="login" />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    // getModulesByRoleIdReducer: state.getModulesByRoleIdReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    // getModulesByRoleId: (roleId, jwtToken) =>
    //   dispatch(getModulesByRoleId(roleId, jwtToken)),
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(App);
