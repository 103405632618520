/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as Yup from "yup";
import TextError from "utils/TextError";
import { useFormik } from "formik";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Alert,
  Collapse,
  Icon,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";

// Functions from store
import {
  getSubModulesByRoleId,
  getProductionProcess,
  getResourcesForAllocation,
  updateResource,
  getUsersToAssign,
  updateUserForProductionProcess,
} from "../../store";

import { addMinutes, format } from "date-fns";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../config/index";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const ProductionProcess = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getProductionProcess,
  productionProcessReducer,
  getResourcesForAllocation,
  resource,
  updateResource,
  getUsersToAssign,
  usersData,
  updateUserForProductionProcess,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let productionProcessModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "b30c2ba0-9ae5-43bf-92fa-b01716a62465"
  );

  let viewProductionProcessAccess = productionProcessModule
    ? productionProcessModule.actionId.includes(
        "b8e7a68a-2920-4497-8a67-72139f151cb0"
      )
    : null;

  let updateResourceAccess = productionProcessModule
    ? productionProcessModule.actionId.includes(
        "3adda262-9de1-46e2-ac82-3c5024927f17"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [loading, setLoading] = useState(true);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getProductionProcess(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [singlePivData, setSinglePivData] = useState({});

  const statusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Not Charged",
      value: 0,
    },
    {
      label: "Charged",
      value: 1,
    },
  ];

  const [state, setState] = useState({
    columns: [
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Batch No / Qty", accessor: "batchNumber" },
      { Header: "Phase No", accessor: "phaseNumber" },
      { Header: "Phase Des", accessor: "phaseDescription" },
      { Header: "Pick List ID", accessor: "pickupListCode" },
      { Header: "Bulk Code / Des", accessor: "finalMaterialCode" },
      { Header: "Item Code / Des", accessor: "materialCode" },
      { Header: "Item Qty", accessor: "qty" },
      { Header: "Resource Category", accessor: "resourceCategory" },
      { Header: "Resource", accessor: "resource" },
      { Header: "From Loc", accessor: "source" },
      { Header: "To Loc", accessor: "destination" },
      {
        Header: "Start Time",
        accessor: "startTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "End TIme",
        accessor: "endTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        searchType: "select",
        selectOptions: statusOptions,
        placeholder: "All Status",
      },
      { Header: "User", accessor: "userName" },
      { Header: "Operator", accessor: "operator" },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [documentNo, setDocumentNo] = useState("");
  const [phaseNumber, setPhaseNumber] = useState("");
  const [resourceCategory, setResourceCategory] = useState("");

  useEffect(() => {
    let tempProductionProcess = [];

    let data = productionProcessReducer.process.data
      ? productionProcessReducer.process.data
      : [];

    setErrorMsgFromApi(productionProcessReducer?.error?.data?.msg);
    setLoading(productionProcessReducer.loading);
    setPaginationObj(productionProcessReducer?.process?.pagination);

    data.map((piv, index) => {
      let bulkDes = piv?.finalMaterialDescription;
      let itemDes = piv.materialDescription;
      let phaseDes = piv.phaseDescription;
      let len = desLength;

      const pivObject = {
        privateKey: piv.pickupListCode,
        documentNo: piv.documentNo,
        batchNumber: `${piv.batchNumber} / ${piv.batchSize} ${piv.batchUom}`,
        finalMaterialCode: `${piv.finalMaterialCode} / ${
          bulkDes ? bulkDes.substring(0, len) : ""
        }`,
        materialCode: `${piv.materialCode} / ${
          itemDes ? itemDes.substring(0, len) : ""
        }`,
        phaseNumber: piv.phaseNumber,
        pickupListCode: piv.pickupListCode,
        phaseDescription: phaseDes ? phaseDes.substring(0, len) : "",
        qty: `${piv.qty} ${piv.uom}`,
        resourceCategory: piv.resourceCategory,
        resource: (
          <span style={{ display: "flex" }}>
            <MDTypography
              variant="h6"
              fontWeight="regular"
              style={{ marginTop: "0.45rem" }}
            >
              {piv.resource}
            </MDTypography>
            <Tooltip title="Edit Resource" style={{ marginLeft: "0.75rem" }}>
              <MDButton
                disabled={!updateResourceAccess}
                color="info"
                variant="gradient"
                type="button"
                iconOnly
                size="small"
                onClick={() => {
                  setSinglePivData(piv);
                  setDocumentNo(piv.documentNo);
                  setPhaseNumber(piv.phaseNumber);
                  setResourceCategory(piv.resourceCategory);
                  getResourcesForAllocation(
                    piv.documentNo,
                    piv.phaseNumber,
                    piv.resourceCategory,
                    jwtToken
                  );
                  handleOpenEditResourceModal();
                }}
              >
                <Icon> edit </Icon>
              </MDButton>
            </Tooltip>
          </span>
        ),
        source: piv.source,
        destination: piv.destination,
        startTime: piv.startTime,
        endTime: piv.endTime,
        status: (
          <button
            className={
              (piv.status === 0 && `status-error`) ||
              (piv.status === 1 && `status-success`)
            }
            style={{ width: "5.5rem" }}
          >
            {(piv.status === 0 && `Not Charged`) ||
              (piv.status === 1 && `Charged`)}
          </button>
        ),
        userName: (
          <span style={{ display: "flex" }}>
            <Tooltip title="Edit User">
              <MDButton
                disabled={piv.status === 1}
                color="success"
                variant="gradient"
                type="button"
                iconOnly
                size="small"
                onClick={() => {
                  getUsersToAssign(jwtToken);
                  handleOpenAddUserModal(piv);
                }}
              >
                <Icon> edit_icon </Icon>
              </MDButton>
            </Tooltip>

            <MDTypography
              variant="h6"
              fontWeight="regular"
              style={{
                marginLeft: "0.75rem",
                marginTop: "-5px",
                width: "7rem",
              }}
            >
              {piv.userName}
            </MDTypography>
          </span>
        ),
        operator: piv.operator,
      };
      tempProductionProcess.push(pivObject);
    });

    viewProductionProcessAccess &&
      setState({ ...state, rows: tempProductionProcess });
  }, [productionProcessReducer]);

  const [resourcesOptions, setResourcesOptionsOptions] = useState([]);

  useEffect(() => {
    let tempResourcesOptions = [];
    let data = resource.resource.data ? resource.resource.data : [];

    data.map((resource) => {
      tempResourcesOptions.push({
        label: resource.resourceName,
        value: resource.id,
      });
    });
    setResourcesOptionsOptions(tempResourcesOptions);
  }, [resource]);

  const [openEditResourceModal, setOpenEditResourceModal] = useState(false);

  const handleOpenEditResourceModal = () => {
    setOpenEditResourceModal(true);
  };

  const handleCloseEditResourceModal = () => {
    setOpenEditResourceModal(false);
  };

  const [resourceSelected, setResourceSelected] = useState("");
  const handleResourceChange = (selectedResource) => {
    setResourceSelected(selectedResource);
  };

  const [isErrorEditResource, setIsErrorEditResource] = useState(false);
  const [isSuccessEditResource, setIsSuccessEditResource] = useState(false);

  useEffect(() => {
    if (isErrorEditResource) {
      setTimeout(() => {
        setIsErrorEditResource(false);
      }, 3000);
    }
  }, [isErrorEditResource]);

  useEffect(() => {
    if (isSuccessEditResource) {
      setTimeout(() => {
        setIsSuccessEditResource(false);
      }, 3000);
    }
  }, [isSuccessEditResource]);

  const initialValueForEditResource = {
    resource: "",
  };

  const validationSchemaEditResource = Yup.object({
    resource: Yup.object().required("Select the Resource"),
  });

  const [editResourceErrorMsg, setEditResourceErrorMsg] = useState("");

  const [buttonName, setButtonName] = useState("Submit");

  const onSubmitForEditResourceLoc = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let payload = {
      documentNo: singlePivData.documentNo,
      phaseNumber: singlePivData.phaseNumber,
      resource: resourceSelected.value,
    };

    let res = await updateResource(payload, jwtToken);

    if (res.status) {
      setIsSuccessEditResource(true);
      setResourceSelected("");
      getProductionProcess(pageNo, rowsPerPage, searchObject, jwtToken);
      resetForm();

      setTimeout(() => {
        handleCloseEditResourceModal();
        setButtonName("Submit");
      }, 2250);
    }
    if (!res.status) {
      setEditResourceErrorMsg(res.data.data.message);
      setIsErrorEditResource(true);
      setButtonName("Submit");
    }
  };

  const formikForEditResource = useFormik({
    initialValues: initialValueForEditResource,
    validationSchema: validationSchemaEditResource,
    onSubmit: onSubmitForEditResourceLoc,
  });

  const [usersOptions, setUsersOptions] = useState([]);

  useEffect(() => {
    let tempUsersOptions = [];
    let data = usersData.usersToAssign.data ? usersData.usersToAssign.data : [];

    data.map((user) => {
      tempUsersOptions.push({
        label: user.userName,
        value: user.userId,
      });
    });
    setUsersOptions(tempUsersOptions);
  }, [usersData]);

  const [isErrorAddUser, setIsErrorAddUser] = useState(false);
  const [isSuccessAddUser, setIsSuccessAddUser] = useState(false);

  useEffect(() => {
    if (isErrorAddUser) {
      setTimeout(() => {
        setIsErrorAddUser(false);
      }, 3000);
    }
  }, [isErrorAddUser]);

  useEffect(() => {
    if (isSuccessAddUser) {
      setTimeout(() => {
        setIsSuccessAddUser(false);
      }, 3000);
    }
  }, [isSuccessAddUser]);

  const [userSelected, setAddUserSelected] = useState("");

  const handleAddUserChange = (selectedUser) => {
    setAddUserSelected(selectedUser);
  };

  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  const [
    singleProductionAllocationDataForUpdate,
    setProductionAllocationDataForUpdate,
  ] = useState({});

  const handleOpenAddUserModal = (data = {}) => {
    setOpenAddUserModal(true);
    setProductionAllocationDataForUpdate(data);
  };

  const handleCloseAddUserModal = () => {
    setOpenAddUserModal(false);
  };

  const initialValueForAddUser = {
    user: "",
  };

  const validationSchemaAddUser = Yup.object({
    user: Yup.object().required("Select user"),
  });

  const [updateUserErrorMsg, setUpdateUserErrorMsg] = useState("");

  const onSubmitForAddUser = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let payload = {
      documentNo: singleProductionAllocationDataForUpdate?.documentNo,
      phaseNumber: singleProductionAllocationDataForUpdate?.phaseNumber,
      pickupListCode: singleProductionAllocationDataForUpdate?.pickupListCode,
      userId: userSelected?.value,
    };

    let res = await updateUserForProductionProcess(payload, jwtToken);

    if (res.status) {
      setIsSuccessAddUser(true);
      setAddUserSelected("");
      getProductionProcess(pageNo, rowsPerPage, searchObject, jwtToken);
      resetForm();

      setTimeout(() => {
        handleCloseAddUserModal();
        setButtonName("Submit");
      }, 2250);
    }
    if (!res.status) {
      setUpdateUserErrorMsg(res.data.data.message);
      setIsErrorAddUser(true);
      setButtonName("Submit");
    }
  };

  const formikForAddUser = useFormik({
    initialValues: initialValueForAddUser,
    validationSchema: validationSchemaAddUser,
    onSubmit: onSubmitForAddUser,
  });

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    entriesPerPage={{
                      defaultValue: 30,
                      entries: [30, 50, 75, 100],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    options={{ draggable: false }}
                    errorMsg={errorMsgFromApi}
                    serverSidePagination={true}
                    paginationObj={paginationObj}
                    pageNoValue={pageNo}
                    rowsPerPageValue={rowsPerPage}
                    pageNoProp={importPageNo}
                    rowsPerPageProp={importRowsPerPage}
                    searchProp={importSearch}
                  />
                </MDBox>

                <Modal
                  open={openEditResourceModal}
                  onClose={handleCloseEditResourceModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Edit Resource
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseEditResourceModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForEditResource.handleSubmit}
                      >
                        <MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Select Resource
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css"
                              placeholder="Select location..."
                              name="resource"
                              value={resourceSelected}
                              options={resourcesOptions}
                              onChange={(selectedOption) => {
                                handleResourceChange(selectedOption);
                                formikForEditResource.setFieldValue(
                                  "resource",
                                  selectedOption
                                );
                              }}
                            />
                            {formikForEditResource.errors.resource && (
                              <TextError
                                msg={formikForEditResource.errors.resource}
                              />
                            )}
                          </MDBox>

                          <MDBox mt={2}>
                            <Collapse in={isSuccessEditResource}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessEditResource(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Location edited successfully!
                              </Alert>
                            </Collapse>
                            <Collapse in={isErrorEditResource}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorEditResource(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {/* Could not edit the Location! */}
                                {editResourceErrorMsg}
                              </Alert>
                            </Collapse>
                          </MDBox>

                          <div className="mt-3">
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="info"
                              fullWidth
                              disabled={
                                !formikForEditResource.isValid ||
                                buttonName === "Please wait..."
                              }
                            >
                              {buttonName}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openAddUserModal}
                  onClose={handleCloseAddUserModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Change User & Confirm
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseAddUserModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForAddUser.handleSubmit}
                      >
                        <MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Picklist Id:-{" "}
                              <span style={{ fontWeight: 400 }}>
                                {
                                  singleProductionAllocationDataForUpdate?.pickupListCode
                                }
                              </span>
                            </MDTypography>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Process Order No:-{" "}
                              <span style={{ fontWeight: 400 }}>
                                {
                                  singleProductionAllocationDataForUpdate?.documentNo
                                }
                              </span>
                            </MDTypography>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Item Code:-{" "}
                              <span style={{ fontWeight: 400 }}>
                                {
                                  singleProductionAllocationDataForUpdate?.materialCode
                                }
                              </span>
                            </MDTypography>
                          </MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Select User
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css"
                              placeholder="Select user..."
                              name="user"
                              value={userSelected}
                              options={usersOptions}
                              onChange={(selectedOption) => {
                                handleAddUserChange(selectedOption);
                                formikForAddUser.setFieldValue(
                                  "user",
                                  selectedOption
                                );
                              }}
                            />
                            {formikForAddUser.errors.user && (
                              <TextError msg={formikForAddUser.errors.user} />
                            )}
                          </MDBox>

                          <MDBox mt={2}>
                            <Collapse in={isSuccessAddUser}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessAddUser(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                User added and picklist released successfully!
                              </Alert>
                            </Collapse>
                            <Collapse in={isErrorAddUser}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorAddUser(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {/* Could not Add the User and release! */}
                                {updateUserErrorMsg}
                              </Alert>
                            </Collapse>
                          </MDBox>

                          <div className="mt-3">
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="success"
                              fullWidth
                              disabled={
                                !formikForAddUser.isValid ||
                                buttonName === "Please wait..."
                              }
                            >
                              {buttonName}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>

        {loading && (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    productionProcessReducer: state.getProductionProcessReducer,
    resource: state.getResourcesForAllocationReducer,
    usersData: state.getUsersToAssignReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getProductionProcess: (pageNo, rowsPerPage, search, jwtToken) =>
      dispatch(getProductionProcess(pageNo, rowsPerPage, search, jwtToken)),
    getResourcesForAllocation: (
      documentNo,
      phaseNumber,
      resourceCategory,
      jwtToken
    ) =>
      dispatch(
        getResourcesForAllocation(
          documentNo,
          phaseNumber,
          resourceCategory,
          jwtToken
        )
      ),
    updateResource: (payload, jwtToken) =>
      dispatch(updateResource(payload, jwtToken)),
    getUsersToAssign: (jwtToken) => dispatch(getUsersToAssign(jwtToken)),
    updateUserForProductionProcess: (payload, jwtToken) =>
      dispatch(updateUserForProductionProcess(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductionProcess);
