/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  getMaterialDesc,
  underTestPrint,
  getSubModulesByRoleId,
} from "../../store";

import Select from "react-select";
import "../../index.css";

import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// @mui material components
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

import Cookies from "universal-cookie";

const cookies = new Cookies();

function UnderTest({
  getMaterialDesc,
  getMaterialDescReducer,
  underTestPrint,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) {
  let data = cookies.get("loginDetailsForWMS");
  let printerId = cookies.get("printerIdForWarehouse");

  let userId = data?.data?.userId;
  let roleId = data.data.roleId;
  let jwtToken = data?.jwt;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let underTestModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "db32a619-e9e5-4e89-a7ce-3a1d0913b37a"
  );

  let printUnderTest = underTestModule
    ? underTestModule.actionId.includes("350705fe-53ae-4b8c-826a-1d7cba481bdb")
    : null;

  const [isSuccessUnderTestPrint, setIsSuccessUnderTestPrint] = useState(false);
  const [isErrorUnderTestPrint, setIsErrorUnderTestPrint] = useState(false);

  useEffect(() => {
    if (isErrorUnderTestPrint) {
      setTimeout(() => {
        setIsErrorUnderTestPrint(false);
      }, 3000);
    }
  }, [isErrorUnderTestPrint]);

  useEffect(() => {
    if (isSuccessUnderTestPrint) {
      setTimeout(() => {
        setIsSuccessUnderTestPrint(false);
      }, 3000);
    }
  }, [isSuccessUnderTestPrint]);

  const locationOptions = [
    {
      label: "2008",
      value: "2008",
    },
    {
      label: "2009",
      value: "2009",
    },
    {
      label: "2010",
      value: "2010",
    },
  ];

  const [locationSelected, setLocationSelected] = useState("");

  const handleLocationChange = (selectedZone) => {
    setLocationSelected(selectedZone);
  };

  const [materialDescription, setMaterialDescription] = useState("");
  const [uom, setUom] = useState("");

  const initialValues = {
    sapCode: "",
    materialDescription: materialDescription,
    netWt: "",
    uom: uom,
    deliveryNo: "",
    location: "",
    printQty: "",
    dateOfReceipt: new Date().toISOString().slice(0, 10),
  };

  const validationSchema = Yup.object({
    sapCode: Yup.string()
      .trim("Please enter a valid sap code")
      .strict(true)
      .required("Enter the SAP Code"),
    netWt: Yup.string()
      .trim("Please enter a valid net wt")
      .strict(true)
      .required("Enter the Net Wt"),
    // uom: Yup.string()
    //   .trim("Please enter a valid uom")
    //   .strict(true)
    //   .required("Enter the uom"),
    deliveryNo: Yup.string()
      .trim("Please enter a valid delivery no")
      .strict(true)
      .required("Enter the Delivery No"),
    location: Yup.object()
      // .trim("Please enter a valid location")
      // .strict(true)
      .required("Select the Location"),
    printQty: Yup.number()
      .positive("Please enter a positive number")
      .required("Enter the Print Qty"),
    dateOfReceipt: Yup.string().required("Select the Receipt Date"),
  });

  const [printErrorMsg, setPrintErrorMsg] = useState("");

  const onSubmit = async (values, { resetForm }) => {
    values.materialDescription = materialDescription;
    values.uom = uom;
    values.printerId = printerId;
    values.location = locationSelected.value;

    let res = await underTestPrint(values, jwtToken);

    if (res.status) {
      resetForm();
      setMaterialDescription("");
      setUom("");
      setIsSuccessUnderTestPrint(true);
    }
    if (!res.status) {
      setPrintErrorMsg(res.data.data.message);
      setIsErrorUnderTestPrint(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const searchSapCodeHandler = async () => {
    let res = await getMaterialDesc(formik.values.sapCode, jwtToken);

    if (!res.status) {
      SapCodeNotification(res?.data?.data?.msg);
    }
  };

  const SapCodeNotification = (msg) =>
    toast.error(msg, {
      position: "top-center",
      autoClose: 2000,
    });

  useEffect(() => {
    setMaterialDescription(
      getMaterialDescReducer?.desc[0]?.materialDescription
    );
    setUom(getMaterialDescReducer?.desc[0]?.uom);
  }, [getMaterialDescReducer]);

  return (
    <div>
      <>
        <DashboardLayout>
          <DashboardNavbar />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Card sx={{ width: "40%", marginTop: "90px" }}>
              <MDBox ml={2} mt={2}>
                <MDTypography variant="h4" fontWeight="medium">
                  Under Test Print
                </MDTypography>
              </MDBox>

              <hr style={{ marginTop: "0.1rem" }} />

              <MDBox
                component="form"
                role="form"
                onSubmit={formik.handleSubmit}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <MDBox p={1}>
                      <MDTypography
                        variant="h6"
                        style={{ fontSize: 14, fontWeight: 500 }}
                      >
                        SAP Code
                      </MDTypography>
                      <span style={{ display: "flex" }}>
                        <MDInput
                          type="text"
                          name="sapCode"
                          fullWidth
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.sapCode}
                          error={
                            formik.touched.sapCode &&
                            formik.errors.sapCode &&
                            true
                          }
                        />

                        <Tooltip title="Search SAP Code">
                          <MDButton
                            variant="gradient"
                            color="info"
                            iconOnly
                            type="button"
                            style={{ marginLeft: "0.5rem" }}
                            onClick={searchSapCodeHandler}
                          >
                            <Icon> search </Icon>
                          </MDButton>
                        </Tooltip>
                      </span>
                      {formik.touched.sapCode && formik.errors.sapCode && (
                        <TextError msg={formik.errors.sapCode} />
                      )}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox p={1}>
                      <MDTypography
                        variant="h6"
                        style={{ fontSize: 14, fontWeight: 500 }}
                      >
                        Material Description
                      </MDTypography>
                      <MDInput
                        type="text"
                        name="materialDescription"
                        disabled
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={materialDescription}
                        error={
                          formik.touched.materialDescription &&
                          formik.errors.materialDescription &&
                          true
                        }
                      />
                      {formik.touched.materialDescription &&
                        formik.errors.materialDescription && (
                          <TextError msg={formik.errors.materialDescription} />
                        )}
                    </MDBox>
                  </Grid>
                  <Grid item xs={6}>
                    <MDBox p={1}>
                      <MDTypography
                        variant="h6"
                        style={{ fontSize: 14, fontWeight: 500 }}
                      >
                        Net Wt
                      </MDTypography>
                      <MDInput
                        type="text"
                        name="netWt"
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.netWt}
                        error={
                          formik.touched.netWt && formik.errors.netWt && true
                        }
                      />
                      {formik.touched.netWt && formik.errors.netWt && (
                        <TextError msg={formik.errors.netWt} />
                      )}
                    </MDBox>
                  </Grid>
                  <Grid item xs={6}>
                    <MDBox p={1}>
                      <MDTypography
                        variant="h6"
                        style={{ fontSize: 14, fontWeight: 500 }}
                      >
                        UOM
                      </MDTypography>
                      <MDInput
                        type="text"
                        name="uom"
                        fullWidth
                        disabled
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={uom}
                        error={formik.touched.uom && formik.errors.uom && true}
                      />
                      {formik.touched.uom && formik.errors.uom && (
                        <TextError msg={formik.errors.uom} />
                      )}
                    </MDBox>
                  </Grid>
                  <Grid item xs={6}>
                    <MDBox p={1}>
                      <MDTypography
                        variant="h6"
                        style={{ fontSize: 14, fontWeight: 500 }}
                      >
                        Delivery No
                      </MDTypography>
                      <MDInput
                        type="text"
                        name="deliveryNo"
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.deliveryNo}
                        error={
                          formik.touched.deliveryNo &&
                          formik.errors.deliveryNo &&
                          true
                        }
                      />
                      {formik.touched.deliveryNo &&
                        formik.errors.deliveryNo && (
                          <TextError msg={formik.errors.deliveryNo} />
                        )}
                    </MDBox>
                  </Grid>
                  <Grid item xs={6}>
                    <MDBox p={1}>
                      <MDTypography
                        variant="h6"
                        style={{ fontSize: 14, fontWeight: 500 }}
                      >
                        Print Qty
                      </MDTypography>
                      <MDInput
                        type="number"
                        name="printQty"
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.printQty}
                        error={
                          formik.touched.printQty &&
                          formik.errors.printQty &&
                          true
                        }
                      />
                      {formik.touched.printQty && formik.errors.printQty && (
                        <TextError msg={formik.errors.printQty} />
                      )}
                    </MDBox>
                  </Grid>
                  <Grid item xs={6}>
                    <MDBox p={1}>
                      <MDTypography
                        variant="h6"
                        style={{ fontSize: 14, fontWeight: 500 }}
                      >
                        Date of Receipt
                      </MDTypography>
                      <MDInput
                        type="date"
                        name="dateOfReceipt"
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.dateOfReceipt}
                        error={
                          formik.touched.dateOfReceipt &&
                          formik.errors.dateOfReceipt &&
                          true
                        }
                      />
                      {formik.touched.dateOfReceipt &&
                        formik.errors.dateOfReceipt && (
                          <TextError msg={formik.errors.dateOfReceipt} />
                        )}
                    </MDBox>
                  </Grid>
                  <Grid item xs={6}>
                    <MDBox p={1}>
                      <MDTypography
                        variant="h6"
                        style={{ fontSize: 14, fontWeight: 500 }}
                      >
                        Location
                      </MDTypography>
                      <Select
                        isClearable
                        className="select-css"
                        placeholder="Select location..."
                        name="location"
                        value={locationSelected}
                        options={locationOptions}
                        onChange={(selectedOption) => {
                          handleLocationChange(selectedOption);
                          formik.setFieldValue("location", selectedOption);
                        }}
                      />
                      {formik.errors.location && (
                        <TextError msg={formik.errors.location} />
                      )}
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox className="mt-3">
                  <Collapse in={isErrorUnderTestPrint}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorUnderTestPrint(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {/* Could not print the Under Test label! */}
                      {printErrorMsg}
                    </Alert>
                  </Collapse>
                  <Collapse in={isSuccessUnderTestPrint}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsSuccessUnderTestPrint(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Under Test label printed!
                    </Alert>
                  </Collapse>
                </MDBox>

                <MDBox display="flex" style={{ float: "right" }} p={1}>
                  {printerId == undefined && (
                    <MDTypography
                      style={{
                        color: "red",
                        marginRight: "10px",
                        fontSize: "15px",
                        marginTop: "5px",
                      }}
                    >
                      Please configure the Printer!
                    </MDTypography>
                  )}
                  <MDButton
                    color="success"
                    type="submit"
                    size="small"
                    disabled={
                      !formik.isValid ||
                      !printUnderTest ||
                      !materialDescription ||
                      !uom ||
                      printerId == undefined
                    }
                  >
                    Print
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </div>
          <ToastContainer />
          <br />
          <br />
          <br />
          <Footer />
        </DashboardLayout>
      </>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getMaterialDescReducer: state.getMaterialDescReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMaterialDesc: (materialCode, jwtToken) =>
      dispatch(getMaterialDesc(materialCode, jwtToken)),
    underTestPrint: (payload, jwtToken) =>
      dispatch(underTestPrint(payload, jwtToken)),
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnderTest);
