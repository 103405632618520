export const GET_PRODUCTION_ALLOCATION_REQUEST =
  "GET_PRODUCTION_ALLOCATION_REQUEST";
export const GET_PRODUCTION_ALLOCATION_SUCCESS =
  "GET_PRODUCTION_ALLOCATION_SUCCESS";
export const GET_PRODUCTION_ALLOCATION_FAILURE =
  "GET_PRODUCTION_ALLOCATION_FAILURE";

export const UPDATE_RESOURCE_REQUEST = "UPDATE_RESOURCE_REQUEST";
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS";
export const UPDATE_RESOURCE_FAILURE = "UPDATE_RESOURCE_FAILURE";

export const UPDATE_FROM_LOCATION_REQUEST = "UPDATE_FROM_LOCATION_REQUEST";
export const UPDATE_FROM_LOCATION_SUCCESS = "UPDATE_FROM_LOCATION_SUCCESS";
export const UPDATE_FROM_LOCATION_FAILURE = "UPDATE_FROM_LOCATION_FAILURE";

export const RELEASETO_PRODUCTION_PROCESS_REQUEST =
  "RELEASETO_PRODUCTION_PROCESS_REQUEST";
export const RELEASETO_PRODUCTION_PROCESS_SUCCESS =
  "RELEASETO_PRODUCTION_PROCESS_SUCCESS";
export const RELEASETO_PRODUCTION_PROCESS_FAILURE =
  "RELEASETO_PRODUCTION_PROCESS_FAILURE";

export const GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_REQUEST =
  "GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_REQUEST";
export const GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_SUCCESS =
  "GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_SUCCESS";
export const GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_FAILURE =
  "GET_LOCATIONS_FOR_PRODUCTIONALLOCATION_FAILURE";
