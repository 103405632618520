import {
  GET_PICKUPLISTWISECHARGING_REQUEST,
  GET_PICKUPLISTWISECHARGING_SUCCESS,
  GET_PICKUPLISTWISECHARGING_FAILURE,
  DOWNLOAD_PICKUPLISTWISECHARGING_REQUEST,
  DOWNLOAD_PICKUPLISTWISECHARGING_SUCCESS,
  DOWNLOAD_PICKUPLISTWISECHARGING_FAILURE,
} from "./pickuplistWiseChargingTypes";

const initialStateForPickupListCharging = {
  loading: false,
  pickupListCharging: [],
  error: "",
};

const getPickupListWiseChargingReducer = (
  state = initialStateForPickupListCharging,
  action
) => {
  switch (action.type) {
    case GET_PICKUPLISTWISECHARGING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PICKUPLISTWISECHARGING_SUCCESS:
      return {
        loading: false,
        pickupListCharging: action.payload,
        error: "",
      };
    case GET_PICKUPLISTWISECHARGING_FAILURE:
      return {
        loading: false,
        pickupListCharging: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDownload = {
  loading: false,
  pickupListCharging: [],
  error: "",
};

const downloadPickupListWiseChargingReducer = (
  state = initialStateForDownload,
  action
) => {
  switch (action.type) {
    case DOWNLOAD_PICKUPLISTWISECHARGING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_PICKUPLISTWISECHARGING_SUCCESS:
      return {
        loading: false,
        pickupListCharging: action.payload,
        error: "",
      };
    case DOWNLOAD_PICKUPLISTWISECHARGING_FAILURE:
      return {
        loading: false,
        pickupListCharging: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getPickupListWiseChargingReducer,
  downloadPickupListWiseChargingReducer,
};
