/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";

// Functions from store
import {
  getItemMaster,
  editItemMaster,
  getSubModulesByRoleId,
} from "../../store";

import { addMinutes, format } from "date-fns";

// React-Select component and styling
import Select from "react-select";

// DatePicker components and  styling
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Config file
import Config from "../../config/index";
import { CSVLink } from "react-csv";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForEdit = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const ItemMaster = ({
  getItemMaster,
  itemMaster,

  editItemMaster,

  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userId = loginDetails?.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let itemModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "b496ec6f-d8d8-4047-990a-d357587027b9"
  );

  let createAccess = itemModule
    ? itemModule.actionId.includes("bd0ff26b-060e-4ed4-af61-01468c0205d3")
    : null;

  let viewAccess = itemModule
    ? itemModule.actionId.includes("f139822c-6f3e-4525-9c91-0f7cdc9ac62f")
    : null;

  let uploadItem = itemModule
    ? itemModule.actionId.includes("1fa09da4-d42e-44c6-b2ca-b3ef663bddf3")
    : null;
  let editItem = itemModule
    ? itemModule.actionId.includes("1614175c-e0bf-4910-8033-79f0bb3f77fa")
    : null;
  let downloadItem = itemModule
    ? itemModule.actionId.includes("aa106995-31d7-45a4-a0a3-d14e6204ad2c")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [loading, setLoading] = useState(true);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getItemMaster(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const fefoStatusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "True",
      value: 1,
    },
    {
      label: "False",
      value: 0,
    },
  ];

  const [state, setState] = useState({
    columns: [
      { Header: "Material Code", accessor: "materialCode" },
      { Header: "Material Desc", accessor: "materialDescription" },
      { Header: "Material Type", accessor: "materialType", align: "left" },
      { Header: "Material Group", accessor: "materialGroup", align: "left" },
      { Header: "UOM", accessor: "uom", align: "left" },
      {
        Header: "Expiry",
        accessor: "shelfLife",
      },
      {
        Header: "FEFO Status",
        accessor: "isFEFO",
        searchType: "select",
        selectOptions: fefoStatusOptions,
        placeholder: "All Status",
        align: "center",
      },

      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  useEffect(() => {
    let tempItemMaster = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];

    setErrorMsgFromApi(itemMaster?.error?.data?.msg);
    setLoading(itemMaster.loading);
    setPaginationObj(itemMaster?.itemMaster?.pagination);
    data.map((item) => {
      let des = item?.materialDescription;
      let len = desLength;
      const itemObject = {
        materialCode: item.materialCode,
        materialDescription: `${des ? des.substring(0, len) : ""}`,
        uom: item.uom,
        materialType: item.materialType,
        materialGroup: item.materialGroup,
        shelfLife: item.shelfLife,
        isFEFO: (
          <button
            className={
              (item.isFEFO === 0 && `status-error`) ||
              (item.isFEFO === 1 && `status-success`)
            }
            style={{
              width: "5.5rem",
              marginRight: "1rem",
              // marginLeft: "2rem",
            }}
          >
            {(item.isFEFO === 0 && `False`) || (item.isFEFO === 1 && `True`)}
          </button>
        ),
        action: (
          <Tooltip title="Edit">
            <MDButton
              type="button"
              variant="gradient"
              color="success"
              iconOnly
              onClick={() => {
                handleOpenEditSingleItemMasterModal(item);
              }}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Tooltip>
        ),
      };
      tempItemMaster.push(itemObject);
    });

    setState({ ...state, rows: tempItemMaster });
  }, [itemMaster]);

  const [buttonName, setButtonName] = useState("Submit");

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [errorMsgForUpdate, setErrorMsgForUpdate] = useState("");

  const [isSuccessEdit, setIsSuccessEdit] = useState(false);
  const [isErrorEdit, setIsErrorEdit] = useState(false);
  useEffect(() => {
    if (isSuccessEdit) {
      setTimeout(() => {
        setIsSuccessEdit(false);
      }, 3000);
    }
  }, [isSuccessEdit]);
  useEffect(() => {
    if (isErrorEdit) {
      setTimeout(() => {
        setIsErrorEdit(false);
      }, 3000);
    }
  }, [isErrorEdit]);

  const [editItemModal, setEditItemModal] = useState(false);
  const handleEditItemModal = () => {
    setEditItemModal(!editItemModal);
    formik.setErrors({});
  };

  const [singleItemMasterDataForEdit, setSingleItemMasterDataForEdit] =
    useState({});

  const handleOpenEditSingleItemMasterModal = (data = {}) => {
    setFefoStatusSelectedForEdit({
      label: data.isFEFO == 1 ? "True" : "False",
      value: data.isFEFO,
    });

    setSingleItemMasterDataForEdit(data);
    setEditItemModal(true);
  };

  const initialValueForEdit = {
    itemDescription: singleItemMasterDataForEdit.itemDescription
      ? singleItemMasterDataForEdit.itemDescription
      : "",

    fefoStatus: singleItemMasterDataForEdit.fefoStatus
      ? singleItemMasterDataForEdit.fefoStatus
      : "",
  };

  const validationSchemaForEdit = Yup.object({});

  const onSubmitForEdit = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    const payload = {
      userId: userId,
      materialCode: singleItemMasterDataForEdit.materialCode,

      isFEFO: fefoStatusSelectedForEdit.value,
    };

    let res = await editItemMaster(payload, jwtToken);

    if (res.status) {
      setIsSuccessEdit(true);
      getItemMaster(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        handleEditItemModal();
        setButtonName("Submit");
      }, 2250);
    }
    if (!res.status) {
      setIsErrorEdit(true);
      setErrorMsgForUpdate(res.data.data.message);
      setButtonName("Submit");
    }
    // resetForm();
  };

  const formik = useFormik({
    initialValues: initialValueForEdit,
    onSubmit: onSubmitForEdit,
    validationSchema: validationSchemaForEdit,
    enableReinitialize: true,
  });

  const [fefoStatusSelectedForEdit, setFefoStatusSelectedForEdit] =
    useState("");

  const handleChangeFefoStatusForEdit = (selectedOption) => {
    setFefoStatusSelectedForEdit(selectedOption);
  };

  const fefoStatusOptionsForEdit = [
    {
      label: "True",
      value: 1,
    },
    {
      label: "False",
      value: 0,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} mt={0}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  errorMsg={errorMsgFromApi}
                  showTotalEntries={true}
                  noEndBorder
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>

              <Modal
                open={editItemModal}
                onClose={handleEditItemModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForEdit}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Update
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleEditItemModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            // marginLeft: "-55px",
                            // marginBottom: "5px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formik.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          FEFO Applicable
                        </MDTypography>

                        <Select
                          isClearable
                          className="select-css"
                          placeholder="Choose one..."
                          name="fefoStatus"
                          value={fefoStatusSelectedForEdit}
                          options={fefoStatusOptionsForEdit}
                          onChange={(selectedOption) => {
                            handleChangeFefoStatusForEdit(selectedOption);
                            formik.setFieldValue("fefoStatus", selectedOption);
                          }}
                        />
                        {formik.errors.fefoStatus && (
                          <TextError msg={formik.errors.fefoStatus} />
                        )}
                      </MDBox>
                      <MDBox>
                        <Collapse in={isErrorEdit}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorEdit(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsgForUpdate}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessEdit}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessEdit(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Updated successfully!
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={2}>
                        <MDButton
                          fullWidth
                          variant="gradient"
                          color="success"
                          type="submit"
                          disabled={
                            !fefoStatusSelectedForEdit ||
                            buttonName === "Please wait..."
                          }
                        >
                          {buttonName}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    itemMaster: state.itemMaster,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getItemMaster: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getItemMaster(pageNo, rowsPerPage, searchObject, jwtToken)),

    editItemMaster: (payload, jwtToken) =>
      dispatch(editItemMaster(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemMaster);
