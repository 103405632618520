import {
  GET_RELEASE_PICKLIST_REQUEST,
  GET_RELEASE_PICKLIST_SUCCESS,
  GET_RELEASE_PICKLIST_FAILURE,
  GET_USERS_TO_ASSIGN_REQUEST,
  GET_USERS_TO_ASSIGN_SUCCESS,
  GET_USERS_TO_ASSIGN_FAILURE,
} from "./releasePicklistTypes";

const initialStateForReleasePicklist = {
  loading: false,
  releasePicklist: [],
  error: "",
};

const getReleasePicklistReducer = (
  state = initialStateForReleasePicklist,
  action
) => {
  switch (action.type) {
    case GET_RELEASE_PICKLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RELEASE_PICKLIST_SUCCESS:
      return {
        loading: false,
        releasePicklist: action.payload,
        error: "",
      };
    case GET_RELEASE_PICKLIST_FAILURE:
      return {
        loading: false,
        releasePicklist: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUsersToAssign = {
  loading: false,
  usersToAssign: [],
  error: "",
};

const getUsersToAssignReducer = (
  state = initialStateForUsersToAssign,
  action
) => {
  switch (action.type) {
    case GET_USERS_TO_ASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_TO_ASSIGN_SUCCESS:
      return {
        loading: false,
        usersToAssign: action.payload,
        error: "",
      };
    case GET_USERS_TO_ASSIGN_FAILURE:
      return {
        loading: false,
        usersToAssign: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getReleasePicklistReducer, getUsersToAssignReducer };
