/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { TabView, TabPanel } from "primereact/tabview";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";

// Layouts
import StoreIssuance from "./store-issuance";
import ReleasePicklist from "./release-picklist";

import { getSubModulesByRoleId } from "../../store";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const StoreIssuanceParent = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let storeIssuanceModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "b0ea8017-5741-4469-95a4-20f56414f28e"
  );

  let releasePicklistModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "b0ea8017-5741-4469-95a4-20f56414f28e"
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {storeIssuanceModule && releasePicklistModule ? (
              <>
                <TabView>
                  <TabPanel header="Store Issuance">
                    <StoreIssuance />
                  </TabPanel>
                  <TabPanel header="Release Picklist">
                    <ReleasePicklist />
                  </TabPanel>
                </TabView>
              </>
            ) : !storeIssuanceModule && releasePicklistModule ? (
              <>
                <TabView>
                  <TabPanel header="Release Picklist">
                    <ReleasePicklist />
                  </TabPanel>
                </TabView>
              </>
            ) : storeIssuanceModule && !releasePicklistModule ? (
              <>
                <TabView>
                  <TabPanel header="Store Issuance">
                    <StoreIssuance />
                  </TabPanel>
                </TabView>
              </>
            ) : (
              <>
                <h4 style={{ textAlign: "center", marginTop: "6rem" }}>
                  No Store Issuance Access
                </h4>
              </>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(StoreIssuanceParent);
