import {
  PRINT_STOCK_CUT_OVER_REQUEST,
  PRINT_STOCK_CUT_OVER_SUCCESS,
  PRINT_STOCK_CUT_OVER_FAILURE,
  GET_ADD_TO_STORE_REQUEST,
  GET_ADD_TO_STORE_SUCCESS,
  GET_ADD_TO_STORE_FAILURE,
  UPDATE_ADD_TO_STORE_REQUEST,
  UPDATE_ADD_TO_STORE_SUCCESS,
  UPDATE_ADD_TO_STORE_FAILURE,
} from "./stockCutOverTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const stockCutOverRequest = () => {
  return {
    type: PRINT_STOCK_CUT_OVER_REQUEST,
  };
};

const stockCutOverSuccess = (payload) => {
  return {
    type: PRINT_STOCK_CUT_OVER_SUCCESS,
    payload: payload,
  };
};

const stockCutOverFailure = (error) => {
  return {
    type: PRINT_STOCK_CUT_OVER_FAILURE,
    payload: error,
  };
};

const stockCutOver = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(stockCutOverRequest());
    let url = `${baseUrl}/cut_over/add_stock`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(stockCutOverSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(stockCutOverFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getAddToStoreRequest = () => {
  return {
    type: GET_ADD_TO_STORE_REQUEST,
  };
};

const getAddToStoreSuccess = (payload) => {
  return {
    type: GET_ADD_TO_STORE_SUCCESS,
    payload: payload,
  };
};

const getAddToStoreFailure = (error) => {
  return {
    type: GET_ADD_TO_STORE_FAILURE,
    payload: error,
  };
};

const getAddToStore = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAddToStoreRequest());
    let url = `${baseUrl}/cut_over/cut_over_stock?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAddToStoreSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAddToStoreFailure(err.message));
      });
  };
};

const addToStoreRequest = () => {
  return {
    type: UPDATE_ADD_TO_STORE_REQUEST,
  };
};

const addToStoreSuccess = (payload) => {
  return {
    type: UPDATE_ADD_TO_STORE_SUCCESS,
    payload: payload,
  };
};

const addToStoreFaliure = (error) => {
  return {
    type: UPDATE_ADD_TO_STORE_FAILURE,
    payload: error,
  };
};

const addToStore = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(addToStoreRequest());
    let url = `${baseUrl}/cut_over/cut_over_to_store`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(addToStoreSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(addToStoreFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export { stockCutOver, getAddToStore, addToStore };
