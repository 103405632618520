import {
  GET_CONSOLIDATED_PIV_FOR_REPORTS_REQUEST,
  GET_CONSOLIDATED_PIV_FOR_REPORTS_SUCCESS,
  GET_CONSOLIDATED_PIV_FOR_REPORTS_FAILURE,
  DOWNLOAD_CONSOLIDATED_PIV_REQUEST,
  DOWNLOAD_CONSOLIDATED_PIV_SUCCESS,
  DOWNLOAD_CONSOLIDATED_PIV_FAILURE,
} from "./consolidatedPIVTypes";

const initialStateForReports = {
  loading: false,
  piv: [],
  error: "",
};

const getConsolidatedPIVReportsReducer = (
  state = initialStateForReports,
  action
) => {
  switch (action.type) {
    case GET_CONSOLIDATED_PIV_FOR_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CONSOLIDATED_PIV_FOR_REPORTS_SUCCESS:
      return {
        loading: false,
        piv: action.payload,
        error: "",
      };
    case GET_CONSOLIDATED_PIV_FOR_REPORTS_FAILURE:
      return {
        loading: false,
        piv: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDownload = {
  loading: false,
  piv: [],
  error: "",
};

const downloadConsolidatedPIVReducer = (
  state = initialStateForDownload,
  action
) => {
  switch (action.type) {
    case DOWNLOAD_CONSOLIDATED_PIV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_CONSOLIDATED_PIV_SUCCESS:
      return {
        loading: false,
        piv: action.payload,
        error: "",
      };
    case DOWNLOAD_CONSOLIDATED_PIV_FAILURE:
      return {
        loading: false,
        piv: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getConsolidatedPIVReportsReducer, downloadConsolidatedPIVReducer };
