import {
  GET_WORK_FLOW_REQUEST,
  GET_WORK_FLOW_SUCCESS,
  GET_WORK_FLOW_FAILURE,
  GET_LINEITEMSBYGRN_REQUEST,
  GET_LINEITEMSBYGRN_SUCCESS,
  GET_LINEITEMSBYGRN_FAILURE,
  GET_WORK_FLOW_DATA_FOR_DOWNLOAD_REQUEST,
  GET_WORK_FLOW_DATA_FOR_DOWNLOAD_SUCCESS,
  GET_WORK_FLOW_DATA_FOR_DOWNLOAD_FAILURE,
  UPLOADCSV_FOR_GRN_REQUEST,
  UPLOADCSV_FOR_GRN_SUCCESS,
  UPLOADCSV_FOR_GRN_FAILURE,
  PRINT_WORK_FLOW_REQUEST,
  PRINT_WORK_FLOW_SUCCESS,
  PRINT_WORK_FLOW_FAILURE,
  GET_MATERIAL_CODE_REQUEST,
  GET_MATERIAL_CODE_SUCCESS,
  GET_MATERIAL_CODE_FAILURE,
} from "./workFlowTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getWorkFlowRequest = () => {
  return {
    type: GET_WORK_FLOW_REQUEST,
  };
};

const getWorkFlowSuccess = (payload) => {
  return {
    type: GET_WORK_FLOW_SUCCESS,
    payload: payload,
  };
};

const getWorkFlowFailure = (error) => {
  return {
    type: GET_WORK_FLOW_FAILURE,
    payload: error,
  };
};

const getWorkFlow = (materialCode, batchNumber, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getWorkFlowRequest());

    let url = `${baseUrl}/workoff/get?materialCode=${materialCode}&batch=${batchNumber}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getWorkFlowSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getWorkFlowFailure(err.response));
      });
  };
};

const getLineItemsByGrnRequest = () => {
  return {
    type: GET_LINEITEMSBYGRN_REQUEST,
  };
};

const getLineItemsByGrnSuccess = (payload) => {
  return {
    type: GET_LINEITEMSBYGRN_SUCCESS,
    payload: payload,
  };
};

const getLineItemsByGrnFailure = (error) => {
  return {
    type: GET_LINEITEMSBYGRN_FAILURE,
    payload: error,
  };
};

const getLineItemsByGrn = (grn, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getLineItemsByGrnRequest());
    let url = `${baseUrl}/grn/get_by_grn?grnNumber=${grn}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLineItemsByGrnSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLineItemsByGrnFailure(err.response));
      });
  };
};

const getWorkFlowDataForDownloadRequest = () => {
  return {
    type: GET_WORK_FLOW_DATA_FOR_DOWNLOAD_REQUEST,
  };
};

const getWorkFlowDataForDownloadSuccess = (payload) => {
  return {
    type: GET_WORK_FLOW_DATA_FOR_DOWNLOAD_SUCCESS,
    payload: payload,
  };
};

const getWorkFlowDataForDownloadFailure = (error) => {
  return {
    type: GET_WORK_FLOW_DATA_FOR_DOWNLOAD_FAILURE,
    payload: error,
  };
};

const getWorkFlowDataForDownload = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getWorkFlowDataForDownloadRequest());
    // let url = `${baseUrl}/grn`;
    let url = `${baseUrl}/download_grn_data`;
    // let url = `${baseUrl}/v2/grn?page_number=3&rows_per_page=5&search=`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getWorkFlowDataForDownloadSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getWorkFlowDataForDownloadFailure(err.message));
      });
  };
};

const uploadCsvForGrnRequest = () => {
  return {
    type: UPLOADCSV_FOR_GRN_REQUEST,
  };
};

const uploadCsvForGrnSuccess = (payload) => {
  return {
    type: UPLOADCSV_FOR_GRN_SUCCESS,
    payload: payload,
  };
};

const uploadCsvForGrnFailure = (error) => {
  return {
    type: UPLOADCSV_FOR_GRN_FAILURE,
    payload: error,
  };
};

const uploadCsvForGrn = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadCsvForGrnRequest());
    let url = `${baseUrl}/grn/upload_csv`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadCsvForGrnSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadCsvForGrnFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const printWorkFlowRequest = () => {
  return {
    type: PRINT_WORK_FLOW_REQUEST,
  };
};

const printWorkFlowSuccess = (payload) => {
  return {
    type: PRINT_WORK_FLOW_SUCCESS,
    payload: payload,
  };
};

const printWorkFlowFailure = (error) => {
  return {
    type: PRINT_WORK_FLOW_FAILURE,
    payload: error,
  };
};

const printWorkFlow = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(printWorkFlowRequest());
    let url = `${baseUrl}/workoff_grn/print`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(printWorkFlowSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(printWorkFlowFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getMaterialCodeRequest = () => {
  return {
    type: GET_MATERIAL_CODE_REQUEST,
  };
};

const getMaterialCodeSuccess = (payload) => {
  return {
    type: GET_MATERIAL_CODE_SUCCESS,
    payload: payload,
  };
};

const getMaterialCodeFailure = (error) => {
  return {
    type: GET_MATERIAL_CODE_FAILURE,
    payload: error,
  };
};

const getMaterialCode = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getMaterialCodeRequest());
    let url = `${baseUrl}/material`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getMaterialCodeSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getMaterialCodeFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getWorkFlow,
  getLineItemsByGrn,
  getWorkFlowDataForDownload,
  uploadCsvForGrn,
  printWorkFlow,
  getMaterialCode,
};
