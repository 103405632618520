/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import { getPickListTracking, getSubModulesByRoleId } from "../../store";

// Config
import Config from "../../config/index";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const styleForLocationsModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 500, lg: 600, xl: 600 },
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const PicklistTracking = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getPickListTracking,
  picklistTrackingReducer,
}) => {
  const desLength = Config.descriptionLength;

  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let picklistTrackingModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "b48aec4a-3e3c-4a50-b5ba-2967f0a4b71d"
  );

  let viewPicklistTracking = picklistTrackingModule
    ? picklistTrackingModule.actionId.includes(
        "9b2ac726-c1bc-4318-9d8c-97266aaf38ea"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [loading, setLoading] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getPickListTracking(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [state, setState] = useState({
    columns: [
      { Header: "Document Type", accessor: "documentType" },
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Pick List ID", accessor: "pickupListCode" },
      { Header: "Item Code / Des", accessor: "materialCode" },
      { Header: "Item Qty", accessor: "qty" },
      { Header: "Remarks 1", accessor: "remarks1" },
      { Header: "Remarks 2", accessor: "remarks2" },
      { Header: "Remarks 3", accessor: "remarks3" },
      { Header: "Remarks 4", accessor: "remarks4" },
      { Header: "Locations", accessor: "locations" },
      { Header: "From Loc", accessor: "source" },
      { Header: "To Loc", accessor: "destination" },
      { Header: "Status", accessor: "status" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  const [locationsModal, setOpenLocationsModal] = useState(false);
  const [locationsData, setLocationsData] = useState([]);

  const handleOpenLocationsModal = () => {
    setOpenLocationsModal(true);
  };

  const handleCloseLocationsModal = () => {
    setOpenLocationsModal(false);
  };

  useEffect(() => {
    let tempPicklist = [];

    let data = picklistTrackingReducer.pickList.data
      ? picklistTrackingReducer.pickList.data
      : [];

    setErrorMsgFromApi(picklistTrackingReducer?.error?.data?.msg);
    setLoading(picklistTrackingReducer.loading);
    setPaginationObj(picklistTrackingReducer?.pickList?.pagination);

    data.map((picklist) => {
      let itemDes = picklist.materialDescription;
      let len = desLength;

      const picklistObject = {
        privateKey: picklist.pickupListCode,
        documentType: picklist.documentType,
        documentNo: picklist.documentNo,
        pickupListCode: picklist.pickupListCode,
        materialCode: `${picklist.materialCode} / ${
          itemDes ? itemDes.substring(0, len) : ""
        }`,
        qty: `${picklist.qty} ${picklist.uom}`,
        locations:
          (picklist.locations?.length === 1 &&
            `${picklist.locations[0]?.location}`) ||
          (picklist.locations?.length === 0 && "") ||
          (picklist.locations?.length > 1 && (
            <Tooltip title="View Locations" style={{ marginLeft: "0.75rem" }}>
              <MDButton
                color="info"
                variant="gradient"
                type="button"
                iconOnly
                size="small"
                onClick={() => {
                  setLocationsData(picklist.locations);
                  handleOpenLocationsModal();
                }}
              >
                <Icon> visibility_icon </Icon>
              </MDButton>
            </Tooltip>
          )),
        source: picklist.source,
        destination: picklist.destination,
        status: picklist.status,
        remarks1: picklist.remarks1,
        remarks2: picklist.remarks2,
        remarks3: picklist.remarks3,
        remarks4: picklist.remarks4,
      };
      tempPicklist.push(picklistObject);
    });
    viewPicklistTracking && setState({ ...state, rows: tempPicklist });
  }, [picklistTrackingReducer]);

  const [locationsState, setLocationsState] = useState({
    columns: [{ Header: "Location", accessor: "location" }],
    rows: [],
  });

  const { columns: locationsColumns, rows: locationsRows } = locationsState;

  useEffect(() => {
    let tempLocationsObj = [];

    let data = locationsData ? locationsData : [];

    data.map((data) => {
      const locationObj = {
        location: data.location,
      };

      tempLocationsObj.push(locationObj);
    });

    setLocationsState({ ...locationsState, rows: tempLocationsObj });
  }, [locationsData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  errorMsg={errorMsgFromApi}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>
              <div>
                <Modal
                  open={locationsModal}
                  onClose={handleCloseLocationsModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForLocationsModal}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Locations
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseLocationsModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>

                    <MDBox pt={1} pb={3} px={3}>
                      <DataTable
                        table={{
                          columns: locationsColumns,
                          rows: locationsRows,
                        }}
                        isSorted={true}
                        entriesPerPage={{
                          defaultValue: 30,
                          entries: [30, 50, 75, 100],
                        }}
                        showTotalEntries={true}
                        noEndBorder
                        options={{ draggable: false }}
                      />
                    </MDBox>
                  </MDCard>
                </Modal>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    picklistTrackingReducer: state.getPickListTrackingReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getPickListTracking: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getPickListTracking(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(PicklistTracking);
