import {
  GET_PICKLISTTRACKING_REQUEST,
  GET_PICKLISTTRACKING_SUCCESS,
  GET_PICKLISTTRACKING_FAILURE,
} from "./picklistTrackingTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getPickListTrackingRequest = () => {
  return {
    type: GET_PICKLISTTRACKING_REQUEST,
  };
};

const getPickListTrackingSuccess = (payload) => {
  return {
    type: GET_PICKLISTTRACKING_SUCCESS,
    payload: payload,
  };
};

const getPickListTrackingFailure = (error) => {
  return {
    type: GET_PICKLISTTRACKING_FAILURE,
    payload: error,
  };
};

const getPickListTracking = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPickListTrackingRequest());
    let url = `${baseUrl}/pickuplist_tracking/get?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPickListTrackingSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPickListTrackingFailure(err.response));
      });
  };
};

export { getPickListTracking };
