import {
  GET_STORE_ISSUANCE_REQUEST,
  GET_STORE_ISSUANCE_SUCCESS,
  GET_STORE_ISSUANCE_FAILURE,
  CREATE_ADD_TO_RELEASE_REQUEST,
  CREATE_ADD_TO_RELEASE_SUCCESS,
  CREATE_ADD_TO_RELEASE_FAILURE,
  VALIDATION_STOREISSUANCE_REQUEST,
  VALIDATION_STOREISSUANCE_SUCCESS,
  VALIDATION_STOREISSUANCE_FAILURE,
} from "./storeIssuanceTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getStoreIssuanceRequest = () => {
  return {
    type: GET_STORE_ISSUANCE_REQUEST,
  };
};

const getStoreIssuanceSuccess = (payload) => {
  return {
    type: GET_STORE_ISSUANCE_SUCCESS,
    payload: payload,
  };
};

const getStoreIssuanceFailure = (error) => {
  return {
    type: GET_STORE_ISSUANCE_FAILURE,
    payload: error,
  };
};

const getStoreIssuance = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getStoreIssuanceRequest());
    let url = `${baseUrl}/st_issued_pickuplist/get?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getStoreIssuanceSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getStoreIssuanceFailure(err.response));
      });
  };
};

const addToReleaseRequest = () => {
  return {
    type: CREATE_ADD_TO_RELEASE_REQUEST,
  };
};

const addToReleaseSuccess = (payload) => {
  return {
    type: CREATE_ADD_TO_RELEASE_SUCCESS,
    payload: payload,
  };
};

const addToReleaseFailure = (error) => {
  return {
    type: CREATE_ADD_TO_RELEASE_FAILURE,
    payload: error,
  };
};

const addToRelease = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(addToReleaseRequest());
    let url = `${baseUrl}/st_issued_pickuplist/add_to_release`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(addToReleaseSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(addToReleaseFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const validationForStoreIssuanceRequest = () => {
  return {
    type: VALIDATION_STOREISSUANCE_REQUEST,
  };
};

const validationForStoreIssuanceSuccess = (payload) => {
  return {
    type: VALIDATION_STOREISSUANCE_SUCCESS,
    payload: payload,
  };
};

const validationForStoreIssuanceFaliure = (error) => {
  return {
    type: VALIDATION_STOREISSUANCE_FAILURE,
    payload: error,
  };
};

const validationForStoreIssuance = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(validationForStoreIssuanceRequest());
    let url = `${baseUrl}/piv/validation/st_issued_pickuplist/add_to_release`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(validationForStoreIssuanceSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(validationForStoreIssuanceFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export { getStoreIssuance, addToRelease, validationForStoreIssuance };
