import {
  PRINTLABEL_INDIVIDUALPRINT_REQUEST,
  PRINTLABEL_INDIVIDUALPRINT_SUCCESS,
  PRINTLABEL_INDIVIDUALPRINT_FAILURE,
  PRINTLABEL_PACKAGEPRINT_REQUEST,
  PRINTLABEL_PACKAGEPRINT_SUCCESS,
  PRINTLABEL_PACKAGEPRINT_FAILURE,
} from "./labelPrintingTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const individualPrintRequest = () => {
  return {
    type: PRINTLABEL_INDIVIDUALPRINT_REQUEST,
  };
};

const individualPrintSuccess = (payload) => {
  return {
    type: PRINTLABEL_INDIVIDUALPRINT_SUCCESS,
    payload: payload,
  };
};

const individualPrintFailure = (error) => {
  return {
    type: PRINTLABEL_INDIVIDUALPRINT_FAILURE,
    payload: error,
  };
};

const individualPrint = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(individualPrintRequest());
    let url = `${baseUrl}/print_individual`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(individualPrintSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(individualPrintFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const packagePrintRequest = () => {
  return {
    type: PRINTLABEL_PACKAGEPRINT_REQUEST,
  };
};

const packagePrintSuccess = (payload) => {
  return {
    type: PRINTLABEL_PACKAGEPRINT_SUCCESS,
    payload: payload,
  };
};

const packagePrintFailure = (error) => {
  return {
    type: PRINTLABEL_PACKAGEPRINT_FAILURE,
    payload: error,
  };
};

const packagePrint = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(packagePrintRequest());
    let url = `${baseUrl}/print_package`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(packagePrintSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(packagePrintFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export { individualPrint, packagePrint };
