export const GET_PIV_REQUEST = "GET_PIV_REQUEST";
export const GET_PIV_SUCCESS = "GET_PIV_SUCCESS";
export const GET_PIV_FAILURE = "GET_PIV_FAILURE";

export const UPDATE_PICKLISTLOCATION_REQUEST =
  "UPDATE_PICKLISTLOCATION_REQUEST";
export const UPDATE_PICKLISTLOCATION_SUCCESS =
  "UPDATE_PICKLISTLOCATION_SUCCESS";
export const UPDATE_PICKLISTLOCATION_FAILURE =
  "UPDATE_PICKLISTLOCATION_FAILURE";

export const ADD_RELEASEFORISSUANCE_REQUEST = "ADD_RELEASEFORISSUANCE_REQUEST";
export const ADD_RELEASEFORISSUANCE_SUCCESS = "ADD_RELEASEFORISSUANCE_SUCCESS";
export const ADD_RELEASEFORISSUANCE_FAILURE = "ADD_RELEASEFORISSUANCE_FAILURE";

export const VALIDATION_MATERIALISSUANCE_REQUEST =
  "VALIDATION_MATERIALISSUANCE_REQUEST";
export const VALIDATION_MATERIALISSUANCE_SUCCESS =
  "VALIDATION_MATERIALISSUANCE_SUCCESS";
export const VALIDATION_MATERIALISSUANCE_FAILURE =
  "VALIDATION_MATERIALISSUANCE_FAILURE";

export const VALIDATION_RELEASE_REQUEST = "VALIDATION_RELEASE_REQUEST";
export const VALIDATION_RELEASE_SUCCESS = "VALIDATION_RELEASE_SUCCESS";
export const VALIDATION_RELEASE_FAILURE = "VALIDATION_RELEASE_FAILURE";
