import {
  GET_PIVFILLING_REQUEST,
  GET_PIVFILLING_FAILURE,
  GET_PIVFILLING_SUCCESS,
  GET_RELEASEDPIV_REQUEST,
  GET_RELEASEDPIV_SUCCESS,
  GET_RELEASEDPIV_FAILURE,
  GET_ASSETS_BY_DOCUMENTNO_REQUEST,
  GET_ASSETS_BY_DOCUMENTNO_SUCCESS,
  GET_ASSETS_BY_DOCUMENTNO_FAILURE,
  REPRINT_ALL_ASSET_REQUEST,
  REPRINT_ALL_ASSET_SUCCESS,
  REPRINT_ALL_ASSET_FAILURE,
  REPRINT_ASSET_REQUEST,
  REPRINT_ASSET_SUCCESS,
  REPRINT_ASSET_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from "./pivFillingAndReleasedPivTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getPivFillingRequest = () => {
  return {
    type: GET_PIVFILLING_REQUEST,
  };
};

const getPivFillingSuccess = (payload) => {
  return {
    type: GET_PIVFILLING_SUCCESS,
    payload: payload,
  };
};

const getPivFillingFailure = (error) => {
  return {
    type: GET_PIVFILLING_FAILURE,
    payload: error,
  };
};

const getPivFilling = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPivFillingRequest());
    let url = `${baseUrl}/piv_filling_and_release/get_intermediate_filling?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPivFillingSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPivFillingFailure(err.response));
      });
  };
};

const getReleasedPivRequest = () => {
  return {
    type: GET_RELEASEDPIV_REQUEST,
  };
};

const getReleasedPivSuccess = (payload) => {
  return {
    type: GET_RELEASEDPIV_SUCCESS,
    payload: payload,
  };
};

const getReleasedPivFailure = (error) => {
  return {
    type: GET_RELEASEDPIV_FAILURE,
    payload: error,
  };
};

const getReleasedPiv = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getReleasedPivRequest());
    let url = `${baseUrl}/piv_filling_and_release/get_released_piv?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getReleasedPivSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getReleasedPivFailure(err.response));
      });
  };
};

const getAssetsByDocumentNoRequest = () => {
  return {
    type: GET_ASSETS_BY_DOCUMENTNO_REQUEST,
  };
};

const getAssetsByDocumentNoSuccess = (payload) => {
  return {
    type: GET_ASSETS_BY_DOCUMENTNO_SUCCESS,
    payload: payload,
  };
};

const getAssetsByDocumentNoFailure = (error) => {
  return {
    type: GET_ASSETS_BY_DOCUMENTNO_FAILURE,
    payload: error,
  };
};

const getAssetsByDocumentNo = (documentNo, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAssetsByDocumentNoRequest());
    let url = `${baseUrl}/piv_filling_and_release/get_assets_by_po_number?documentNumber=${documentNo}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAssetsByDocumentNoSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAssetsByDocumentNoFailure(err.response));
      });
  };
};

const reprintAssetRequest = () => {
  return {
    type: REPRINT_ASSET_REQUEST,
  };
};

const reprintAssetSuccess = (payload) => {
  return {
    type: REPRINT_ASSET_SUCCESS,
    payload: payload,
  };
};

const reprintAssetFaliure = (error) => {
  return {
    type: REPRINT_ASSET_FAILURE,
    payload: error,
  };
};

const reprintAsset = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(reprintAssetRequest());
    let url = `${baseUrl}/piv_filling_and_release/reprint`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(reprintAssetSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(reprintAssetFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const reprintAllAssetRequest = () => {
  return {
    type: REPRINT_ALL_ASSET_REQUEST,
  };
};

const reprintAllAssetSuccess = (payload) => {
  return {
    type: REPRINT_ALL_ASSET_SUCCESS,
    payload: payload,
  };
};

const reprintAllAssetFaliure = (error) => {
  return {
    type: REPRINT_ALL_ASSET_FAILURE,
    payload: error,
  };
};

const reprintAllAsset = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(reprintAllAssetRequest());
    let url = `${baseUrl}/piv_filling_and_release/reprint_all`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(reprintAllAssetSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(reprintAllAssetFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateUserRequest = () => {
  return {
    type: UPDATE_USER_REQUEST,
  };
};

const updateUserSuccess = (payload) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: payload,
  };
};

const updateUserFaliure = (error) => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: error,
  };
};

const updateUserPivFilling = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(updateUserRequest());
    let url = `${baseUrl}/piv_filling_and_release/update_user`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateUserSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateUserFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getPivFilling,
  getReleasedPiv,
  getAssetsByDocumentNo,
  reprintAsset,
  reprintAllAsset,
  updateUserPivFilling,
};
