/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import { Switch, ThemeProvider, createTheme } from "@mui/material";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getAssets,
  deleteAsset,
  createNewAsset,
  getAssetTypes,
  printAsset,
  getProfile,
  printVirtualBin,
  updateAsset,
} from "../../store";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

// Cookies
import Cookies from "universal-cookie";

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "white",
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "rgb(25, 118, 210)",
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.5,
          backgroundColor: "grey",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.6,
            backgroundColor: "rgb(25, 118, 210)",
          },
        },
      },
    },
  },
});

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const AssetMaster = ({
  getAssets,
  assets,
  deleteAsset,
  createNewAsset,
  getAssetTypes,
  assetTypes,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  printAsset,
  getProfile,
  profileData,
  printVirtualBin,
  updateAsset,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let userId = loginDetails?.data.userId;
  let roleId = loginDetails.data.roleId;
  let email = loginDetails.data.email;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let assetsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "38c5ffd6-00ba-4dfe-85de-17f54e8c3525"
  );

  let createAssetAccess = assetsModule
    ? assetsModule.actionId.includes("1f2caecb-03d1-48a3-9e6f-f96c93ec4962")
    : null;

  let viewAssetAccess = assetsModule
    ? assetsModule.actionId.includes("c850c8df-cba0-42a4-bf23-a17928d6a8ed")
    : null;

  let deleteAssetAccess = assetsModule
    ? assetsModule.actionId.includes("86846ada-7616-40f9-bc0f-0a5b3f431857")
    : null;

  let updateAssetAccess = assetsModule
    ? assetsModule.actionId.includes("9997e82d-6368-4171-b8da-0119aadb1040")
    : null;

  useEffect(() => {
    getProfile(email, jwtToken);
  }, [getProfile]);

  const [userRole, setUserRole] = useState("");
  const [printerId, setPrinterId] = useState("");
  const [printerName, setPrinterName] = useState("");

  useEffect(() => {
    setUserRole(profileData?.profile?.roleName);
    setPrinterId(profileData?.profile?.printerId);
    setPrinterName(profileData?.profile?.printerName);
  }, [profileData]);

  const statusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Empty",
      value: 1,
    },
    {
      label: "Occupied",
      value: 0,
    },
  ];

  const [state, setState] = useState({
    columns: [
      { Header: "Asset ID", accessor: "assetId" },
      { Header: "Asset Type", accessor: "assetType" },
      { Header: "Asset Name", accessor: "assetName" },
      {
        Header: "Status",
        accessor: "isEmpty",
        searchType: "select",
        selectOptions: statusOptions,
        placeholder: "All Status",
      },
      {
        Header: "action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [loading, setLoading] = useState(true);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [openDeleteAssetModal, setOpenDeleteAssetModal] = useState(false);
  const [singleAssetDataForDelete, setSingleAssetDataForDelete] = useState({});

  const handleOpenDeleteAssetModal = (asset = {}) => {
    setSingleAssetDataForDelete(asset);
    setOpenDeleteAssetModal(true);
  };
  const handleCloseDeleteAssetModal = () => {
    setOpenDeleteAssetModal(false);
    setAssetTypeSelected("");
  };

  const [openEmptyAssetModal, setOpenEmptyAssetModal] = useState(false);
  const [singleAssetDataForEmpty, setSingleAssetDataForEmpty] = useState({});

  const handleOpenEmptyAssetModal = (asset = {}) => {
    setSingleAssetDataForEmpty(asset);
    setOpenEmptyAssetModal(true);
  };
  const handleCloseEmptyAssetModal = () => {
    setOpenEmptyAssetModal(false);
  };

  const [isErrorEmptyAsset, setIsErrorEmptyAsset] = useState(false);
  const [isSuccessEmptyAsset, setIsSuccessEmptyAsset] = useState(false);

  useEffect(() => {
    if (isErrorEmptyAsset) {
      setTimeout(() => {
        setIsErrorEmptyAsset(false);
      }, 3000);
    }
  }, [isErrorEmptyAsset]);

  useEffect(() => {
    if (isSuccessEmptyAsset) {
      setTimeout(() => {
        setIsSuccessEmptyAsset(false);
      }, 3000);
    }
  }, [isSuccessEmptyAsset]);

  const [emptyErrorMsg, setEmptyErrorMsg] = useState("");

  const emptyAssetData = async () => {
    let payload = {
      id: singleAssetDataForEmpty.id,
      assetId: singleAssetDataForEmpty.assetId,
      assetType: singleAssetDataForEmpty.assetType,
      isEmpty: 1,
      userId: userId,
    };
    let response = await updateAsset(payload, jwtToken);

    if (response.status) {
      setIsSuccessEmptyAsset(true);
      getAssets(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleCloseEmptyAssetModal();
      }, 2250);
    }
    if (!response.status) {
      setEmptyErrorMsg(response.data.data.message);
      setIsErrorEmptyAsset(true);
    }
  };

  useEffect(() => {
    getAssets(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  useEffect(() => {
    let tempAsset = [];
    let data = assets.assets.data ? assets.assets.data : [];

    setErrorMsgFromApi(assets?.error?.data?.msg);
    setLoading(assets.loading);
    setPaginationObj(assets?.assets?.pagination);

    data.map((asset) => {
      const formatDate = new Date(asset.cd);
      const palletObject = {
        assetId: asset.assetId,
        assetName: asset.assetName,
        assetType: asset.assetType,
        isEmpty: asset.isEmpty === 1 ? "Empty" : "Occupied",
        action: (
          <div>
            <Tooltip title="Empty Asset">
              <MDButton
                disabled={!updateAssetAccess || asset.isEmpty === 1}
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                onClick={() => handleOpenEmptyAssetModal(asset)}
              >
                <Icon>hourglass_empty</Icon>
              </MDButton>
            </Tooltip>
            <ThemeProvider theme={theme}>
              <Tooltip title="Delete">
                <Switch
                  disabled={!deleteAssetAccess}
                  checked={asset.isDeleted === 0 ? true : false}
                  onChange={() => {
                    handleOpenDeleteAssetModal(asset);
                  }}
                />
              </Tooltip>
            </ThemeProvider>
            {asset.assetType === "pallet" ? (
              <Tooltip title="Print">
                <MDButton
                  variant="gradient"
                  color="success"
                  iconOnly
                  type="button"
                  disabled={userRole !== "admin" && asset.isEmpty === 0}
                  onClick={() => handleOpenPrintAssetModal(asset)}
                >
                  <Icon>print_two_tone</Icon>
                </MDButton>
              </Tooltip>
            ) : (
              <Tooltip title="Download PDF">
                <MDButton
                  variant="gradient"
                  color="warning"
                  iconOnly
                  type="button"
                  // disabled={!deleteAssetAccess}
                  // onClick={() => handleOpenDeleteAssetModal(asset)}
                >
                  <Icon>download</Icon>
                </MDButton>
              </Tooltip>
            )}
          </div>
        ),
      };
      tempAsset.push(palletObject);
    });

    viewAssetAccess && setState({ ...state, rows: tempAsset });
  }, [assets]);

  const [isErrorCreateNewAsset, setIsErrorCreateNewAsset] = useState(false);
  const [isSuccessCreateNewAsset, setIsSuccessCreateNewAsset] = useState(false);

  useEffect(() => {
    if (isErrorCreateNewAsset) {
      setTimeout(() => {
        setIsErrorCreateNewAsset(false);
      }, 3000);
    }
  }, [isErrorCreateNewAsset]);

  useEffect(() => {
    if (isSuccessCreateNewAsset) {
      setTimeout(() => {
        setIsSuccessCreateNewAsset(false);
      }, 3000);
    }
  }, [isSuccessCreateNewAsset]);

  const [assetTypeSelected, setAssetTypeSelected] = useState("");
  const [assetTypeOptions, setAssetTypeOptions] = useState([]);

  const handleAssetTypeChange = (selectedZone) => {
    setAssetTypeSelected(selectedZone);
  };

  useEffect(() => {
    let tempAssetTypeOptions = [];
    let data = assetTypes.assetTypes.data ? assetTypes.assetTypes.data : [];

    data.map((asset) => {
      tempAssetTypeOptions.push({
        label: asset.assetType,
        value: asset.assetType,
      });
    });

    setAssetTypeOptions(tempAssetTypeOptions);
  }, [assetTypes]);

  const [openCreateAssetModal, setOpenCreateAssetModal] = useState(false);

  const handleOpenCreateAssetModal = () => {
    getAssetTypes(jwtToken);
    setOpenCreateAssetModal(true);
  };

  const handleCloseCreateAssetModal = () => {
    setOpenCreateAssetModal(false);
    setAssetTypeSelected("");
  };

  const initialValueForCreateAsset = {
    assetType: "",
    assetName: "",
  };

  const validationSchema = Yup.object({
    assetType: Yup.object().required("Select the Asset Type"),
    assetName: Yup.string().required("Enter the Asset Name"),
  });

  const [createErrorMsg, setCreateErrorMsg] = useState("");

  const [buttonName, setButtonName] = useState("Submit");

  const [printButtonName, setPrintButtonName] = useState("Print");

  const onSubmitForCreateAssetMaster = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    values.assetType = assetTypeSelected.value;

    let res = await createNewAsset(values, jwtToken);

    if (res.status) {
      setIsSuccessCreateNewAsset(true);
      getAssets(pageNo, rowsPerPage, searchObject, jwtToken);
      setAssetTypeSelected("");
      setTimeout(() => {
        handleCloseCreateAssetModal();
        setButtonName("Submit");
      }, 2250);
      resetForm();
    }
    if (!res.status) {
      setCreateErrorMsg(res.data.data.message);
      setIsErrorCreateNewAsset(true);
      setButtonName("Submit");
    }
  };

  const formikForCreateAsset = useFormik({
    initialValues: initialValueForCreateAsset,
    validationSchema: validationSchema,
    onSubmit: onSubmitForCreateAssetMaster,
  });

  const [isErrorPrint, setIsErrorPrint] = useState(false);
  const [isSuccessPrint, setIsSuccessPrint] = useState(false);

  useEffect(() => {
    if (isErrorPrint) {
      setTimeout(() => {
        setIsErrorPrint(false);
      }, 3000);
    }
  }, [isErrorPrint]);

  useEffect(() => {
    if (isSuccessPrint) {
      setTimeout(() => {
        setIsSuccessPrint(false);
      }, 3000);
    }
  }, [isSuccessPrint]);

  const [openPrintModal, setOpenPrintModal] = useState(false);

  const handleOpenPrintModal = () => {
    setOpenPrintModal(true);
  };

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  const initialValueForPrint = {
    noOfBins: "",
  };

  const validationSchemaForPrint = Yup.object({
    noOfBins: Yup.number()
      .positive("Please enter a positive number")
      .required("Enter the no of prints"),
  });

  const [printErrorMsg, setPrintErrorMsg] = useState("");

  const onSubmitForPrint = async (values, { resetForm }) => {
    setPrintButtonName("Please wait...");
    let payload = {
      noOfPrints: values.noOfBins,
      printerId: printerId,
    };
    let res = await printVirtualBin(payload, jwtToken);

    if (res.status) {
      setIsSuccessPrint(true);
      getAssets(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        handleClosePrintModal();
        setPrintButtonName("Print");
      }, 2250);
      resetForm();
    }
    if (!res.status) {
      setPrintErrorMsg(res.data.data.message);
      setIsErrorPrint(true);
      setPrintButtonName("Print");
    }
  };

  const formikForPrint = useFormik({
    initialValues: initialValueForPrint,
    validationSchema: validationSchemaForPrint,
    onSubmit: onSubmitForPrint,
  });

  const [isErrorDeleteAsset, setIsErrorDeleteAsset] = useState(false);
  const [isSuccessDeleteAsset, setIsSuccessDeleteAsset] = useState(false);

  useEffect(() => {
    if (isErrorDeleteAsset) {
      setTimeout(() => {
        setIsErrorDeleteAsset(false);
      }, 3000);
    }
  }, [isErrorDeleteAsset]);

  useEffect(() => {
    if (isSuccessDeleteAsset) {
      setTimeout(() => {
        setIsSuccessDeleteAsset(false);
      }, 3000);
    }
  }, [isSuccessDeleteAsset]);

  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");

  const deleteAssetData = async () => {
    let id = singleAssetDataForDelete.assetId;
    let status = singleAssetDataForDelete.isDeleted === 0 ? 1 : 0;

    let response = await deleteAsset(id, status, jwtToken);

    if (response.status) {
      setIsSuccessDeleteAsset(true);
      getAssets(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleCloseDeleteAssetModal();
      }, 2250);
    }
    if (!response.status) {
      setDeleteErrorMsg(response.data.data.message);
      setIsErrorDeleteAsset(true);
    }
  };

  const [isErrorPrintAsset, setIsErrorPrintAsset] = useState(false);
  const [isSuccessPrintAsset, setIsSuccessPrintAsset] = useState(false);

  useEffect(() => {
    if (isErrorPrintAsset) {
      setTimeout(() => {
        setIsErrorPrintAsset(false);
      }, 3000);
    }
  }, [isErrorPrintAsset]);

  useEffect(() => {
    if (isSuccessPrintAsset) {
      setTimeout(() => {
        setIsSuccessPrintAsset(false);
      }, 3000);
    }
  }, [isSuccessPrintAsset]);

  const [openPrintAssetModal, setOpenPrintAssetModal] = useState(false);
  const [dataForPrint, setDataForPrint] = useState({});

  const handleOpenPrintAssetModal = (data) => {
    setDataForPrint(data);
    setOpenPrintAssetModal(true);
  };

  const handleClosePrintAssetModal = () => {
    setOpenPrintAssetModal(false);
  };

  const [printAssetErrorMsg, setPrintAssetErrorMsg] = useState("");

  const printAssetHandler = async () => {
    let payload = {
      assetType: dataForPrint.assetType,
      assetId: dataForPrint.assetId,
      isEmpty: dataForPrint.isEmpty,
      printerId: printerId,
    };

    let res = await printAsset(payload, jwtToken);

    if (res.status) {
      setIsSuccessPrintAsset(true);
      getAssets(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleClosePrintAssetModal();
      }, 2250);
    }
    if (!res.status) {
      setPrintAssetErrorMsg(res.data.data.message);
      setIsErrorPrintAsset(true);
    }
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                {createAssetAccess && (
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                    // mb={3}
                  >
                    <MDBox color="text" title="Add Pallet">
                      <MDButton
                        color="info"
                        variant="outlined"
                        type="button"
                        onClick={handleOpenCreateAssetModal}
                        style={{ marginRight: "10px" }}
                      >
                        Create New Asset
                      </MDButton>
                      <Tooltip title="Print">
                        <MDButton
                          color="success"
                          variant="contained"
                          type="button"
                          onClick={handleOpenPrintModal}
                        >
                          Print Virtual Bin
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                )}
                <MDBox mt={createAssetAccess ? -3 : 0}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 30,
                      entries: [30, 50, 75, 100],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    options={{ draggable: false }}
                    errorMsg={errorMsgFromApi}
                    serverSidePagination={true}
                    paginationObj={paginationObj}
                    pageNoValue={pageNo}
                    rowsPerPageValue={rowsPerPage}
                    pageNoProp={importPageNo}
                    rowsPerPageProp={importRowsPerPage}
                    searchProp={importSearch}
                  />
                </MDBox>
                <Modal
                  open={openCreateAssetModal}
                  onClose={handleCloseCreateAssetModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Create Asset
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateAssetModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForCreateAsset.handleSubmit}
                      >
                        <MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Select the Asset Type
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css"
                              placeholder="Select Asset Type..."
                              name="assetType"
                              value={assetTypeSelected}
                              options={assetTypeOptions}
                              onChange={(selectedOption) => {
                                handleAssetTypeChange(selectedOption);
                                formikForCreateAsset.setFieldValue(
                                  "assetType",
                                  selectedOption
                                );
                              }}
                            />
                            {formikForCreateAsset.errors.assetType && (
                              <TextError
                                msg={formikForCreateAsset.errors.assetType}
                              />
                            )}
                          </MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Asset Name
                            </MDTypography>
                            <MDInput
                              type="text"
                              name="assetName"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreateAsset.handleBlur}
                              onChange={formikForCreateAsset.handleChange}
                              value={formikForCreateAsset.values.assetName}
                              error={
                                formikForCreateAsset.touched.assetName &&
                                formikForCreateAsset.errors.assetName &&
                                true
                              }
                            />
                            {formikForCreateAsset.errors.assetName && (
                              <TextError
                                msg={formikForCreateAsset.errors.assetName}
                              />
                            )}
                          </MDBox>

                          <MDBox mt={2}>
                            <Collapse in={isSuccessCreateNewAsset}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessCreateNewAsset(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Asset created successfully!
                              </Alert>
                            </Collapse>
                            <Collapse in={isErrorCreateNewAsset}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorCreateNewAsset(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {/* Could not create the Asset! */}
                                {createErrorMsg}
                              </Alert>
                            </Collapse>
                          </MDBox>

                          <div className="mt-3">
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="info"
                              fullWidth
                              disabled={
                                !formikForCreateAsset.isValid ||
                                buttonName === "Please wait..."
                              }
                            >
                              {buttonName}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>

                <Modal
                  open={openPrintModal}
                  onClose={handleClosePrintModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Print
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleClosePrintModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForPrint.handleSubmit}
                      >
                        <MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Printer Name:-{" "}
                              <span style={{ fontWeight: 400 }}>
                                {printerName}
                              </span>
                            </MDTypography>
                          </MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              No of Prints
                            </MDTypography>
                            <MDInput
                              type="number"
                              name="noOfBins"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForPrint.handleBlur}
                              onChange={formikForPrint.handleChange}
                              value={formikForPrint.values.noOfBins}
                              error={
                                formikForPrint.touched.noOfBins &&
                                formikForPrint.errors.noOfBins &&
                                true
                              }
                            />
                            {formikForPrint.errors.noOfBins && (
                              <TextError msg={formikForPrint.errors.noOfBins} />
                            )}
                          </MDBox>

                          <MDBox mt={2}>
                            <Collapse in={isSuccessPrint}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessPrint(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Virtual Bin printed successfully!
                              </Alert>
                            </Collapse>
                            <Collapse in={isErrorPrint}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorPrint(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {printErrorMsg}
                                {/* Could not print! */}
                              </Alert>
                            </Collapse>
                          </MDBox>

                          <div className="mt-3">
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="success"
                              fullWidth
                              disabled={
                                !formikForPrint.isValid ||
                                printButtonName === "Please wait..."
                              }
                            >
                              {printButtonName}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>

                <Modal
                  open={openDeleteAssetModal}
                  onClose={handleCloseDeleteAssetModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={deleteStyle}>
                    <MDTypography>
                      Are you sure you want to change the status?
                    </MDTypography>
                    <MDBox mt={3} display="flex">
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "2.4rem" }}
                          color="info"
                          variant="outlined"
                          onClick={handleCloseDeleteAssetModal}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div style={{ float: "right" }}>
                        <MDButton
                          style={{ width: "100%", marginLeft: "2.4rem" }}
                          color="error"
                          onClick={deleteAssetData}
                        >
                          Change
                        </MDButton>
                      </div>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessDeleteAsset}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessDeleteAsset(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Status changed successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorDeleteAsset}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorDeleteAsset(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {deleteErrorMsg}
                          {/* Could not change the status of the Asset! */}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openEmptyAssetModal}
                  onClose={handleCloseEmptyAssetModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={deleteStyle}>
                    <MDTypography>
                      Are you sure you want to empty the asset?
                    </MDTypography>
                    <MDBox mt={3} display="flex">
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "2.4rem" }}
                          color="info"
                          variant="outlined"
                          onClick={handleCloseEmptyAssetModal}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div style={{ float: "right" }}>
                        <MDButton
                          style={{ width: "100%", marginLeft: "2.4rem" }}
                          color="error"
                          onClick={emptyAssetData}
                        >
                          Yes
                        </MDButton>
                      </div>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessEmptyAsset}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessEmptyAsset(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Asset is empty successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorEmptyAsset}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorEmptyAsset(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {emptyErrorMsg}
                          {/* Could not change the status of the Asset! */}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openPrintAssetModal}
                  onClose={handleClosePrintAssetModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={deleteStyle}>
                    <MDTypography>Are you sure you want to print?</MDTypography>
                    <MDBox mt={3} display="flex">
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "2.4rem" }}
                          color="info"
                          variant="outlined"
                          onClick={handleClosePrintAssetModal}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div style={{ float: "right" }}>
                        <MDButton
                          style={{ width: "100%", marginLeft: "2.4rem" }}
                          color="warning"
                          onClick={printAssetHandler}
                        >
                          Yes
                        </MDButton>
                      </div>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessPrintAsset}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessPrintAsset(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Printed successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorPrintAsset}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorPrintAsset(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {/* Could not print the Asset! */}
                          {printAssetErrorMsg}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {loading && (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    assets: state.getAssetsReducer,
    assetTypes: state.getAssetTypesReducer,
    profileData: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getAssets: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getAssets(pageNo, rowsPerPage, searchObject, jwtToken)),
    deleteAsset: (id, status, jwtToken) =>
      dispatch(deleteAsset(id, status, jwtToken)),
    createNewAsset: (payload, jwtToken) =>
      dispatch(createNewAsset(payload, jwtToken)),
    printVirtualBin: (payload, jwtToken) =>
      dispatch(printVirtualBin(payload, jwtToken)),
    getAssetTypes: (jwtToken) => dispatch(getAssetTypes(jwtToken)),
    printAsset: (payload, jwtToken) => dispatch(printAsset(payload, jwtToken)),
    updateAsset: (payload, jwtToken) =>
      dispatch(updateAsset(payload, jwtToken)),
    getProfile: (email, jwtToken) => dispatch(getProfile(email, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetMaster);
