/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getPiv,
  updatePicklistLocation,
  addToReleaseForIssuance,
  getWarehouses,
  getResourcesForAllocation,
  getStoreLocationByMaterialCode,
  validationForMaterialIssuance,
} from "../../../store";

import { addMinutes, format } from "date-fns";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../../config/index";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

const desLength = Config.descriptionLength;

const cookies = new Cookies();

const styleForModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const MaterialIssuance = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getPiv,
  piv,
  updatePicklistLocation,
  addToReleaseForIssuance,
  getWarehouses,
  warehouses,
  getResourcesForAllocation,
  resource,
  getStoreLocationByMaterialCode,
  getStoreLocationByMaterialCodeReducer,
  validationForMaterialIssuance,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let userId = loginDetails?.data.userId;
  let roleId = loginDetails.data.roleId;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let palletModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "ab400e95-8733-4b0d-9a99-c258245c3152"
  );

  let viewPivAccess = palletModule
    ? palletModule.actionId.includes("13ca2e6e-b006-4ca6-9b4c-8527ddd56f8c")
    : null;

  let editLocationAccess = palletModule
    ? palletModule.actionId.includes("1d80a8c0-1c2a-49d6-9c31-b9ea8727b521")
    : null;

  let addToReleaseAccess = palletModule
    ? palletModule.actionId.includes("f048a271-dcae-41d5-94b2-4297ca6545b6")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [materialCode, setMaterialCode] = useState("");

  const [storeLocationOptions, setStoreLocationOptions] = useState([]);

  useEffect(() => {
    let tempStoreLocationOptions = [];
    let data = getStoreLocationByMaterialCodeReducer.storeLocationData
      ? getStoreLocationByMaterialCodeReducer.storeLocationData
      : [];

    data.map((store) => {
      tempStoreLocationOptions.push({
        label: `${store.storingLocation} / ${store.warehouseName}`,
        value: store.storingLocation,
      });
    });
    setStoreLocationOptions(tempStoreLocationOptions);
  }, [getStoreLocationByMaterialCodeReducer]);

  useEffect(() => {
    getPiv(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];

    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: `${warehouse.warehouseCode} / ${warehouse.warehouseName}`,
        value: warehouse.warehouseCode,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [state, setState] = useState({
    columns: [
      {
        Header: "SAP Realeased Date",
        accessor: "cd",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Batch No / Qty", accessor: "batchNumber" },
      { Header: "Resource Category", accessor: "resourceCategory" },
      { Header: "Bulk Code / Des", accessor: "finalMaterialCode" },
      { Header: "MRP Type", accessor: "mrpType" },
      { Header: "RM Category / Des", accessor: "rmCategory" },
      { Header: "Phase No", accessor: "phaseNumber" },
      { Header: "Phase Des", accessor: "phaseDescription" },
      { Header: "Item Code / Des", accessor: "materialCode" },
      { Header: "Item Qty", accessor: "qty" },
      { Header: "From Loc", accessor: "source" },
      { Header: "To Loc", accessor: "destination" },
      // { Header: "Status", accessor: "status" },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [singlePivData, setSinglePivData] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [selectedPivData, setSelectedPivData] = useState([]);
  const [resourceCategoryValidation, setResourceCategoryValidation] =
    useState(false);

  useEffect(() => {
    let filteredPayload = selectedPivData.filter(
      (v, i, a) =>
        a.findIndex((v2) =>
          ["documentNo", "phaseNumber", "resourceCategory"].every(
            (k) => v2[k] === v[k]
          )
        ) === i
    );

    let filteredResourceCategory = selectedPivData.filter(
      (v, i, a) =>
        a.findIndex((v2) =>
          ["resourceCategory"].every((k) => v2[k] === v[k])
        ) === i
    );

    setResourceCategoryValidation(filteredResourceCategory.length > 1);

    let tempDocumentNo = filteredPayload[0]?.documentNo;
    let tempPhaseNumber = filteredPayload[0]?.phaseNumber;
    let tempResourceCategory = filteredPayload[0]?.resourceCategory;

    setDocumentNo(tempDocumentNo);
    setPhaseNumber(tempPhaseNumber);
    setResourceCategory(tempResourceCategory);
  }, [selectedPivData]);

  const [documentNo, setDocumentNo] = useState("");
  const [phaseNumber, setPhaseNumber] = useState("");
  const [resourceCategory, setResourceCategory] = useState("");

  const [resourcesOptions, setResourcesOptions] = useState([]);

  useEffect(() => {
    let tempResourcesOptions = [];
    let data = resource.resource.data ? resource.resource.data : [];

    if (data.length > 0) {
      data.map((resource) => {
        tempResourcesOptions.push({
          label: resource.resourceName,
          value: resource.id,
        });
      });
      setResourcesOptions(tempResourcesOptions);
    }

    if (data.length === 0) {
      setResourcesOptions([
        {
          label: "Others",
          value: "others",
        },
      ]);
    }
  }, [resource]);

  useEffect(() => {
    let tempPiv = [];

    let data = piv.piv.data ? piv.piv.data : [];

    setErrorMsgFromApi(piv?.error?.data?.msg);
    setLoading(piv.loading);
    setPaginationObj(piv?.piv?.pagination);

    data.map((piv, index) => {
      let bulkDes = piv?.finalMaterialDescription;
      let itemDes = piv.materialDescription;
      let phaseDes = piv.phaseDescription;
      let rmDes = piv.rmCatDescription;
      let len = desLength;

      const pivObject = {
        privateKey: piv.pickupListCode,
        documentNo: piv.documentNo,
        batchNumber: `${piv.batchNumber} / ${piv.batchSize} ${piv.batchUom}`,
        finalMaterialCode: `${piv.finalMaterialCode} / ${
          bulkDes ? bulkDes.substring(0, len) : ""
        }`,
        materialCode: `${piv.materialCode} / ${
          itemDes ? itemDes.substring(0, len) : ""
        }`,
        mrpType: piv.mrpType,
        rmCategory: `${piv.rmCategory} / ${
          rmDes ? rmDes.substring(0, len) : ""
        }`,
        phaseNumber: piv.phaseNumber,
        phaseDescription: phaseDes ? phaseDes.substring(0, len) : "",
        qty: `${piv.qty} ${piv.uom}`,
        resourceCategory: piv.resourceCategory,
        cd: piv.cd,
        status: (
          <button
            className={
              (piv.status === 0 && `status-error`) ||
              (piv.status === 1 && `status-warning`) ||
              (piv.status === 2 && `status-success`)
            }
            style={{ width: "5.5rem" }}
          >
            {(piv.status === 0 && `Not Picked`) ||
              (piv.status === 1 && `Picked`) ||
              (piv.status === 2 && `Delivered`)}
          </button>
        ),
        source: (
          <span style={{ display: "flex" }}>
            <MDTypography
              variant="h6"
              fontWeight="regular"
              style={{ marginTop: "0.45rem" }}
            >
              {piv.source}
            </MDTypography>
            <Tooltip title="Edit Location" style={{ marginLeft: "0.75rem" }}>
              <MDButton
                color="info"
                variant="gradient"
                type="button"
                iconOnly
                size="small"
                disabled={!editLocationAccess}
                onClick={() => {
                  setSinglePivData(piv);
                  setMaterialCode(piv.materialCode);
                  getStoreLocationByMaterialCode(piv.materialCode, jwtToken);
                  handleOpenEditFromLocModal();
                }}
              >
                <Icon> edit </Icon>
              </MDButton>
            </Tooltip>
          </span>
        ),
        destination: piv.destination,
        pickupListCode: piv.pickupListCode,
        fromLoc: piv.source,
        srNo: piv.srNo,
        disableSelection: piv.source === "NA" ? true : false,
      };
      tempPiv.push(pivObject);
    });

    viewPivAccess && setState({ ...state, rows: tempPiv });
  }, [piv]);

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    let value = selectedPivData.some((data) => data.fromLoc === "NA");
    setDisableButton(value);
  }, [selectedPivData]);

  const pivDataSelectionHandler = (data) => {
    setSelectedPivData(data);
  };

  const [isErrorEditFromLoc, setIsErrorEditFromLoc] = useState(false);
  const [isSuccessEditFromLoc, setIsSuccessEditFromLoc] = useState(false);

  useEffect(() => {
    if (isErrorEditFromLoc) {
      setTimeout(() => {
        setIsErrorEditFromLoc(false);
      }, 3000);
    }
  }, [isErrorEditFromLoc]);

  useEffect(() => {
    if (isSuccessEditFromLoc) {
      setTimeout(() => {
        setIsSuccessEditFromLoc(false);
      }, 3000);
    }
  }, [isSuccessEditFromLoc]);

  const [fromLocSelected, setFromLocSelected] = useState("");

  const handleFromLocChange = (selectedFromLoc) => {
    setFromLocSelected(selectedFromLoc);
  };

  const [buttonName, setButtonName] = useState("Submit");

  const [openEditFromLocModal, setOpenEditFromLocModal] = useState(false);

  const handleOpenEditFromLocModal = () => {
    setOpenEditFromLocModal(true);
  };

  const handleCloseEditFromLocModal = () => {
    setOpenEditFromLocModal(false);
  };

  const initialValueForEditFromLoc = {
    fromLoc: "",
  };

  const validationSchemaEditFromLoc = Yup.object({
    fromLoc: Yup.object().required("Select location"),
  });

  const [fromLocErrorMsg, setFromLocErrorMsg] = useState("");

  const onSubmitForEditFromLoc = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    const payload = {
      srNo: singlePivData.srNo,
      documentNo: singlePivData.documentNo,
      phaseNumber: singlePivData.phaseNumber,
      pickupListCode: singlePivData.pickupListCode,
      source: fromLocSelected.value,
      destination: "",
    };

    let res = await updatePicklistLocation([payload], jwtToken);

    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessEditFromLoc(true);
      setFromLocSelected("");
      getPiv(pageNo, rowsPerPage, searchObject, jwtToken);
      resetForm();

      setTimeout(() => {
        handleCloseEditFromLocModal();
        setButtonName("Submit");
      }, 2250);
    }
    if (!res.status) {
      setFromLocErrorMsg(res.data.data.message);
      setIsErrorEditFromLoc(true);
      setButtonName("Submit");
    }
  };

  const formikForEditFromLoc = useFormik({
    initialValues: initialValueForEditFromLoc,
    validationSchema: validationSchemaEditFromLoc,
    onSubmit: onSubmitForEditFromLoc,
  });

  const [isErrorEditToLoc, setIsErrorEditToLoc] = useState(false);
  const [isSuccessEditToLoc, setIsSuccessEditToLoc] = useState(false);

  useEffect(() => {
    if (isErrorEditToLoc) {
      setTimeout(() => {
        setIsErrorEditToLoc(false);
      }, 3000);
    }
  }, [isErrorEditToLoc]);

  useEffect(() => {
    if (isSuccessEditToLoc) {
      setTimeout(() => {
        setIsSuccessEditToLoc(false);
      }, 3000);
    }
  }, [isSuccessEditToLoc]);

  const [toLocSelected, setToLocSelected] = useState("");

  const handleToLocChange = (selectedToLoc) => {
    setToLocSelected(selectedToLoc);
  };

  const [resourceSelected, setResourceSelected] = useState("");
  const handleResourceChange = (selectedResource) => {
    setResourceSelected(selectedResource);
  };

  const [openEditToLocModal, setOpenEditToLocModal] = useState(false);

  const handleOpenEditToLocModal = () => {
    setOpenEditToLocModal(true);
    getResourcesForAllocation(
      documentNo,
      phaseNumber,
      resourceCategory,
      jwtToken
    );
    getWarehouses("", jwtToken);
  };

  const handleCloseEditToLocModal = () => {
    setOpenEditToLocModal(false);
  };

  const initialValueForEditToLoc = {
    toLoc: "",
    resource: "",
  };

  const validationSchemaEditToLoc = Yup.object({
    toLoc: Yup.object().required("Select location"),
    resource: Yup.object().required("Select resource"),
  });

  const [toLocErrorMsg, setToLocErrorMsg] = useState("");

  const onSubmitForEditToLoc = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let payload = [];

    selectedPivData.map((data) => {
      const tempPayload = {
        ...data.original,
        pickupListCode: data.pickupListCode,
        srNo: data.srNo,
        documentNo: data.documentNo,
        phaseNumber: data.phaseNumber,
        destination: toLocSelected.value,
        source: data.fromLoc,
        resource: resourceSelected.value,
      };

      payload.push(tempPayload);
    });

    let res = await addToReleaseForIssuance(payload, jwtToken);

    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessEditToLoc(true);
      setToLocSelected("");
      getPiv(pageNo, rowsPerPage, searchObject, jwtToken);
      resetForm();

      setTimeout(() => {
        handleCloseEditToLocModal();
        setButtonName("Submit");
      }, 2250);
    }
    if (!res.status) {
      setToLocErrorMsg(res.data.data.message);
      setIsErrorEditToLoc(true);
      setButtonName("Submit");
    }
  };

  const formikForEditToLoc = useFormik({
    initialValues: initialValueForEditToLoc,
    validationSchema: validationSchemaEditToLoc,
    onSubmit: onSubmitForEditToLoc,
  });

  const [validationErrorMsg, setValidationErrorMsg] = useState("");
  const [errorDocumentNo, setErrorDocumentNo] = useState("");
  const [validationErrorArray, setValidationErrorArray] = useState([]);

  useEffect(() => {
    setValidationErrorMsg("");
    setErrorDocumentNo("");
  }, [selectedPivData]);

  useEffect(() => {
    const msg = validationErrorArray.map((data) => {
      return `${data.documentNo}-${data.phaseNumber}`;
    });

    setErrorDocumentNo(msg.join(", "));
  }, [validationErrorArray]);

  const releaseValidationHandler = async () => {
    let payload = [];

    selectedPivData.map((data) => {
      const tempPayload = {
        pickupListCode: data.pickupListCode,
        srNo: data.srNo,
        documentNo: data.documentNo,
        phaseNumber: data.phaseNumber,
        resourceCategory: data.resourceCategory,
      };

      payload.push(tempPayload);
    });

    let res = await validationForMaterialIssuance(payload, jwtToken);

    if (res.status) {
      handleOpenEditToLocModal();
    }

    if (!res.status) {
      setValidationErrorArray(res.data.data.data);
      setValidationErrorMsg(res.data.data.msg);
    }
  };

  return (
    <div>
      <MDBox mt={-6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mt={-2}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  errorMsg={errorMsgFromApi}
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                  uncheckProp={uncheck}
                  selection={true}
                  selectionButtonClick={pivDataSelectionHandler}
                  selectionButtonComponent={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <span>
                        <Tooltip title="Add to Release">
                          <MDButton
                            disabled={
                              disableButton ||
                              resourceCategoryValidation ||
                              !addToReleaseAccess
                            }
                            color="white"
                            type="button"
                            style={{
                              width: "10rem",
                              marginTop: "0.5rem",
                              color: "white",
                              border: "1px solid #0275d8",
                              background: "#0275d8",
                              // marginLeft: 5,
                            }}
                            onClick={() => {
                              releaseValidationHandler();
                            }}
                          >
                            Add to Release
                          </MDButton>
                        </Tooltip>
                      </span>
                      <span>
                        {validationErrorMsg && (
                          <>
                            <MDTypography
                              color="error"
                              variant="p"
                              style={{
                                // textAlign: "center",
                                marginLeft: "1rem",
                                marginTop: "1rem",
                              }}
                            >
                              {validationErrorMsg}
                            </MDTypography>
                            <br />
                            <MDTypography
                              color="error"
                              variant="p"
                              style={{
                                // textAlign: "center",
                                marginLeft: "1rem",
                                // marginTop: "1rem",
                              }}
                            >
                              Document No - {errorDocumentNo}
                            </MDTypography>
                          </>
                        )}
                        {disableButton && (
                          <MDTypography
                            color="error"
                            variant="p"
                            style={{
                              // textAlign: "center",
                              marginLeft: "1rem",
                              marginTop: "1rem",
                            }}
                          >
                            Remove records with "NA" source!
                          </MDTypography>
                        )}
                        {resourceCategoryValidation && (
                          <>
                            <br />
                            <MDTypography
                              color="error"
                              variant="p"
                              style={{
                                // textAlign: "center",
                                marginLeft: "1rem",
                                // marginTop: "1rem",
                              }}
                            >
                              Select records with same "Resource Category"!
                            </MDTypography>
                          </>
                        )}
                      </span>
                    </span>
                  }
                />
              </MDBox>

              <Modal
                open={openEditFromLocModal}
                onClose={handleCloseEditFromLocModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForModal}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Edit From Location
                    </MDTypography>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseEditFromLocModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForEditFromLoc.handleSubmit}
                    >
                      <MDBox>
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Select Source Location
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            placeholder="Select location..."
                            name="fromLoc"
                            value={fromLocSelected}
                            options={storeLocationOptions}
                            onChange={(selectedOption) => {
                              handleFromLocChange(selectedOption);
                              formikForEditFromLoc.setFieldValue(
                                "fromLoc",
                                selectedOption
                              );
                            }}
                          />
                          {formikForEditFromLoc.errors.fromLoc && (
                            <TextError
                              msg={formikForEditFromLoc.errors.fromLoc}
                            />
                          )}
                        </MDBox>

                        <MDBox mt={2}>
                          <Collapse in={isSuccessEditFromLoc}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessEditFromLoc(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Location edited successfully!
                            </Alert>
                          </Collapse>
                          <Collapse in={isErrorEditFromLoc}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorEditFromLoc(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {/* Could not edit the Location! */}
                              {fromLocErrorMsg}
                            </Alert>
                          </Collapse>
                        </MDBox>

                        <div className="mt-3">
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                            disabled={
                              !formikForEditFromLoc.isValid ||
                              buttonName === "Please wait..."
                            }
                          >
                            {buttonName}
                          </MDButton>
                        </div>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={openEditToLocModal}
                onClose={handleCloseEditToLocModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForModal}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Destination
                    </MDTypography>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseEditToLocModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForEditToLoc.handleSubmit}
                    >
                      <MDBox>
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Select Destination
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            placeholder="Select location..."
                            name="toLoc"
                            value={toLocSelected}
                            options={warehouseOptions}
                            onChange={(selectedOption) => {
                              handleToLocChange(selectedOption);
                              formikForEditToLoc.setFieldValue(
                                "toLoc",
                                selectedOption
                              );
                            }}
                          />
                          {formikForEditToLoc.errors.toLoc && (
                            <TextError msg={formikForEditToLoc.errors.toLoc} />
                          )}
                        </MDBox>

                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Resource Category : {resourceCategory}
                          </MDTypography>
                        </MDBox>

                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Resource
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            placeholder="Select resource..."
                            name="resource"
                            value={resourceSelected}
                            options={resourcesOptions}
                            onChange={(selectedOption) => {
                              handleResourceChange(selectedOption);
                              formikForEditToLoc.setFieldValue(
                                "resource",
                                selectedOption
                              );
                            }}
                          />
                          {formikForEditToLoc.errors.resource && (
                            <TextError
                              msg={formikForEditToLoc.errors.resource}
                            />
                          )}
                        </MDBox>

                        <MDBox mt={2}>
                          <Collapse in={isSuccessEditToLoc}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessEditToLoc(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Released for Issuance successfully!
                            </Alert>
                          </Collapse>
                          <Collapse in={isErrorEditToLoc}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorEditToLoc(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {/* Could not Release for Issuance! */}
                              {toLocErrorMsg}
                            </Alert>
                          </Collapse>
                        </MDBox>

                        <div className="mt-3">
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                            disabled={
                              !formikForEditToLoc.isValid ||
                              buttonName === "Please wait..."
                            }
                          >
                            {buttonName}
                          </MDButton>
                        </div>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    piv: state.getPivReducer,
    warehouses: state.warehouses,
    resource: state.getResourcesForAllocationReducer,
    getStoreLocationByMaterialCodeReducer:
      state.getStoreLocationByMaterialCodeReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getPiv: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getPiv(pageNo, rowsPerPage, searchObject, jwtToken)),
    updatePicklistLocation: (payload, jwtToken) =>
      dispatch(updatePicklistLocation(payload, jwtToken)),
    addToReleaseForIssuance: (payload, jwtToken) =>
      dispatch(addToReleaseForIssuance(payload, jwtToken)),
    validationForMaterialIssuance: (payload, jwtToken) =>
      dispatch(validationForMaterialIssuance(payload, jwtToken)),
    getWarehouses: (warehouseType, jwtToken) =>
      dispatch(getWarehouses(warehouseType, jwtToken)),
    getResourcesForAllocation: (
      documentNo,
      phaseNumber,
      resourceCategory,
      jwtToken
    ) =>
      dispatch(
        getResourcesForAllocation(
          documentNo,
          phaseNumber,
          resourceCategory,
          jwtToken
        )
      ),
    getStoreLocationByMaterialCode: (materialCode, jwtToken) =>
      dispatch(getStoreLocationByMaterialCode(materialCode, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialIssuance);
