import {
  GETLOCATION_BYWAREHOUSE_REQUEST,
  GETLOCATION_BYWAREHOUSE_SUCCESS,
  GETLOCATION_BYWAREHOUSE_FAILURE,
  ADDLOCATION_TOWAREHOUSE_REQUEST,
  ADDLOCATION_TOWAREHOUSE_SUCCESS,
  ADDLOCATION_TOWAREHOUSE_FAILURE,
  GETITEM_BYLOCATION_REQUEST,
  GETITEM_BYLOCATION_SUCCESS,
  GETITEM_BYLOCATION_FAILURE,
  GETLOCATION_BYWAREHOUSEANDZONE_REQUEST,
  GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS,
  GETLOCATION_BYWAREHOUSEANDZONE_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
} from "./viewLocationTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getLocationsByWarehouseRequest = () => {
  return {
    type: GETLOCATION_BYWAREHOUSE_REQUEST,
  };
};

const getLocationsByWarehouseSuccess = (payload) => {
  return {
    type: GETLOCATION_BYWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const getLocationsByWarehouseFailure = (error) => {
  return {
    type: GETLOCATION_BYWAREHOUSE_FAILURE,
    payload: error,
  };
};

const getLocationsByWarehouse = (warehouseId, zoneId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLocationsByWarehouseRequest());

    let url = `${baseUrl}/location/get_by_wid_&_zid?warehouseId=${warehouseId}${
      zoneId === undefined ? "" : `&zoneId=${zoneId}`
    }`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLocationsByWarehouseSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getLocationsByWarehouseFailure(err.message));
      });
  };
};

const addLocationToWarehouseRequest = () => {
  return {
    type: ADDLOCATION_TOWAREHOUSE_REQUEST,
  };
};

const addLocationToWarehouseSuccess = (payload) => {
  return {
    type: ADDLOCATION_TOWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const addLocationToWarehouseFailure = (error) => {
  return {
    type: ADDLOCATION_TOWAREHOUSE_FAILURE,
    payload: error,
  };
};

const addLocationToWarehouse = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(addLocationToWarehouseRequest());
    let url = `${baseUrl}/location/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(addLocationToWarehouseSuccess(response.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(addLocationToWarehouseFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getItemByLocationRequest = () => {
  return {
    type: GETITEM_BYLOCATION_REQUEST,
  };
};

const getItemByLocationSuccess = (payload) => {
  return {
    type: GETITEM_BYLOCATION_SUCCESS,
    payload: payload,
  };
};

const getItemByLocationFailure = (error) => {
  return {
    type: GETITEM_BYLOCATION_FAILURE,
    payload: error,
  };
};

const getItemByLocation = (
  locationId,
  pageNo,
  rowsPerPage,
  search,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getItemByLocationRequest());
    let url = `${baseUrl}/store_putaway/get_sku_by_location_id?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&location_Id=${locationId}${
      search ? `&search=${search}` : ""
    }`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemByLocationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemByLocationFailure(err.message));
      });
  };
};

const getLocationsByWarehouseAndZoneRequest = () => {
  return {
    type: GETLOCATION_BYWAREHOUSEANDZONE_REQUEST,
  };
};

const getLocationsByWarehouseAndZoneSuccess = (payload) => {
  return {
    type: GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS,
    payload: payload,
  };
};

const getLocationsByWarehouseAndZoneFailure = (error) => {
  return {
    type: GETLOCATION_BYWAREHOUSEANDZONE_FAILURE,
    payload: error,
  };
};

const getLocationsByWarehouseAndZone = (warehouseId, zoneId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLocationsByWarehouseAndZoneRequest());

    let url = `${baseUrl}/location/get_by_wid_&_zid?warehouseId=${warehouseId}${
      zoneId ? `&zoneId=${zoneId}` : ""
    }`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLocationsByWarehouseAndZoneSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getLocationsByWarehouseAndZoneFailure(err.message));
      });
  };
};

const deleteLocationRequest = () => {
  return {
    type: DELETE_LOCATION_REQUEST,
  };
};

const deleteLocationSuccess = (payload) => {
  return {
    type: DELETE_LOCATION_SUCCESS,
    payload: payload,
  };
};

const deleteLocationFaliure = (error) => {
  return {
    type: DELETE_LOCATION_FAILURE,
    payload: error,
  };
};

const deleteLocation = (id, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    let url = `${baseUrl}/location/delete?id=${id}`;

    dispatch(deleteLocationRequest());
    return axios
      .delete(url, headers)
      .then((response) => {
        dispatch(deleteLocationSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteLocationFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getLocationsByWarehouse,
  addLocationToWarehouse,
  getItemByLocation,
  getLocationsByWarehouseAndZone,
  deleteLocation,
};
