/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { combineReducers } from "redux";
import {
  loginReducer,
  logoutReducer,
} from "./authentication/login/loginReducer";
import resetPasswordReducer from "./authentication/reset-password/resetPasswordReducer";
import {
  setPasswordReducer,
  setPasswordForNewUserReducer,
  setNewPasswordReducer,
} from "./authentication/set-password/setPasswordReducer";
import {
  forgotPasswordReducer,
  validateOtpForForgotPasswordReducer,
} from "./authentication/forgot-password/forgotPasswordReducer";
import profileReducer from "./profile/profileReducer";

import {
  getInventoryReducer,
  getPickupReducer,
  getWarehouseReducer,
  getYardReducer,
  getFgReducer,
  getPdiReducer,
  getRejectionReducer,
} from "./home/homeReducers";
import {
  getWarehousesReducer,
  getWarehouseByIdReducer,
  updateWarehouseReducer,
  createWarehouseReducer,
  deliveryWarehouseReducer,
} from "./warehouses/warehousesReducer";

import {
  getZonesByCategoryAndWarehouseReducer,
  getOpenCategoryZonesByWarehouseReducer,
  getAllCategoriesReducer,
  addZoneToWarehouseReducer,
  getZonesByWarehouseReducer,
  getOpenBinsByWarehouseReducer,
  deleteZoneReducer,
} from "./zones-and-categories/zoneCategoryReducer";
import {
  getLocationsByWarehouseReducer,
  addLocationReducer,
  getItemByLocationReducer,
  getLocationsByWarehouseAndZoneReducer,
  deleteLocationReducer,
} from "./view-location/viewLocationReducer";
import {
  getAllGRNReducer,
  getLineItemsByGrnReducer,
  getAllGRNDataForDownloadReducer,
  setPackSizeReducer,
} from "./grn/grnReducer";
import {
  individualPrintReducer,
  packagePrintReducer,
} from "./label-printing/labelPrintingReducer";
import {
  getSkuForReprintReducer,
  reprintReducer,
  getSkuForReprintForReportsReducer,
} from "./reprint/reprintReducer";

import { printersReducer } from "./device-management/printer-onboarding/printerOnboardingReducer";
import {
  allPrintersReducer,
  printerConfigReducer,
} from "./printer-config/printerConfigReducer";
import devicesReducer from "./device-management/device-onboarding/deviceOnboardingReducer";
import {
  getDepartmentsReducer,
  createDepartmentReducer,
  updateDepartmentReducer,
  deleteDepartmentReducer,
} from "./user-management/department/departmentReducer";
import { getLoginIdsReducer } from "./user-management/login-id/loginIdReducer";
// import { getModulesReducer } from "./user-management/modules/modulesReducer";
import {
  getUsersDetailsReducer,
  createUserDetailsReducer,
  updateUserDetailsReducer,
  deleteUserDetailsReducer,
} from "./user-management/user-details/userDetailsReducer";
import {
  getAllRolesReducer,
  getUserRolesByDepartment,
} from "./user-management/user-roles/userRolesReducer";
import {
  getModulesReducer,
  getSubModulesReducer,
  getModulesForViewReducer,
  getModulesForEditReducer,
  getSubModulesForViewReducer,
  getSubModulesForCreateRoleReducer,
} from "./modules/modulesReducer";

import {
  getModulesByRoleIdReducer,
  getSubModulesByRoleIdReducer,
} from "./user-modules/userModulesReducers";

import {
  getAssetsReducer,
  updateAssetReducer,
  createNewAssetReducer,
  deleteAssetReducer,
  getAssetTypesReducer,
} from "./asset-master/assetMasterReducer";
import {
  getMaterialDescReducer,
  underTestPrintReducer,
} from "./under-test/underTestReducer";
import {
  getPivReducer,
  updatePicklistLocationReducer,
  addToReleaseForIssuanceReducer,
} from "./material-issuance/materialIssuanceReducer";
import {
  getAddedPivReducer,
  removePivReducer,
  releasePivReducer,
} from "./issuance-release/issuanceReleaseReducer";
import { getStoreIssuanceReducer } from "./store-issuance/storeIssuanceReducer";
import {
  getReleasePicklistReducer,
  getUsersToAssignReducer,
} from "./release-picklist/releasePicklistReducer";
import {
  getPivTrackingReducer,
  getStoreLocationByMaterialCodeReducer,
  getPivTrackingForReportsReducer,
} from "./piv-tracking/pivTrackingReducer";
import { getPickListTrackingReducer } from "./picklist-tracking/picklistTrackingReducer";
import {
  getProductionProcessReducer,
  getResourcesForAllocationReducer,
  getProductionProcessForReportsReducer,
} from "./production-process/productionProcessReducer";
import {
  getProductionAllocationReducer,
  getLocationsForProductionAllocationReducer,
  releaseToProductionProcessReducer,
  updateFromLocationReducer,
  updateResourceReducer,
} from "./production-allocation/productionAllocationReducer";

import {
  getPivFillingReducer,
  getReleasedPivReducer,
  getAssetsByDocumentNoReducer,
} from "./piv-filling-and-release/pivFillingAndReleasedPivReducer";
import { getTempUsersReducer } from "./user-management/temp-users/tempUsersReducer";

import {
  getWarehouseOccupancyReducer,
  downloadWarehouseOccupancyReducer,
} from "./reports/warehouse-occupancy/warehouseOccupancyReducer";
import { getInboundReportsReducer } from "./reports/inbound/inboundReducer";
import {
  getPhaseWiseChargingReducer,
  downloadPhaseWiseChargingReducer,
} from "./reports/phase-wise-charging/phaseWiseChargingReducer";
import {
  getPickupListWiseChargingReducer,
  downloadPickupListWiseChargingReducer,
} from "./reports/pickuplist-wise-charging/pickuplistWiseChargingReducer";

import { getItemMasterReducer } from "./item-master/itemMasterReducer";
import {
  getConsolidatedPIVReportsReducer,
  downloadConsolidatedPIVReducer,
} from "./reports/consolidated-piv/consolidatedPIVReducer";
import {
  getMaterialCodeReducer,
  getWorkFlowReducer,
  getWorkFlowDataForDownloadReducer,
} from "./work-flow/workFlowReducer";

import { getAddToStoreReducer } from "./stock-cut-over/stockCutOverReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  logoutReducer: logoutReducer,
  resetPassword: resetPasswordReducer,
  setPasswordReducer: setPasswordReducer,
  setPasswordForNewUserReducer: setPasswordForNewUserReducer,
  setNewPasswordReducer: setNewPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  validateOtpForForgotPasswordReducer: validateOtpForForgotPasswordReducer,
  profile: profileReducer,
  printersReducer: printersReducer,
  allPrintersReducer: allPrintersReducer,
  printerConfigReducer: printerConfigReducer,
  devicesReducer: devicesReducer,
  getDepartmentsReducer: getDepartmentsReducer,
  createDepartmentReducer: createDepartmentReducer,
  updateDepartmentReducer: updateDepartmentReducer,
  deleteDepartmentReducer: deleteDepartmentReducer,

  getAllRolesReducer: getAllRolesReducer,
  getUserRolesByDepartment: getUserRolesByDepartment,
  getModulesReducer: getModulesReducer,
  getSubModulesReducer: getSubModulesReducer,
  getSubModulesForViewReducer: getSubModulesForViewReducer,
  getModulesForViewReducer: getModulesForViewReducer,
  getModulesForEditReducer: getModulesForEditReducer,
  getSubModulesForCreateRoleReducer: getSubModulesForCreateRoleReducer,
  getUsersDetailsReducer: getUsersDetailsReducer,
  createUserDetailsReducer: createUserDetailsReducer,
  updateUserDetailsReducer: updateUserDetailsReducer,
  deleteUserDetailsReducer: deleteUserDetailsReducer,
  getLoginIdsReducer: getLoginIdsReducer,
  getInventoryHomePage: getInventoryReducer,
  getPickupListHomePage: getPickupReducer,
  warehouseOccupancyHomePage: getWarehouseReducer,
  getYardOccupancyHomePage: getYardReducer,
  getFinishedGoodsHomePage: getFgReducer,
  getPdiHomePage: getPdiReducer,
  getRejectionHomePage: getRejectionReducer,
  locationsByWarehouse: getLocationsByWarehouseReducer,
  locationsByWarehouseAndZone: getLocationsByWarehouseAndZoneReducer,
  deleteLocation: deleteLocationReducer,
  addLocationToWarehouse: addLocationReducer,
  item: getItemByLocationReducer,
  warehouses: getWarehousesReducer,
  warehouseById: getWarehouseByIdReducer,
  updateWarehouse: updateWarehouseReducer,
  deliveryWarehouse: deliveryWarehouseReducer,
  createWarehouseReducer: createWarehouseReducer,
  skuForReprint: getSkuForReprintReducer,
  reprintReducer: reprintReducer,
  getSkuForReprintForReportsReducer: getSkuForReprintForReportsReducer,
  zonesByCategoryAndWarehouse: getZonesByCategoryAndWarehouseReducer,
  openCategoryZones: getOpenCategoryZonesByWarehouseReducer,
  zonesByWarehouse: getZonesByWarehouseReducer,
  deleteZoneReducer: deleteZoneReducer,
  openBinsByWarehouse: getOpenBinsByWarehouseReducer,
  allCategories: getAllCategoriesReducer,
  addZoneToWarehouse: addZoneToWarehouseReducer,
  allGRN: getAllGRNReducer,
  getAllGRNDataForDownloadReducer: getAllGRNDataForDownloadReducer,
  setPackSizeReducer: setPackSizeReducer,
  lineItemsByGrn: getLineItemsByGrnReducer,
  individualLabelPrint: individualPrintReducer,
  packageLabelPrint: packagePrintReducer,
  getModulesByRoleIdReducer: getModulesByRoleIdReducer,
  getSubModulesByRoleIdReducer: getSubModulesByRoleIdReducer,
  getMaterialDescReducer: getMaterialDescReducer,
  underTestPrintReducer: underTestPrintReducer,
  getPivReducer: getPivReducer,
  updatePicklistLocationReducer: updatePicklistLocationReducer,
  addToReleaseForIssuanceReducer: addToReleaseForIssuanceReducer,
  getAddedPivReducer: getAddedPivReducer,
  removePivReducer: removePivReducer,
  releasePivReducer: releasePivReducer,
  getAssetsReducer: getAssetsReducer,
  updateAssetReducer: updateAssetReducer,
  createNewAssetReducer: createNewAssetReducer,
  deleteAssetReducer: deleteAssetReducer,
  getAssetTypesReducer: getAssetTypesReducer,
  getStoreIssuanceReducer: getStoreIssuanceReducer,
  getReleasePicklistReducer: getReleasePicklistReducer,
  getUsersToAssignReducer: getUsersToAssignReducer,
  getPivTrackingReducer: getPivTrackingReducer,
  getStoreLocationByMaterialCodeReducer: getStoreLocationByMaterialCodeReducer,
  getPivTrackingForReportsReducer: getPivTrackingForReportsReducer,
  getPickListTrackingReducer: getPickListTrackingReducer,
  getPivFillingReducer: getPivFillingReducer,
  getReleasedPivReducer: getReleasedPivReducer,
  getProductionProcessReducer: getProductionProcessReducer,
  getResourcesForAllocationReducer: getResourcesForAllocationReducer,
  getProductionProcessForReportsReducer: getProductionProcessForReportsReducer,
  getProductionAllocationReducer: getProductionAllocationReducer,
  getLocationsForProductionAllocationReducer:
    getLocationsForProductionAllocationReducer,
  updateResourceReducer: updateResourceReducer,
  updateFromLocationReducer: updateFromLocationReducer,
  releaseToProductionProcessReducer: releaseToProductionProcessReducer,
  getWarehouseOccupancy: getWarehouseOccupancyReducer,
  downloadWarehouseOccupancyReducer: downloadWarehouseOccupancyReducer,
  getAssetsByDocumentNoReducer: getAssetsByDocumentNoReducer,
  inboundReportsState: getInboundReportsReducer,
  getPhaseWiseChargingReducer: getPhaseWiseChargingReducer,
  downloadPhaseWiseChargingReducer: downloadPhaseWiseChargingReducer,
  getPickupListWiseChargingReducer: getPickupListWiseChargingReducer,
  downloadPickupListWiseChargingReducer: downloadPickupListWiseChargingReducer,

  getTempUsersReducer: getTempUsersReducer,
  itemMaster: getItemMasterReducer,

  getConsolidatedPIVReportsReducer: getConsolidatedPIVReportsReducer,
  downloadConsolidatedPIVReducer: downloadConsolidatedPIVReducer,
  getMaterialCodeReducer: getMaterialCodeReducer,
  getWorkFlowReducer: getWorkFlowReducer,
  getWorkFlowDataForDownloadReducer: getWorkFlowDataForDownloadReducer,
  getAddToStoreReducer: getAddToStoreReducer,
});

export default rootReducer;
