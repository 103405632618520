/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useState, useEffect } from "react";
import { connect } from "react-redux";

// react-router components
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MuiLink from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Dashboard components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Dashboard example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Dashboard context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Config file
import Config from "../../../config/index";
import MDTypography from "components/MDTypography";

// Functions from store
import { getProfile } from "../../../store";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();


function DashboardNavbar({ absolute, light, isMini, previousPage, profileData, getProfile }) {
  let user = cookies.get("loginDetailsForWMS");
  let jwtToken = user?.jwt;

  let email = user.data.email;

  useEffect(() => {
    getProfile(email, jwtToken);
  }, [getProfile]);

  const [profileInfo, setProfileInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let temp = profileData.profile ? profileData.profile : {};

    setLoading(profileData.loading);

    setProfileInfo(temp);
  }, [profileData]);

  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 1 }}
    >
      <NotificationItem
        icon={<Icon>person</Icon>}
        title="User Profile"
        route="/user-profile"
      />
      <NotificationItem
        icon={<Icon>printer</Icon>}
        title="Printer Configurator"
        route="/printer-configurator"
      />

      <NotificationItem
        icon={<Icon>key</Icon>}
        title="Reset Password"
        route="/authentication/reset-password"
      />
      <NotificationItem
        icon={<Icon>lock</Icon>}
        title="Logout"
        route="/authentication/logout"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      // sx={(theme) =>
      //   navbar(theme, { transparentNavbar, absolute, light, darkMode })
      // }
      style={{
        marginBottom: "-26px",
        background: "white",
        borderRadius: "10px",
      }}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
          style={{ background: "white" }}
        >
          {previousPage && (
            <Tooltip title="Go To Previous Page">
              <MDButton
                color="primary"
                variant="contained"
                type="button"
                onClick={() => {
                  navigate(-1);
                }}
                size="small"
                iconOnly
                style={{
                  marginLeft: "-15px",
                  marginRight: "20px",
                  borderRadius: "5px",
                }}
              >
                <Icon>arrow_back_ios</Icon>
              </MDButton>
            </Tooltip>
          )}
          <MDBox style={{ background: "white" }}>
            <Breadcrumbs
              icon="home"
              title={route[route.length - 1]}
              route={route}
              light={light}
            />
          </MDBox>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              {/* <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link> */}
<MDBox display="flex">
              <MDButton variant="h3" fontWeight="large" color="black"
              style={{background:"white", color:"grey", fontSize:"15px", marginTop:"-2.5px"}}>
                {profileInfo?.userName}
              </MDButton>
              
              <Tooltip title={miniSidenav ? "Open Sidenav" : "Close Sidenav"}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleMiniSidenav}
                  style={{ marginBottom: "0.28rem" }}
                >
                  <Icon sx={iconsStyle}>
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Space Management Dashboard">
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                >
                  <MuiLink href={smUrl}>
                    <Icon sx={iconsStyle}>dashboard</Icon>
                  </MuiLink>
                </IconButton>
              </Tooltip> */}
              {/* <Tooltip title="Go To Editor">
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                >
                  <MuiLink href={mobillorUrl}>
                    <Icon sx={iconsStyle}>account_tree</Icon>
                  </MuiLink>
                </IconButton>
              </Tooltip> */}
              <Tooltip title="Account">
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <MDBox mb={0.3}>
                    <Icon sx={iconsStyle}>account_circle</Icon>
                  </MDBox>
                </IconButton>
              </Tooltip>
              <Tooltip title="Settings">
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleConfiguratorOpen}
                >
                  <MDBox mb={0.3}>
                    <Icon sx={iconsStyle}>settings</Icon>
                  </MDBox>
                </IconButton>
              </Tooltip>
              </MDBox>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    profileData: state.profile,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getProfile: (email, jwtToken) => dispatch(getProfile(email, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(DashboardNavbar);
