/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getWarehouses,
  updateWarehouse,
  getSubModulesByRoleId,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const styleForStatus = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const WIPWarehouse = ({
  getWarehouses,
  warehouses,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  updateWarehouse,
}) => {
  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: "white",
          },
          colorPrimary: {
            "&.Mui-checked": {
              // Controls checked color for the thumb
              color: "rgb(25, 118, 210)",
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.5,
            backgroundColor: "grey",
            ".Mui-checked.Mui-checked + &": {
              // Controls checked color for the track
              opacity: 0.6,
              backgroundColor: "rgb(25, 118, 210)",
            },
          },
        },
      },
    },
  });

  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let warehouseModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "7f0945f6-2252-4a4e-8433-fae36bea97bd"
  );

  let viewAccess = warehouseModule
    ? warehouseModule.actionId.includes("fd0b8ae4-ec8b-422b-a72f-765166cc0548")
    : null;

  let updateAccess = warehouseModule
    ? warehouseModule.actionId.includes("62748768-3b3c-4136-be5d-622d99f5c92c")
    : null;

  let warehouseType = "d";

  useEffect(() => {
    getWarehouses(warehouseType, jwtToken);
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Warehouse Code", accessor: "warehouseCode", align: "left" },
      { Header: "Warehouse Name", accessor: "warehouse", align: "left" },
      {
        Header: "Status (Inactive/Active)",
        accessor: "status",
        align: "left",
        cannotSearch: true,
      },
      {
        Header: "Action",
        accessor: "action",
        align: "left",
        cannotSearch: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const navigate = useNavigate();

  const [isErrorStatusChange, setIsErrorStatusChange] = useState(false);

  useEffect(() => {
    if (isErrorStatusChange) {
      setTimeout(() => {
        setIsErrorStatusChange(false);
      }, 3000);
    }
  }, [isErrorStatusChange]);

  const [openWarehouseStatusModal, setOpenWarehouseStatusModal] =
    useState(false);

  const handleOpenWarehouseStatusModal = () => {
    setOpenWarehouseStatusModal(true);
  };
  const handleCloseWarehouseStatusModal = () => {
    setOpenWarehouseStatusModal(false);
  };

  const [singleWarehouseDate, setSingleWarehouseDate] = useState({});

  const updateStatus = async () => {
    singleWarehouseDate["isActive"] = !singleWarehouseDate.isActive;
    let res = await updateWarehouse(singleWarehouseDate, jwtToken);

    if (res.status) {
      await getWarehouses(warehouseType, jwtToken);
      handleCloseWarehouseStatusModal();
    }
    if (!res.status) {
      setIsErrorStatusChange(true);
    }
  };

  const [loading, setLoading] = useState(false);

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  useEffect(() => {
    let tempWarehouses = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];

    setErrorMsgFromApi(warehouses?.error?.data?.msg);

    setLoading(warehouses.loading);

    data.map((warehouse) => {
      const warehouseObj = {
        warehouseCode: warehouse.warehouseCode,
        warehouse: warehouse.warehouseName,
        status: (
          <ThemeProvider theme={theme}>
            <Switch
              disabled={!updateAccess}
              checked={warehouse.isActive === 1 ? true : false}
              onChange={() => {
                handleOpenWarehouseStatusModal();
                setSingleWarehouseDate(warehouse);
              }}
            />
          </ThemeProvider>
        ),
        action: (
          <div>
            {warehouse.isActive === 1 ? (
              <Tooltip title="View locations">
                <MDButton
                  type="button"
                  variant="gradient"
                  color="info"
                  iconOnly
                  disabled={!viewAccess}
                  style={{
                    marginRight: 5,
                  }}
                  onClick={() => {
                    navigate(`/${warehouse.type}/${warehouse.id}`);
                  }}
                >
                  <Icon> visibility_icon </Icon>
                </MDButton>
              </Tooltip>
            ) : (
              <Tooltip title="View locations Disabled">
                <MDButton
                  type="button"
                  variant="gradient"
                  style={{
                    background: "grey",
                    color: "white",
                    opacity: 0.5,
                    marginRight: 5,
                  }}
                  disabled
                  iconOnly
                >
                  <Icon> visibility_icon </Icon>
                </MDButton>
              </Tooltip>
            )}
          </div>
        ),
      };
      tempWarehouses.push(warehouseObj);
    });

    viewAccess && setState({ ...state, rows: tempWarehouses });
  }, [warehouses]);

  return (
    <div>
      <MDBox mt={-6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mt={-2}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  errorMsg={errorMsgFromApi}
                />
              </MDBox>
              <Modal
                open={openWarehouseStatusModal}
                onClose={handleCloseWarehouseStatusModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForStatus}>
                  <MDTypography>
                    Are you sure you want to change the status of this
                    warehouse?
                  </MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseWarehouseStatusModal}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{ width: "48%", marginLeft: "0.5rem" }}
                      color="success"
                      onClick={updateStatus}
                    >
                      Yes
                    </MDButton>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isErrorStatusChange}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorStatusChange(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Could not change the status of the Warehouse!
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    warehouses: state.warehouses,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getWarehouses: (warehouseType, jwtToken) =>
      dispatch(getWarehouses(warehouseType, jwtToken)),
    updateWarehouse: (payload, jwtToken) =>
      dispatch(updateWarehouse(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(WIPWarehouse);
