export const GET_SKUFORREPRINT_REQUEST = "GET_SKUFORREPRINT_REQUEST";
export const GET_SKUFORREPRINT_SUCCESS = "GET_SKUFORREPRINT_SUCCESS";
export const GET_SKUFORREPRINT_FAILURE = "GET_SKUFORREPRINT_FAILURE";

export const REPRINT_REQUEST = "REPRINT_REQUEST";
export const REPRINT_SUCCESS = "REPRINT_SUCCESS";
export const REPRINT_FAILURE = "REPRINT_FAILURE";

export const GET_SKUFORREPRINT_FORREPORTS_REQUEST =
  "GET_SKUFORREPRINT_FORREPORTS_REQUEST";
export const GET_SKUFORREPRINT_FORREPORTS_SUCCESS =
  "GET_SKUFORREPRINT_FORREPORTS_SUCCESS";
export const GET_SKUFORREPRINT_FORREPORTS_FAILURE =
  "GET_SKUFORREPRINT_FORREPORTS_FAILURE";
