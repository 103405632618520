/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDBox from "components/MDBox";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { addMinutes, format } from "date-fns";

// Functions from store
import { getReleasedPiv, getSubModulesByRoleId } from "../../../store";

// Config
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const ReleasedPiv = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getReleasedPiv,
  releasedPivReducer,
}) => {
  const desLength = Config.descriptionLength;

  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let releasedPivModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "34436b4c-9e2c-44a8-afe1-aa59659739cb"
  );

  let viewReleasedPiv = releasedPivModule
    ? releasedPivModule.actionId.includes(
        "000a2ecf-cec6-4e14-987b-2b02f9244961"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getReleasedPiv(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const statusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Not Picked",
      value: 0,
    },
    {
      label: "Picked",
      value: 1,
    },
    {
      label: "Delivered",
      value: 2,
    },
  ];

  const [state, setState] = useState({
    columns: [
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Bulk Code / Des", accessor: "bulkCode" },
      { Header: "Batch No / Qty", accessor: "batchNumber" },
      {
        Header: "Release Date",
        accessor: "cd",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        searchType: "select",
        selectOptions: statusOptions,
        placeholder: "All Status",
      },
      { Header: "User", accessor: "userName" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  const [locationsModal, setOpenLocationsModal] = useState(false);
  const [locationsData, setLocationsData] = useState([]);

  const handleOpenLocationsModal = () => {
    setOpenLocationsModal(true);
  };

  const handleCloseLocationsModal = () => {
    setOpenLocationsModal(false);
  };

  useEffect(() => {
    let tempPiv = [];

    let data = releasedPivReducer.releasedPivData.data
      ? releasedPivReducer.releasedPivData.data
      : [];

    setErrorMsgFromApi(releasedPivReducer?.error?.data?.msg);
    setPaginationObj(releasedPivReducer?.releasedPivData?.pagination);
    setLoading(releasedPivReducer.loading);

    data.map((piv) => {
      let bulkDes = piv?.bulkCodeDescription;
      let len = desLength;

      const pivObject = {
        documentNo: piv.documentNo,
        bulkCode: `${piv.bulkCode} / ${
          bulkDes ? bulkDes.substring(0, len) : ""
        }`,
        batchNumber: `${piv.batchNumber} / ${piv.batchQty} ${piv.batchUom}`,
        cd: piv.cd,
        status: (
          <button
            className={
              (piv.status === 0 && `status-error`) ||
              (piv.status === 1 && `status-warning`) ||
              (piv.status === 2 && `status-success`)
            }
            style={{ width: "5.5rem" }}
          >
            {(piv.status === 0 && `Not Picked`) ||
              (piv.status === 1 && `Picked`) ||
              (piv.status === 2 && `Delivered`)}
          </button>
        ),
        userName: piv.userName,
      };
      tempPiv.push(pivObject);
    });

    viewReleasedPiv && setState({ ...state, rows: tempPiv });
  }, [releasedPivReducer]);

  return (
    <>
      <MDBox mt={-6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mt={-2}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  errorMsg={errorMsgFromApi}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    releasedPivReducer: state.getReleasedPivReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getReleasedPiv: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getReleasedPiv(pageNo, rowsPerPage, searchObject, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(ReleasedPiv);
