/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

import { CSVLink } from "react-csv";

// Functions from store
import {
  getSubModulesByRoleId,
  getProductionProcess,
  getProductionProcessForReports,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";

// Config file
import Config from "../../../config/index";
import { addMinutes, format } from "date-fns";

let desLength = Config.descriptionLength;
const cookies = new Cookies();

const styleForEditExpiry = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForDownloadModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProductionProcessReport = ({
  getProductionProcess,
  productionProcessReducer,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getProductionProcessForReports,
  productionProcessReportsReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userId = loginDetails?.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let reportsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "07390a6c-3bf9-4474-9396-b7d6192eb805"
  );

  let viewReportsAccess = reportsModule
    ? reportsModule.actionId.includes("f94da251-d4c1-40a2-b5b9-1a7c1b783c59")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [loading, setLoading] = useState(true);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getProductionProcess(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const statusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Not Charged",
      value: 0,
    },
    {
      label: "Charged",
      value: 1,
    },
  ];

  const [state, setState] = useState({
    columns: [
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Batch No / Qty", accessor: "batchNumber" },
      { Header: "Phase No", accessor: "phaseNumber" },
      { Header: "Phase Des", accessor: "phaseDescription" },
      { Header: "Pick List ID", accessor: "pickupListCode" },
      { Header: "Bulk Code / Des", accessor: "finalMaterialCode" },
      { Header: "Item Code / Des", accessor: "materialCode" },
      { Header: "Item Qty", accessor: "qty" },
      { Header: "Resource Category", accessor: "resourceCategory" },
      { Header: "Resource", accessor: "resource" },
      { Header: "From Loc", accessor: "source" },
      { Header: "To Loc", accessor: "destination" },
      {
        Header: "Start Time",
        accessor: "startTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "End TIme",
        accessor: "endTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        searchType: "select",
        selectOptions: statusOptions,
        placeholder: "All Status",
      },
      { Header: "User", accessor: "userName" },
      { Header: "Operator", accessor: "operator" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  useEffect(() => {
    let tempProductionProcess = [];

    let data = productionProcessReducer.process.data
      ? productionProcessReducer.process.data
      : [];

    // setErrorMsgFromApi(productionProcessReducer?.error?.data?.msg);
    setLoading(productionProcessReducer.loading);
    setPaginationObj(productionProcessReducer?.process?.pagination);

    data.map((piv, index) => {
      let bulkDes = piv?.finalMaterialDescription;
      let itemDes = piv.materialDescription;
      let phaseDes = piv.phaseDescription;
      let len = desLength;

      const pivObject = {
        privateKey: piv.pickupListCode,
        documentNo: piv.documentNo,
        batchNumber: `${piv.batchNumber} / ${piv.batchSize} ${piv.batchUom}`,
        finalMaterialCode: `${piv.finalMaterialCode} / ${
          bulkDes ? bulkDes.substring(0, len) : ""
        }`,
        materialCode: `${piv.materialCode} / ${
          itemDes ? itemDes.substring(0, len) : ""
        }`,
        phaseNumber: piv.phaseNumber,
        pickupListCode: piv.pickupListCode,
        phaseDescription: phaseDes ? phaseDes.substring(0, len) : "",
        qty: `${piv.qty} ${piv.uom}`,
        resourceCategory: piv.resourceCategory,
        resource: piv.resource,
        source: piv.source,
        destination: piv.destination,
        startTime: piv.startTime,
        endTime: piv.endTime,
        status: (
          <button
            className={
              (piv.status === 0 && `status-error`) ||
              (piv.status === 1 && `status-success`)
            }
            style={{ width: "5.5rem" }}
          >
            {(piv.status === 0 && `Not Charged`) ||
              (piv.status === 1 && `Charged`)}
          </button>
        ),
        userName: piv.userName,
        operator: piv.operator,
      };
      tempProductionProcess.push(pivObject);
    });

    viewReportsAccess && setState({ ...state, rows: tempProductionProcess });
  }, [productionProcessReducer]);

  const [loadingData, setLoadingData] = useState(false);
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    let tempReports = [];
    let data = productionProcessReportsReducer.process.data
      ? productionProcessReportsReducer.process.data
      : [];

    data.map((piv, index) => {
      let bulkDes = piv?.finalMaterialDescription;
      let itemDes = piv.materialDescription;
      let phaseDes = piv.phaseDescription;
      let len = desLength;

      const formatDateStartTime = new Date(piv.startTime);
      const formatDateEndTime = new Date(piv.endTime);

      const pivObject = {
        privateKey: piv.pickupListCode,
        documentNo: piv.documentNo,
        batchNumber: `${piv.batchNumber} / ${piv.batchSize} ${piv.batchUom}`,
        finalMaterialCode: `${piv.finalMaterialCode} / ${
          bulkDes ? bulkDes.substring(0, len) : ""
        }`,
        materialCode: `${piv.materialCode} / ${
          itemDes ? itemDes.substring(0, len) : ""
        }`,
        phaseNumber: piv.phaseNumber,
        pickupListCode: piv.pickupListCode,
        phaseDescription: phaseDes ? phaseDes.substring(0, len) : "",
        qty: `${piv.qty} ${piv.uom}`,
        resourceCategory: piv.resourceCategory,
        resource: piv.resource,
        source: piv.source,
        destination: piv.destination,
        startTime: piv.startTime
          ? format(
              addMinutes(
                formatDateStartTime,
                formatDateStartTime.getTimezoneOffset()
              ),
              "dd/MM/yyyy, HH:mm"
            )
          : "",
        endTime: piv.endTime
          ? format(
              addMinutes(
                formatDateEndTime,
                formatDateEndTime.getTimezoneOffset()
              ),
              "dd/MM/yyyy, HH:mm"
            )
          : "",
        status: piv.status === 0 ? "Not Charged" : "Charged",
        userName: piv.userName,
        operator: piv.operator,
      };
      tempReports.push(pivObject);
    });
    setDownloadData(tempReports);
  }, [productionProcessReportsReducer]);

  const headers = [
    { label: "Document No", key: "documentNo" },
    { label: "Batch No / Qty", key: "batchNumber" },
    { label: "Phase No", key: "phaseNumber" },
    { label: "Phase Des", key: "phaseDescription" },
    { label: "Pick List ID", key: "pickupListCode" },
    { label: "Bulk Code / Des", key: "finalMaterialCode" },
    { label: "Item Code / Des", key: "materialCode" },
    { label: "Item Qty", key: "qty" },
    { label: "Resource Category", key: "resourceCategory" },
    { label: "Resource", key: "resource" },
    { label: "From Loc", key: "source" },
    { label: "To Loc", key: "destination" },
    { label: "Start Time", key: "startTime", searchType: "date" },
    { label: "End TIme", key: "endTime", searchType: "date" },
    {
      label: "Status",
      key: "status",
    },
    { label: "User", key: "userName" },
    { label: "Operator", key: "operator" },
  ];

  const csvLink = {
    filename: "Production Process Report.csv",
    headers: headers,
    data: downloadData,
  };

  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [disableDownloadButton, setDisableDownloadButton] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const handleOpenDownloadModal = (data = {}) => {
    setOpenDownloadModal(true);
  };

  const handleCloseDownloadModal = () => {
    setDisableDownloadButton(false);
    setOpenDownloadModal(false);
  };

  const downloadHandler = async () => {
    setLoadingData(true);

    let res = await getProductionProcessForReports(
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    );

    if (res.status) {
      setModalMsg("Ready for download!");
      setLoadingData(false);
      handleOpenDownloadModal();
    }
    if (!res.status) {
      setModalMsg("Could not prepare the data for download!");
      setLoadingData(false);
      setDisableDownloadButton(true);
      handleOpenDownloadModal();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {viewReportsAccess && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  // mb={-6}
                >
                  <MDBox color="text">
                    <Tooltip title="Download">
                      <MDButton
                        style={{ marginRight: "1rem", marginLeft: "10px" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        iconOnly
                        onClick={() => {
                          downloadHandler();
                        }}
                      >
                        <Icon>download</Icon>
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              )}

              <MDBox mt={viewReportsAccess ? -3 : 0}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>

              <Modal
                open={openDownloadModal}
                onClose={handleCloseDownloadModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForDownloadModal}>
                  <MDTypography>{`${
                    disableDownloadButton
                      ? "Could not prepare the data for download!"
                      : "Ready for download!"
                  }`}</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDownloadModal}
                    >
                      Cancel
                    </MDButton>
                    {!disableDownloadButton && (
                      <CSVLink {...csvLink}>
                        <MDButton
                          style={{ width: "48%", marginRight: "0.5rem" }}
                          type="button"
                          variant="outlined"
                          color="error"
                        >
                          Download
                        </MDButton>
                      </CSVLink>
                    )}
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {loadingData && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <MDTypography color="warning" variant="h2">
            Preparing Data. Please wait...
          </MDTypography>
        </Backdrop>
      )}
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    productionProcessReducer: state.getProductionProcessReducer,
    productionProcessReportsReducer:
      state.getProductionProcessForReportsReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getProductionProcess: (pageNo, rowsPerPage, search, jwtToken) =>
      dispatch(getProductionProcess(pageNo, rowsPerPage, search, jwtToken)),
    getProductionProcessForReports: (pageNo, rowsPerPage, search, jwtToken) =>
      dispatch(
        getProductionProcessForReports(pageNo, rowsPerPage, search, jwtToken)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(ProductionProcessReport);
