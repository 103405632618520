import {
  GET_INBOUNDREPORTS_REQUEST,
  GET_INBOUNDREPORTS_SUCCESS,
  GET_INBOUNDREPORTS_FAILURE,
} from "./inboundTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getInboundReportsRequest = () => {
  return {
    type: GET_INBOUNDREPORTS_REQUEST,
  };
};

const getInboundReportsSuccess = (payload) => {
  return {
    type: GET_INBOUNDREPORTS_SUCCESS,
    payload: payload,
  };
};

const getInboundReportsFailure = (error) => {
  return {
    type: GET_INBOUNDREPORTS_FAILURE,
    payload: error,
  };
};

const getInboundReports = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getInboundReportsRequest());
    let url = `${baseUrl}/reports/inbound_report`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getInboundReportsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getInboundReportsFailure(err.response));
      });
  };
};

export { getInboundReports };
