/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getAddToStore,
  addToStore,
} from "../../../store";

import { addMinutes, format } from "date-fns";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../../config/index";
import { Icon } from "@mui/material";

const desLength = Config.descriptionLength;

const cookies = new Cookies();

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const AddToStore = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getAddToStore,
  getAddToStoreReducer,
  addToStore,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let userId = loginDetails?.data.userId;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let addToStoreModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "b92447b0-645d-4a7a-846f-47cf351d33fd"
  );

  let viewAccess = addToStoreModule
    ? addToStoreModule.actionId.includes("16400e0c-aba0-4c7f-b3a1-4f754f677091")
    : null;

  let addToStoreAccess = addToStoreModule
    ? addToStoreModule.actionId.includes("94678e1d-2f61-4fd7-8c45-4e823a89ff83")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getAddToStore(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const expiryStatusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Not Expired",
      value: 0,
    },
    {
      label: "Expired",
      value: 1,
    },
  ];

  const [state, setState] = useState({
    columns: [
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Item Code", accessor: "materialCode" },
      { Header: "Description", accessor: "materialDescription" },
      { Header: "Batch No", accessor: "batchNumber" },
      { Header: "Qty", accessor: "totalQty" },
      { Header: "UID", accessor: "sku" },
      {
        Header: "Expiry",
        accessor: "shelfLife",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Grn Date",
        accessor: "grnDate",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value)?.getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Expiry Status",
        accessor: "isExpired",
        searchType: "select",
        selectOptions: expiryStatusOptions,
        placeholder: "All Status",
        align: "center",
      },

      { Header: "Warehouse", accessor: "warehouseCode" },

      { Header: "Asset", accessor: "asset" },
      {
        Header: "Action",
        accessor: "action",
        align: "left",
        cannotSearch: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [loading, setLoading] = useState(false);

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [addToStoreErrorMsg, setAddToStoreErrorMsg] = useState("");

  useEffect(() => {
    let tempAddToStore = [];

    let data = getAddToStoreReducer.addToStore.data
      ? getAddToStoreReducer.addToStore.data
      : [];

    setErrorMsgFromApi(getAddToStoreReducer?.error?.data?.msg);
    setLoading(getAddToStoreReducer.loading);
    setPaginationObj(getAddToStoreReducer?.addToStore?.pagination);

    data.map((singleData, index) => {
      let des = singleData?.materialDescription;
      let len = desLength;

      let validation =
        singleData.isBatchManaged === 0 && singleData.isExpired === 1;

      const dataObject = {
        documentType:
          singleData.isExpired === 1 ? (
            <button className="status-error" style={{ width: "5.5rem" }}>
              {singleData.documentType}
            </button>
          ) : (
            singleData.documentType
          ),
        documentNo: singleData.documentNo,
        batchNumber: singleData.batchNumber,
        reValidation: singleData.reValidation,
        materialCode: singleData.materialCode,
        materialDescription: des ? des.substring(0, len) : "",
        totalQty: `${singleData.totalQty} ${singleData.uom}`,
        sku: singleData.sku,
        shelfLife: singleData.shelfLife,
        grnDate: singleData?.grnDate,
        reprintCount: singleData?.reprintCount,
        isExpired: (
          <span style={{ display: "flex" }}>
            <button
              className={
                (singleData.isExpired === 1 && `status-error`) ||
                (singleData.isExpired === 0 && `status-success`)
              }
              style={{
                width: "5.5rem",
                marginRight: "1rem",
                // marginLeft: "2rem",
              }}
            >
              {(singleData.isExpired === 1 && `Expired`) ||
                (singleData.isExpired === 0 && `Not Expired`)}
            </button>
          </span>
        ),
        indicator: singleData.indicator,
        warehouseCode: singleData.warehouseCode,
        zoneName: singleData.zoneName,
        locationName: singleData.locationName,
        asset: singleData.asset,
        stockType: singleData.stockType,
        action: (
          <Tooltip title="Add To Store">
            <MDButton
              disabled={!addToStoreAccess}
              color="success"
              variant="gradient"
              type="button"
              iconOnly
              size="small"
              onClick={() => {
                handleOpenAddToStoreModal(singleData);
              }}
            >
              <Icon> forward </Icon>
            </MDButton>
          </Tooltip>
        ),
      };
      tempAddToStore.push(dataObject);
    });

    viewAccess && setState({ ...state, rows: tempAddToStore });
  }, [getAddToStoreReducer]);

  const [selectedPivData, setSelectedPivData] = useState([]);

  const [isErrorAddToStore, setIsErrorAddToStore] = useState(false);
  const [isSuccessAddToStore, setIsSuccessAddToStore] = useState(false);

  useEffect(() => {
    if (isErrorAddToStore) {
      setTimeout(() => {
        setIsErrorAddToStore(false);
      }, 3000);
    }
  }, [isErrorAddToStore]);

  useEffect(() => {
    if (isSuccessAddToStore) {
      setTimeout(() => {
        setIsSuccessAddToStore(false);
      }, 3000);
    }
  }, [isSuccessAddToStore]);

  const [openAddToStoreModal, setOpenAddToStoreModal] = useState(false);

  const handleOpenAddToStoreModal = (data) => {
    setSelectedPivData(data);
    setOpenAddToStoreModal(true);
  };
  const handleCloseAddToStoreModal = () => {
    setOpenAddToStoreModal(false);
  };

  const addToStoreHandler = async () => {
    let payload = { sku: selectedPivData?.sku };
    let response = await addToStore(payload, jwtToken);

    if (response.status) {
      getAddToStore(pageNo, rowsPerPage, searchObject, jwtToken);
      setIsSuccessAddToStore(true);

      setTimeout(() => {
        handleCloseAddToStoreModal();
      }, 2250);
    }
    if (!response.status) {
      setAddToStoreErrorMsg(response?.data?.data?.message);
      setIsErrorAddToStore(true);
    }
  };

  return (
    <div>
      <MDBox mt={-6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mt={-2}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  errorMsg={errorMsgFromApi}
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>

              <Modal
                open={openAddToStoreModal}
                onClose={handleCloseAddToStoreModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>
                    Are you sure you want to add to store ?
                  </MDTypography>
                  <MDBox mt={3} display="flex">
                    <div>
                      <MDButton
                        style={{ width: "100%", marginRight: "0.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseAddToStoreModal}
                      >
                        Cancel
                      </MDButton>
                    </div>
                    <div style={{ float: "right" }}>
                      <MDButton
                        style={{ width: "100%", marginLeft: "0.5rem" }}
                        color="success"
                        onClick={addToStoreHandler}
                      >
                        Yes
                      </MDButton>
                    </div>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessAddToStore}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessAddToStore(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Added to store successfully!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorAddToStore}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorAddToStore(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {/* Could not Release For Issuance! */}
                        {addToStoreErrorMsg}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getAddToStoreReducer: state.getAddToStoreReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getAddToStore: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getAddToStore(pageNo, rowsPerPage, searchObject, jwtToken)),
    addToStore: (payload, jwtToken) => dispatch(addToStore(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToStore);
