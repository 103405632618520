import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import TimelineItem from "examples/Timeline/TimelineItem";

// Functions from store
import {
  getAllCategories,
  getZonesByCategoryAndWarehouse,
  getLocationsByWarehouse,
  getLocationsByWarehouseAndZone,
  addLocationToWarehouse,
  deleteLocation,
  getItemByLocation,
  getWarehouseById,
  getZonesByWarehouse,
  deleteZone,
  addZoneToWarehouse,
  getSubModulesByRoleId,
} from "../../store";

// Router Components
import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { useFormik } from "formik";
import * as Yup from "yup";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

import Config from "../../config/index";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Utils component
import TextError from "utils/TextError";

import { addMinutes, format } from "date-fns";

// Cookies
import Cookies from "universal-cookie";

const desLength = Config.descriptionLength;
const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const styleForZoneModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 800, lg: 1200, xl: 1200 },
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const ViewLocation = ({
  getAllCategories,
  allCategories,
  getZonesByCategoryAndWarehouse,
  zonesByCategoryAndWarehouse,
  getLocationsByWarehouse,
  getLocationsByWarehouseAndZone,
  locationsByWarehouseAndZone,
  locationsByWarehouse,
  addLocationToWarehouse,
  deleteLocation,
  getItemByLocation,
  item,
  getWarehouseById,
  warehouseById,
  getZonesByWarehouse,
  zonesByWarehouse,
  deleteZone,
  addZoneToWarehouse,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  const navigate = useNavigate();

  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let zoneModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "4382e974-d4e7-4a98-acd0-316c8e413616"
  );

  let locationModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "ffc4a456-d723-4870-9d80-0b35b53ee055"
  );

  let locationModuleCreateAccess = locationModule
    ? locationModule.actionId.includes("f6ef2aee-8beb-49f1-a273-5bc44ce34d3e")
    : null;

  let locationModuleViewAccess = locationModule
    ? locationModule.actionId.includes("04f41675-54d2-4b45-9b6f-363d8de0b419")
    : null;

  let locationModuleDeleteAccess = locationModule
    ? locationModule.actionId.includes("3bcbc8a2-0965-4eaf-93b1-3bb529b00882")
    : null;

  let zoneModuleCreateAccess = zoneModule
    ? zoneModule.actionId.includes("9bb9c257-7e5a-48b6-8279-6d2ae497d1b8")
    : null;

  let zoneModuleViewAccess = zoneModule
    ? zoneModule.actionId.includes("17771f6b-4fba-41c1-a07a-e1a61c3be66b")
    : null;

  let zoneModuleDeleteAccess = zoneModule
    ? zoneModule.actionId.includes("f0661ff0-c138-4e7f-8c9b-74bf6cb60e70")
    : null;

  const { warehouseType, warehouseId } = useParams();

  const [zoneIdSelected, setZoneIdSelected] = useState("");

  const handleZoneIdChange = (selectedZone) => {
    setZoneIdSelected(selectedZone);
  };

  const [zoneTypeSelected, setZoneTypeSelected] = useState("");

  const handleZoneTypeChange = (selectedZoneType) => {
    setZoneTypeSelected(selectedZoneType);
  };

  const [locationSelected, setLocationSelected] = useState("");

  const handleLocationChange = (selectedOption) => {
    setLocationSelected(selectedOption);
  };

  const [warehouseLocations, setWarehouseLocations] = useState([]);

  let loading = locationsByWarehouse.loading;

  useEffect(() => {
    getLocationsByWarehouse(warehouseId, "", jwtToken);
  }, [warehouseId]);

  useEffect(() => {
    locationModuleViewAccess &&
      setWarehouseLocations(locationsByWarehouse.locations);
  }, [locationsByWarehouse]);

  useEffect(() => {
    getZonesByWarehouse(warehouseId, jwtToken);
  }, [warehouseId]);

  useEffect(() => {
    getWarehouseById(warehouseId, jwtToken);
  }, [warehouseId]);

  const [warehouseNameHeading, setWarehouseNameHeading] = useState("");

  useEffect(() => {
    setWarehouseNameHeading(warehouseById.warehouse[0]?.warehouseName);
  }, [warehouseById]);

  const [warehouseCodeHeading, setWarehouseCodeHeading] = useState("");

  useEffect(() => {
    setWarehouseCodeHeading(warehouseById.warehouse[0]?.warehouseCode);
  }, [warehouseById]);

  const [totalLocations, setTotalLocations] = useState("");

  useEffect(() => {
    setTotalLocations(warehouseById.warehouse[0]?.totalLocations);
  }, [warehouseById]);

  const [zonesOptions, setZonesOptions] = useState([]);

  useEffect(() => {
    let tempZoneOptions = [];

    let data = zonesByWarehouse.zones.data ? zonesByWarehouse.zones.data : [];

    data.map((zone) => {
      tempZoneOptions.push({
        label: zone.zoneName,
        value: zone.id,
      });
    });

    setZonesOptions(tempZoneOptions);
  }, [zonesByWarehouse]);

  const [isSuccessAddLocation, setIsSuccessAddLocation] = useState(false);
  const [isErrorAddLocation, setIsErrorAddLocation] = useState(false);

  useEffect(() => {
    if (isErrorAddLocation) {
      setTimeout(() => {
        setIsErrorAddLocation(false);
      }, 3000);
    }
  }, [isErrorAddLocation]);

  useEffect(() => {
    if (isSuccessAddLocation) {
      setTimeout(() => {
        setIsSuccessAddLocation(false);
      }, 3000);
    }
  }, [isSuccessAddLocation]);

  const [addLocationModal, setAddLocationModal] = useState(false);
  const handleAddLocationModal = () => {
    setZoneIdSelected("");
    getZonesByWarehouse(warehouseId, jwtToken);
    setAddLocationModal(!addLocationModal);
    formik.setErrors({});
  };

  const initialValue = {
    zoneName: "",
    location: "",
  };

  const validationSchema = Yup.object({
    zoneName: Yup.string().required("Select the Zone"),
    location: Yup.string().required("Enter the Location"),
  });

  const [addLocationErrorMsg, setAddLocationErrorMsg] = useState("");

  const onSubmitForAddLocation = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    const payload = {
      warehouseId: warehouseId,
      zoneId: zoneIdSelected.value,
      locationName: values.location,
    };

    let res = await addLocationToWarehouse(payload, jwtToken);

    if (res.status) {
      setIsSuccessAddLocation(true);
      getZonesByWarehouse(warehouseId, jwtToken);
      getLocationsByWarehouse(warehouseId, "", jwtToken);
      setZoneIdSelected("");
      setTimeout(() => {
        setButtonName("Submit");
      }, 2250);
    }
    if (!res.status) {
      setAddLocationErrorMsg(res.data.data.message);
      setIsErrorAddLocation(true);
      setButtonName("Submit");
    }
    resetForm();
  };

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: onSubmitForAddLocation,
    validationSchema: validationSchema,
  });

  const initialValueForDelete = {
    zoneName: "",
    location: "",
  };

  const validationSchemaForDelete = Yup.object({
    // zoneName: Yup.string().required("Select the Zone"),
    // location: Yup.string().required("Select the Location"),
  });

  const [deleteLocationErrorMsg, setDeleteLocationErrorMsg] = useState("");

  const onSubmitForDeleteLocation = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let res = await deleteLocation(locationSelected.value, jwtToken);

    if (res.status) {
      setIsSuccessDeleteLocation(true);
      getLocationsByWarehouse(warehouseId, "", jwtToken);
      getLocationsByWarehouseAndZone(
        warehouseId,
        zoneIdSelected.value,
        jwtToken
      );
      getZonesByWarehouse(warehouseId, jwtToken);
      setZoneIdSelected("");
      setLocationSelected("");
      setTimeout(() => {
        setButtonName("Submit");
      }, 2250);
    }
    if (!res.status) {
      setDeleteLocationErrorMsg(res.data.data.message);
      setIsErrorDeleteLocation(true);
      setButtonName("Submit");
    }
    resetForm();
  };

  const formikForDelete = useFormik({
    initialValues: initialValueForDelete,
    onSubmit: onSubmitForDeleteLocation,
    validationSchema: validationSchemaForDelete,
  });

  const [isSuccessDeleteLocation, setIsSuccessDeleteLocation] = useState(false);
  const [isErrorDeleteLocation, setIsErrorDeleteLocation] = useState(false);

  useEffect(() => {
    if (isErrorDeleteLocation) {
      setTimeout(() => {
        setIsErrorDeleteLocation(false);
      }, 3000);
    }
  }, [isErrorDeleteLocation]);

  useEffect(() => {
    if (isSuccessDeleteLocation) {
      setTimeout(() => {
        setIsSuccessDeleteLocation(false);
      }, 3000);
    }
  }, [isSuccessDeleteLocation]);

  const [deleteLocationModal, setDeleteLocationModal] = useState(false);
  const handleDeleteLocationModal = () => {
    getZonesByWarehouse(warehouseId, jwtToken);
    setDeleteLocationModal(!deleteLocationModal);
  };

  const [locationsOptions, setLocationsOptions] = useState([]);

  useEffect(() => {
    getLocationsByWarehouseAndZone(
      warehouseId,
      zoneIdSelected?.value,
      jwtToken
    );
  }, [warehouseId, zoneIdSelected?.value]);

  useEffect(() => {
    let tempLocationsOptions = [];
    let data = locationsByWarehouseAndZone.locations
      ? locationsByWarehouseAndZone.locations
      : [];

    data.map((zone) => {
      zone.locations.map((loc) => {
        tempLocationsOptions.push({
          label: loc.locationName,
          value: loc.locationId,
        });
      });
    });
    setLocationsOptions(tempLocationsOptions);
  }, [locationsByWarehouseAndZone, zoneIdSelected?.value]);

  const [isSuccessAddZone, setIsSuccessAddZone] = useState(false);
  const [isErrorAddZone, setIsErrorAddZone] = useState(false);

  useEffect(() => {
    if (isErrorAddZone) {
      setTimeout(() => {
        setIsErrorAddZone(false);
      }, 3000);
    }
  }, [isErrorAddZone]);

  useEffect(() => {
    if (isSuccessAddZone) {
      setTimeout(() => {
        setIsSuccessAddZone(false);
      }, 3000);
    }
  }, [isSuccessAddZone]);

  const [buttonName, setButtonName] = useState("Submit");

  const [openAddZoneModal, setOpenAddZoneModal] = useState(false);

  const handleAddZoneModal = () => {
    setOpenAddZoneModal(!openAddZoneModal);
  };

  const initialValuesForAddZone = {
    zoneName: "",
  };

  const validationSchemaForAddZone = Yup.object({
    zoneName: Yup.string().required("Enter the Zone"),
  });

  const [addZoneErrorMsg, setAddZoneErrorMsg] = useState("");

  const onSubmitForAddZone = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    const payload = {
      warehouseId: warehouseId,
      zoneName: values.zoneName,
    };

    let res = await addZoneToWarehouse(payload, jwtToken);

    if (res.status) {
      setIsSuccessAddZone(true);
      getZonesByWarehouse(warehouseId, jwtToken);
      getLocationsByWarehouse(warehouseId, "", jwtToken);
      setTimeout(() => {
        setButtonName("Submit");
      }, 2250);
    }
    if (!res.status) {
      setAddZoneErrorMsg(res.data.data.message);
      setIsErrorAddZone(true);
      setButtonName("Submit");
    }
    resetForm();
  };

  const formikForAddZone = useFormik({
    initialValues: initialValuesForAddZone,
    onSubmit: onSubmitForAddZone,
    validationSchema: validationSchemaForAddZone,
  });

  const [isSuccessDeleteZone, setIsSuccessDeleteZone] = useState(false);
  const [isErrorDeleteZone, setIsErrorDeleteZone] = useState(false);

  useEffect(() => {
    if (isErrorDeleteZone) {
      setTimeout(() => {
        setIsErrorDeleteZone(false);
      }, 3000);
    }
  }, [isErrorDeleteZone]);

  useEffect(() => {
    if (isSuccessDeleteZone) {
      setTimeout(() => {
        setIsSuccessDeleteZone(false);
      }, 3000);
    }
  }, [isSuccessDeleteZone]);

  const [openDeleteZoneModal, setOpenDeleteZoneModal] = useState(false);

  const handleDeleteZoneModal = () => {
    setOpenDeleteZoneModal(!openDeleteZoneModal);
  };

  const initialValueForDeleteZone = {
    zoneName: "",
  };

  const [deleteZoneErrorMsg, setDeleteZoneErrorMsg] = useState("");

  const onSubmitForDeleteZone = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let res = await deleteZone(zoneIdSelected.value, jwtToken);

    if (res.status) {
      setIsSuccessDeleteZone(true);
      getLocationsByWarehouse(warehouseId, "", jwtToken);
      getLocationsByWarehouseAndZone(
        warehouseId,
        zoneIdSelected.value,
        jwtToken
      );
      getZonesByWarehouse(warehouseId, jwtToken);
      setZoneIdSelected("");
      setTimeout(() => {
        setButtonName("Submit");
      }, 2250);
    }
    if (!res.status) {
      setDeleteZoneErrorMsg(res.data.data.message);
      setIsErrorDeleteZone(true);
      setButtonName("Submit");
    }
    resetForm();
  };

  const formikForDeleteZone = useFormik({
    initialValues: initialValueForDeleteZone,
    onSubmit: onSubmitForDeleteZone,
  });

  const [filterButtonName, setFilterButtonName] = useState("Filter");

  const [isSuccessFilter, setIsSuccessFilter] = useState(false);
  const [isErrorFilter, setIsErrorFilter] = useState(false);

  useEffect(() => {
    if (isErrorFilter) {
      setTimeout(() => {
        setIsErrorFilter(false);
      }, 3000);
    }
  }, [isErrorFilter]);

  useEffect(() => {
    if (isSuccessFilter) {
      setTimeout(() => {
        setIsSuccessFilter(false);
      }, 3000);
    }
  }, [isSuccessFilter]);

  const [filterModal, setFilterModal] = useState(false);
  const handleFilterModal = () => {
    setFilterModal(!filterModal);
  };

  const initialValueForFilter = {
    zone: "",
  };

  const filterButtonClick = () => {
    if (filterButtonName === "Reset Filter") {
      getLocationsByWarehouse(warehouseId, "", jwtToken);
      setFilterButtonName("Filter");
    }

    if (filterButtonName === "Filter") {
      setFilterModal(!filterModal);
    }
  };

  const onSubmitForFilter = (values, { resetForm }) => {
    getLocationsByWarehouse(warehouseId, zoneIdSelected.value, jwtToken);
    setFilterButtonName("Reset Filter");
    setFilterModal(false);
    setZoneIdSelected("");
    resetForm();
  };

  const formikForFilter = useFormik({
    initialValues: initialValueForFilter,
    onSubmit: onSubmitForFilter,
    // validationSchema: validationSchema,
  });

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [itemDetailsModal, setOpenItemDetailsModal] = useState(false);

  const handleOpenItemDetailsModal = () => {
    setOpenItemDetailsModal(!itemDetailsModal);
  };

  const [itemTable, setItemTable] = useState({
    columns: [
      {
        Header: "Material Code / Desc",
        accessor: "materialCode",
        align: "left",
      },
      { Header: "GRN", accessor: "grnNumber", align: "left" },
      { Header: "ASN", accessor: "asnNumber", align: "left" },
      {
        Header: "Putaway Time",
        accessor: "putawayEndTime",
        align: "left",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      { Header: "Pallet / UID", accessor: "palletId", align: "left" },
      {
        Header: "Batch No / Pack Size",
        accessor: "batchNumber",
        align: "left",
      },
      { Header: "Total Packs / Qty", accessor: "totalQty", align: "left" },
    ],
    rows: [],
  });

  const { columns, rows } = itemTable;

  const [fgDetailsModal, setOpenFgDetailsModal] = useState(false);

  const handleOpenFgDetailsModal = () => {
    setOpenFgDetailsModal(!fgDetailsModal);
  };

  const [fgTable, setFgTable] = useState({
    columns: [
      { Header: "FG Code", accessor: "fgCode", align: "left" },
      { Header: "VIN", accessor: "vin", align: "left" },
    ],
    rows: [],
  });

  const { columns: pColumns, rows: pRows } = fgTable;

  const [tableHeading, setTableHeading] = useState("");
  const [zoneTableHeading, setZoneTableHeading] = useState("");

  const [locationId, setLocationId] = useState(0);
  const [zoneId, setZoneId] = useState("");

  useEffect(() => {
    getItemByLocation(locationId, pageNo, rowsPerPage, searchObject, jwtToken);
  }, [locationId, pageNo, rowsPerPage, searchObject]);
  let loading2 = item.loading;
  useEffect(() => {
    let tempItem = [];
    let data = item.item.data ? item.item.data : [];

    setPaginationObj(item?.item?.pagination);

    data.map((item) => {
      let des = item?.materialDescription;
      let len = desLength;

      const itemData = {
        materialCode: `${item.materialCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        // grnAsn: `${item.grnNumber} / ${item.asnNumber}`,
        grnNumber: item.grnNumber,
        asnNumber: item.asnNumber,
        palletId: `${item.palletId} / ${item.sku}`,
        batchNumber: `${item.batchNumber} / ${item.packSize} ${item.uom}`,
        totalQty: `${item.totalPacks} / ${item.totalQty} ${item.uom}`,
        putawayEndTime: item.putawayEndTime,
      };
      tempItem.push(itemData);
    });
    setItemTable({ ...itemTable, rows: tempItem });
  }, [item]);

  return (
    <DashboardLayout>
      <DashboardNavbar previousPage={true} />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {!loading ? (
              <Card>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="left"
                  p={3}
                  mb={-1}
                >
                  <MDBox display="flex">
                    <MDTypography
                      variant="h4"
                      gutterBottom
                      style={{ marginRight: "50px" }}
                    >
                      {warehouseNameHeading} - {warehouseCodeHeading}
                    </MDTypography>

                    <MDTypography variant="h4" gutterBottom>
                      Total Locations - {totalLocations}
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDBox
                      display="flex"
                      flexDirection={{
                        xs: "column",
                        sm: "column",
                        md: "row",
                        lg: "row",
                        xl: "row",
                      }}
                    >
                      {zoneModuleCreateAccess && (
                        <Tooltip title="Add Zone">
                          <MDButton
                            color="info"
                            variant="outlined"
                            type="button"
                            onClick={handleAddZoneModal}
                            // disabled={!zoneModuleCreateAccess}
                            style={{
                              width: "110px",
                              height: "10px",
                              margin: "5px",
                              marginLeft: {
                                xs: 10,
                                sm: 10,
                                md: 0,
                                lg: 0,
                                xl: 0,
                              },
                            }}
                          >
                            Add Zone
                          </MDButton>
                        </Tooltip>
                      )}
                      {zoneModuleDeleteAccess && (
                        <Tooltip title="Delete Zone">
                          <MDButton
                            color="error"
                            variant="outlined"
                            type="button"
                            onClick={handleDeleteZoneModal}
                            // disabled={!zoneModuleDeleteAccess}
                            style={{
                              width: "130px",
                              height: "10px",
                              margin: "5px",
                            }}
                          >
                            Delete Zone
                          </MDButton>
                        </Tooltip>
                      )}
                      {locationModuleCreateAccess && (
                        <Tooltip title="Add Location">
                          <MDButton
                            color="info"
                            variant="outlined"
                            type="button"
                            onClick={handleAddLocationModal}
                            // disabled={!locationModuleCreateAccess}
                            style={{
                              width: "140px",
                              height: "10px",
                              margin: "5px",
                            }}
                          >
                            Add Location
                          </MDButton>
                        </Tooltip>
                      )}
                      {locationModuleDeleteAccess && (
                        <Tooltip title="Delete Location">
                          <MDButton
                            color="error"
                            variant="outlined"
                            type="button"
                            onClick={handleDeleteLocationModal}
                            // disabled={!locationModuleDeleteAccess}
                            style={{
                              width: "140px",
                              height: "10px",
                              margin: "5px",
                            }}
                          >
                            Delete Location
                          </MDButton>
                        </Tooltip>
                      )}
                      {zoneModuleViewAccess && (
                        <Tooltip title="Filter">
                          <MDButton
                            color="secondary"
                            variant="contained"
                            type="button"
                            onClick={filterButtonClick}
                            // disabled={!zoneModuleViewAccess}
                            style={{
                              width: "100px",
                              height: "10px",
                              margin: "5px",
                            }}
                          >
                            {filterButtonName}
                            <Icon> filter_alt_icon </Icon>
                          </MDButton>
                        </Tooltip>
                      )}
                      <MDBox marginLeft="100px" display="flex">
                        <MDButton
                          variant="outlined"
                          type="button"
                          style={{
                            cursor: "auto",
                            margin: "10px",
                            float: "right",
                            marginRight: "1rem",
                            marginLeft: "1rem",
                            backgroundColor: "#28B463",
                            color: "white",
                          }}
                        >
                          Empty
                        </MDButton>
                        <MDButton
                          variant="contained"
                          type="button"
                          style={{
                            float: "right",
                            margin: "10px",
                            cursor: "auto",
                            backgroundColor: "#B03A2E",
                            color: "white",
                          }}
                        >
                          Occupied
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>

                <Grid mb={2}>
                  {warehouseLocations.length !== 0 ? (
                    warehouseLocations?.map((zones) => (
                      <Grid>
                        <MDTypography
                          variant="h5"
                          style={{ marginTop: "1.5rem", marginLeft: "20px" }}
                        >
                          Zone:-{" "}
                          <span style={{ fontWeight: "500" }}>
                            {zones.zoneName}
                          </span>{" "}
                          &nbsp; &nbsp; &nbsp; Total Records:-{" "}
                          <span style={{ fontWeight: "500" }}>
                            {zones.locations.length}
                          </span>
                        </MDTypography>
                        <Grid
                          display="flex"
                          container
                          spacing={2}
                          mt={2}
                          ml={1}
                        >
                          {zones.locations.length !== 0 ? (
                            zones?.locations?.map((location) => (
                              <div
                                className={`masterStoreItem ${
                                  location.isEmpty === 1
                                    ? `badge-success`
                                    : `badge-danger`
                                }`}
                              >
                                {location.isEmpty === 0 && (
                                  <Button
                                    variant="contained"
                                    style={{
                                      margin: "10px",
                                      marginRight: "4.5px",
                                      backgroundColor: "#B03A2E",
                                      color: "white",
                                    }}
                                    onClick={() => {
                                      setZoneId(location.zoneId);
                                      setLocationId(location.locationId);
                                      setTableHeading(location.locationName);
                                      setZoneTableHeading(location.zoneName);

                                      handleOpenItemDetailsModal();
                                    }}
                                  >
                                    {location.locationName}
                                  </Button>
                                )}
                                {location.isEmpty === 1 && (
                                  <MDButton
                                    type="button"
                                    variant="contained"
                                    style={{
                                      margin: "10px",
                                      marginRight: "4.5px",
                                      cursor: "auto",
                                      backgroundColor: "#28B463",
                                      color: "white",
                                    }}
                                  >
                                    {location.locationName}
                                  </MDButton>
                                )}
                              </div>
                            ))
                          ) : (
                            <div>
                              <MDTypography
                                variant="h6"
                                style={{ marginLeft: "100px" }}
                                color="text"
                              >
                                No locations for this zone
                              </MDTypography>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <div>
                      <MDTypography
                        variant="h3"
                        color="text"
                        style={{
                          marginTop: "1.5rem",
                          marginLeft: "35px",
                        }}
                      >
                        No Zones and Locations for this Warehouse
                      </MDTypography>
                    </div>
                  )}
                  <div>
                    <Modal
                      open={addLocationModal}
                      onClose={handleAddLocationModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <MDCard sx={style}>
                        <MDBox
                          ml={3}
                          mt={-1}
                          display="flex"
                          alignItems="center"
                        >
                          <MDTypography
                            variant="h4"
                            fontWeight="medium"
                            flexGrow={1}
                          >
                            Add Location
                          </MDTypography>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={handleAddLocationModal}
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                                marginLeft: "-55px",
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                        <hr
                          style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                        />
                        <MDBox pt={1} pb={3} px={3}>
                          <MDBox
                            component="form"
                            role="form"
                            onSubmit={formik.handleSubmit}
                          >
                            <MDBox>
                              <MDBox mb={2}>
                                <MDTypography variant="h6">
                                  Select the Zone
                                </MDTypography>
                                <Select
                                  // isClearable
                                  className="select-css"
                                  placeholder="Select Zone Name..."
                                  name="zoneName"
                                  value={zoneIdSelected}
                                  options={zonesOptions}
                                  // options={
                                  //   formik.values.zoneType === "Assigned Zone"
                                  //     ? assignedZonesOptions
                                  //     : unassignedZonesOptions
                                  // }
                                  onChange={(selectedOption) => {
                                    handleZoneIdChange(selectedOption);
                                    // formik.handleChange("zoneName")(
                                    //   selectedOption.value
                                    // );
                                    formik.setFieldValue(
                                      "zoneName",
                                      selectedOption.value
                                    );
                                  }}
                                />

                                {formik.touched.zoneName &&
                                  formik.errors.zoneName && (
                                    <TextError msg={formik.errors.zoneName} />
                                  )}
                              </MDBox>
                              <MDBox mb={2}>
                                <MDTypography variant="h6">
                                  Location
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  name="location"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.location}
                                  error={
                                    formik.touched.location &&
                                    formik.errors.location &&
                                    true
                                  }
                                />
                                {formik.touched.location &&
                                  formik.errors.location && (
                                    <TextError msg={formik.errors.location} />
                                  )}
                              </MDBox>

                              <MDBox>
                                <Collapse in={isErrorAddLocation}>
                                  <Alert
                                    severity="error"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsErrorAddLocation(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    {/* Could not add the Location */}
                                    {addLocationErrorMsg}
                                  </Alert>
                                </Collapse>
                                <Collapse in={isSuccessAddLocation}>
                                  <Alert
                                    severity="success"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsSuccessAddLocation(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    Location added successfully
                                  </Alert>
                                </Collapse>
                              </MDBox>
                              <div className="mt-3">
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  color="info"
                                  fullWidth
                                  disabled={
                                    !formik.isValid ||
                                    buttonName === "Please wait..."
                                  }
                                >
                                  {buttonName}
                                </MDButton>
                              </div>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDCard>
                    </Modal>
                  </div>
                  <div>
                    <Modal
                      open={filterModal}
                      onClose={handleFilterModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <MDCard sx={style}>
                        <MDBox
                          ml={3}
                          mt={-1}
                          display="flex"
                          alignItems="center"
                        >
                          <MDTypography
                            variant="h4"
                            fontWeight="medium"
                            flexGrow={1}
                          >
                            Filter
                          </MDTypography>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={handleFilterModal}
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                                marginLeft: "-55px",
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>

                        <hr
                          style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                        />
                        <MDBox pt={1} pb={3} px={3}>
                          <MDBox
                            component="form"
                            role="form"
                            onSubmit={formikForFilter.handleSubmit}
                          >
                            <MDBox>
                              <MDBox mb={2}>
                                <MDTypography variant="h6">
                                  Select the Zone
                                </MDTypography>

                                <Select
                                  // isClearable
                                  className="select-css"
                                  placeholder="Select Zone..."
                                  name="zone"
                                  value={zoneIdSelected}
                                  options={zonesOptions}
                                  onChange={(selectedOption) => {
                                    handleZoneIdChange(selectedOption);
                                    // formikForFilter.handleChange("zone")(
                                    //   selectedOption.value
                                    // );
                                    formikForFilter.setFieldValue(
                                      "zone",
                                      selectedOption.value
                                    );
                                  }}
                                />
                                {formikForFilter.errors.zone && (
                                  <TextError
                                    msg={formikForFilter.errors.zone}
                                  />
                                )}
                              </MDBox>
                              <MDBox className="mt-3">
                                <Collapse in={isErrorFilter}>
                                  <Alert
                                    severity="error"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsErrorFilter(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    Could not apply the filters
                                  </Alert>
                                </Collapse>
                                <Collapse in={isSuccessFilter}>
                                  <Alert
                                    severity="success"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsSuccessFilter(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    Filters applied
                                  </Alert>
                                </Collapse>
                              </MDBox>
                              <div>
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  color="secondary"
                                  fullWidth
                                  disabled={!formikForFilter.isValid}
                                >
                                  Apply Filter
                                </MDButton>
                              </div>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDCard>
                    </Modal>
                  </div>
                  <div>
                    <Modal
                      open={itemDetailsModal}
                      onClose={handleOpenItemDetailsModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <MDCard sx={styleForZoneModal}>
                        <MDBox
                          ml={3}
                          mt={-1}
                          display="flex"
                          alignItems="center"
                        >
                          <MDTypography
                            variant="h4"
                            fontWeight="medium"
                            flexGrow={1}
                          >
                            {zoneTableHeading} - {tableHeading}
                          </MDTypography>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={handleOpenItemDetailsModal}
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                                marginLeft: "-55px",
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                        {!loading2 ? (
                          <MDBox pt={1} pb={3} px={3}>
                            <DataTable
                              table={{ columns, rows }}
                              isSorted={true}
                              canSearch={true}
                              entriesPerPage={{
                                defaultValue: 30,
                                entries: [30, 50, 75, 100],
                              }}
                              showTotalEntries={true}
                              noEndBorder
                              options={{ draggable: false }}
                              serverSidePagination={true}
                              paginationObj={paginationObj}
                              pageNoValue={pageNo}
                              rowsPerPageValue={rowsPerPage}
                              pageNoProp={importPageNo}
                              rowsPerPageProp={importRowsPerPage}
                              searchProp={importSearch}
                            />
                          </MDBox>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "100px",
                              marginBottom: "100px",
                            }}
                          >
                            <CircularProgress color="info" />
                          </div>
                        )}
                      </MDCard>
                    </Modal>
                  </div>
                  <div>
                    <Modal
                      open={fgDetailsModal}
                      onClose={handleOpenFgDetailsModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <MDCard sx={styleForZoneModal}>
                        <MDBox
                          ml={3}
                          mt={-1}
                          display="flex"
                          alignItems="center"
                        >
                          <MDTypography
                            variant="h4"
                            fontWeight="medium"
                            flexGrow={1}
                          >
                            FG
                          </MDTypography>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={handleOpenFgDetailsModal}
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                                marginLeft: "-55px",
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>

                        <MDBox pt={1} pb={3} px={3}>
                          <DataTable
                            table={{ columns: pColumns, rows: pRows }}
                            isSorted={true}
                            canSearch={true}
                            entriesPerPage={{
                              defaultValue: 30,
                              entries: [30, 50, 75, 100],
                            }}
                            showTotalEntries={true}
                            noEndBorder
                            options={{ draggable: false }}
                          />
                        </MDBox>
                      </MDCard>
                    </Modal>
                  </div>
                  <div>
                    <Modal
                      open={deleteLocationModal}
                      onClose={handleDeleteLocationModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <MDCard sx={style}>
                        <MDBox
                          ml={3}
                          mt={-1}
                          display="flex"
                          alignItems="center"
                        >
                          <MDTypography
                            variant="h4"
                            fontWeight="medium"
                            flexGrow={1}
                          >
                            Delete Location
                          </MDTypography>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={handleDeleteLocationModal}
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                                marginLeft: "-55px",
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                        <hr
                          style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                        />
                        <MDBox pt={1} pb={3} px={3}>
                          <MDBox
                            component="form"
                            role="form"
                            onSubmit={formikForDelete.handleSubmit}
                          >
                            <MDBox>
                              <MDBox mb={2}>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: 500 }}
                                >
                                  Select the Zone
                                </MDTypography>

                                <Select
                                  // isClearable
                                  className="select-css"
                                  placeholder="Select Zone Name..."
                                  name="zoneName"
                                  value={zoneIdSelected}
                                  options={zonesOptions}
                                  onChange={(selectedOption) => {
                                    handleZoneIdChange(selectedOption);
                                    // formikForDelete.handleChange("zoneName")(
                                    //   selectedOption.value
                                    // );
                                    formikForDelete.setFieldValue(
                                      "zoneName",
                                      selectedOption.value
                                    );
                                  }}
                                />
                                {formikForDelete.errors.zoneName && (
                                  <TextError
                                    msg={formikForDelete.errors.zoneName}
                                  />
                                )}
                              </MDBox>

                              {/* {formikForDelete.values.zoneName &&
                              setZoneIdSelected(
                                formikForDelete.values.zoneName
                              )} */}

                              <MDBox mb={2}>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: 500 }}
                                >
                                  Location
                                </MDTypography>

                                <Select
                                  // isClearable
                                  className="select-css"
                                  placeholder="Select Location"
                                  name="location"
                                  value={locationSelected}
                                  options={locationsOptions}
                                  onChange={(selectedOption) => {
                                    handleLocationChange(selectedOption);
                                    // formikForDelete.handleChange("location")(
                                    //   selectedOption.value
                                    // );
                                    formikForDelete.setFieldValue(
                                      "location",
                                      selectedOption.value
                                    );
                                  }}
                                />
                                {formikForDelete.errors.location && (
                                  <TextError
                                    msg={formikForDelete.errors.location}
                                  />
                                )}
                              </MDBox>

                              <div className="mt-3">
                                <Collapse in={isErrorDeleteLocation}>
                                  <Alert
                                    severity="error"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsErrorDeleteLocation(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    {/* Could not delete the Location */}
                                    {deleteLocationErrorMsg}
                                  </Alert>
                                </Collapse>
                                <Collapse in={isSuccessDeleteLocation}>
                                  <Alert
                                    severity="success"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsSuccessDeleteLocation(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    Location deleted successfully
                                  </Alert>
                                </Collapse>
                              </div>
                              <div className="mt-3">
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  color="error"
                                  fullWidth
                                  disabled={
                                    !formikForDelete.isValid ||
                                    buttonName === "Please wait..."
                                  }
                                >
                                  {buttonName}
                                </MDButton>
                              </div>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDCard>
                    </Modal>
                  </div>

                  <div>
                    <Modal
                      open={openAddZoneModal}
                      onClose={handleAddZoneModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <MDCard sx={style}>
                        <MDBox
                          ml={3}
                          mt={-1}
                          display="flex"
                          alignItems="center"
                        >
                          <MDTypography
                            variant="h4"
                            fontWeight="medium"
                            flexGrow={1}
                          >
                            Add Zone
                          </MDTypography>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={handleAddZoneModal}
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                                marginLeft: "-55px",
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                        <hr
                          style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                        />
                        <MDBox pt={1} pb={3} px={3}>
                          <MDBox
                            component="form"
                            role="form"
                            onSubmit={formikForAddZone.handleSubmit}
                          >
                            <MDBox>
                              <MDBox mb={2}>
                                <MDTypography variant="h6">
                                  Enter the Zone
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  name="zoneName"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formikForAddZone.handleChange}
                                  onBlur={formikForAddZone.handleBlur}
                                  value={formikForAddZone.values.zoneName}
                                  error={
                                    formikForAddZone.touched.zoneName &&
                                    formikForAddZone.errors.zoneName &&
                                    true
                                  }
                                />
                                {formikForAddZone.touched.zoneName &&
                                  formikForAddZone.errors.zoneName && (
                                    <TextError
                                      msg={formikForAddZone.errors.zoneName}
                                    />
                                  )}
                              </MDBox>

                              <MDBox>
                                <Collapse in={isErrorAddZone}>
                                  <Alert
                                    severity="error"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsErrorAddZone(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    {/* Could not add the Zone */}
                                    {addZoneErrorMsg}
                                  </Alert>
                                </Collapse>
                                <Collapse in={isSuccessAddZone}>
                                  <Alert
                                    severity="success"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsSuccessAddZone(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    Zone added successfully
                                  </Alert>
                                </Collapse>
                              </MDBox>
                              <div className="mt-3">
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  color="info"
                                  fullWidth
                                  disabled={
                                    !formikForAddZone.isValid ||
                                    buttonName === "Please wait..."
                                  }
                                >
                                  {buttonName}
                                </MDButton>
                              </div>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDCard>
                    </Modal>
                  </div>

                  <div>
                    <Modal
                      open={openDeleteZoneModal}
                      onClose={handleDeleteZoneModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <MDCard sx={style}>
                        <MDBox
                          ml={3}
                          mt={-1}
                          display="flex"
                          alignItems="center"
                        >
                          <MDTypography
                            variant="h4"
                            fontWeight="medium"
                            flexGrow={1}
                          >
                            Delete Zone
                          </MDTypography>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={handleDeleteZoneModal}
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                                marginLeft: "-55px",
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                        <hr
                          style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                        />
                        <MDBox pt={1} pb={3} px={3}>
                          <MDBox
                            component="form"
                            role="form"
                            onSubmit={formikForDeleteZone.handleSubmit}
                          >
                            <MDBox>
                              <MDBox mb={2}>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: 500 }}
                                >
                                  Select the Zone
                                </MDTypography>

                                <Select
                                  // isClearable
                                  className="select-css"
                                  placeholder="Select Zone Name..."
                                  name="zoneName"
                                  value={zoneIdSelected}
                                  options={zonesOptions}
                                  onChange={(selectedOption) => {
                                    handleZoneIdChange(selectedOption);
                                    // formikForDelete.handleChange("zoneName")(
                                    //   selectedOption.value
                                    // );
                                    formikForDeleteZone.setFieldValue(
                                      "zoneName",
                                      selectedOption.value
                                    );
                                  }}
                                />
                                {formikForDeleteZone.errors.zoneName && (
                                  <TextError
                                    msg={formikForDeleteZone.errors.zoneName}
                                  />
                                )}
                              </MDBox>

                              <div className="mt-3">
                                <Collapse in={isErrorDeleteZone}>
                                  <Alert
                                    severity="error"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsErrorDeleteZone(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    {/* Could not delete the Zone */}
                                    {deleteZoneErrorMsg}
                                  </Alert>
                                </Collapse>
                                <Collapse in={isSuccessDeleteZone}>
                                  <Alert
                                    severity="success"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsSuccessDeleteZone(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    Zone deleted successfully
                                  </Alert>
                                </Collapse>
                              </div>
                              <div className="mt-3">
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  color="error"
                                  fullWidth
                                  disabled={
                                    !formikForDelete.isValid ||
                                    buttonName === "Please wait..."
                                  }
                                >
                                  {buttonName}
                                </MDButton>
                              </div>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDCard>
                    </Modal>
                  </div>
                </Grid>
              </Card>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "100px",
                  marginBottom: "100px",
                }}
              >
                <CircularProgress color="info" />
              </div>
            )}
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    locationsByWarehouse: state.locationsByWarehouse,
    locationsByWarehouseAndZone: state.locationsByWarehouseAndZone,
    item: state.item,
    allCategories: state.allCategories,
    zonesByCategoryAndWarehouse: state.zonesByCategoryAndWarehouse,
    warehouseById: state.warehouseById,
    zonesByWarehouse: state.zonesByWarehouse,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLocationsByWarehouse: (warehouseId, zoneId, jwtToken) =>
      dispatch(getLocationsByWarehouse(warehouseId, zoneId, jwtToken)),
    getLocationsByWarehouseAndZone: (warehouseId, zoneId, jwtToken) =>
      dispatch(getLocationsByWarehouseAndZone(warehouseId, zoneId, jwtToken)),
    addLocationToWarehouse: (payload, jwtToken) =>
      dispatch(addLocationToWarehouse(payload, jwtToken)),
    deleteLocation: (id, jwtToken) => dispatch(deleteLocation(id, jwtToken)),
    getItemByLocation: (
      locationId,
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    ) =>
      dispatch(
        getItemByLocation(
          locationId,
          pageNo,
          rowsPerPage,
          searchObject,
          jwtToken
        )
      ),
    getAllCategories: (jwtToken) => dispatch(getAllCategories(jwtToken)),
    getZonesByCategoryAndWarehouse: (warehouseId, categoryId, jwtToken) =>
      dispatch(
        getZonesByCategoryAndWarehouse(warehouseId, categoryId, jwtToken)
      ),
    getWarehouseById: (id, jwtToken) =>
      dispatch(getWarehouseById(id, jwtToken)),
    getZonesByWarehouse: (payload, jwtToken) =>
      dispatch(getZonesByWarehouse(payload, jwtToken)),
    addZoneToWarehouse: (payload, jwtToken) =>
      dispatch(addZoneToWarehouse(payload, jwtToken)),
    deleteZone: (id, jwtToken) => dispatch(deleteZone(id, jwtToken)),
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewLocation);
