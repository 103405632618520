import {
  GETLOCATION_BYWAREHOUSE_REQUEST,
  GETLOCATION_BYWAREHOUSE_SUCCESS,
  GETLOCATION_BYWAREHOUSE_FAILURE,
  ADDLOCATION_TOWAREHOUSE_REQUEST,
  ADDLOCATION_TOWAREHOUSE_SUCCESS,
  ADDLOCATION_TOWAREHOUSE_FAILURE,
  GETITEM_BYLOCATION_REQUEST,
  GETITEM_BYLOCATION_SUCCESS,
  GETITEM_BYLOCATION_FAILURE,
  GETLOCATION_BYWAREHOUSEANDZONE_REQUEST,
  GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS,
  GETLOCATION_BYWAREHOUSEANDZONE_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
} from "./viewLocationTypes";

const initialState = {
  loading: false,
  locations: [],
  error: "",
};

const getLocationsByWarehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETLOCATION_BYWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETLOCATION_BYWAREHOUSE_SUCCESS:
      return {
        loading: false,
        locations: action.payload,
        error: "",
      };
    case GETLOCATION_BYWAREHOUSE_FAILURE:
      return {
        loading: false,
        locations: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAddLocation = {
  loading: false,
  location: [],
  error: "",
};

const addLocationReducer = (state = initialStateForAddLocation, action) => {
  switch (action.type) {
    case ADDLOCATION_TOWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADDLOCATION_TOWAREHOUSE_SUCCESS:
      return {
        loading: false,
        location: action.payload,
        error: "",
      };
    case ADDLOCATION_TOWAREHOUSE_FAILURE:
      return {
        loading: false,
        location: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForGetItem = {
  loading: false,
  item: [],
  error: "",
};

const getItemByLocationReducer = (state = initialStateForGetItem, action) => {
  switch (action.type) {
    case GETITEM_BYLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETITEM_BYLOCATION_SUCCESS:
      return {
        loading: false,
        item: action.payload,
        error: "",
      };
    case GETITEM_BYLOCATION_FAILURE:
      return {
        loading: false,
        item: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLocations = {
  loading: false,
  locations: [],
  error: "",
};

const getLocationsByWarehouseAndZoneReducer = (
  state = initialStateForLocations,
  action
) => {
  switch (action.type) {
    case GETLOCATION_BYWAREHOUSEANDZONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS:
      return {
        loading: false,
        locations: action.payload,
        error: "",
      };
    case GETLOCATION_BYWAREHOUSEANDZONE_FAILURE:
      return {
        loading: false,
        locations: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDeleteLocation = {
  loading: false,
  location: [],
  error: "",
};

const deleteLocationReducer = (
  state = initialStateForDeleteLocation,
  action
) => {
  switch (action.type) {
    case DELETE_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_LOCATION_SUCCESS:
      return {
        loading: false,
        location: action.payload,
        error: "",
      };
    case DELETE_LOCATION_FAILURE:
      return {
        loading: false,
        location: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getLocationsByWarehouseReducer,
  addLocationReducer,
  getItemByLocationReducer,
  getLocationsByWarehouseAndZoneReducer,
  deleteLocationReducer,
};
