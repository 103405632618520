import {
  GET_PIV_REQUEST,
  GET_PIV_SUCCESS,
  GET_PIV_FAILURE,
  UPDATE_PICKLISTLOCATION_REQUEST,
  UPDATE_PICKLISTLOCATION_SUCCESS,
  UPDATE_PICKLISTLOCATION_FAILURE,
  ADD_RELEASEFORISSUANCE_REQUEST,
  ADD_RELEASEFORISSUANCE_SUCCESS,
  ADD_RELEASEFORISSUANCE_FAILURE,
  VALIDATION_MATERIALISSUANCE_REQUEST,
  VALIDATION_MATERIALISSUANCE_SUCCESS,
  VALIDATION_MATERIALISSUANCE_FAILURE,
  VALIDATION_RELEASE_REQUEST,
  VALIDATION_RELEASE_SUCCESS,
  VALIDATION_RELEASE_FAILURE,
} from "./materialIssuanceTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getPivRequest = () => {
  return {
    type: GET_PIV_REQUEST,
  };
};

const getPivSuccess = (payload) => {
  return {
    type: GET_PIV_SUCCESS,
    payload: payload,
  };
};

const getPivFailure = (error) => {
  return {
    type: GET_PIV_FAILURE,
    payload: error,
  };
};

const getPiv = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getPivRequest());
    let url = `${baseUrl}/piv/get?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPivSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPivFailure(err.response));
      });
  };
};

const updatePicklistLocationRequest = () => {
  return {
    type: UPDATE_PICKLISTLOCATION_REQUEST,
  };
};

const updatePicklistLocationSuccess = (payload) => {
  return {
    type: UPDATE_PICKLISTLOCATION_SUCCESS,
    payload: payload,
  };
};

const updatePicklistLocationFaliure = (error) => {
  return {
    type: UPDATE_PICKLISTLOCATION_FAILURE,
    payload: error,
  };
};

const updatePicklistLocation = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(updatePicklistLocationRequest());
    let url = `${baseUrl}/piv/update_location`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updatePicklistLocationSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updatePicklistLocationFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const addToReleaseForIssuanceRequest = () => {
  return {
    type: ADD_RELEASEFORISSUANCE_REQUEST,
  };
};

const addToReleaseForIssuanceSuccess = (payload) => {
  return {
    type: ADD_RELEASEFORISSUANCE_SUCCESS,
    payload: payload,
  };
};

const addToReleaseForIssuanceFaliure = (error) => {
  return {
    type: ADD_RELEASEFORISSUANCE_FAILURE,
    payload: error,
  };
};

const addToReleaseForIssuance = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(addToReleaseForIssuanceRequest());
    let url = `${baseUrl}/piv/add_piv_to_release_pickuplist`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(addToReleaseForIssuanceSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(addToReleaseForIssuanceFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const validationForMaterialIssuanceRequest = () => {
  return {
    type: VALIDATION_MATERIALISSUANCE_REQUEST,
  };
};

const validationForMaterialIssuanceSuccess = (payload) => {
  return {
    type: VALIDATION_MATERIALISSUANCE_SUCCESS,
    payload: payload,
  };
};

const validationForMaterialIssuanceFaliure = (error) => {
  return {
    type: VALIDATION_MATERIALISSUANCE_FAILURE,
    payload: error,
  };
};

const validationForMaterialIssuance = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(validationForMaterialIssuanceRequest());
    let url = `${baseUrl}/piv/validation/add_piv_to_release_pickuplist`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(validationForMaterialIssuanceSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(validationForMaterialIssuanceFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const validationForReleaseRequest = () => {
  return {
    type: VALIDATION_RELEASE_REQUEST,
  };
};

const validationForReleaseSuccess = (payload) => {
  return {
    type: VALIDATION_RELEASE_SUCCESS,
    payload: payload,
  };
};

const validationForReleaseFaliure = (error) => {
  return {
    type: VALIDATION_RELEASE_FAILURE,
    payload: error,
  };
};

const validationForRelease = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(validationForReleaseRequest());
    let url = `${baseUrl}/piv/validation/release_pickuplist_to_st_Issue`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(validationForReleaseSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(validationForReleaseFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getPiv,
  updatePicklistLocation,
  addToReleaseForIssuance,
  validationForRelease,
  validationForMaterialIssuance,
};
