export const GET_CONSOLIDATED_PIV_FOR_REPORTS_REQUEST =
  "GET_CONSOLIDATED_PIV_FOR_REPORTS_REQUEST";
export const GET_CONSOLIDATED_PIV_FOR_REPORTS_SUCCESS =
  "GET_CONSOLIDATED_PIV_FOR_REPORTS_SUCCESS";
export const GET_CONSOLIDATED_PIV_FOR_REPORTS_FAILURE =
  "GET_CONSOLIDATED_PIV_FOR_REPORTS_FAILURE";

export const DOWNLOAD_CONSOLIDATED_PIV_REQUEST =
  "DOWNLOAD_CONSOLIDATED_PIV_REQUEST";
export const DOWNLOAD_CONSOLIDATED_PIV_SUCCESS =
  "DOWNLOAD_CONSOLIDATED_PIV_SUCCESS";
export const DOWNLOAD_CONSOLIDATED_PIV_FAILURE =
  "DOWNLOAD_CONSOLIDATED_PIV_FAILURE";
