import {
  GET_MATERIALDESC_REQUEST,
  GET_MATERIALDESC_SUCCESS,
  GET_MATERIALDESC_FAILURE,
  PRINT_UNDERTEST_REQUEST,
  PRINT_UNDERTEST_SUCCESS,
  PRINT_UNDERTEST_FAILURE,
} from "./underTestTypes";

const initialState = {
  loading: false,
  desc: [],
  error: "",
};

const getMaterialDescReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MATERIALDESC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MATERIALDESC_SUCCESS:
      return {
        loading: false,
        desc: action.payload,
        error: "",
      };
    case GET_MATERIALDESC_FAILURE:
      return {
        loading: false,
        desc: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUnderTestPrint = {
  loading: false,
  underTestPrint: [],
  error: "",
};

const underTestPrintReducer = (
  state = initialStateForUnderTestPrint,
  action
) => {
  switch (action.type) {
    case PRINT_UNDERTEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PRINT_UNDERTEST_SUCCESS:
      return {
        loading: false,
        underTestPrint: action.payload,
        error: "",
      };
    case PRINT_UNDERTEST_FAILURE:
      return {
        loading: false,
        underTestPrint: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getMaterialDescReducer, underTestPrintReducer };
