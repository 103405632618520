/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

// react-router-dom components

import { useNavigate } from "react-router-dom";

// Functions from store
import { logout } from "../../../store";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

function Logout({ logout }) {
  let loginDetails = cookies.get("loginDetailsForWMS")?.data;

  // logout(loginDetails);

  const navigate = useNavigate();

  useEffect(() => {
    logout(loginDetails);

    cookies.remove("loginDetailsForWMS", { path: "/" });
    cookies.remove("jwtForMobillorEditor", { path: "/" });
    cookies.remove("printerIdForWarehouse", { path: "/" });
    cookies.remove("printerConfigForWarehouse", { path: "/" });
    localStorage.removeItem("active");

    navigate("/");
    window.location.reload();
  }, []);

  return null;
}

const mapStateToProps = (state) => {
  return {
    logoutReducer: state.logoutReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    logout: (loginDetails) => dispatch(logout(loginDetails)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Logout);
