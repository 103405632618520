import {
  GET_WAREHOUSEOCCUPANCY_REQUEST,
  GET_WAREHOUSEOCCUPANCY_SUCCESS,
  GET_WAREHOUSEOCCUPANCY_FAILURE,
  DOWNLOAD_WAREHOUSEOCCUPANCY_REQUEST,
  DOWNLOAD_WAREHOUSEOCCUPANCY_SUCCESS,
  DOWNLOAD_WAREHOUSEOCCUPANCY_FAILURE,
  EDIT_EXPIRY_REQUEST,
  EDIT_EXPIRY_SUCCESS,
  EDIT_EXPIRY_FAILURE,
  CREATE_DOCUMENT_UPLOAD_REQUEST,
  CREATE_DOCUMENT_UPLOAD_SUCCESS,
  CREATE_DOCUMENT_UPLOAD_FAILURE,
  UPDATE_QI_STATUS_REQUEST,
  UPDATE_QI_STATUS_SUCCESS,
  UPDATE_QI_STATUS_FAILURE,
} from "./warehouseOccupancyTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getWarehouseOccupancyRequest = () => {
  return {
    type: GET_WAREHOUSEOCCUPANCY_REQUEST,
  };
};

const getWarehouseOccupancySuccess = (payload) => {
  return {
    type: GET_WAREHOUSEOCCUPANCY_SUCCESS,
    payload: payload,
  };
};

const getWarehouseOccupancyFailure = (error) => {
  return {
    type: GET_WAREHOUSEOCCUPANCY_FAILURE,
    payload: error,
  };
};

const getWarehouseOccupancy = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getWarehouseOccupancyRequest());
    let url = `${baseUrl}/reports/warehouse_occupancy_report?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}&download=0`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getWarehouseOccupancySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getWarehouseOccupancyFailure(err.message));
      });
  };
};

const downloadWarehouseOccupancyRequest = () => {
  return {
    type: DOWNLOAD_WAREHOUSEOCCUPANCY_REQUEST,
  };
};

const downloadWarehouseOccupancySuccess = (payload) => {
  return {
    type: DOWNLOAD_WAREHOUSEOCCUPANCY_SUCCESS,
    payload: payload,
  };
};

const downloadWarehouseOccupancyFailure = (error) => {
  return {
    type: DOWNLOAD_WAREHOUSEOCCUPANCY_FAILURE,
    payload: error,
  };
};

const downloadWarehouseOccupancy = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(downloadWarehouseOccupancyRequest());
    let url = `${baseUrl}/reports/warehouse_occupancy_report?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}&download=1`;

    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(downloadWarehouseOccupancySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(downloadWarehouseOccupancyFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const editExpiryRequest = () => {
  return {
    type: EDIT_EXPIRY_REQUEST,
  };
};

const editExpirySuccess = (payload) => {
  return {
    type: EDIT_EXPIRY_SUCCESS,
    payload: payload,
  };
};

const editExpiryFaliure = (error) => {
  return {
    type: EDIT_EXPIRY_FAILURE,
    payload: error,
  };
};

const editExpiry = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(editExpiryRequest());
    let url = `${baseUrl}/wh_occ_report/revalidate_material`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editExpirySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editExpiryFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const documentUploadRequest = () => {
  return {
    type: CREATE_DOCUMENT_UPLOAD_REQUEST,
  };
};

const documentUploadSuccess = (payload) => {
  return {
    type: CREATE_DOCUMENT_UPLOAD_SUCCESS,
    payload: payload,
  };
};

const documentUploadFailure = (error) => {
  return {
    type: CREATE_DOCUMENT_UPLOAD_FAILURE,
    payload: error,
  };
};

const documentUpload = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(documentUploadRequest());
    let url = `${baseUrl}/wh_occ_report/revalidate_material/upload_file`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(documentUploadSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(documentUploadFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateQIStatusRequest = () => {
  return {
    type: UPDATE_QI_STATUS_REQUEST,
  };
};

const updateQIStatusSuccess = (payload) => {
  return {
    type: UPDATE_QI_STATUS_SUCCESS,
    payload: payload,
  };
};

const updateQIStatusFaliure = (error) => {
  return {
    type: UPDATE_QI_STATUS_FAILURE,
    payload: error,
  };
};

const updateQIStatus = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateQIStatusRequest());
    let url = `${baseUrl}/wh_occ_report/updateStatus_material`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateQIStatusSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateQIStatusFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};
export {
  getWarehouseOccupancy,
  downloadWarehouseOccupancy,
  editExpiry,
  documentUpload,
  updateQIStatus,
};
