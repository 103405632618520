export const GET_ADDEDPIV_REQUEST = "GET_ADDEDPIV_REQUEST";
export const GET_ADDEDPIV_SUCCESS = "GET_ADDEDPIV_SUCCESS";
export const GET_ADDEDPIV_FAILURE = "GET_ADDEDPIV_FAILURE";

export const REMOVE_PIVFROMISSUANCERELEASE_REQUEST =
  "REMOVE_PIVFROMISSUANCERELEASE_REQUEST";
export const REMOVE_PIVFROMISSUANCERELEASE_SUCCESS =
  "REMOVE_PIVFROMISSUANCERELEASE_SUCCESS";
export const REMOVE_PIVFROMISSUANCERELEASE_FAILURE =
  "REMOVE_PIVFROMISSUANCERELEASE_FAILURE";

export const RELEASE_PIV_REQUEST = "RELEASE_PIV_REQUEST";
export const RELEASE_PIV_SUCCESS = "RELEASE_PIV_SUCCESS";
export const RELEASE_PIV_FAILURE = "RELEASE_PIV_FAILURE";
