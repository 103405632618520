import {
  GETWAREHOUSE_REQUEST,
  GETWAREHOUSE_SUCCESS,
  GETWAREHOUSE_FAILURE,
  GETWAREHOUSE_BYID_REQUEST,
  GETWAREHOUSE_BYID_SUCCESS,
  GETWAREHOUSE_BYID_FAILURE,
  UPDATEWAREHOUSE_REQUEST,
  UPDATEWAREHOUSE_SUCCESS,
  UPDATEWAREHOUSE_FAILURE,
  CREATE_WAREHOUSE_REQUEST,
  CREATE_WAREHOUSE_SUCCESS,
  CREATE_WAREHOUSE_FAILURE,
  GET_DELIVERYWAREHOUSE_REQUEST ,
  GET_DELIVERYWAREHOUSE_SUCCESS ,
  GET_DELIVERYWAREHOUSE_FAILURE 
} from "./warehousesTypes";

const initialState = {
  loading: false,
  warehouses: [],
  error: "",
};

const getWarehousesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETWAREHOUSE_SUCCESS:
      return {
        loading: false,
        warehouses: action.payload,
        error: "",
      };
    case GETWAREHOUSE_FAILURE:
      return {
        loading: false,
        warehouses: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForWarehouseById = {
  loading: false,
  warehouse: [],
  error: "",
};

const getWarehouseByIdReducer = (
  state = initialStateForWarehouseById,
  action
) => {
  switch (action.type) {
    case GETWAREHOUSE_BYID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETWAREHOUSE_BYID_SUCCESS:
      return {
        loading: false,
        warehouse: action.payload,
        error: "",
      };
    case GETWAREHOUSE_BYID_FAILURE:
      return {
        loading: false,
        warehouse: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUpdateWarehouse = {
  loading: false,
  warehouse: [],
  error: "",
};

const updateWarehouseReducer = (
  state = initialStateForUpdateWarehouse,
  action
) => {
  switch (action.type) {
    case UPDATEWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATEWAREHOUSE_SUCCESS:
      return {
        loading: false,
        warehouse: action.payload,
        error: "",
      };
    case UPDATEWAREHOUSE_FAILURE:
      return {
        loading: false,
        warehouse: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreateWarehouse = {
  loading: false,
  warehouse: {},
  error: "",
};

const createWarehouseReducer = (
  state = initialStateForCreateWarehouse,
  action
) => {
  switch (action.type) {
    case CREATE_WAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_WAREHOUSE_SUCCESS:
      return {
        loading: false,
        warehouse: action.payload,
        error: "",
      };
    case CREATE_WAREHOUSE_FAILURE:
      return {
        loading: false,
        warehouse: {},
        error: action.payload,
      };
    default:
      return state;
  }
};







const initialStateForDeliveryWarehouse = {
  loading: false,
  deliveryWarehouse: {},
  error: "",
};

const deliveryWarehouseReducer = (
  state = initialStateForDeliveryWarehouse,
  action
) => {
  switch (action.type) {
    case GET_DELIVERYWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DELIVERYWAREHOUSE_SUCCESS:
      return {
        loading: false,
        dWarehouse: action.payload,
        error: "",
      };
    case GET_DELIVERYWAREHOUSE_FAILURE:
      return {
        loading: false,
        dWarehouse: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getWarehousesReducer,
  getWarehouseByIdReducer,
  updateWarehouseReducer,
  createWarehouseReducer,
  deliveryWarehouseReducer
};
