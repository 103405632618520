import {
  CREATE_NEWASSET_FAILURE,
  CREATE_NEWASSET_REQUEST,
  CREATE_NEWASSET_SUCCESS,
  DELETE_ASSET_FAILURE,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  GET_ASSETMASTER_FAILURE,
  GET_ASSETMASTER_REQUEST,
  GET_ASSETMASTER_SUCCESS,
  GET_ASSETTYPES_FAILURE,
  GET_ASSETTYPES_REQUEST,
  GET_ASSETTYPES_SUCCESS,
  UPDATE_ASSET_FAILURE,
  UPDATE_ASSET_REQUEST,
  UPDATE_ASSET_SUCCESS,
  PRINT_ASSET_REQUEST,
  PRINT_ASSET_SUCCESS,
  PRINT_ASSET_FAILURE,
  PRINT_VIRTUAL_BIN_REQUEST,
  PRINT_VIRTUAL_BIN_SUCCESS,
  PRINT_VIRTUAL_BIN_FAILURE,
} from "./assetMasterTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getAssetsRequest = () => {
  return {
    type: GET_ASSETMASTER_REQUEST,
  };
};

const getAssetsSuccess = (payload) => {
  return {
    type: GET_ASSETMASTER_SUCCESS,
    payload: payload,
  };
};

const getAssetsFailure = (error) => {
  return {
    type: GET_ASSETMASTER_FAILURE,
    payload: error,
  };
};

const getAssets = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getAssetsRequest());
    let url = `${baseUrl}/asset/get?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAssetsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAssetsFailure(err.response));
      });
  };
};

const createNewAssetRequest = () => {
  return {
    type: CREATE_NEWASSET_REQUEST,
  };
};

const createNewAssetSuccess = (payload) => {
  return {
    type: CREATE_NEWASSET_SUCCESS,
    payload: payload,
  };
};

const createNewAssetFaliure = (error) => {
  return {
    type: CREATE_NEWASSET_FAILURE,
    payload: error,
  };
};

const createNewAsset = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(createNewAssetRequest());
    let url = `${baseUrl}/asset/create`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createNewAssetSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createNewAssetFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const printVirtualBinRequest = () => {
  return {
    type: PRINT_VIRTUAL_BIN_REQUEST,
  };
};

const printVirtualBinSuccess = (payload) => {
  return {
    type: PRINT_VIRTUAL_BIN_SUCCESS,
    payload: payload,
  };
};

const printVirtualBinFaliure = (error) => {
  return {
    type: PRINT_VIRTUAL_BIN_FAILURE,
    payload: error,
  };
};

const printVirtualBin = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(printVirtualBinRequest());
    let url = `${baseUrl}/asset_label/virtual_bin`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(printVirtualBinSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(printVirtualBinFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateAssetRequest = () => {
  return {
    type: UPDATE_ASSET_REQUEST,
  };
};

const updateAssetSuccess = (payload) => {
  return {
    type: UPDATE_ASSET_SUCCESS,
    payload: payload,
  };
};

const updateAssetFaliure = (error) => {
  return {
    type: UPDATE_ASSET_FAILURE,
    payload: error,
  };
};

const updateAsset = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(updateAssetRequest());
    let url = `${baseUrl}/asset/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateAssetSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateAssetFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const deleteAssetRequest = () => {
  return {
    type: DELETE_ASSET_REQUEST,
  };
};

const deleteAssetSuccess = (payload) => {
  return {
    type: DELETE_ASSET_SUCCESS,
    payload: payload,
  };
};

const deleteAssetFaliure = (error) => {
  return {
    type: DELETE_ASSET_FAILURE,
    payload: error,
  };
};

const deleteAsset = (id, status, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(deleteAssetRequest());
    let url = `${baseUrl}/asset/delete?assetId=${id}&isDeleted=${status}`;

    return axios
      .delete(url, headers)
      .then((response) => {
        dispatch(deleteAssetSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteAssetFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getAssetTypesRequest = () => {
  return {
    type: GET_ASSETTYPES_REQUEST,
  };
};

const getAssetTypesSuccess = (payload) => {
  return {
    type: GET_ASSETTYPES_SUCCESS,
    payload: payload,
  };
};

const getAssetTypesFailure = (error) => {
  return {
    type: GET_ASSETTYPES_FAILURE,
    payload: error,
  };
};

const getAssetTypes = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAssetTypesRequest());
    let url = `${baseUrl}/asset_type/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAssetTypesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAssetTypesFailure(err.message));
      });
  };
};

const printAssetRequest = () => {
  return {
    type: PRINT_ASSET_REQUEST,
  };
};

const printAssetSuccess = (payload) => {
  return {
    type: PRINT_ASSET_SUCCESS,
    payload: payload,
  };
};

const printAssetFaliure = (error) => {
  return {
    type: PRINT_ASSET_FAILURE,
    payload: error,
  };
};

const printAsset = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(printAssetRequest());
    let url = `${baseUrl}/asset_label/reprint`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(printAssetSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(printAssetFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getAssets,
  createNewAsset,
  updateAsset,
  deleteAsset,
  getAssetTypes,
  printAsset,
  printVirtualBin,
};
