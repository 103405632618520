/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

import { CSVLink } from "react-csv";

// Functions from store
import {
  getSubModulesByRoleId,
  getPickuplistWiseCharging,
  downloadPickuplistWiseCharging,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";

// Config file
import Config from "../../../config/index";
import { addMinutes, format } from "date-fns";

let desLength = Config.descriptionLength;
const cookies = new Cookies();

const styleForEditExpiry = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForDownloadModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PickupListWiseCharging = ({
  getPickuplistWiseCharging,
  pickuplistWiseChargingState,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  downloadPickuplistWiseCharging,
  pickuplistWiseChargingReport,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userId = loginDetails?.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let reportsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "07390a6c-3bf9-4474-9396-b7d6192eb805"
  );

  let viewReportsAccess = reportsModule
    ? reportsModule.actionId.includes("00336968-6324-4d6e-9854-5f3ab4e0c8be")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [loading, setLoading] = useState(true);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getPickuplistWiseCharging(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const statusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Not Charged",
      value: "Not Charged",
    },
    {
      label: "Charged",
      value: "Charged",
    },
    {
      label: "Over Charged",
      value: "Over Charged",
    },
    {
      label: "Under Charged",
      value: "Under Charged",
    },
  ];

  const [state, setState] = useState({
    columns: [
      { Header: "Pick List ID", accessor: "pickupListCode" },
      { Header: "Phase No/Location", accessor: "phaseNumber" },
      { Header: "Material Code", accessor: "materialCode" },
      { Header: "Item Des", accessor: "itemDescription" },
      { Header: "Batch Qty", accessor: "qty" },
      { Header: "Charge Lower Limit", accessor: "lowerLimit" },
      { Header: "Charge Upper Limit", accessor: "upperLimit" },
      { Header: "Actual Charged Qty", accessor: "chargedQty" },
      {
        Header: "Charging Start Time",
        accessor: "startChargingTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Charging End Time",
        accessor: "endChargingTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      { Header: "RM Category/Des", accessor: "rmCategory" },
      { Header: "SAP Resource Category", accessor: "sapResourceCategory" },
      { Header: "Resource", accessor: "resourceName" },
      { Header: "Charged Type", accessor: "chargedType" },
      {
        Header: "Status",
        accessor: "chargedStatus",
        searchType: "select",
        selectOptions: statusOptions,
        placeholder: "All Status",
      },
      { Header: "User", accessor: "assignedUser" },
      { Header: "Operator", accessor: "operator" },
      { Header: "Checker", accessor: "checker" },
      { Header: "UID", accessor: "uid" },
      { Header: "HOD", accessor: "hod" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  useEffect(() => {
    let tempPickuplistCharging = [];

    let data = pickuplistWiseChargingState.pickupListCharging.data
      ? pickuplistWiseChargingState.pickupListCharging.data
      : [];

    // setErrorMsgFromApi(pickuplistWiseChargingState?.error?.data?.msg);
    setLoading(pickuplistWiseChargingState.loading);
    setPaginationObj(
      pickuplistWiseChargingState?.pickupListCharging?.pagination
    );

    data.map((singleData, index) => {
      let itemDes = singleData.itemDescription;
      let len = desLength;

      const dataObject = {
        pickupListCode: singleData.pickupListCode,
        phaseNumber: singleData.phaseNumber,
        materialCode: singleData.materialCode,
        itemDescription: `${itemDes ? itemDes.substring(0, len) : ""}`,
        qty: `${singleData.qty} ${singleData.uom}`,
        lowerLimit: singleData.lowerLimit,
        upperLimit: singleData.upperLimit,
        chargedQty: `${singleData.chargedQty} ${singleData.uom}`,
        startChargingTime: singleData.startChargingTime,
        endChargingTime: singleData.endChargingTime,
        rmCategory: singleData.rmCategory,
        resourceName: singleData.resourceName,
        chargedType: singleData.chargedType,
        chargedStatus: singleData.chargedStatus,
        assignedUser: singleData.assignedUser,
        operator: singleData.operator,
        checker: singleData.checker,
        uid: singleData.uid,
        hod: singleData.hod,
      };
      tempPickuplistCharging.push(dataObject);
    });

    viewReportsAccess && setState({ ...state, rows: tempPickuplistCharging });
  }, [pickuplistWiseChargingState]);

  const [loadingData, setLoadingData] = useState(false);
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    let tempReports = [];
    let data = pickuplistWiseChargingReport.pickupListCharging.data
      ? pickuplistWiseChargingReport.pickupListCharging.data
      : [];

    data.map((singleData, index) => {
      let itemDes = singleData.itemDescription;
      let len = desLength;

      const formatDateStartTime = new Date(singleData.startChargingTime);
      const formatDateEndTime = new Date(singleData.endChargingTime);

      const dataObject = {
        pickupListCode: singleData.pickupListCode,
        phaseNumber: singleData.phaseNumber,
        materialCode: singleData.materialCode,
        itemDescription: `${itemDes ? itemDes.substring(0, len) : ""}`,
        qty: `${singleData.qty} ${singleData.uom}`,
        lowerLimit: singleData.lowerLimit,
        upperLimit: singleData.upperLimit,
        chargedQty: `${singleData.chargedQty} ${singleData.uom}`,
        rmCategory: singleData.rmCategory,
        resourceName: singleData.resourceName,
        chargedType: singleData.chargedType,
        chargedStatus: singleData.chargedStatus,
        assignedUser: singleData.assignedUser,
        operator: singleData.operator,
        checker: singleData.checker,
        uid: singleData.uid,
        hod: singleData.hod,
        startChargingTime: singleData.startChargingTime
          ? format(
              addMinutes(
                formatDateStartTime,
                formatDateStartTime.getTimezoneOffset()
              ),
              "dd/MM/yyyy, HH:mm"
            )
          : "",
        endChargingTime: singleData.endChargingTime
          ? format(
              addMinutes(
                formatDateEndTime,
                formatDateEndTime.getTimezoneOffset()
              ),
              "dd/MM/yyyy, HH:mm"
            )
          : "",
      };
      tempReports.push(dataObject);
    });
    setDownloadData(tempReports);
  }, [pickuplistWiseChargingReport]);

  const headers = [
    { label: "Pick List ID", key: "pickupListCode" },
    { label: "Phase No/Location", key: "phaseNumber" },
    { label: "Material Code", key: "materialCode" },
    { label: "Item Des", key: "itemDescription" },
    { label: "Batch Qty", key: "qty" },
    { label: "Charge Lower Limit", key: "lowerLimit" },
    { label: "Charge Upper Limit", key: "upperLimit" },
    { label: "Actual Charged Qty", key: "chargedQty" },
    {
      label: "Charging Start Time",
      key: "startChargingTime",
      searchType: "date",
    },
    {
      label: "Charging End Time",
      key: "endChargingTime",
      searchType: "date",
    },
    { label: "RM Category/Des", key: "rmCategory" },
    { label: "SAP Resource Category", key: "sapResourceCategory" },
    { label: "Resource", key: "resourceName" },
    { label: "Charged Type", key: "chargedType" },
    {
      label: "Status",
      key: "chargedStatus",
    },
    { label: "User", key: "assignedUser" },
    { label: "Operator", key: "operator" },
    { label: "Checker", key: "checker" },
    { label: "UID", key: "uid" },
    { label: "HOD", key: "hod" },
  ];

  const csvLink = {
    filename: "Pickuplist Charging Report.csv",
    headers: headers,
    data: downloadData,
  };

  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [disableDownloadButton, setDisableDownloadButton] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const handleOpenDownloadModal = (data = {}) => {
    setOpenDownloadModal(true);
  };

  const handleCloseDownloadModal = () => {
    setDisableDownloadButton(false);
    setOpenDownloadModal(false);
  };

  const downloadHandler = async () => {
    setLoadingData(true);

    let res = await downloadPickuplistWiseCharging(
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    );

    if (res.status) {
      setModalMsg("Ready for download!");
      setLoadingData(false);
      handleOpenDownloadModal();
    }
    if (!res.status) {
      setModalMsg("Could not prepare the data for download!");
      setLoadingData(false);
      setDisableDownloadButton(true);
      handleOpenDownloadModal();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {viewReportsAccess && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  // mb={-6}
                >
                  <MDBox color="text">
                    <Tooltip title="Download">
                      <MDButton
                        style={{ marginRight: "1rem", marginLeft: "10px" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        iconOnly
                        onClick={() => {
                          downloadHandler();
                        }}
                      >
                        <Icon>download</Icon>
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              )}

              <MDBox mt={viewReportsAccess ? -3 : 0}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>

              <Modal
                open={openDownloadModal}
                onClose={handleCloseDownloadModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForDownloadModal}>
                  <MDTypography>{`${
                    disableDownloadButton
                      ? "Could not prepare the data for download!"
                      : "Ready for download!"
                  }`}</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDownloadModal}
                    >
                      Cancel
                    </MDButton>
                    {!disableDownloadButton && (
                      <CSVLink {...csvLink}>
                        <MDButton
                          style={{ width: "48%", marginRight: "0.5rem" }}
                          type="button"
                          variant="outlined"
                          color="error"
                        >
                          Download
                        </MDButton>
                      </CSVLink>
                    )}
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {loadingData && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <MDTypography color="warning" variant="h2">
            Preparing Data. Please wait...
          </MDTypography>
        </Backdrop>
      )}
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    pickuplistWiseChargingState: state.getPickupListWiseChargingReducer,
    pickuplistWiseChargingReport: state.downloadPickupListWiseChargingReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getPickuplistWiseCharging: (pageNo, rowsPerPage, search, jwtToken) =>
      dispatch(
        getPickuplistWiseCharging(pageNo, rowsPerPage, search, jwtToken)
      ),
    downloadPickuplistWiseCharging: (pageNo, rowsPerPage, search, jwtToken) =>
      dispatch(
        downloadPickuplistWiseCharging(pageNo, rowsPerPage, search, jwtToken)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(PickupListWiseCharging);
