/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Formik and Yup
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getAllGRN,
  getLineItemsByGrn,
  individualPrint,
  packagePrint,
  getSubModulesByRoleId,
  setPackSize,
} from "../../store";

// config file
import Config from "../../config/index";

// Cookies
import Cookies from "universal-cookie";

import { Switch, ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "white",
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "rgb(25, 118, 210)",
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.5,
          backgroundColor: "grey",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.6,
            backgroundColor: "rgb(25, 118, 210)",
          },
        },
      },
    },
  },
});

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 800, lg: 1000, xl: 1200 },
  overflow: "auto",
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const styleForAddPackSize = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForLabelModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 1000,
  width: "auto",
  maxWidth: 1000,
  minWidth: 500,
  // height: 600,
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const packSizeMsgModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const GrnDetails = ({
  getAllGRN,
  allGRN,
  getLineItemsByGrn,
  lineItemsByGrn,
  individualPrint,
  packagePrint,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  setPackSizeHandler,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let printerConfig = cookies.get("printerConfigForWarehouse");
  let printerId = cookies.get("printerIdForWarehouse");

  let jwtToken = loginDetails?.jwt;
  let role = loginDetails?.data.role;
  let userId = loginDetails.data.userId;
  let userName = loginDetails.data.userName;
  let roleId = loginDetails.data.roleId;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let grnModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "3279c985-ff7e-4b94-941c-0e34ad514a03"
  );

  let viewGRN = grnModule
    ? grnModule.actionId.includes("cd194e45-3838-4873-937e-fca759a55436")
    : null;

  let printGrn = grnModule
    ? grnModule.actionId.includes("fbdb9be2-aed8-4a65-872f-843b83262ef8")
    : null;

  const navigate = useNavigate();

  const [materialCode, setMaterialCode] = useState("");

  useEffect(() => {
    let timeout = setTimeout(() => {
      getAllGRN(materialCode, jwtToken);
    }, 500);

    return () => clearTimeout(timeout);
  }, [materialCode]);

  const [quotient, setQuotient] = useState("");
  const [remainder, setRemainder] = useState("");

  const [singleLineItemData, setSingleLineItemData] = useState({});

  useEffect(() => {
    let a = +singleLineItemData?.ActualQuantity;
    let b = +singleLineItemData?.PackSize;

    let quo = +Math.floor(a / b);
    let rem = +a % b;

    setQuotient(quo !== Infinity ? quo : 0);
    setRemainder(rem !== NaN ? rem : 0);
  }, [singleLineItemData]);

  const [isErrorLabelPrint, setIsErrorLabelPrint] = useState(false);
  const [errorMsgLabelPrint, setErrorMsgLabelPrint] = useState("");

  const initialValue = {
    printingType: "",
    packSize: +singleLineItemData?.PackSize,
    totalPacks: quotient
      ? remainder === 0
        ? Array(quotient).fill(+singleLineItemData?.PackSize)
        : [
            ...Array(quotient).fill(+singleLineItemData?.PackSize),
            ...Array(1).fill(remainder),
          ]
      : [],
    fullPacks: "",
    packPerPallet: "",
    packsPerPalletArray: [],
    isPartialPacks: false,
    partialPacksNo: "",
    partialPacks: [],
  };

  const [limitForFullPacks, setLimitForFullPacks] = useState(0);
  const [limitForPackPerPallet, setLimitForPackPerPallet] = useState(0);

  const validationSchema = Yup.object({
    fullPacks: Yup.number()
      .positive("Please enter a positive number")
      .max(limitForFullPacks, `Value should be < or = to ${limitForFullPacks}`),
    packPerPallet: Yup.number()
      .positive("Please enter a positive number")
      .max(
        limitForPackPerPallet,
        `Value should be < or = to ${limitForPackPerPallet}`
      ),
    partialPacksNo: Yup.number().positive("Please enter a positive number"),
  });

  const onSubmitForPrint = async (values, { resetForm }) => {
    values.partialPacks =
      values.partialPacks === null || Number.isNaN(values.partialPacks[0])
        ? []
        : values.partialPacks;

    setPrintLabelButton(false);
    const payload = {
      ...singleLineItemData,
      ...values,
      printerId: printerId,
      userId: userId,
      userName: userName,
    };

    if (values.printingType === "Individual Printing") {
      // const payload = singleLineItemData;
      let res = await individualPrint(payload, jwtToken);

      if (res.status) {
        getLineItemsByGrn(grnNo, jwtToken);
        getAllGRN(materialCode, jwtToken);
        setOpenPrintLabelModal(false);
        setConfirmationMsg(false);
        setShowWarningMsg(false);
      }
      if (!res.status) {
        setErrorMsgLabelPrint(res.data.data.msg);
        setIsErrorLabelPrint(true);
      }
    }

    if (values.printingType === "Package Printing") {
      let res = await packagePrint(payload, jwtToken);

      if (res.status) {
        getLineItemsByGrn(grnNo, jwtToken);
        getAllGRN(materialCode, jwtToken);
        setConfirmationMsg(false);
        setShowWarningMsg(false);
        handleClosePrintLabelModal();
      }
      if (!res.status) {
        setErrorMsgLabelPrint(res.data.data.msg);
        setIsErrorLabelPrint(true);
      }
    }
  };

  useEffect(() => {
    if (isErrorLabelPrint) {
      setTimeout(() => {
        setIsErrorLabelPrint(false);
      }, 3000);
    }
  }, [isErrorLabelPrint]);

  const [state, setState] = useState({
    columns: [
      {
        Header: "GRN No",
        accessor: "grnNo",
        align: "left",
      },
      { Header: "PO No", accessor: "poNo", align: "left" },
      { Header: "ASN No", accessor: "asnNo", align: "left" },
      { Header: "Inv No", accessor: "invNo", align: "left" },
      { Header: "GRN Date", accessor: "grnDate", align: "left" },
      {
        Header: "Vendor Code / Name",
        accessor: "vendorCodeName",
        align: "left",
      },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
      {
        Header: "Putaway Status",
        accessor: "putawayStatus",
        align: "left",
        cannotSearch: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [itemTable, setItemTable] = useState({
    columns: [
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
      {
        Header: "Item Code/Desc",
        accessor: "itemCodeDesc",
        align: "left",
      },
      { Header: "MFG Date", accessor: "mfgDate", align: "left" },
      { Header: "Item Qty", accessor: "itemQty", align: "left" },
      { Header: "Batch", accessor: "batch", align: "left" },
      { Header: "SLOC", accessor: "sloc", align: "left" },
      {
        Header: "Status",
        accessor: "status",
        align: "left",
        cannotSearch: true,
      },
      { Header: "Pack Size", accessor: "packSize", align: "left" },
    ],
    rows: [],
  });

  const { columns: itemColumns, rows: itemRows } = itemTable;

  const [grnNo, setGrnNo] = useState("");
  const [vendorCodeName, setVendorCodeName] = useState("");
  const [invNo, setInvNo] = useState("");
  const [asnNo, setAsnNo] = useState("");

  const [openGrnLineItemsModal, setOpenGrnLineItemsModal] = useState(false);

  const handleOpenLineItemsModal = () => {
    setOpenGrnLineItemsModal(true);
  };

  const handleCloseLineItemsModal = () => {
    setOpenGrnLineItemsModal(false);
  };

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  const [grnData, setGrnData] = useState("");

  const [printLabelButton, setPrintLabelButton] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPrintLabelButton(true);
    }, 500);
  }, [allGRN]);

  useEffect(() => {
    let tempGRN = [];
    let data = allGRN.GRN.data ? allGRN.GRN.data : [];

    setErrorMsgFromApi(allGRN?.error?.data?.msg);
    setGrnData(data);
    setLoading(allGRN.loading);

    data.map((singleGrn) => {
      const year = parseInt(singleGrn?.GrnDate?.substring(0, 4));
      const month = parseInt(singleGrn?.GrnDate?.substring(4, 6)) - 1;
      const day = parseInt(singleGrn?.GrnDate?.substring(6, 8));

      const dateObject = new Date(year, month, day);
      const formattedDate = dateObject.toLocaleDateString("en-GB");

      // const formatDate = singleGrn.grnDate ? new Date(singleGrn.grnDate) : "";
      const grnObj = {
        privateKey: singleGrn.GRNNumber,
        grnNo: singleGrn.GRNNumber,
        poNo: singleGrn.PONumber,
        asnNo: singleGrn.ASNNumber,
        invNo: singleGrn.InvoiceNo,
        vendorCodeName: `${singleGrn.VendorCode} / ${singleGrn.VendorName}`,
        grnDate: singleGrn.GrnDate === "00000000" ? "" : formattedDate,
        putawayStatus: (
          <button
            className={
              (singleGrn.IsPrinted === "0" &&
                singleGrn.IsPutaway === "0" &&
                `status-error`) ||
              (singleGrn.IsPrinted === "1" &&
                singleGrn.IsPutaway === "1" &&
                `status-success`) ||
              `status-warning`
            }
            style={{ width: "5.5rem" }}
          >
            {(singleGrn.IsPrinted === "0" &&
              singleGrn.IsPutaway === "0" &&
              `Not Printed`) ||
              (singleGrn.IsPrinted === "1" &&
                singleGrn.IsPutaway === "1" &&
                `Done`) ||
              `Pending`}
          </button>
        ),
        action: (
          <Tooltip title="View Line Items">
            <MDButton
              variant="gradient"
              color="info"
              disabled={!viewGRN}
              iconOnly
              type="button"
              onClick={() => {
                setGrnNo(singleGrn.GRNNumber);
                setAsnNo(singleGrn.PONumber);
                setInvNo(singleGrn.InvoiceNo);
                getLineItemsByGrn(singleGrn.GRNNumber, jwtToken);
                handleOpenLineItemsModal();
                setVendorCodeName(
                  `${singleGrn.VendorCode} / ${singleGrn.VendorName}`
                );
              }}
            >
              <Icon> visibility_icon </Icon>
            </MDButton>
          </Tooltip>
        ),
      };
      tempGRN.push(grnObj);
    });

    viewGRN && setState({ ...state, rows: tempGRN });
  }, [allGRN]);

  const [loadingForLineItems, setLoadingForLineItems] = useState(true);
  const [loadingForPackSize, setLoadingForPackSize] = useState(false);

  // let loading = allGRN.loading;
  // let loadingForLineItems = lineItemsByGrn.loading;

  const [errorMsgForLineItemFromApi, setErrorMsgForLineItemFromApi] =
    useState("");

  useEffect(() => {
    let tempGRN = [];
    let data = lineItemsByGrn.lineItems.data
      ? lineItemsByGrn?.lineItems.data
      : [];

    setErrorMsgForLineItemFromApi(lineItemsByGrn?.error?.data?.msg);
    setLoadingForLineItems(lineItemsByGrn.loading);

    data.map((lineItem) => {
      let des = lineItem?.MaterialDescription;
      let len = desLength;

      const year = parseInt(lineItem?.MfgDate.substring(0, 4));
      const month = parseInt(lineItem?.MfgDate.substring(4, 6)) - 1;
      const day = parseInt(lineItem?.MfgDate.substring(6, 8));

      const dateObject = new Date(year, month, day);
      const formattedDate = dateObject.toLocaleDateString("en-GB");

      let cookieGrnLineNo = cookies.get(
        `${lineItem.GRNNumber}-${lineItem.GRNLineNumber}`
      );

      // const formatDate = lineItem.mfgDate ? new Date(lineItem.mfgDate) : "";
      const lineItemObj = {
        privateKey: lineItem.id,
        itemCodeDesc: `${lineItem.MaterialCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        mfgDate: lineItem.MfgDate === "00000000" ? "" : formattedDate,
        itemQty: `${lineItem.ActualQuantity} ${lineItem.UOM}`,
        batch: lineItem.BatchNumber,
        sloc: lineItem.StoringLocation,
        status: (
          <button
            className={
              (lineItem.IsPrinted === "0" &&
                lineItem.IsPutaway === "0" &&
                `status-error`) ||
              (lineItem.IsPrinted === "1" &&
                lineItem.IsPutaway === "1" &&
                `status-success`) ||
              `status-warning`
            }
            style={{ width: "5.5rem" }}
          >
            {(lineItem.IsPrinted === "0" &&
              lineItem.IsPutaway === "0" &&
              `Not Printed`) ||
              (lineItem.IsPrinted === "1" &&
                lineItem.IsPutaway === "1" &&
                `Done`) ||
              `Pending`}
          </button>
        ),
        packSize: lineItem.PackSize ? (
          lineItem.IsPrinted === "1" ? (
            `${lineItem.PackSize} ${lineItem.UOM}`
          ) : (
            <span style={{ display: "flex" }}>
              <MDTypography
                variant="h6"
                fontWeight="regular"
                style={{ marginTop: "0.45rem" }}
              >
                {lineItem.PackSize} {lineItem.UOM}
              </MDTypography>
              <Tooltip title="Edit Pack Size" style={{ marginLeft: "0.75rem" }}>
                <MDButton
                  color="info"
                  variant="gradient"
                  type="button"
                  iconOnly
                  onClick={() => {
                    setSingleLineItemData(lineItem);
                    handleOpenAddPackSizeModal();
                  }}
                >
                  <Icon> edit </Icon>
                </MDButton>
              </Tooltip>
            </span>
          )
        ) : (
          <Tooltip title="Add Pack Size">
            <MDButton
              color="info"
              variant="gradient"
              type="button"
              onClick={() => {
                setSingleLineItemData(lineItem);
                handleOpenAddPackSizeModal();
              }}
            >
              Add
            </MDButton>
          </Tooltip>
        ),
        action: (
          <Tooltip title={lineItem.IsPrinted === "0" ? `Print` : `Re-Print`}>
            <MDButton
              color={lineItem.IsPrinted === "0" ? `success` : `error`}
              variant="gradient"
              disabled={!printGrn || lineItem.IsPrinted === "1"}
              type="button"
              iconOnly
              onClick={() => {
                setSingleLineItemData(lineItem);

                if (printerConfig && lineItem.IsPrinted === "0") {
                  if (lineItem.PackSize !== "" && lineItem.PackSize !== "0") {
                    handleOpenPrintLabelModal();
                  } else {
                    handleOpenSetPackSizeMsgModal();
                  }
                }
                // if (printerConfig && lineItem.IsPrinted === 1) {
                //   navigate("/reprint");
                // }
                if (!printerConfig) {
                  navigate("/printer-configurator");
                }
              }}
            >
              <Icon> print </Icon>
            </MDButton>
          </Tooltip>
        ),
      };
      tempGRN.push(lineItemObj);
    });
    setItemTable({ ...itemTable, rows: tempGRN });
  }, [lineItemsByGrn]);

  const [isErrorSetPackSize, setIsErrorSetPackSize] = useState(false);
  const [isSuccessSetPackSize, setIsSuccessSetPackSize] = useState(false);

  useEffect(() => {
    if (isErrorSetPackSize) {
      setTimeout(() => {
        setIsErrorSetPackSize(false);
      }, 3000);
    }
  }, [isErrorSetPackSize]);

  useEffect(() => {
    if (isSuccessSetPackSize) {
      setTimeout(() => {
        setIsSuccessSetPackSize(false);
      }, 3000);
    }
  }, [isSuccessSetPackSize]);

  const [packSizeState, setPackSizeState] = useState("");

  const packSizeHandler = (e) => {
    setPackSizeState(+e.target.value);
  };

  useEffect(() => {
    setPackSizeState(+singleLineItemData.PackSize);
  }, [singleLineItemData]);

  const [packSizeErrorMsg, setPackSizeErrorMsg] = useState("");

  const packSizeSubmitHandler = async () => {
    const payload = {
      GRNNumber: singleLineItemData.GRNNumber,
      MaterialCode: singleLineItemData.MaterialCode,
      BatchNumber: singleLineItemData.BatchNumber,
      GRNLineNumber: singleLineItemData.GRNLineNumber,
      PackSize: packSizeState,
    };
    setLoadingForPackSize(true);

    let res = await setPackSizeHandler(payload, jwtToken);

    if (res.status) {
      getLineItemsByGrn(grnNo, jwtToken);
      // handleCloseAddPackSizeModal();
      // setPackSizeState("");
      setIsSuccessSetPackSize(true);
      setLoadingForPackSize(false);
      setTimeout(() => {
        handleCloseAddPackSizeModal();
      }, 3000);
    }
    if (!res.status) {
      setPackSizeErrorMsg(res.data.data.message);
      setIsErrorSetPackSize(true);
      setLoadingForPackSize(false);
    }
  };

  const [openAddPackSizeModal, setOpenAddPackSizeModal] = useState(false);

  const handleOpenAddPackSizeModal = () => {
    setOpenAddPackSizeModal(true);
  };
  const handleCloseAddPackSizeModal = () => {
    setPackSizeState("");
    setOpenAddPackSizeModal(false);
  };

  const [openSetPackSizeMsgModal, setOpenSetPackSizeMsgModal] = useState(false);

  const handleOpenSetPackSizeMsgModal = () => {
    setOpenSetPackSizeMsgModal(true);
  };

  const handleCloseSetPackSizeMsgModal = () => {
    setOpenSetPackSizeMsgModal(false);
    handleOpenAddPackSizeModal();
  };

  const [openPrintLabelModal, setOpenPrintLabelModal] = useState(false);

  const handleOpenPrintLabelModal = () => {
    setOpenPrintLabelModal(true);
  };
  const handleClosePrintLabelModal = () => {
    setShowPackagePrintingContent(true);
    setBalance("");
    setConfirmationMsg(false);
    setShowWarningMsg(false);
    setOpenPrintLabelModal(false);
    setSumOfArray(0);
  };

  const [confirmationMsg, setConfirmationMsg] = useState(false);

  const onClickForConfirmation = () => {
    setConfirmationMsg(true);
  };

  const [sumOfFullPacksArray, setSumOfFullPacksArray] = useState(0);

  const [sumOfArray, setSumOfArray] = useState(
    +singleLineItemData?.ActualQuantity
  );

  const [balance, setBalance] = useState(0);

  useEffect(() => {
    let rem = +singleLineItemData?.ActualQuantity - +sumOfArray;

    if (isNaN(rem)) {
      setBalance(+singleLineItemData?.ActualQuantity);
    }
    if (!isNaN(rem)) {
      setBalance(rem);
    }
  }, [sumOfArray]);

  let pushPackageForPackagePrintPartial;
  let pushPackageForpackagePrintPacksPerPallet;
  let pushPackageForIndividualPrint;
  const [openBalanceItemModal, setOpenBalanceItemModal] = useState(false);

  const handleCloseBalanceItemModal = () => {
    setOpenBalanceItemModal(false);
  };

  const [balanceItemMsg, setBalanceItemMsg] = useState("");

  useEffect(() => {
    if (balance > 0) {
      setBalanceItemMsg(`${balance} ${singleLineItemData.UOM} 
       remaining`);
    }
    if (balance < 0) {
      setBalanceItemMsg(
        `${Math.abs(balance)} ${singleLineItemData.UOM} needs to be removed`
      );
    }

    if (balance === 0) {
      setBalanceItemMsg("GRN Qty matched");
    }
  }, [balance]);

  const [showWarningMsg, setShowWarningMsg] = useState(false);

  const [showPackagePrintingContent, setShowPackagePrintingContent] =
    useState(true);

  const [isErrorFullPackValue, setIsErrorFullPackValue] = useState(true);
  const [errorMsgFullPackValue, setErrorMsgFullPackValue] = useState("");

  const [isErrorPartialPackOn, setIsErrorPartialPackOn] = useState(false);
  const [errorMsgPartialPackOn, setErrorMsgPartialPackOn] = useState("");

  const [thresholdValue, setThresholdValue] = useState(0);

  useEffect(() => {
    let a = +singleLineItemData?.ActualQuantity;
    let b = +singleLineItemData?.PackSize;

    let c = a / b;

    setThresholdValue(c);
    setLimitForFullPacks(c);

    setErrorMsgFullPackValue(`No of Full Packs should be equal to ${c}`);
  }, [singleLineItemData]);

  useEffect(() => {
    setErrorMsgPartialPackOn(
      `No of Full Packs should be less than ${thresholdValue}`
    );
  }, [thresholdValue]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-3}
              >
                <MDBox display="flex">
                  <MDBox>
                    <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                      Material Code
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="materialCode"
                      placeholder="search..."
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => {
                        setMaterialCode(e.target.value);
                      }}
                      value={materialCode}
                      inputProps={{
                        style: {
                          height: "13px",
                        },
                      }}
                    />
                  </MDBox>
                  {grnData.length === 0 && materialCode !== "" && (
                    <MDTypography
                      color="error"
                      variant="h6"
                      style={{
                        textAlign: "center",
                        marginLeft: "1rem",
                        marginTop: "2rem",
                      }}
                    >
                      Invalid Code!
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>

              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  errorMsg={errorMsgFromApi}
                />
              </MDBox>

              <div>
                <Modal
                  open={openGrnLineItemsModal}
                  onClose={handleCloseLineItemsModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Items
                      </MDTypography>
                      <MDBox>
                        <span>
                          <Tooltip title="Close">
                            <IconButton
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                                marginLeft: "-55px",
                                marginBottom: "5px",
                              }}
                              aria-label="close"
                              color="inherit"
                              onClick={handleCloseLineItemsModal}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </span>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginBottom: "1rem" }} />
                    {!loadingForLineItems ? (
                      <MDBox>
                        <MDTypography
                          variant="h6"
                          gutterBottom
                          marginLeft="25px"
                        >
                          ASN: {asnNo}
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          gutterBottom
                          marginLeft="25px"
                        >
                          Inv No: {invNo}
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          gutterBottom
                          marginLeft="25px"
                        >
                          Vendor: {vendorCodeName}
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          gutterBottom
                          marginLeft="25px"
                        >
                          GRN: {grnNo}
                        </MDTypography>

                        <DataTable
                          table={{ columns: itemColumns, rows: itemRows }}
                          isSorted={true}
                          tableSearch={true}
                          entriesPerPage={{
                            defaultValue: 30,
                            entries: [30, 50, 75, 100],
                          }}
                          showTotalEntries={true}
                          noEndBorder
                          errorMsg={errorMsgForLineItemFromApi}
                        />
                      </MDBox>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "100px",
                          marginBottom: "100px",
                        }}
                      >
                        <CircularProgress color="info" />
                      </div>
                    )}
                  </MDCard>
                </Modal>
              </div>

              <Modal
                open={openAddPackSizeModal}
                onClose={handleCloseAddPackSizeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForAddPackSize}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Add Pack Size
                    </MDTypography>
                    <MDBox>
                      <span>
                        <Tooltip title="Close">
                          <IconButton
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-40px",
                            }}
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseAddPackSizeModal}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />
                  {!loadingForPackSize ? (
                    <span>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Pack Size</MDTypography>
                        <MDInput
                          type="number"
                          name="packSizeState"
                          fullWidth
                          autoComplete="off"
                          // onBlur={formik.handleBlur}
                          onChange={packSizeHandler}
                          value={packSizeState}
                        />
                      </MDBox>

                      <MDBox mt={3}>
                        <MDButton
                          variant="contained"
                          color="info"
                          fullWidth
                          type="submit"
                          disabled={
                            packSizeState === "" ||
                            packSizeState <= 0 ||
                            packSizeState > singleLineItemData.ActualQuantity
                          }
                          onClick={packSizeSubmitHandler}
                        >
                          Add Pack Size
                        </MDButton>
                      </MDBox>
                      <MDBox className="mt-3">
                        <Collapse in={isErrorSetPackSize}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorSetPackSize(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {/* Could not set the Pack Size! */}
                            {packSizeErrorMsg}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessSetPackSize}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessSetPackSize(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Pack Size set successfully!
                          </Alert>
                        </Collapse>
                      </MDBox>
                    </span>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    >
                      <CircularProgress color="info" />
                    </div>
                  )}
                </MDCard>
              </Modal>

              <Modal
                open={openPrintLabelModal}
                onClose={handleClosePrintLabelModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForLabelModal}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Print Label
                    </MDTypography>
                    <MDBox>
                      <span>
                        <Tooltip title="Close">
                          <IconButton
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-40px",
                            }}
                            aria-label="close"
                            color="inherit"
                            onClick={handleClosePrintLabelModal}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <Formik
                    initialValues={initialValue}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitForPrint}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className="form-group">
                            <MDTypography
                              variant="h5"
                              fontWeight="medium"
                              flexGrow={1}
                            >
                              Type of Printing
                            </MDTypography>
                            <div>
                              <Field
                                type="radio"
                                name="printingType"
                                value="Individual Printing"
                                className="radio"
                              />
                              <span>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  style={{
                                    marginLeft: "1.5rem",
                                    fontSize: "15px",
                                    marginTop: "-1.77rem",
                                    fontWeight: 500,
                                  }}
                                >
                                  Individual Printing
                                </MDTypography>
                              </span>
                            </div>
                            <div>
                              <Field
                                type="radio"
                                name="printingType"
                                value="Package Printing"
                                className="radio"
                              />
                              <span>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  style={{
                                    marginLeft: "1.5rem",
                                    fontSize: "15px",
                                    marginTop: "-1.77rem",
                                    fontWeight: 500,
                                  }}
                                >
                                  Package Printing
                                </MDTypography>
                              </span>
                            </div>
                          </div>

                          {formik.values.printingType === "Package Printing" &&
                            !showPackagePrintingContent && (
                              <MDBox mt={1}>
                                <MDButton
                                  type="button"
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    setShowPackagePrintingContent(true);
                                  }}
                                >
                                  <Icon>keyboard_backspace</Icon>&nbsp; Back
                                </MDButton>
                              </MDBox>
                            )}

                          {formik.values.printingType ===
                            "Individual Printing" && (
                            <div style={{ marginTop: "20px" }}>
                              <MDTypography variant="h6">
                                Material Code/Desc:{" "}
                                {`${singleLineItemData.MaterialCode} / ${
                                  singleLineItemData?.MaterialDescription
                                    ? singleLineItemData?.MaterialDescription.substring(
                                        0,
                                        36
                                      )
                                    : ""
                                }`}
                              </MDTypography>
                              <div
                                style={{ display: "flex", marginTop: "1rem" }}
                              >
                                <MDTypography variant="h6" color="info">
                                  Pack Size:{" "}
                                  {`${+singleLineItemData.PackSize} ${
                                    singleLineItemData.UOM
                                  }`}
                                </MDTypography>
                                <MDTypography
                                  variant="h6"
                                  style={{ marginLeft: "2rem" }}
                                  color="info"
                                >
                                  GRN Qty:{" "}
                                  {`${singleLineItemData.ActualQuantity} ${singleLineItemData.UOM}`}
                                </MDTypography>
                                <MDTypography
                                  variant="h6"
                                  style={{ marginLeft: "2rem" }}
                                  color={balance === 0 ? "success" : "error"}
                                >
                                  {formik.values.totalPacks.length > 0 &&
                                    `Balance: ${balanceItemMsg}`}
                                  {/*  `Balance: ${balance} ${singleLineItemData.UOM}`} */}
                                </MDTypography>
                              </div>
                              <div>
                                <FieldArray name="totalPacks">
                                  {(fieldArrayProps) => {
                                    const { push, remove, form } =
                                      fieldArrayProps;
                                    const { values } = form;
                                    const { totalPacks } = values;
                                    pushPackageForIndividualPrint = push;

                                    let newTotalPacks =
                                      totalPacks.filter(Number);

                                    let sum = +newTotalPacks.reduce(
                                      (a, b) => a + b,
                                      0
                                    );

                                    setSumOfArray(sum);

                                    return (
                                      <div>
                                        <Grid container>
                                          {totalPacks.map((type, index) => (
                                            <Grid item xs={6}>
                                              <div
                                                key={index}
                                                style={{
                                                  display: "flex",
                                                  marginTop: "0.2rem",
                                                }}
                                              >
                                                <label
                                                  htmlFor="totalPacks"
                                                  style={{
                                                    marginTop: "0.4rem",
                                                    // marginRight: "3rem",
                                                  }}
                                                >
                                                  <MDTypography variant="h6">
                                                    Package {index + 1}
                                                  </MDTypography>
                                                </label>

                                                <Field
                                                  name={`totalPacks[${index}]`}
                                                  type="number"
                                                  step="any"
                                                  min={0}
                                                  style={{
                                                    width: "100px",
                                                    borderRadius: 10,
                                                    marginLeft: 0,
                                                    height: 35,
                                                    paddingLeft: "15px",
                                                    marginLeft: "2rem",
                                                    margin: "5px",
                                                    // float: "right",
                                                  }}
                                                  autoComplete="off"
                                                  value={
                                                    formik.values.totalPacks[
                                                      index
                                                    ]
                                                  }
                                                  onChange={(e) => {
                                                    formik.handleChange(e);
                                                  }}
                                                />

                                                <span>
                                                  {index > 0 && (
                                                    <MDButton
                                                      type="button"
                                                      onClick={(e) => {
                                                        remove(index);
                                                      }}
                                                    >
                                                      <IconButton
                                                        style={{
                                                          background:
                                                            "whitesmoke",
                                                          color: "black",
                                                          borderRadius: 5,
                                                          // marginLeft: "-42px",
                                                        }}
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                      >
                                                        <CloseIcon fontSize="inherit" />
                                                      </IconButton>
                                                    </MDButton>
                                                  )}
                                                </span>
                                              </div>
                                            </Grid>
                                          ))}
                                          {balance !== 0 &&
                                            formik.values.totalPacks.length >
                                              0 && (
                                              <MDButton
                                                type="button"
                                                variant="outlined"
                                                color="info"
                                                style={{
                                                  marginBottom: "1rem",
                                                  marginTop: "10px",
                                                }}
                                                onClick={() => {
                                                  push("");
                                                }}
                                              >
                                                Add Package
                                              </MDButton>
                                            )}
                                        </Grid>
                                      </div>
                                    );
                                  }}
                                </FieldArray>
                              </div>
                            </div>
                          )}

                          {formik.values.printingType ===
                            "Package Printing" && (
                            <div style={{ marginTop: "20px" }}>
                              <MDTypography variant="h6">
                                Material Code/Desc:{" "}
                                {`${singleLineItemData.MaterialCode} / ${
                                  singleLineItemData?.MaterialDescription
                                    ? singleLineItemData?.MaterialDescription.substring(
                                        0,
                                        36
                                      )
                                    : ""
                                }`}
                              </MDTypography>
                              <div
                                style={{ display: "flex", marginTop: "1rem" }}
                              >
                                <MDTypography variant="h6" color="info">
                                  Pack Size:{" "}
                                  {`${+singleLineItemData.PackSize} ${
                                    singleLineItemData.UOM
                                  }`}
                                </MDTypography>
                                <MDTypography
                                  variant="h6"
                                  style={{ marginLeft: "2rem" }}
                                  color="info"
                                >
                                  GRN Qty:{" "}
                                  {`${singleLineItemData.ActualQuantity} ${singleLineItemData.UOM}`}
                                </MDTypography>
                                <MDTypography
                                  variant="h6"
                                  style={{ marginLeft: "2rem" }}
                                  color={balance === 0 ? "success" : "error"}
                                >
                                  {formik.values.partialPacks.length > 0 &&
                                    `Balance: ${balanceItemMsg}`}
                                  {/*  `Balance: ${balance} ${singleLineItemData.UOM}`} */}
                                </MDTypography>
                              </div>

                              {showPackagePrintingContent && (
                                <div style={{ marginTop: "10px" }}>
                                  <div
                                    className="form-group"
                                    style={{
                                      display: "flex",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <MDTypography
                                      variant="h6"
                                      fontWeight="medium"
                                      flexGrow={1}
                                      style={{
                                        marginTop: "0.2rem",
                                      }}
                                    >
                                      Partial Packs?
                                    </MDTypography>

                                    <ThemeProvider theme={theme}>
                                      <Switch
                                        checked={formik.values.isPartialPacks}
                                        onChange={() => {
                                          formik.setFieldValue(
                                            "isPartialPacks",
                                            !formik.values.isPartialPacks
                                          );
                                        }}
                                      />
                                    </ThemeProvider>
                                  </div>

                                  <div
                                    className="form-group"
                                    style={{
                                      display: "flex",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <MDTypography
                                      variant="h6"
                                      fontWeight="medium"
                                      flexGrow={1}
                                      style={{
                                        marginTop: "0.2rem",
                                      }}
                                    >
                                      No of Full Packs
                                    </MDTypography>

                                    <span>
                                      <Field
                                        type="number"
                                        step="any"
                                        min={0}
                                        max={thresholdValue}
                                        name="fullPacks"
                                        className="form-control"
                                        autoComplete="off"
                                        style={{
                                          width: "200px",
                                          borderRadius: 10,
                                          marginLeft: 0,
                                          height: 35,
                                          paddingLeft: "15px",
                                        }}
                                      />
                                      {formik.errors.fullPacks && (
                                        <TextError
                                          style={{ fontSize: "10px" }}
                                          msg={formik.errors.fullPacks}
                                        />
                                      )}
                                    </span>
                                  </div>

                                  {setLimitForPackPerPallet(
                                    +formik.values.fullPacks
                                  )}
                                  {!formik.values.isPartialPacks &&
                                    +formik.values.fullPacks ===
                                      thresholdValue &&
                                    setIsErrorFullPackValue(false)}

                                  {!formik.values.isPartialPacks &&
                                    +formik.values.fullPacks !==
                                      thresholdValue &&
                                    setIsErrorFullPackValue(true)}

                                  {formik.values.isPartialPacks &&
                                    setIsErrorFullPackValue(false)}

                                  {formik.values.isPartialPacks &&
                                    +formik.values.fullPacks >=
                                      thresholdValue &&
                                    setIsErrorPartialPackOn(true)}

                                  {formik.values.isPartialPacks &&
                                    +formik.values.fullPacks < thresholdValue &&
                                    setIsErrorPartialPackOn(false)}

                                  <div
                                    className="form-group"
                                    style={{
                                      display: "flex",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <MDTypography
                                      variant="h6"
                                      fontWeight="medium"
                                      flexGrow={1}
                                      style={{
                                        marginTop: "0.2rem",
                                      }}
                                    >
                                      Packs per pallet
                                    </MDTypography>
                                    <span>
                                      <Field
                                        type="number"
                                        step="any"
                                        min={0}
                                        max={+formik.values.fullPacks}
                                        name="packPerPallet"
                                        className="form-control"
                                        autoComplete="off"
                                        style={{
                                          width: "200px",
                                          borderRadius: 10,
                                          marginLeft: 0,
                                          height: 35,
                                          paddingLeft: "15px",
                                        }}
                                      />
                                      {formik.errors.packPerPallet && (
                                        <TextError
                                          msg={formik.errors.packPerPallet}
                                        />
                                      )}
                                    </span>
                                  </div>

                                  {formik.values.isPartialPacks && (
                                    <div
                                      className="form-group"
                                      style={{
                                        display: "flex",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <MDTypography
                                        variant="h6"
                                        fontWeight="medium"
                                        flexGrow={1}
                                        style={{
                                          marginTop: "0.2rem",
                                        }}
                                      >
                                        No of Partial Packs
                                      </MDTypography>
                                      <span>
                                        <Field
                                          type="number"
                                          step="any"
                                          min={0}
                                          name="partialPacksNo"
                                          className="form-control"
                                          autoComplete="off"
                                          style={{
                                            width: "200px",
                                            borderRadius: 10,
                                            marginLeft: 0,
                                            height: 35,
                                            paddingLeft: "15px",
                                          }}
                                        />
                                        {formik.errors.partialPacksNo && (
                                          <TextError
                                            msg={formik.errors.partialPacksNo}
                                          />
                                        )}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              )}
                              {/* {+formik.values.fullPacks !== thresholdValue &&
                                setIsErrorFullPackValue(true)}

                              {+formik.values.fullPacks === thresholdValue &&
                                setIsErrorFullPackValue(false)} */}
                              {/* {formik.values.isPartialPacks === false &&
                                +formik.values.fullPacks !== thresholdValue &&
                                setIsErrorFullPackValue(true)} */}

                              {/* {formik.values.isPartialPacks === false &&
                                +formik.values.fullPacks === thresholdValue &&
                                setIsErrorFullPackValue(false)} */}

                              {showPackagePrintingContent && (
                                <div>
                                  <MDButton
                                    disabled={
                                      !formik.isValid ||
                                      formik.values.fullPacks === "" ||
                                      formik.values.packPerPallet === "" ||
                                      (formik.values.isPartialPacks === false &&
                                        formik.values.fullPacks *
                                          +singleLineItemData.PackSize !==
                                          +singleLineItemData.ActualQuantity) ||
                                      (formik.values.isPartialPacks === true &&
                                        formik.values.partialPacksNo === "") ||
                                      (formik.values.isPartialPacks === true &&
                                        formik.values.fullPacks >=
                                          thresholdValue)
                                    }
                                    type="button"
                                    variant="gradient"
                                    color="success"
                                    style={{
                                      marginBottom: "1rem",
                                      marginTop: "12px",
                                    }}
                                    onClick={() => {
                                      setShowWarningMsg(true);
                                      setShowPackagePrintingContent(false);

                                      let a = formik.values.fullPacks;
                                      let b = formik.values.packPerPallet;

                                      let quo = +Math.floor(a / b);
                                      let rem = +a % b;

                                      formik.setFieldValue(
                                        "packsPerPalletArray",
                                        rem === 0
                                          ? Array(quo).fill(b)
                                          : [
                                              ...Array(quo).fill(b),
                                              ...Array(1).fill(rem),
                                            ]
                                      );

                                      let p =
                                        formik.values.fullPacks *
                                        +singleLineItemData.PackSize;

                                      let q =
                                        +singleLineItemData.ActualQuantity - p;

                                      let r = q / formik.values.partialPacksNo;

                                      let s = Array(
                                        formik.values.partialPacksNo
                                      ).fill(r);

                                      formik.setFieldValue("partialPacks", s);
                                    }}
                                  >
                                    Confirm
                                  </MDButton>
                                </div>
                              )}

                              <MDBox className="mt-3">
                                <Collapse in={isErrorFullPackValue}>
                                  <Alert
                                    severity="error"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsErrorFullPackValue(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    {errorMsgFullPackValue}
                                  </Alert>
                                </Collapse>
                                <Collapse in={isErrorPartialPackOn}>
                                  <Alert
                                    severity="error"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsErrorPartialPackOn(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    {errorMsgPartialPackOn}
                                  </Alert>
                                </Collapse>
                              </MDBox>

                              {formik.values.isPartialPacks &&
                                !showPackagePrintingContent && (
                                  <div>
                                    <FieldArray name="partialPacks">
                                      {(fieldArrayProps) => {
                                        const { push, remove, form } =
                                          fieldArrayProps;
                                        const { values } = form;
                                        const { partialPacks } = values;
                                        pushPackageForPackagePrintPartial =
                                          push;

                                        let newPartialPacks =
                                          partialPacks.filter(Number);

                                        let sum = +newPartialPacks.reduce(
                                          (a, b) => a + b,
                                          0
                                        );

                                        if (formik.values.isPartialPacks) {
                                          setSumOfArray(
                                            +newPartialPacks.reduce(
                                              (a, b) => a + b,
                                              0
                                            ) +
                                              formik.values.packsPerPalletArray.reduce(
                                                (a, b) => a + b,
                                                0
                                              ) *
                                                +singleLineItemData.PackSize
                                          );
                                        }

                                        return (
                                          <div>
                                            {partialPacks.length > 0 && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  marginTop: "1rem",
                                                }}
                                              >
                                                <MDTypography
                                                  variant="h6"
                                                  color="warning"
                                                >
                                                  Partial Packs :{" "}
                                                  {partialPacks.length}
                                                </MDTypography>
                                                <MDTypography
                                                  variant="h6"
                                                  style={{ marginLeft: "2rem" }}
                                                  color="warning"
                                                >
                                                  Partial Packs Qty : {sum}{" "}
                                                  {singleLineItemData.UOM}
                                                </MDTypography>
                                              </div>
                                            )}
                                            <Grid container>
                                              {partialPacks.map(
                                                (type, index) => (
                                                  <Grid item xs={6}>
                                                    <div
                                                      key={index}
                                                      style={{
                                                        display: "flex",
                                                        marginTop: "0.2rem",
                                                      }}
                                                    >
                                                      <label
                                                        htmlFor="partialPacks"
                                                        style={{
                                                          marginTop: "0.4rem",
                                                        }}
                                                      >
                                                        <MDTypography variant="h6">
                                                          Pack {index + 1}
                                                        </MDTypography>
                                                      </label>
                                                      <Field
                                                        name={`partialPacks[${index}]`}
                                                        type="number"
                                                        step="any"
                                                        min={0}
                                                        style={{
                                                          width: "100px",
                                                          borderRadius: 10,
                                                          marginLeft: 0,
                                                          height: 35,
                                                          paddingLeft: "15px",
                                                          marginLeft: "2rem",
                                                          margin: "5px",
                                                        }}
                                                        autoComplete="off"
                                                        value={
                                                          formik.values
                                                            .partialPacks[index]
                                                        }
                                                        onChange={(e) => {
                                                          formik.handleChange(
                                                            e
                                                          );
                                                        }}
                                                      />

                                                      <span>
                                                        {index > 0 && (
                                                          <MDButton
                                                            type="button"
                                                            onClick={(e) => {
                                                              remove(index);
                                                            }}
                                                          >
                                                            <IconButton
                                                              style={{
                                                                background:
                                                                  "whitesmoke",
                                                                color: "black",
                                                                borderRadius: 5,
                                                                // marginLeft: "-42px",
                                                              }}
                                                              aria-label="close"
                                                              color="inherit"
                                                              size="small"
                                                            >
                                                              <CloseIcon fontSize="inherit" />
                                                            </IconButton>
                                                          </MDButton>
                                                        )}
                                                      </span>
                                                    </div>
                                                  </Grid>
                                                )
                                              )}
                                              {balance !== 0 &&
                                                formik.values.partialPacks
                                                  .length > 0 && (
                                                  <MDButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="info"
                                                    style={{
                                                      marginBottom: "1rem",
                                                      marginTop: "10px",
                                                    }}
                                                    onClick={() => {
                                                      push("");
                                                    }}
                                                  >
                                                    Add Package
                                                  </MDButton>
                                                )}
                                            </Grid>
                                          </div>
                                        );
                                      }}
                                    </FieldArray>
                                  </div>
                                )}

                              {formik.values.packsPerPalletArray.length > 0 &&
                                !showPackagePrintingContent && (
                                  <hr
                                    style={{
                                      marginTop: "0.5rem",
                                      marginBottom: "1rem",
                                    }}
                                  />
                                )}

                              {!showPackagePrintingContent && (
                                <div>
                                  <FieldArray name="packsPerPalletArray">
                                    {(fieldArrayProps) => {
                                      const { push, remove, form } =
                                        fieldArrayProps;
                                      const { values } = form;
                                      const { packsPerPalletArray } = values;
                                      pushPackageForpackagePrintPacksPerPallet =
                                        push;

                                      let newPacksPerPallet =
                                        packsPerPalletArray.filter(Number);
                                      let newPartialPacks =
                                        formik.values.partialPacks.filter(
                                          Number
                                        );

                                      setSumOfFullPacksArray(
                                        newPacksPerPallet.reduce(
                                          (a, b) => a + b,
                                          0
                                        )
                                      );

                                      if (!formik.values.isPartialPacks) {
                                        setSumOfArray(
                                          newPacksPerPallet.reduce(
                                            (a, b) => a + b,
                                            0
                                          ) * +singleLineItemData.PackSize
                                        );
                                      }

                                      if (formik.values.isPartialPacks) {
                                        setSumOfArray(
                                          +newPartialPacks.reduce(
                                            (a, b) => a + b,
                                            0
                                          ) +
                                            newPacksPerPallet.reduce(
                                              (a, b) => a + b,
                                              0
                                            ) *
                                              +singleLineItemData.PackSize
                                        );
                                      }

                                      return (
                                        <div>
                                          {packsPerPalletArray.length > 0 && (
                                            <div
                                              style={{
                                                display: "flex",
                                                marginTop: "1rem",
                                              }}
                                            >
                                              <MDTypography
                                                variant="h6"
                                                color="warning"
                                              >
                                                No of Full Packs :{" "}
                                                {/* {formik.values.fullPacks} */}
                                                {+sumOfFullPacksArray}
                                              </MDTypography>
                                              <MDTypography
                                                variant="h6"
                                                style={{ marginLeft: "2rem" }}
                                                color="warning"
                                              >
                                                Packs per Pallets{" "}
                                                {`(x ${+singleLineItemData.PackSize} ${
                                                  singleLineItemData.UOM
                                                })`}{" "}
                                                :{" "}
                                                {+sumOfFullPacksArray *
                                                  +singleLineItemData.PackSize}{" "}
                                                {singleLineItemData.UOM}
                                              </MDTypography>
                                            </div>
                                          )}
                                          <Grid container>
                                            {packsPerPalletArray.map(
                                              (type, index) => (
                                                <Grid item xs={6}>
                                                  <div
                                                    key={index}
                                                    style={{
                                                      display: "flex",
                                                      marginTop: "0.2rem",
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor="packsPerPalletArray"
                                                      style={{
                                                        marginTop: "0.4rem",
                                                        // marginRight: "3rem",
                                                      }}
                                                    >
                                                      <MDTypography variant="h6">
                                                        Pallet {index + 1}
                                                      </MDTypography>
                                                    </label>
                                                    {/* <br /> */}
                                                    <Field
                                                      name={`packsPerPalletArray[${index}]`}
                                                      type="number"
                                                      step="any"
                                                      min={0}
                                                      style={{
                                                        width: "100px",
                                                        borderRadius: 10,
                                                        marginLeft: 0,
                                                        height: 35,
                                                        paddingLeft: "15px",
                                                        marginLeft: "2rem",
                                                        margin: "5px",
                                                        // float: "right",
                                                      }}
                                                      autoComplete="off"
                                                      value={
                                                        formik.values
                                                          .packsPerPalletArray[
                                                          index
                                                        ]
                                                      }
                                                      onChange={(e) => {
                                                        formik.handleChange(e);
                                                      }}
                                                    />

                                                    <span>
                                                      {index > 0 && (
                                                        <MDButton
                                                          type="button"
                                                          onClick={(e) => {
                                                            remove(index);
                                                          }}
                                                        >
                                                          <IconButton
                                                            style={{
                                                              background:
                                                                "whitesmoke",
                                                              color: "black",
                                                              borderRadius: 5,
                                                              // marginLeft: "-42px",
                                                            }}
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                          >
                                                            <CloseIcon fontSize="inherit" />
                                                          </IconButton>
                                                        </MDButton>
                                                      )}
                                                    </span>
                                                  </div>
                                                </Grid>
                                              )
                                            )}
                                          </Grid>
                                          {balance !== 0 &&
                                            formik.values.packsPerPalletArray
                                              .length > 0 && (
                                              <MDButton
                                                type="button"
                                                variant="outlined"
                                                color="info"
                                                style={{
                                                  marginBottom: "1rem",
                                                  marginTop: "10px",
                                                }}
                                                onClick={() => {
                                                  push("");
                                                }}
                                              >
                                                Add Package
                                              </MDButton>
                                            )}
                                        </div>
                                      );
                                    }}
                                  </FieldArray>
                                </div>
                              )}
                            </div>
                          )}

                          {balance === 0 && (
                            <MDTypography variant="h5" mt={2}>
                              {formik.values.printingType ===
                                "Individual Printing" &&
                                confirmationMsg &&
                                `Do you want to print ${formik.values.totalPacks.length} individual labels?`}
                              {formik.values.printingType ===
                                "Package Printing" &&
                                confirmationMsg &&
                                `Do you want to print ${
                                  formik.values.isPartialPacks
                                    ? formik.values.packsPerPalletArray.length +
                                      formik.values.partialPacks.length
                                    : formik.values.packsPerPalletArray.length
                                } package labels?`}
                            </MDTypography>
                          )}
                          {/* {formik.values.printingType ===
                            "Individual Printing" &&
                            balance !== 0 &&
                            balance !== "" && (
                              <h6 style={{ color: "red" }}>{balanceItemMsg}</h6>
                            )}
                          {formik.values.printingType === "Package Printing" &&
                            balance !== 0 &&
                            balance !== "" &&
                            showWarningMsg && (
                              <h6 style={{ color: "red" }}>{balanceItemMsg}</h6>
                            )} */}

                          {balance === 0 && (
                            <div className="clearfix">
                              {!confirmationMsg &&
                                formik.values.printingType !== "" && (
                                  <MDButton
                                    style={{ marginTop: "10px" }}
                                    type="button"
                                    onClick={onClickForConfirmation}
                                    variant="gradient"
                                    color="info"
                                    fullWidth
                                    // color="info"
                                    disabled={balance === 0 ? false : true}
                                  >
                                    Print Label
                                  </MDButton>
                                )}
                              {confirmationMsg && (
                                <span>
                                  <MDButton
                                    type="button"
                                    variant="outlined"
                                    color="error"
                                    style={{ marginTop: "10px" }}
                                    onClick={() => {
                                      // setOpenPrintLabelModal(false);
                                      setConfirmationMsg(false);
                                    }}
                                  >
                                    Cancel
                                  </MDButton>
                                  <MDButton
                                    disabled={
                                      !printLabelButton || balance !== 0
                                    }
                                    type="submit"
                                    style={{
                                      marginTop: "10px",
                                      marginLeft: "40px",
                                    }}
                                    variant="gradient"
                                    color="success"
                                  >
                                    Yes
                                  </MDButton>
                                </span>
                              )}
                            </div>
                          )}
                          <MDBox className="mt-3">
                            <Collapse in={isErrorLabelPrint}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorLabelPrint(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {errorMsgLabelPrint}
                              </Alert>
                            </Collapse>
                          </MDBox>
                        </Form>
                      );
                    }}
                  </Formik>
                </MDCard>
              </Modal>

              <Modal
                open={openSetPackSizeMsgModal}
                onClose={handleCloseSetPackSizeMsgModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={packSizeMsgModal}>
                  <MDTypography>
                    Set the Pack Size before Printing the Label!
                  </MDTypography>
                  <MDBox mt={2} display="flex">
                    <div>
                      <MDButton
                        style={{ width: "100%", marginRight: "0.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseSetPackSizeMsgModal}
                      >
                        Close
                      </MDButton>
                    </div>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    allGRN: state.allGRN,
    lineItemsByGrn: state.lineItemsByGrn,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getAllGRN: (materialCode, jwtToken) =>
      dispatch(getAllGRN(materialCode, jwtToken)),
    getLineItemsByGrn: (grn, jwtToken) =>
      dispatch(getLineItemsByGrn(grn, jwtToken)),
    individualPrint: (payload, jwtToken) =>
      dispatch(individualPrint(payload, jwtToken)),
    packagePrint: (payload, jwtToken) =>
      dispatch(packagePrint(payload, jwtToken)),
    setPackSizeHandler: (payload, jwtToken) =>
      dispatch(setPackSize(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(GrnDetails);
