/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getReleasePicklist,
  removeReleasePicklist,
  createRelease,
  getUsersToAssign,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../../config/index";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

import { addMinutes, format } from "date-fns";

const desLength = Config.descriptionLength;

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const ReleasePicklist = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getReleasePicklist,
  releasePicklistData,
  removeReleasePicklist,
  createRelease,
  getUsersToAssign,
  usersData,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let userId = loginDetails?.data.userId;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let palletModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "b0ea8017-5741-4469-95a4-20f56414f28e"
  );

  let viewPivAccess = palletModule
    ? palletModule.actionId.includes("97a204aa-f56f-4bb1-be91-36bd5ef800e4")
    : null;

  let removeAccess = palletModule
    ? palletModule.actionId.includes("65e0b3fa-3124-4b21-ac92-da48b645f32f")
    : null;

  let releaseAccess = palletModule
    ? palletModule.actionId.includes("6b97547f-adec-420f-a3d1-ece9003bb1df")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getReleasePicklist(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [usersOptions, setUsersOptions] = useState([]);

  useEffect(() => {
    let tempUsersOptions = [];
    let data = usersData.usersToAssign.data ? usersData.usersToAssign.data : [];

    data.map((user) => {
      tempUsersOptions.push({
        label: user.userName,
        value: user.userId,
      });
    });
    setUsersOptions(tempUsersOptions);
  }, [usersData]);

  const [state, setState] = useState({
    columns: [
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Item Code / Des", accessor: "materialCode" },
      { Header: "Item Qty", accessor: "qty" },
      {
        Header: "Date & Time",
        accessor: "proReleaseTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      { Header: "From Loc", accessor: "source" },
      { Header: "To Loc", accessor: "destination" },
      { Header: "Pick List ID", accessor: "pickupListCode" },
      { Header: "Batch No / Qty", accessor: "batchNumber" },
      { Header: "Resource Category/ Resource", accessor: "resourceCategory" },
      { Header: "Bulk Code / Des", accessor: "finalMaterialCode" },
      { Header: "RM Category / Des", accessor: "rmCategory" },
      { Header: "Phase No", accessor: "phaseNumber" },
      { Header: "Phase Des", accessor: "phaseDescription" },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [loading, setLoading] = useState(false);

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  useEffect(() => {
    let tempPiv = [];

    let data = releasePicklistData.releasePicklist.data
      ? releasePicklistData.releasePicklist.data
      : [];

    setErrorMsgFromApi(releasePicklistData?.error?.data?.msg);
    setLoading(releasePicklistData.loading);
    setPaginationObj(releasePicklistData?.releasePicklist?.pagination);

    data.map((piv, index) => {
      let bulkDes = piv?.finalMaterialDescription;
      let itemDes = piv.materialDescription;
      let phaseDes = piv.phaseDescription;
      let rmDes = piv.rmCatDescription;
      let len = desLength;

      const pivObject = {
        privateKey: piv.pickupListCode,
        documentNo: piv.documentNo,
        batchNumber: `${piv.batchNumber} / ${piv.batchSize} ${piv.batchUom}`,
        finalMaterialCode: `${piv.finalMaterialCode} / ${
          bulkDes ? bulkDes.substring(0, len) : ""
        }`,
        materialCode: `${piv.materialCode} / ${
          itemDes ? itemDes.substring(0, len) : ""
        }`,
        rmCategory: `${piv.rmCategory} / ${
          rmDes ? rmDes.substring(0, len) : ""
        }`,
        phaseNumber: piv.phaseNumber,

        phaseDescription: phaseDes ? phaseDes.substring(0, len) : "",
        qty: `${piv.qty} ${piv.uom}`,
        destination: piv.destination,
        source: piv.source,
        resourceCategory: `${piv.resourceCategory} - ${piv.resource}`,
        pickupListCode: piv.pickupListCode,
        srNo: piv.srNo,
        proReleaseTime: piv.proReleaseTime,
      };
      tempPiv.push(pivObject);
    });

    viewPivAccess && setState({ ...state, rows: tempPiv });
  }, [releasePicklistData]);

  const [selectedReleasePicklistData, setSelectedReleasePicklistData] =
    useState([]);

  const releasePicklistDataSelectionHandler = (data) => {
    setSelectedReleasePicklistData(data);
  };

  const [isErrorRemoveRecords, setIsErrorRemoveRecords] = useState(false);
  const [isSuccessRemoveRecords, setIsSuccessRemoveRecords] = useState(false);

  useEffect(() => {
    if (isErrorRemoveRecords) {
      setTimeout(() => {
        setIsErrorRemoveRecords(false);
      }, 3000);
    }
  }, [isErrorRemoveRecords]);

  useEffect(() => {
    if (isSuccessRemoveRecords) {
      setTimeout(() => {
        setIsSuccessRemoveRecords(false);
      }, 3000);
    }
  }, [isSuccessRemoveRecords]);

  const [openRemoveRecordsModal, setOpenRemoveRecordsModal] = useState(false);

  const handleOpenRemoveRecordsModal = () => {
    setOpenRemoveRecordsModal(true);
  };
  const handleCloseRemoveRecordsModal = () => {
    setOpenRemoveRecordsModal(false);
  };

  const [isErrorAddUser, setIsErrorAddUser] = useState(false);
  const [isSuccessAddUser, setIsSuccessAddUser] = useState(false);

  useEffect(() => {
    if (isErrorAddUser) {
      setTimeout(() => {
        setIsErrorAddUser(false);
      }, 3000);
    }
  }, [isErrorAddUser]);

  useEffect(() => {
    if (isSuccessAddUser) {
      setTimeout(() => {
        setIsSuccessAddUser(false);
      }, 3000);
    }
  }, [isSuccessAddUser]);

  const [userSelected, setAddUserSelected] = useState("");

  const handleAddUserChange = (selectedUser) => {
    setAddUserSelected(selectedUser);
  };

  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  const handleOpenAddUserModal = () => {
    setOpenAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setOpenAddUserModal(false);
  };

  const initialValueForAddUser = {
    user: "",
  };

  const validationSchemaAddUser = Yup.object({
    user: Yup.object().required("Select user"),
  });

  const [releaseErrorMsg, setReleaseErrorMsg] = useState("");

  const [buttonName, setButtonName] = useState("Release");

  const onSubmitForAddUser = async (values, { resetForm }) => {
    setButtonName("Please wait...");

    let payload = [];

    selectedReleasePicklistData.map((data) => {
      const tempPayload = {
        documentNo: data.documentNo,
        phaseNumber: data.phaseNumber,
        userId: userSelected.value,
        pickupListCode: data.pickupListCode,
        srNo: data.srNo,
        materialCode: data.materialCode,
        qty: data.qty,
      };

      payload.push(tempPayload);
    });

    let res = await createRelease(payload, jwtToken);

    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessAddUser(true);
      setAddUserSelected("");
      getReleasePicklist(pageNo, rowsPerPage, searchObject, jwtToken);
      resetForm();

      setTimeout(() => {
        handleCloseAddUserModal();
        setButtonName("Release");
      }, 2250);
    }
    if (!res.status) {
      setReleaseErrorMsg(res.data.data.message);
      setIsErrorAddUser(true);
    }
  };

  const formikForAddUser = useFormik({
    initialValues: initialValueForAddUser,
    validationSchema: validationSchemaAddUser,
    onSubmit: onSubmitForAddUser,
  });

  const [removeErrorMsg, setRemoveErrorMsg] = useState("");

  const removeRecordsHandler = async () => {
    let payload = [];

    selectedReleasePicklistData.map((data) => {
      const tempPayload = {
        documentNo: data.documentNo,
        phaseNumber: data.phaseNumber,
        srNo: data.srNo,
      };

      payload.push(tempPayload);
    });

    let res = await removeReleasePicklist(payload, jwtToken);

    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessRemoveRecords(true);
      getReleasePicklist(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleCloseRemoveRecordsModal();
      }, 2250);
    }
    if (!res.status) {
      setRemoveErrorMsg(res.data.data.message);
      setIsErrorRemoveRecords(true);
    }
  };

  return (
    <div>
      <MDBox mt={-6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mt={-2}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  errorMsg={errorMsgFromApi}
                  options={{ draggable: false }}
                  uncheckProp={uncheck}
                  selection={true}
                  selectionButtonClick={releasePicklistDataSelectionHandler}
                  selectionButtonComponent={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <span>
                        <Tooltip title="Remove">
                          <MDButton
                            type="button"
                            color="white"
                            style={{
                              marginRight: "15px",
                              color: "white",
                              border: "1px solid #ef5350",
                              background: "#ef5350",
                            }}
                            onClick={() => {
                              handleOpenRemoveRecordsModal();
                            }}
                            disabled={!removeAccess}
                          >
                            <CloseOutlinedIcon fontSize="large" /> &nbsp; Remove
                          </MDButton>
                        </Tooltip>
                        <Tooltip title="Release">
                          <MDButton
                            type="button"
                            variant="gradient"
                            color="warning"
                            style={{ float: "right" }}
                            onClick={() => {
                              handleOpenAddUserModal();
                              getUsersToAssign(jwtToken);
                            }}
                            disabled={!releaseAccess}
                          >
                            Release
                          </MDButton>
                        </Tooltip>
                      </span>
                    </span>
                  }
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>

              <Modal
                open={openAddUserModal}
                onClose={handleCloseAddUserModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Add User & Release
                    </MDTypography>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseAddUserModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForAddUser.handleSubmit}
                    >
                      <MDBox>
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Select User
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            placeholder="Select user..."
                            name="user"
                            value={userSelected}
                            options={usersOptions}
                            onChange={(selectedOption) => {
                              handleAddUserChange(selectedOption);
                              formikForAddUser.setFieldValue(
                                "user",
                                selectedOption
                              );
                            }}
                          />
                          {formikForAddUser.errors.user && (
                            <TextError msg={formikForAddUser.errors.user} />
                          )}
                        </MDBox>

                        <MDBox mt={2}>
                          <Collapse in={isSuccessAddUser}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessAddUser(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              User added and picklist released successfully!
                            </Alert>
                          </Collapse>
                          <Collapse in={isErrorAddUser}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorAddUser(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {/* Could not Add the User and release! */}
                              {releaseErrorMsg}
                            </Alert>
                          </Collapse>
                        </MDBox>

                        <div className="mt-3">
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="warning"
                            fullWidth
                            disabled={
                              !formikForAddUser.isValid ||
                              buttonName === "Please wait..."
                            }
                          >
                            {buttonName}
                          </MDButton>
                        </div>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={openRemoveRecordsModal}
                onClose={handleCloseRemoveRecordsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>
                    Are you sure you want to Remove the Selected Records?
                  </MDTypography>
                  <MDBox mt={3} display="flex">
                    <div>
                      <MDButton
                        style={{ width: "100%", marginRight: "0.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseRemoveRecordsModal}
                      >
                        Cancel
                      </MDButton>
                    </div>
                    <div style={{ float: "right" }}>
                      <MDButton
                        style={{ width: "100%", marginLeft: "0.5rem" }}
                        color="error"
                        onClick={removeRecordsHandler}
                      >
                        Remove
                      </MDButton>
                    </div>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessRemoveRecords}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessRemoveRecords(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Removed successfully!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorRemoveRecords}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorRemoveRecords(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {/* Could not Remove! */}
                        {removeErrorMsg}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    releasePicklistData: state.getReleasePicklistReducer,
    usersData: state.getUsersToAssignReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getReleasePicklist: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getReleasePicklist(pageNo, rowsPerPage, searchObject, jwtToken)),
    getUsersToAssign: (jwtToken) => dispatch(getUsersToAssign(jwtToken)),
    createRelease: (payload, jwtToken) =>
      dispatch(createRelease(payload, jwtToken)),
    removeReleasePicklist: (payload, jwtToken) =>
      dispatch(removeReleasePicklist(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReleasePicklist);
