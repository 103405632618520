/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";

// Functions from store
import {
  createPrinter,
  deletePrinter,
  getPrinters,
  updatePrinter,
  getSubModulesByRoleId,
} from "../../store";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const PrinterOnboarding = ({
  createPrinter,
  getPrinters,
  printersReducer,
  deletePrinter,
  updatePrinter,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let userId = loginDetails?.data.userId;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let printerModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "cb7d647c-b348-4688-bc53-77f42197d67f"
  );

  let createAccess = printerModule
    ? printerModule.actionId.includes("a11e2d09-9b83-43fc-9794-73f4366a3cda")
    : null;

  let viewAccess = printerModule
    ? printerModule.actionId.includes("14779810-10bc-4eaa-874c-d4c94a7740c8")
    : null;

  let updateAccess = printerModule
    ? printerModule.actionId.includes("a0db6ef8-35b7-443a-a9b9-3afc24a4290b")
    : null;

  let deleteAccess = printerModule
    ? printerModule.actionId.includes("4ab44034-fcc3-4823-a173-6a68c8c65e37")
    : null;

  useEffect(() => {
    getPrinters(jwtToken);
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Location", accessor: "location", align: "left" },
      { Header: "IP Address", accessor: "ip", align: "left" },
      { Header: "Port", accessor: "port", align: "left" },
      {
        Header: "Actions",
        accessor: "actions",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [printerData, setPrinterData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  useEffect(() => {
    let printersTemp = [];
    let data = printersReducer.printers.data
      ? printersReducer.printers.data
      : [];

    setErrorMsgFromApi(printersReducer?.error?.data?.msg);
    setLoading(printersReducer.loading);

    data.map((printer) => {
      const printerObject = {
        name: printer.name,
        location: printer.location,
        ip: printer.ip,
        port: printer.port,
        actions: (
          <div>
            <Tooltip title="Edit Printer">
              <MDButton
                disabled={!updateAccess}
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => handleUpdatePrinterModalOpen(printer)}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </MDButton>
            </Tooltip>

            <Tooltip title="Delete Printer">
              <MDButton
                disabled={!deleteAccess}
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                onClick={() => handleDeletePrinterModalOpen(printer)}
              >
                <Icon>delete</Icon>
              </MDButton>
            </Tooltip>
          </div>
        ),
      };
      printersTemp.push(printerObject);
    });
    viewAccess && setState({ ...state, rows: printersTemp });
  }, [printersReducer]);

  const [createPrinterModal, setCreatePrinterModal] = useState(false);
  const handleCreatePrinterModalOpen = () => {
    setIsSuccess(false);
    setIsError(false);
    setCreatePrinterModal(true);
  };

  const handleCreatePrinterModalClose = () => {
    formik.setErrors({});
    setIsSuccess(false);
    setIsError(false);
    setCreatePrinterModal(false);
  };

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }
  }, [isSuccess]);

  const initialValues = {
    name: "",
    location: "",
    ip: "",
    port: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Enter the printer name!"),
    location: Yup.string().required("Enter the location!"),
    ip: Yup.string().required("Enter the IP!"),
    port: Yup.string().required("Enter the port!"),
  });

  const [createErrorMsg, setCreateErrorMsg] = useState("");

  const onSubmit = async (values, { resetForm }) => {
    let res = await createPrinter(values, jwtToken);
    if (res.status) {
      setIsSuccess(true);
      getPrinters(jwtToken);
      resetForm();
    }
    if (!res.status) {
      setCreateErrorMsg(res.data.data.message);
      setIsError(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [updatePrinterModal, setUpdatePrinterModal] = useState(false);
  const handleUpdatePrinterModalOpen = (printer) => {
    setIsSuccess(false);
    setIsError(false);
    setUpdatePrinterModal(true);
    setPrinterData(printer);
  };

  const handleUpdatePrinterModalClose = () => {
    formikForUpdate.setErrors({});
    setIsSuccess(false);
    setIsError(false);
    setUpdatePrinterModal(false);
    setPrinterData({});
  };

  const initialValueForUpdate = {
    name: printerData.name ? printerData.name : "",
    location: printerData.location ? printerData.location : "",
    ip: printerData.ip ? printerData.ip : "",
    port: printerData.port ? printerData.port : "",
  };

  const validationSchemaForUpdate = Yup.object({
    name: Yup.string().required("Enter the printer name!"),
    location: Yup.string().required("Enter the location!"),
    ip: Yup.string().required("Enter the IP!"),
    port: Yup.string().required("Enter the port!"),
  });

  const [updateErrorMsg, setUpdateErrorMsg] = useState("");

  const onSubmitForUpdate = async (values) => {
    values.id = printerData.id;
    values.printerId = printerData.printerId;
    let res = await updatePrinter(values, jwtToken);
    if (res.status) {
      setIsSuccess(true);
      getPrinters(jwtToken);
    }
    if (!res.status) {
      setUpdateErrorMsg(res.data.data.message);
      setIsError(true);
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const [deletPrinterModal, setDeletePrinterModal] = useState(false);
  const handleDeletePrinterModalOpen = (printer) => {
    setDeletePrinterModal(true);
    setPrinterData(printer);
  };

  const handleDeletePrinterModalClose = () => {
    setDeletePrinterModal(false);
    setPrinterData({});
  };

  const [isErrorDeletePrinter, setIsErrorDeletePrinter] = useState(false);
  const [isSuccessDeletePrinter, setIsSuccessDeletePrinter] = useState(false);

  useEffect(() => {
    if (isErrorDeletePrinter) {
      setTimeout(() => {
        setIsErrorDeletePrinter(false);
      }, 3000);
    }
  }, [isErrorDeletePrinter]);

  useEffect(() => {
    if (isSuccessDeletePrinter) {
      setTimeout(() => {
        setIsSuccessDeletePrinter(false);
      }, 3000);
    }
  }, [isSuccessDeletePrinter]);

  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");

  const deletePrinterData = async () => {
    let printerId = printerData.printerId;
    let response = await deletePrinter(printerId, jwtToken);
    if (response.status) {
      getPrinters(jwtToken);
      handleDeletePrinterModalClose();
    }
    if (!response.status) {
      setDeleteErrorMsg(response.data.data.message);
      setIsErrorDeletePrinter(true);
    }
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                {createAccess && (
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                    // mb={-6}
                  >
                    <MDBox color="text" px={2} title="Add User">
                      <MDButton
                        color="info"
                        variant="outlined"
                        type="button"
                        onClick={handleCreatePrinterModalOpen}
                      >
                        Add Printer
                      </MDButton>
                    </MDBox>
                  </MDBox>
                )}

                <MDBox mt={createAccess ? -3 : 0}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 30,
                      entries: [30, 50, 75, 100],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    errorMsg={errorMsgFromApi}
                  />
                </MDBox>
                <Modal
                  open={createPrinterModal}
                  onClose={handleCreatePrinterModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Create Printer
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCreatePrinterModalClose}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formik.handleSubmit}
                      >
                        <MDBox mb={2}>
                          <MDTypography variant="h6">Name</MDTypography>
                          <MDInput
                            type="text"
                            name="name"
                            fullWidth
                            autoComplete="off"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            error={
                              formik.touched.name && formik.errors.name && true
                            }
                          />
                          {formik.touched.name && formik.errors.name && (
                            <TextError msg={formik.errors.name} />
                          )}
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">Location</MDTypography>
                          <MDInput
                            type="text"
                            name="location"
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.location}
                            error={
                              formik.touched.location &&
                              formik.errors.location &&
                              true
                            }
                          />
                          {formik.touched.location &&
                            formik.errors.location && (
                              <TextError msg={formik.errors.location} />
                            )}
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">IP Address</MDTypography>
                          <MDInput
                            type="text"
                            name="ip"
                            fullWidth
                            autoComplete="off"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.ip}
                            error={
                              formik.touched.ip && formik.errors.ip && true
                            }
                          />
                          {formik.touched.ip && formik.errors.ip && (
                            <TextError msg={formik.errors.ip} />
                          )}
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">Port</MDTypography>
                          <MDInput
                            type="text"
                            name="port"
                            fullWidth
                            autoComplete="off"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.port}
                            error={
                              formik.touched.port && formik.errors.port && true
                            }
                          />
                          {formik.touched.port && formik.errors.port && (
                            <TextError msg={formik.errors.port} />
                          )}
                        </MDBox>
                        <MDBox>
                          <Collapse in={isError}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsError(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {/* Please check your printer details. Server error! */}
                              {createErrorMsg}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccess}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccess(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Printer Created successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={3}>
                          <MDButton
                            color="info"
                            fullWidth
                            type="submit"
                            disabled={!formik.isValid}
                          >
                            Create Printer
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={updatePrinterModal}
                  onClose={handleUpdatePrinterModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Update Printer
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleUpdatePrinterModalClose}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForUpdate.handleSubmit}
                      >
                        <MDBox mb={2}>
                          <MDTypography variant="h6">Name</MDTypography>
                          <MDInput
                            type="text"
                            name="name"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForUpdate.handleBlur}
                            onChange={formikForUpdate.handleChange}
                            value={formikForUpdate.values.name}
                            error={
                              formikForUpdate.touched.name &&
                              formikForUpdate.errors.name &&
                              true
                            }
                          />
                          {formikForUpdate.touched.name &&
                            formikForUpdate.errors.name && (
                              <TextError msg={formikForUpdate.errors.name} />
                            )}
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">Location</MDTypography>
                          <MDInput
                            type="text"
                            name="location"
                            fullWidth
                            autoComplete="off"
                            onChange={formikForUpdate.handleChange}
                            onBlur={formikForUpdate.handleBlur}
                            value={formikForUpdate.values.location}
                            error={
                              formikForUpdate.touched.location &&
                              formikForUpdate.errors.location &&
                              true
                            }
                          />
                          {formikForUpdate.touched.location &&
                            formikForUpdate.errors.location && (
                              <TextError
                                msg={formikForUpdate.errors.location}
                              />
                            )}
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">IP Address</MDTypography>
                          <MDInput
                            type="text"
                            name="ip"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForUpdate.handleBlur}
                            onChange={formikForUpdate.handleChange}
                            value={formikForUpdate.values.ip}
                            error={
                              formikForUpdate.touched.ip &&
                              formikForUpdate.errors.ip &&
                              true
                            }
                          />
                          {formikForUpdate.touched.ip &&
                            formikForUpdate.errors.ip && (
                              <TextError msg={formikForUpdate.errors.ip} />
                            )}
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">Port</MDTypography>
                          <MDInput
                            type="text"
                            name="port"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForUpdate.handleBlur}
                            onChange={formikForUpdate.handleChange}
                            value={formikForUpdate.values.port}
                            error={
                              formikForUpdate.touched.port &&
                              formikForUpdate.errors.port &&
                              true
                            }
                          />
                          {formikForUpdate.touched.port &&
                            formikForUpdate.errors.port && (
                              <TextError msg={formikForUpdate.errors.port} />
                            )}
                        </MDBox>
                        <MDBox>
                          <Collapse in={isError}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsError(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {/* Please check your printer details. Server error! */}
                              {updateErrorMsg}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccess}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccess(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Printer updated successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={3}>
                          <MDButton
                            variant="contained"
                            color="success"
                            fullWidth
                            type="submit"
                            disabled={!formikForUpdate.isValid}
                          >
                            Update Printer
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={deletPrinterModal}
                  onClose={handleDeletePrinterModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={deleteStyle}>
                    <MDTypography>
                      Are you sure you want to delete?
                    </MDTypography>
                    <MDBox mt={2} display="flex">
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "0.5rem" }}
                          color="info"
                          variant="outlined"
                          onClick={handleDeletePrinterModalClose}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div style={{ float: "right" }}>
                        <MDButton
                          style={{ width: "100%", marginLeft: "0.5rem" }}
                          color="error"
                          onClick={deletePrinterData}
                        >
                          Delete
                        </MDButton>
                      </div>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessDeletePrinter}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessDeletePrinter(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Deleted successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorDeletePrinter}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorDeletePrinter(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {deleteErrorMsg}
                          {/* Could not change the status of the Printer! */}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {loading && (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    printersReducer: state.printersReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    createPrinter: (payload, jwtToken) =>
      dispatch(createPrinter(payload, jwtToken)),
    getPrinters: (jwtToken) => dispatch(getPrinters(jwtToken)),
    deletePrinter: (id, jwtToken) => dispatch(deletePrinter(id, jwtToken)),
    updatePrinter: (payload, id, jwtToken) =>
      dispatch(updatePrinter(payload, id, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrinterOnboarding);
