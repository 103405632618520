/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import { getSkuForReprint, reprint, getSubModulesByRoleId } from "../../store";

// Router Components
import { useNavigate } from "react-router-dom";

// Config
import Config from "../../config/index";
import { addMinutes, format } from "date-fns";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RePrint = ({
  getSkuForReprint,
  skuForReprint,
  reprint,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  const desLength = Config.descriptionLength;

  let loginDetails = cookies.get("loginDetailsForWMS");
  let printerConfig = cookies.get("printerConfigForWarehouse");
  let printerId = cookies.get("printerIdForWarehouse");

  const navigate = useNavigate();

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userName = loginDetails.data.userName;
  let userId = loginDetails.data.userId;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let materialReturnOrderModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "9395c0e6-20a6-472b-8c90-45ed2a0eba9d"
  );

  let viewSkuAccess = materialReturnOrderModule
    ? materialReturnOrderModule.actionId.includes(
        "02018b06-fb36-4665-a404-7082c007d275"
      )
    : null;

  let reprintSkuAccess = materialReturnOrderModule
    ? materialReturnOrderModule.actionId.includes(
        "6ee1ba65-11de-4c30-bd06-394c8523c329"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getSkuForReprint(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [state, setState] = useState({
    columns: [
      { Header: "Pallet Id", accessor: "palletId", align: "left" },
      { Header: "UID", accessor: "sku", align: "left" },
      { Header: "GRN No", accessor: "grnNumber", align: "left" },
      { Header: "Batch", accessor: "batchNumber", align: "left" },
      { Header: "Inv Qty", accessor: "invoiceQty", align: "left" },
      { Header: "Actual Qty", accessor: "actualQty", align: "left" },
      {
        Header: "Material Code / Des",
        accessor: "materialCode",
        align: "left",
      },
      { Header: "PO No", accessor: "asnNumber", align: "left" },
      { Header: "Inv No", accessor: "invoiceNumber", align: "left" },
      { Header: "GRN Date", accessor: "grnDate", align: "left" },
      {
        Header: "Vendor Code / Name",
        accessor: "vendorCode",
        align: "left",
      },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  useEffect(() => {
    let tempSkus = [];
    let data = skuForReprint.sku.data ? skuForReprint.sku.data : [];

    setLoading(skuForReprint.loading);
    setPaginationObj(skuForReprint?.sku?.pagination);

    data.map((sku) => {
      const formatDate = sku.grnDate ? new Date(sku.grnDate) : "";

      let des = sku?.materialDescription;
      let len = desLength;
      const object = {
        privateKey: sku.grnNumber,
        sku: sku.sku,
        palletId: sku.palletId,
        grnNumber: sku.grnNumber,
        batchNumber: sku.batchNumber,
        materialCode: `${sku.materialCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        invoiceQty: `${sku.invoiceQty} ${sku.uom}`,
        actualQty: `${sku.actualQty} ${sku.uom}`,
        invoiceNumber: sku.invoiceNumber,
        asnNumber: sku.asnNumber,
        vendorCode: `${sku.vendorCode} / ${sku.vendorName}`,
        grnDate: sku.grnDate
          ? format(
              addMinutes(formatDate, formatDate.getTimezoneOffset()),
              "do MMMM yyyy"
            )
          : "",
        action: (
          <Tooltip title="Reprint">
            <MDButton
              disabled={!reprintSkuAccess}
              iconOnly
              variant="gradient"
              color="success"
              onClick={() => {
                if (printerConfig) {
                  handleOpenReprintModal(sku);
                }
                if (!printerConfig) {
                  navigate("/printer-configurator");
                }
              }}
            >
              <Icon> print </Icon>
            </MDButton>
          </Tooltip>
        ),
      };
      tempSkus.push(object);
    });

    viewSkuAccess && setState({ ...state, rows: tempSkus });
  }, [skuForReprint]);

  const [isErrorReprint, setIsErrorReprint] = useState(false);
  const [isSuccessReprint, setIsSuccessReprint] = useState(false);

  useEffect(() => {
    if (isErrorReprint) {
      setTimeout(() => {
        setIsErrorReprint(false);
      }, 3000);
    }
  }, [isErrorReprint]);

  useEffect(() => {
    if (isSuccessReprint) {
      setTimeout(() => {
        setIsSuccessReprint(false);
      }, 3000);
    }
  }, [isSuccessReprint]);

  const [reprintData, setReprintData] = useState({});
  const [openReprintModal, setOpenReprintModal] = useState(false);

  const handleOpenReprintModal = (data = {}) => {
    setReprintData(data);
    setOpenReprintModal(true);
  };

  const handleCloseReprintModal = () => {
    setOpenReprintModal(false);
  };

  const [reprintErrorMsg, setReprintErrorMsg] = useState("");
  const [buttonName, setButtonName] = useState("Yes");
  const onClickRePrint = async () => {
    setButtonName("Please wait...");
    const payload = {
      ...reprintData,
      userId,
      userName,
      printerId,
    };

    let res = await reprint(payload, jwtToken);

    if (res.status) {
      setIsSuccessReprint(true);
      getSkuForReprint(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        setOpenReprintModal(false);
        setButtonName("Yes");
      }, 2250);
    }
    if (!res.status) {
      setReprintErrorMsg(res.data.data.message);
      setIsErrorReprint(true);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>

              <Modal
                open={openReprintModal}
                onClose={handleCloseReprintModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDTypography>
                    Are you sure you want to reprint ?
                  </MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseReprintModal}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{ width: "48%", marginLeft: "0.5rem" }}
                      color="success"
                      onClick={onClickRePrint}
                      disabled={buttonName === "Please wait..."}
                    >
                      {buttonName}
                    </MDButton>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessReprint}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessReprint(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Reprint successful!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorReprint}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorReprint(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {/* Could not reprint */}
                        {reprintErrorMsg}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    skuForReprint: state.skuForReprint,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getSkuForReprint: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getSkuForReprint(pageNo, rowsPerPage, searchObject, jwtToken)),
    reprint: (payload, jwtToken) => dispatch(reprint(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(RePrint);
