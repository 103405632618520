/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_MODULES_BY_ROLE_ID_REQUEST,
  GET_MODULES_BY_ROLE_ID_SUCCESS,
  GET_MODULES_BY_ROLE_ID_FAILURE,
  GET_SUB_MODULES_BY_ROLE_ID_REQUEST,
  GET_SUB_MODULES_BY_ROLE_ID_SUCCESS,
  GET_SUB_MODULES_BY_ROLE_ID_FAILURE,
} from "./userModulesTypes.js";
import axios from "axios";
import Config from "../../config/index";
let userManagementUrl = Config.userManagementUrl;

const getModulesByRoleIdRequest = () => {
  return {
    type: GET_MODULES_BY_ROLE_ID_REQUEST,
  };
};

const getModulesByRoleIdSuccess = (payload) => {
  return {
    type: GET_MODULES_BY_ROLE_ID_SUCCESS,
    payload: payload,
  };
};

const getModulesByRoleIdFaliure = (error) => {
  return {
    type: GET_MODULES_BY_ROLE_ID_FAILURE,
    payload: error,
  };
};

const getModulesByRoleId = (roleId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getModulesByRoleIdRequest());
    let url = `${userManagementUrl}/user/get_access_modules?roleId=${roleId}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getModulesByRoleIdSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getModulesByRoleIdFaliure(err.message));
        return { status: false };
      });
  };
};

const getSubModulesByRoleIdRequest = () => {
  return {
    type: GET_SUB_MODULES_BY_ROLE_ID_REQUEST,
  };
};

const getSubModulesByRoleIdSuccess = (payload) => {
  return {
    type: GET_SUB_MODULES_BY_ROLE_ID_SUCCESS,
    payload: payload,
  };
};

const getSubModulesByRoleIdFaliure = (error) => {
  return {
    type: GET_SUB_MODULES_BY_ROLE_ID_FAILURE,
    payload: error,
  };
};

const getSubModulesByRoleId = (roleId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSubModulesByRoleIdRequest());
    let url = `${userManagementUrl}/user/get_sub_modules?roleId=${roleId}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSubModulesByRoleIdSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getSubModulesByRoleIdFaliure(err.message));
        return { status: false };
      });
  };
};

export { getModulesByRoleId, getSubModulesByRoleId };
