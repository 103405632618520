import {
  GET_PIVTRACKING_REQUEST,
  GET_PIVTRACKING_FAILURE,
  GET_PIVTRACKING_SUCCESS,
  GET_STORE_LOCATION_REQUEST,
  GET_STORE_LOCATION_SUCCESS,
  GET_STORE_LOCATION_FAILURE,
  GET_PIVTRACKING_FORREPORTS_REQUEST,
  GET_PIVTRACKING_FORREPORTS_SUCCESS,
  GET_PIVTRACKING_FORREPORTS_FAILURE,
} from "./pivTrackingTypes";

const initialStateForPivTracking = {
  loading: false,
  piv: [],
  error: "",
};

const getPivTrackingReducer = (state = initialStateForPivTracking, action) => {
  switch (action.type) {
    case GET_PIVTRACKING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PIVTRACKING_SUCCESS:
      return {
        loading: false,
        piv: action.payload,
        error: "",
      };
    case GET_PIVTRACKING_FAILURE:
      return {
        loading: false,
        piv: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForStoreLocationByMaterialCode = {
  loading: false,
  storeLocationData: [],
  error: "",
};

const getStoreLocationByMaterialCodeReducer = (
  state = initialStateForStoreLocationByMaterialCode,
  action
) => {
  switch (action.type) {
    case GET_STORE_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_STORE_LOCATION_SUCCESS:
      return {
        loading: false,
        storeLocationData: action.payload,
        error: "",
      };
    case GET_STORE_LOCATION_FAILURE:
      return {
        loading: false,
        storeLocationData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForReports = {
  loading: false,
  piv: [],
  error: "",
};

const getPivTrackingForReportsReducer = (
  state = initialStateForReports,
  action
) => {
  switch (action.type) {
    case GET_PIVTRACKING_FORREPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PIVTRACKING_FORREPORTS_SUCCESS:
      return {
        loading: false,
        piv: action.payload,
        error: "",
      };
    case GET_PIVTRACKING_FORREPORTS_FAILURE:
      return {
        loading: false,
        piv: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getPivTrackingReducer,
  getStoreLocationByMaterialCodeReducer,
  getPivTrackingForReportsReducer,
};
