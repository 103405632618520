import {
  GET_PICKUPLISTWISECHARGING_REQUEST,
  GET_PICKUPLISTWISECHARGING_SUCCESS,
  GET_PICKUPLISTWISECHARGING_FAILURE,
  DOWNLOAD_PICKUPLISTWISECHARGING_REQUEST,
  DOWNLOAD_PICKUPLISTWISECHARGING_SUCCESS,
  DOWNLOAD_PICKUPLISTWISECHARGING_FAILURE,
} from "./pickuplistWiseChargingTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getPickuplistWiseChargingRequest = () => {
  return {
    type: GET_PICKUPLISTWISECHARGING_REQUEST,
  };
};

const getPickuplistWiseChargingSuccess = (payload) => {
  return {
    type: GET_PICKUPLISTWISECHARGING_SUCCESS,
    payload: payload,
  };
};

const getPickuplistWiseChargingFailure = (error) => {
  return {
    type: GET_PICKUPLISTWISECHARGING_FAILURE,
    payload: error,
  };
};

const getPickuplistWiseCharging = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPickuplistWiseChargingRequest());
    let url = `${baseUrl}/reports/pickist_wise_charging_report?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}&download=0`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPickuplistWiseChargingSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPickuplistWiseChargingFailure(err.message));
      });
  };
};

const downloadPickuplistWiseChargingRequest = () => {
  return {
    type: DOWNLOAD_PICKUPLISTWISECHARGING_REQUEST,
  };
};

const downloadPickuplistWiseChargingSuccess = (payload) => {
  return {
    type: DOWNLOAD_PICKUPLISTWISECHARGING_SUCCESS,
    payload: payload,
  };
};

const downloadPickuplistWiseChargingFailure = (error) => {
  return {
    type: DOWNLOAD_PICKUPLISTWISECHARGING_FAILURE,
    payload: error,
  };
};

const downloadPickuplistWiseCharging = (
  pageNo,
  rowsPerPage,
  search,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(downloadPickuplistWiseChargingRequest());
    let url = `${baseUrl}/reports/pickist_wise_charging_report?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}&download=1`;

    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(downloadPickuplistWiseChargingSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(downloadPickuplistWiseChargingFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export { getPickuplistWiseCharging, downloadPickuplistWiseCharging };
